import { AssessmentAction } from './types';

import { AssessmentState } from './types';

import { FETCH_ASSESSMENT_RESOURCES_SUCCESS } from './types';

const initialState: AssessmentState = {
  administration: [],
  benchmarkAssessments: [],
  observationChecklists: [],
  implementationResources: [],
  overview: {
    id: 0,
    type: '',
    mediaType: '',
    url: '',
    title: '',
    aspectRatio: '',
    heightAdjust: 125,
    fileExtension: '',
    resourceTypeId: 0
  }
};

const assessment = (
  state: AssessmentState = initialState,
  action: AssessmentAction
): AssessmentState => {
  switch (action.type) {
    case FETCH_ASSESSMENT_RESOURCES_SUCCESS: {
      return {
        ...action.assessmentResources
      };
    }
    default:
      return state;
  }
};

export default assessment;
