import React from 'react';
import { Box, Button } from '@mui/material';
import { remCalc } from 'util/style-util';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const t = key => translate('copyright', key);

const getWorkbookTourModalCSS = position => {
  const caratPositionY =
    position?.orientation === 'above'
      ? {
          bottom: -15,
          borderTop: '20px solid white'
        }
      : {
          top: -15,
          borderBottom: '20px solid white'
        };

  return {
    position: 'absolute',
    ...(position?.offset ?? {}),
    '&:after': {
      content: "''",
      position: 'absolute',
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      width: 0,
      transform: 'translateX(20px)',
      right: `${position?.caratPos}%`,
      ...caratPositionY
    }
  } as any;
};

const WorkbookTourModal: React.FC<{
  step: { text: string; position: Record<string, unknown> };
  handleNextStep: () => void;
}> = ({ step, handleNextStep }) => {
  const { text, position } = step;
  return (
    <Box sx={{ ...styles.container, ...getWorkbookTourModalCSS(position) }}>
      {text && <Box component="span" dangerouslySetInnerHTML={{ __html: text }} />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box
          sx={{
            marginLeft: 'auto'
          }}
        >
          <Button
            variant="contained"
            style={styles.button}
            onClick={handleNextStep}
            aria-label={t('gotIt')}
          >
            {t('gotIt')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    fontFamily: 'GSS Futura',
    fontWeight: '300',
    boxSizing: 'border-box',
    width: 'max-content',
    maxWidth: remCalc(600),
    borderRadius: remCalc(16),
    backgroundColor: 'white',
    padding: '10px 25px',
    display: 'flex',
    flexDirection: 'column'
  } as any,
  button: {
    marginBottom: 0,
    marginTop: remCalc(30),
    padding: '10px 45px',
    fontFamily: 'GSS Futura',
    fontSize: '16px',
    textTransform: 'none'
  } as any
};

export default WorkbookTourModal;
