import React from 'react';
import { Grid, Paper, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const HomeGridCard = ({ children, xs = 12, gridStyles }: any) => {
  const classes = useStyles();

  return (
    <Grid item xs={xs} className={classes.grid} style={gridStyles}>
      <Paper elevation={3} className={classes.cardSection}>
        <Box display="flex" flexDirection="column-reverse" flex={1} m={2}>
          {children}
        </Box>
      </Paper>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  cardSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flex: 1,
    margin: '10px 0px'
  },
  cardRow: {
    margin: 10
  },
  grid: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default HomeGridCard;
