import React from 'react';
import { useFullScreenHandle, FullScreenHandle } from 'react-full-screen';

export type OpenFullScreenPayload = {
  isFullScreen: boolean;
  isLayeredElementFullScreen: boolean;
};

type ComputedState = {
  screen1Handle: FullScreenHandle;
  screen2Handle: FullScreenHandle;
};

export type UseFullScreenState = OpenFullScreenPayload & ComputedState;

type Action =
  | { type: 'SET_FULLSCREEN'; payload: boolean }
  | { type: 'SET_IS_LAYERED_ELEMENT_FULL_SCREEN'; payload: boolean };

export type UseFullScreenDispatch = {
  setFullScreen: (payload: boolean) => void;
  setIsLayeredElementFullScreen: (payload: boolean) => void;
};

type UseFullScreen = [UseFullScreenState, UseFullScreenDispatch];

export const getDefaultFullScreenPayload = (): OpenFullScreenPayload => ({
  isFullScreen: false,
  isLayeredElementFullScreen: false
});

const fullScreenReducer = (state: OpenFullScreenPayload, action: Action): OpenFullScreenPayload => {
  switch (action.type) {
    case 'SET_FULLSCREEN':
      return {
        ...state,
        isFullScreen: action.payload
      };
    case 'SET_IS_LAYERED_ELEMENT_FULL_SCREEN':
      return {
        ...state,
        isLayeredElementFullScreen: action.payload
      };
    default:
      return getDefaultFullScreenPayload();
  }
};

const FullScreenContext = React.createContext<any>(getDefaultFullScreenPayload());

export const FullScreenProvider = props => {
  const [state, dispatch] = React.useReducer<React.Reducer<OpenFullScreenPayload, Action>>(
    fullScreenReducer,
    getDefaultFullScreenPayload()
  );

  const screen1Handle = useFullScreenHandle();
  const screen2Handle = useFullScreenHandle();

  const setFullScreen = (payload: boolean) => {
    dispatch({ type: 'SET_FULLSCREEN', payload });
  };

  const setIsLayeredElementFullScreen = (payload: boolean) => {
    dispatch({ type: 'SET_IS_LAYERED_ELEMENT_FULL_SCREEN', payload });
  };

  return (
    <FullScreenContext.Provider
      value={[
        { ...state, screen1Handle, screen2Handle },
        { setFullScreen, setIsLayeredElementFullScreen }
      ]}
    >
      {props.children}
    </FullScreenContext.Provider>
  );
};

export const useFullScreen = (): UseFullScreen => {
  const [state, dispatch] = React.useContext<UseFullScreen>(FullScreenContext);
  return [state, dispatch];
};
