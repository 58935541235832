import type { FtueTour, ViewedTour, TourStep } from 'types';
import type { LOGIN_SUCCESS_ACTION } from '../auth';
import PreKittApi from '../../api';

export type FtueState = {
  allTours: { [name: string]: FtueTour };
  queuedTours: string[];
  toursViewed: ViewedTour;
  currentStep: number;
};

export const FETCH_TOURS = 'FETCH_TOURS';
export const FETCH_TOURS_SUCCESS = 'FETCH_TOURS_SUCCESS';
export const FETCH_TOURS_FAILURE = 'FETCH_TOURS_FAILURE';
export type FETCH_TOURS_ACTION = { type: typeof FETCH_TOURS };
export type FETCH_TOURS_SUCCESS_ACTION = { type: typeof FETCH_TOURS_SUCCESS; tours: FtueState };
export type FETCH_TOURS_FAILURE_ACTION = { type: typeof FETCH_TOURS_FAILURE; error: Error };

export const SAVING_COMPLETED_TOUR = 'SAVING_COMPLETED_TOUR';
export const SAVE_COMPLETED_TOUR_SUCCESS = 'SAVE_COMPLETED_TOUR_SUCCESS';
export const SAVE_COMPLETED_TOUR_FAILURE = 'SAVE_COMPLETED_TOUR_FAILURE';
export type SAVING_COMPLETED_TOUR_ACTION = { type: typeof SAVING_COMPLETED_TOUR };
export type SAVE_COMPLETED_TOUR_SUCCESS_ACTION = { type: typeof SAVE_COMPLETED_TOUR_SUCCESS };
export type SAVE_COMPLETED_TOUR_FAILURE_ACTION = { type: typeof SAVE_COMPLETED_TOUR_FAILURE };

export const QUEUE_TOUR = 'QUEUE_TOUR';
export type QUEUE_TOUR_ACTION = { type: typeof QUEUE_TOUR; name: string };

export const DEQUEUE_TOUR = 'DEQUEUE_TOUR';
export type DEQUEUE_TOUR_ACTION = { type: typeof DEQUEUE_TOUR; names: string[] };

export const PRIORITIZE_TOUR = 'PRIORITIZE_TOUR';
export type PRIORITIZE_TOUR_ACTION = { type: typeof PRIORITIZE_TOUR; name: string };

export const INCREMENT_CURRENT_STEP = 'INCREMENT_CURRENT_STEP';
export type INCREMENT_CURRENT_STEP_ACTION = { type: typeof INCREMENT_CURRENT_STEP; step: TourStep };

export const COMPLETE_CURRENT_TOUR = 'COMPLETE_CURRENT_TOUR';
export type COMPLETE_CURRENT_TOUR_ACTION = { type: typeof COMPLETE_CURRENT_TOUR };

export type FtueAction =
  | FETCH_TOURS_ACTION
  | FETCH_TOURS_SUCCESS_ACTION
  | FETCH_TOURS_FAILURE_ACTION
  | INCREMENT_CURRENT_STEP_ACTION
  | QUEUE_TOUR_ACTION
  | DEQUEUE_TOUR_ACTION
  | PRIORITIZE_TOUR_ACTION
  | COMPLETE_CURRENT_TOUR_ACTION
  | LOGIN_SUCCESS_ACTION;

/* eslint-disable no-use-before-define */
export type GetState = () => { ftue: FtueState };
type PromiseAction = Promise<FtueAction>;
export type Dispatch = (
  action: FtueAction | ThunkAction | PromiseAction | Array<FtueAction>
) => any;
export type ThunkAction = (dispatch: Dispatch, getState: GetState, api: typeof PreKittApi) => any;
