import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import CharacterGrid from '../Resources/CharacterGrid/CharacterGrid';
import CheckBox from '../core/CheckBox/CheckBox';
import FilterDropDown from '../core/Dropdowns/FilterDropdown';
import {
  toggleTag,
  clearResourceFilters,
  setResourceFilters
} from '../../actions/resource-actions';
import { InputAdornment, TextField } from '@mui/material';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { fetchFilterTags } from '../../actions/resource-operations';
import { FilterTag } from '../../reducers/resource-reducer';

const ResourcesFilter = () => {
  const {
    selectedFilters: { selectedTags },
    filterGroups
  } = useSelector(({ resources }: any) => resources);
  const [openFilter, setOpenFilter] = useState('');
  const classes = useStyles();
  const dispatch = useDispatch() as any;
  const [displayedSearchText, setDisplayedSearchText] = useState('');
  const translateResources = key => translate('resources', key);

  React.useEffect(() => {
    dispatch(clearResourceFilters());
    dispatch(setResourceFilters('', []));
    dispatch(fetchFilterTags());
  }, [dispatch]);

  const handleFilterToggle = (filter: any) => {
    dispatch(toggleTag(filter));
  };

  const clearFilters = () => {
    dispatch(clearResourceFilters());
    dispatch(setResourceFilters('', []));
    setDisplayedSearchText('');
  };

  function renderGrid(filterTags: Array<FilterTag>) {
    return (
      <CharacterGrid
        items={filterTags}
        selectedItems={selectedTags}
        onSelectItem={handleFilterToggle}
      />
    );
  }
  const setSearchText = (newSearchText: string) => {
    setDisplayedSearchText(newSearchText);
    if (newSearchText.length > 2 || displayedSearchText !== '') {
      dispatch(setResourceFilters(newSearchText, []));
    }
  };
  function renderDynamicFilters(filterTags: Array<FilterTag>): any {
    let checked = false;
    if (filterTags[0].componentType === 'button') {
      return renderGrid(filterTags);
    } else {
      return filterTags.map(
        (filterTag: FilterTag, index: number): React.ReactElement => {
          checked = selectedTags.findIndex(tag => tag.id === filterTag.id) !== -1;

          return (
            <CheckBox
              key={index}
              label={filterTag.value}
              ariaLabel={`${tA11y('aria.filterFor')} ${filterTag.value}`}
              checked={checked} // [TODO: check if is selectedTag
              onChange={() => handleFilterToggle(filterTag)}
              onClick={event => event.preventDefault()}
            />
          );
        }
      );
    }
  }

  function renderDropdowns(): Array<React.ReactElement> {
    return filterGroups.map(
      (filterGroup: any, index: number): React.ReactElement => {
        return (
          <FilterDropDown
            key={index}
            label={filterGroup.filter}
            setOpenFilter={setOpenFilter}
            openFilter={openFilter}
            selectedTags={selectedTags}
            filterGroupValues={filterGroup.values}
            openByDefault={
              filterGroup.values.find(filter => selectedTags.indexOf(filter.id) >= 0) !== undefined
            }
          >
            {renderDynamicFilters(filterGroup.values)}
          </FilterDropDown>
        );
      }
    );
  }

  return (
    <div className={classes.filterBarContainer}>
      <div className={classes.enclosingDiv}>
        <label className={classes.searchlabel} htmlFor="searchbox">
          {translateResources('search')}
        </label>
        <TextField
          id="searchbox"
          className={classes.searchInput}
          role="search"
          aria-label={tA11y('aria.search')}
          onChange={event => setSearchText(event.target.value)}
          value={displayedSearchText}
          placeholder={translateResources('search')}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
            classes: { root: classes.inputText }
          }}
        />
      </div>
      {renderDropdowns()}
      <div className={classes.clearFiltersBtn}>
        <Button
          startIcon={<FontAwesomeIcon icon={faTimes} size="lg" />}
          style={{ marginTop: '10px', verticalAlign: 'center' }}
          onClick={clearFilters}
          aria-label={tA11y('aria.clearFilters')}
        >
          <span>{translateResources('clearFilters')}</span>
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  enclosingDiv: {
    paddingTop: '10px',
    paddingBottom: '15px',
    color: '#202020',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    fontFamily: 'GSS Futura'
  },
  searchInput: {
    display: 'block',
    color: '#202020',
    fontFamily: 'GSS Futura',
    paddingLeft: 5,
    paddingRight: 5,
    height: 42,
  },
  inputText: {
    color: '#202020',
    fontFamily: 'GSS Futura',
    border: '1px solid #000000',
    borderRadius: '8px',
    height: 42,
  },
  searchlabel: {
    display: 'block',
    paddingLeft: '3px',
    marginBottom: '2px',
    fontWeight: 400
  },
  filterBarContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  clearFiltersBtn: {
    paddingTop: '20px',
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: 'GSS Futura'
  }
});

export default ResourcesFilter;
