import { combineReducers } from 'redux';
import workbook, { WorkbookState } from '../modules/workbook';
import ui, { UIState } from './ui-reducer';
import resources from './resource-reducer';
import auth, { AuthState } from '../modules/auth';
import error, { ErrorState } from '../modules/error';
import messaging, { MessagingState } from '../modules/messaging';
import user, { UserState } from '../modules/user';
import ftue, { FtueState } from '../modules/ftue';
import characterSelection, { CharacterSelectionState } from '../modules/character-selection';
import assessment, { AssessmentState } from '../modules/assessment';

export type { AuthState } from '../modules/auth';

export type { ErrorState } from '../modules/error';

export type { MessagingState } from '../modules/messaging';
export type { UserState } from '../modules/user';
export type { FtueState } from '../modules/ftue';
export type { CharacterSelectionState } from '../modules/character-selection';
export type { AssessmentState } from '../modules/assessment';

export type AppState = {
  workbook: WorkbookState;
  ui: UIState;
  resources: any;
  auth: AuthState;
  messaging: MessagingState;
  user: UserState;
  error: ErrorState;
  ftue: FtueState;
  characterSelection: CharacterSelectionState;
  assessment: AssessmentState;
};

const rootReducer = combineReducers({
  ftue,
  workbook,
  ui,
  resources,
  auth,
  messaging,
  user,
  error,
  characterSelection,
  assessment
});

export default rootReducer;
