import React, { Component, SyntheticEvent } from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import PreKittMessageFrame from '../core/Modals/PreKittMessageFrame';
import Pointer, { pointerSide } from '../core/PointerTriangle';
import TourCard from './TourCard';
import BasicButton from '../core/BasicButton';
import DrawnCircleXIcon from '../icons/DrawnCircleXIcon';
import { remCalc } from '../../util/style-util';
import { lwtBlue } from '../../styles/style-constants';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const translateModals = key => translate('modals', key);

type Props = {
  position: any;
  justifyPointer?: any;
  step: any;
  advanceTourStep: () => any;
  transition: { state: string; duration: number };
  show: boolean;
  noFlex?: boolean;
  style?: any;
};

type State = {
  node?: HTMLElement;
  parent?: HTMLElement;
};

const styles = StyleSheet.create({
  ftue: {
    position: 'absolute',
    cursor: 'default',
    opacity: 0
  },
  calloutBody: {
    padding: remCalc(10, 20),
    fontWeight: 400,
    '@media(min-width: 1000px)': {
      fontSize: remCalc(18),
      padding: remCalc(20, 40)
    }
  },
  closeButton: {
    width: remCalc(20),
    height: remCalc(20),
    padding: 0,
    zIndex: 6,
    position: 'absolute',
    top: 10,
    right: 10,
    borderRadius: '50%',
    ':active': {
      transform: 'translateY(3px)'
    },
    ':focus': {
      outline: 0,
      boxShadow: `0 0 3px 2px ${lwtBlue}`
    }
  }
});

function flipPosition(position: string) {
  const flipPositionFrom = {
    left: 'right',
    right: 'left',
    top: 'bottom',
    bottom: 'top'
  };

  return flipPositionFrom[position];
}

const transitionStyles = {
  entering: {
    opacity: 0
  },
  entered: {
    opacity: 1
  },
  exiting: {
    opacity: 0
  }
};

class FtueBubble extends Component<Props, State> {
  static defaultProps = {
    step: {
      title: '',
      text: '',
      name: '',
      step: ''
    },
    position: 'top',
    advanceTourStep: () => {},
    transition: { state: '', duration: 0 },
    show: true
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      node: undefined,
      parent: undefined
    };
  }

  componentDidUpdate() {
    const node = document.getElementById('preKittMessageFrame');

    if (!this.state.node && node instanceof HTMLElement && node.style.opacity === '1') {
      const parent = node.parentElement;

      /* Set style here rather than on the parent directly. Component was rendering
         outside of the context of the flex container and not centering. This might be 
         an issue with ReactTransition. Applying the flex style after rendering centers 
         the callout correctly. */
      if (parent instanceof HTMLElement) {
        if (!this.props.noFlex) {
          parent.style.display = 'flex';
          parent.style.justifyContent = 'center';
        }
        this.setState({ node, parent });
      }
    }
  }

  positionCallout = (parent?: HTMLElement, callout?: HTMLElement) =>
    StyleSheet.create({
      left: {
        top: 0,
        right: 0,
        transform: `translate(-${parent ? parent.clientWidth + 20 : 0}px, -${
          (parent ? parent.clientHeight : 0) / 2
        }px)`
      },
      right: {
        top: 0,
        left: 0,
        transform: `translate(${parent ? parent.clientWidth + 25 : 0}px, -${
          (parent ? parent.clientHeight : 0) / 2
        }px)`
      },
      top: {
        top: 0,
        transform: `translateY(-${callout ? callout.clientHeight + pointerSide : 0}px)`
      },
      bottom: {
        bottom: 0,
        transform: `translateY(${callout ? callout.clientHeight + pointerSide : 0}px)`
      }
    });

  handleClick = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  render() {
    const { position, justifyPointer, step, transition, advanceTourStep, style } = this.props;
    const positionStyle = this.positionCallout(this.state.parent, this.state.node)[position];
    const handleCalloutClose = advanceTourStep;

    return (
      <PreKittMessageFrame
        onClick={this.handleClick}
        classList={[styles.ftue, !style && positionStyle]}
        style={{
          ...style,
          ...transitionStyles[transition.state],
          transition: `all ${transition.duration}ms ease-in-out`
        }}
      >
        <BasicButton
          icon={DrawnCircleXIcon}
          onClick={handleCalloutClose}
          buttonStyles={[styles.closeButton]}
          aria-label={tA11y('aria.closePopup')}
          tabIndex={0}
        />
        <Pointer position={flipPosition(position)} justify={justifyPointer} />
        <TourCard
          title={step.title}
          text={step.text}
          advance={advanceTourStep}
          confirmText={translateModals('tooltipsSubmit')}
        />
      </PreKittMessageFrame>
    );
  }
}

export default FtueBubble;
