import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../util/style-util';
import maybe, { MaybeProps } from './enhancers/maybe';
import TrialBadge from '../Badges/TrialBadge';

type Props = {
  isTrialLicense: boolean;
  themeColor: string;
  textColor: string;
  trialDaysLeft: number;
  daysLeft?: any;
};

type PageFooterProps = Partial<Props> & MaybeProps;

const MaybeTrialBadge: React.FC<PageFooterProps> = maybe(TrialBadge as any);

function Footer({ themeColor, textColor, isTrialLicense, trialDaysLeft }: Props): any {
  const { footer } = getStyles(themeColor, textColor);

  return (
    <footer className={css(footer)}>
      <MaybeTrialBadge
        shouldRender={isTrialLicense}
        daysLeft={trialDaysLeft}
        textColor={textColor}
      />
    </footer>
  );
}

export function getStyles(themeColor: string, textColor: string) {
  return StyleSheet.create({
    footer: {
      width: '100%',
      paddingTop: remCalc(15),
      minHeight: remCalc(50),
      color: textColor,
      backgroundColor: themeColor,
      display: 'flex',
      alignItems: 'center'
    }
  });
}

export default Footer;
