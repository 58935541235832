import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@mui/material/IconButton';
import { fileDownload } from '@lwtears/lwt-common-frontend/lib/@common/util/file-util';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const DownloadPdf: React.FC<any> = ({ url, name }: { url: string; name?: string }) => {
  return (
    <IconButton
      aria-label={tA11y('aria.downloadPdf')}
      tabIndex={0}
      style={{ margin: '0px 20px', fontSize: 12 }}
      onClick={() => fileDownload(url, name?.split(' ').join('_'))}
    >
      <FontAwesomeIcon color="#fff" icon={faDownload} />
    </IconButton>
  );
};

export default DownloadPdf;
