import React from 'react';
import MaterialTrapFocus from '@mui/material/Unstable_TrapFocus';

/** Do not use within useModal() openModal or draggableModal */
const TrapFocus = ({ children, open }) => {
  return (
    <MaterialTrapFocus open={open} isEnabled={() => true} getDoc={() => document}>
      {children}
    </MaterialTrapFocus>
  );
};

export default TrapFocus;
