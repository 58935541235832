//
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PageCharacterLink from './PageCharacterLink';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { remCalc } from '../../util/style-util';
import { darkGray } from '../../styles/style-constants';
const translateBookMenus = key => translate('bookMenus', key);

type Props =
  | {
      characters: any[];
      color: string;
      closeMenu: () => any;
      type: string;
    }
  | any;

const styles = StyleSheet.create({
  groupCharacters: {
    listStyle: 'none',
    paddingLeft: 0,
    gridArea: 'main',
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'row wrap',
    width: remCalc(240),
    margin: 'auto',
    marginTop: remCalc(24),
    color: darkGray
  },
  instructions: {
    marginBottom: remCalc(32)
  }
});

const CharacterMenuGroup = ({ characters, color, type, closeMenu }: Props) => (
  <section className={css(styles.groupCharacters)}>
    <div className={css(styles.instructions)}>
      {type === '123' ? translateBookMenus('selectNumber') : translateBookMenus('selectLetter')}
    </div>
    <div>
      {characters.map((symbol, i) => (
        <PageCharacterLink
          key={`${symbol.character} - ${i}`}
          symbol={symbol}
          color={color}
          closeMenu={closeMenu}
          noLeftSpacing={false}
          useFlex={true}
          hasIcon={false}
        />
      ))}
    </div>
  </section>
);

export default CharacterMenuGroup;
