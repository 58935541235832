import { Workbook } from 'types';

export type WorkbookState = {
  allWorkbooks: any;
  pdf: any;
  content: Array<any>;
  pagesDetails?: Array<any>;
  orientation?: any;
};

// Book list
export const FETCHING_WORKBOOKS = 'FETCHING_WORKBOOKS';
export const FETCH_WORKBOOKS_SUCCESS = 'FETCH_WORKBOOKS_SUCCESS';
export const FETCH_WORKBOOKS_FAILURE = 'FETCH_WORKBOOKS_FAILURE';
export const RESET_WORKBOOKS = 'RESET_WORKBOOKS';
export type FETCH_WORKBOOKS_ACTION = { type: typeof FETCHING_WORKBOOKS };
export type FETCH_WORKBOOKS_SUCCESS_ACTION = {
  type: typeof FETCH_WORKBOOKS_SUCCESS;
  workbooks: { [bookCode: string]: Workbook };
};
export type FETCH_WORKBOOKS_FAILURE_ACTION = { type: typeof FETCH_WORKBOOKS_FAILURE; error: Error };

// Page details
export const FETCHING_PAGE_DETAILS = 'FETCHING_PAGE_DETAILS';
export const FETCH_PAGE_DETAILS_SUCCESS = 'FETCH_PAGE_DETAILS_SUCCESS';
export const FETCH_PAGE_DETAILS_FAILURE = 'FETCH_PAGE_DETAILS_FAILURE';
export const RESET_PAGE_DETAILS = 'RESET_PAGE_DETAILS';
export type RESET_PAGE_DETAILS_ACTION = { type: typeof RESET_PAGE_DETAILS };
export type FETCH_PAGE_DETAILS_ACTION = { type: typeof FETCHING_PAGE_DETAILS };
export type FETCH_PAGE_DETAILS_SUCCESS_ACTION = {
  type: typeof FETCH_PAGE_DETAILS_SUCCESS;
  pages: Array<any>;
};
export type FETCH_PAGE_DETAILS_FAILURE_ACTION = {
  type: typeof FETCH_PAGE_DETAILS_FAILURE;
  error: Error;
};

// Fetch table of contents
export const FETCHING_WORKBOOK_CONTENT = 'FETCHING_WORKBOOK_CONTENT';
export const FETCH_WORKBOOK_CONTENT_SUCCESS = 'FETCH_WORKBOOK_CONTENT_SUCCESS';
export const FETCH_WORKBOOK_CONTENT_FAILURE = 'FETCH_WORKBOOK_CONTENT_FAILURE';
export const RESET_ACTIVE_WORKBOOK_CONTENT = 'RESET_ACTIVE_CONTENT';
export type FETCH_WORKBOOK_CONTENT_ACTION = { type: typeof FETCHING_WORKBOOK_CONTENT };
export type FETCH_WORKBOOK_CONTENT_SUCCESS_ACTION = {
  type: typeof FETCH_WORKBOOK_CONTENT_SUCCESS;
  pages: Array<any>;
};
export type FETCH_WORKBOOK_CONTENT_FAILURE_ACTION = {
  type: typeof FETCH_WORKBOOK_CONTENT_FAILURE;
  error: Error;
};

// Fetch PDF
export const FETCHING_PDF = 'FETCHING_PDF';
export const FETCH_PDF_SUCCESS = 'FETCH_PDF_SUCCESS';
export const FETCH_PDF_FAIL = 'FETCH_PDF_FAIL';
export type FETCH_PDF_ACTION = { type: typeof FETCHING_PDF };
export type FETCH_PDF_SUCCESS_ACTION = { type: typeof FETCH_PDF_SUCCESS; pdf: any };
export type FETCH_PDF_FAIL_ACTION = { type: typeof FETCH_PDF_FAIL; error: Error };

export const SET_LAST_WORKBOOK = 'SET_LAST_WORKBOOK';
export type SET_LAST_WORKBOOK_ACTION = { type: typeof SET_LAST_WORKBOOK; book: any };

export const SET_LAST_TEACHERS_GUIDE = 'SET_LAST_TEACHERS_GUIDE';
export type SET_LAST_TEACHERS_GUIDE_ACTION = { type: typeof SET_LAST_TEACHERS_GUIDE; book: any };

export const SET_LAST_STORYBOOK = 'SET_LAST_STORYBOOK';
export type SET_LAST_STORYBOOK_ACTION = { type: typeof SET_LAST_STORYBOOK; book: any };
export const SET_CURRENT_WORKBOOK = 'SET_CURRENT_WORKBOOK';

export type WorkbookAction =
  | FETCH_WORKBOOKS_ACTION
  | FETCH_WORKBOOKS_SUCCESS_ACTION
  | FETCH_WORKBOOKS_FAILURE_ACTION
  | FETCH_PAGE_DETAILS_ACTION
  | FETCH_PAGE_DETAILS_SUCCESS_ACTION
  | FETCH_PAGE_DETAILS_FAILURE_ACTION
  | FETCH_WORKBOOK_CONTENT_ACTION
  | FETCH_WORKBOOK_CONTENT_SUCCESS_ACTION
  | FETCH_WORKBOOK_CONTENT_FAILURE_ACTION
  | FETCH_PDF_ACTION
  | FETCH_PDF_SUCCESS_ACTION
  | 'FETCH_PDF_FAILURE_ACTION'
  | SET_LAST_WORKBOOK_ACTION
  | SET_LAST_TEACHERS_GUIDE_ACTION
  | SET_LAST_STORYBOOK_ACTION
  | 'SET_CURRENT_WORKBOOK';

/* eslint-disable no-use-before-define */
type PromiseAction = Promise<WorkbookAction>;
export type Dispatch = (action: WorkbookAction | ThunkAction | PromiseAction) => any;
export type GetState = () => WorkbookState;
export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
