//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Link } from 'react-router-dom';
import WorkbookBadge from '../Badges/WorkbookBadge';
import maybe, { MaybeProps } from '../core/enhancers/maybe';
import { remCalc } from '../../util/style-util';
import { BadgeType, Workbook } from 'types';
import { white } from '../../styles/style-constants';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props = {
  workbook: Workbook;
  routeTo: string;
  handleWorkbookClick?: () => any;
  styles: any;
  renderWorkbookBadge: boolean;
  badgeType?: BadgeType;
  gradeLevelDescription: string;
};

const styles = StyleSheet.create({
  workbookItem: {
    textAlign: 'center',
    padding: `${remCalc(10)} ${remCalc(12)}`
  },
  workbookImage: {
    width: '90%',
    marginTop: remCalc(14),
    marginBottom: remCalc(12)
  },
  workbookTitle: {
    display: 'inline-block',
    fontFamily: 'futura-pt',
    fontWeight: 300,
    fontSize: remCalc(16),
    color: white,
    textDecoration: 'none'
  },
  workbookLink: {
    display: 'flex',
    textDecoration: 'none',
    flexFlow: 'column nowrap',
    ':hover': {
      textDecoration: 'none'
    }
  },
  badge: {
    bottom: 0,
    right: 0
  },
  gradeLevelDescription: {
    fontFamily: 'futura-pt',
    fontWeight: 300,
    fontSize: remCalc(12),
    color: white,
    display: 'inline-block',
    padding: `0 ${remCalc(6)} 0 ${remCalc(6)}`
  }
});
type BadgeTypeProps = {
  badgeType: any;
} & MaybeProps;

const MaybeWorkbookBadge: React.FC<BadgeTypeProps> = maybe(WorkbookBadge);

const WorkbooksMenuItem = ({
  workbook,
  handleWorkbookClick,
  routeTo,
  styles: styleOverrides,
  renderWorkbookBadge,
  badgeType,
  gradeLevelDescription
}: Props) => (
  <li
    className={css(styles.workbookItem, styleOverrides.workbookItem)}
    onClick={handleWorkbookClick}
  >
    <Link className={css(styles.workbookLink)} to={routeTo}>
      <div style={{ position: 'relative' }}>
        <img
          className={css(styles.workbookImage, styleOverrides.workbookImage)}
          src={workbook.coverImageUrl}
          alt={`${workbook.title} ${tA11y('alt.icon')}`}
        />
        <MaybeWorkbookBadge
          shouldRender={renderWorkbookBadge}
          badgeType={badgeType}
          styles={{ badge: styles.badge }}
        />
      </div>
      <span className={css(styles.workbookTitle, styleOverrides.workbookTitle)}>
        {workbook.title}
      </span>
      <span className={css(styles.gradeLevelDescription, styleOverrides.gradeLevelDescription)}>
        {gradeLevelDescription}
      </span>
    </Link>
  </li>
);

WorkbooksMenuItem.defaultProps = {
  styles: {}
};

export default WorkbooksMenuItem;
