import { RESOURCE_TYPES } from '../constants/Workbook';

export const shouldShowTitle = selectedResource => {
  const resourceType = selectedResource.type?.type ?? selectedResource.type;
  const mediaType = selectedResource.data?.mediaType ?? selectedResource.mediaType;

  const resourceIframeTypes = [
    RESOURCE_TYPES.WOOD_PIECES,
    RESOURCE_TYPES.WDT,
    RESOURCE_TYPES.LNF,
    RESOURCE_TYPES.PDF,
    RESOURCE_TYPES.AUDIO,
    RESOURCE_TYPES.VIDEO,
    RESOURCE_TYPES.MUSIC,
    RESOURCE_TYPES.ANIMATION,
    RESOURCE_TYPES.OUR_WORLD
  ];

  if (resourceType === RESOURCE_TYPES.CLICK_AWAY && mediaType) {
    return true;
  }

  return resourceIframeTypes.includes(resourceType);
};

export const getResourceFileExtention = (file: string) => {
  const items = file.split('.');
  return items[items.length - 1];
};

export const isFilePdf = resource => getResourceFileExtention(resource) === 'pdf';

export const getPdfResourceUrlOrNull = (type = 'clickAway', resource): string | null => {
  if (resource.type === type && isFilePdf(resource.src ?? resource.url)) {
    return resource.src ?? resource.url;
  }
  return null;
};
