//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../../util/style-util';
import gssLogoPath from '../../../images/gss-logo-round.png';
import closeBtnPath from '../../../images/close-button.svg';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const translateResources = key => translate('resources', key);

type Props = {
  show: boolean;
  onClose: () => any;
};

function ExpiredLicenseAlert(props: Props) {
  const { alertContainer, alertContent, alertImg, alertText, closeBtn, closeImg, link } = styles;

  return props.show ? (
    <div className={css(alertContainer)}>
      <div className={css(alertContent)}>
        <img src={gssLogoPath} alt={tA11y('alt.prekittLogo')} className={css(alertImg)} />
        <span className={css(alertText)}>
          {translateResources('clickAwayOnly')}
          <a
            target="_blank"
            href="https://www.lwtears.com/prekitt-purchase"
            className={css(link)}
            rel="noopener noreferrer"
          >
            {translateResources('getPreKitt')}
          </a>
        </span>
      </div>
      <button type="button" className={css(closeBtn)} onClick={props.onClose}>
        <img src={closeBtnPath} alt={tA11y('alt.closeIcon')} className={css(closeImg)} />
      </button>
    </div>
  ) : null;
}

const styles = StyleSheet.create({
  alertContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#e7e7e7',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  alertContent: {
    padding: remCalc(10)
  },
  alertImg: {
    width: remCalc(50),
    paddingRight: remCalc(10)
  },
  closeBtn: {
    border: 'none',
    backgroundColor: '#e7e7e7',
    position: 'absolute',
    right: 0
  },
  closeImg: {
    width: remCalc(25)
  },
  alertText: {
    fontFamily: 'futura-pt, sans-serif',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: remCalc(18),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    maxHeight: remCalc(77)
  },
  link: {
    paddingLeft: remCalc(5),
    fontWeight: 500
  }
});

export default ExpiredLicenseAlert;
