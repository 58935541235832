import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../../util/style-util';
import { white, resourceDarkGray, resourceDarkGray2 } from '../../../styles/style-constants';
import { Resource } from 'types';
import UnpinIcon from '../../icons/UnpinIcon';
import BasicButton from '../../../components/core/BasicButton';
import maybe, { MaybeProps } from '../../../components/core/enhancers/maybe';
import ResourceItemContent from './ResourceItemContent';
import ResourceItemFooter from './ResourceItemFooter';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type MaybeResourceItemProps = MaybeProps & {
  isFavoritePage: boolean;
  hasUnpinIcon: boolean;
  onClickPinIcon?: (resource: Resource) => void;
};

type Props = {
  resource: Resource;
  hasUnpinIcon?: boolean;
  isFavoritePage?: boolean;
  isAssessmentPage?: boolean;
  onClick: () => any;
  onClickPinIcon?: (resource: Resource) => any;
  onClickCloseIcon?: (resource: Resource) => any;
  tabIndex?: number;
  listStyleOverrides?: any;
  itemStyleOverides?: any;
};

function ResourceItem(props: Props) {
  const { resourceItem, iconSvg, unpinIconDiv } = styles;
  const MaybeUnpinButton: React.FC<MaybeProps> = maybe(BasicButton);
  const MaybeResourceItemFooter: React.FC<MaybeResourceItemProps> = maybe(ResourceItemFooter);

  function handlePinItem() {
    if (props.onClickPinIcon) {
      props.onClickPinIcon(props.resource);
    }
  }

  function handleUnpinItem() {
    if (props.onClickCloseIcon) {
      props.onClickCloseIcon(props.resource);
    }
  }

  function handleTogglePinIcon(e: any) {
    e.stopPropagation();
    if (props.hasUnpinIcon) {
      handleUnpinItem();
    } else {
      handlePinItem();
    }
  }

  function handleClickCloseIcon(e: any) {
    e.stopPropagation();
    handleUnpinItem();
  }

  function handleKeyPress(e: any) {
    if ((e.charCode === 32 || e.charCode === 13) && e.target.id === 'resourceCard') {
      props.onClick();
    }
  }

  return (
    <div
      {...props.listStyleOverrides}
      className={css(resourceItem)}
      onClick={props.onClick}
      tabIndex={0}
      id="resourceCard"
      onKeyPress={handleKeyPress}
    >
      <MaybeUnpinButton
        tabIndex={0}
        aria-label={`${tA11y('aria.unpin')} ${props.resource.title}`}
        shouldRender={!!props.isFavoritePage}
        buttonStyles={[unpinIconDiv]}
        onClick={handleClickCloseIcon}
      >
        <UnpinIcon className={css(iconSvg)} tabIndex={0} />
      </MaybeUnpinButton>
      <MaybeResourceItemFooter
        resource={props.resource}
        isFavoritePage={!!props.isFavoritePage}
        shouldRender={!props.isAssessmentPage}
        hasUnpinIcon={!!props.hasUnpinIcon}
        onClickPinIcon={handleTogglePinIcon}
      />
      <ResourceItemContent resource={props.resource} {...props.itemStyleOverides} />
    </div>
  );
}

const styles = StyleSheet.create({
  resourceItem: {
    backgroundColor: white,
    width: '100%',
    height: remCalc(245),
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    boxShadow: '0px 3px 7px 1px' + resourceDarkGray,
    border: remCalc(1) + ' solid' + resourceDarkGray,
    borderRadius: remCalc(3),
    justifyContent: 'space-between',
    color: resourceDarkGray2,
    cursor: 'pointer',
    userSelect: 'none'
  },

  unpinIconDiv: {
    position: 'absolute',
    top: -10,
    right: -10,
    width: remCalc(35),
    height: remCalc(35),
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent'
  },
  iconSvg: {
    width: '100%',
    height: '100%'
  }
});

export default ResourceItem;
