//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../util/style-util';

type Props = {
  position: any;
  justify: any;
};

export const pointerSide = 35;

const { pointer } = StyleSheet.create({
  pointer: {
    backgroundColor: 'white',
    height: remCalc(pointerSide),
    width: remCalc(pointerSide),
    borderBottom: '4px solid black',
    borderLeft: '4px solid black',
    position: 'absolute',
    zIndex: 1
  }
});

const alignments = {
  start: '80%',
  center: '50%',
  end: '15%'
};

const calculatePosition = (alignment: string) => ({
  left: {
    left: remCalc(-20),
    bottom: `calc(${alignment} - 10px)`,
    transform: 'rotate(45deg)'
  },
  right: {
    right: remCalc(-19),
    bottom: `calc(${alignment} - 10px)`,
    transform: 'rotate(225deg)'
  },
  bottom: {
    right: `calc(${alignment} - ${remCalc(20)})`,
    bottom: remCalc(-21.5),
    transform: 'rotate(315deg)'
  },
  top: {
    right: `calc(${alignment} - ${remCalc(20)})`,
    top: remCalc(-21.5),
    transform: 'rotate(135deg)'
  }
});

function getPosition(position: any, justify: any) {
  const pointerPosition = calculatePosition(alignments[justify])[position];

  return StyleSheet.create({ pointerPosition }).pointerPosition;
}

const PointerTriangle = ({ position, justify }: Props) =>
  position && <div className={css(pointer, getPosition(position, justify))} />;

PointerTriangle.defaultProps = {
  justify: 'start'
};

export default PointerTriangle;
