//
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withRouter from 'state/hooks/withRouter';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../../util/style-util';
import ResourcePlayer from '../../../components/Resources/ResourcePlayer/ResourcePlayer';
import withRoutePersistence from '../../../components/core/enhancers/withRoutePersistence';
import ResourcesList from '../../../components/Resources/ResourcesList/ResourcesList';
import camelCase from 'lodash/camelCase';
import PrekittApi from '../../../api';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { selectResource, toggleResourcePlayerVisibility } from '../../../actions/resource-actions';
import { createUserFavorite, removeUserFavorite } from '../../../actions/resource-operations';
import { Resource } from 'types';
import { AppState, AssessmentState } from '../../../reducers/index';
import { LiteResource } from '../../../reducers/resource-reducer';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import { DraggableModalTitle } from '../../../components/core/Modals/DraggableModalTitle';
import { parseQueryParams } from '../../../util/query-params-util';
const translateAssessments = key => translate('assessments', key);

type Props =
  | ({
      assessmentResources: AssessmentState;
      match: any;
      selectedResource: LiteResource;
      showResourcePlayer: boolean;
      createUserFavorite: (resource: Resource, isClickAway: boolean) => any;
      removeUserFavorite: (favoriteId: number) => any;
      selectResource: (resource: LiteResource) => any;
      toggleResourcePlayerVisibility: (show: boolean) => any;
    } & UseModalDispatch)
  | any;

class AssessmentResources extends React.Component<Props> {
  openResourcePlayer = (selectedResource: any) => {
    this.props.openDraggableModal({
      Title: props => <DraggableModalTitle title={selectedResource?.title} {...props} />,
      body: (
        <ResourcePlayer
          selectedResource={selectedResource}
          loadedData={this.createEvent(selectedResource)}
        />
      ),
      ariaLabel: tA11y('aria.modal', { title: selectedResource.title }),
      options: { closeOnBackdropClick: true }
    } as any);
  };
  createEvent = (resource: LiteResource) => {
    const event = {
      section: 'assessments',
      resourceId: resource.id,
      resourceType: resource.type
    };

    return () => PrekittApi.createEvent('accessResource', event);
  };

  handleSelectResource = (resource: LiteResource): void => {
    this.props.selectResource(resource);
    this.props.toggleResourcePlayerVisibility(true);
    this.openResourcePlayer(resource);
  };
  handlePinResource = (resource: Resource): void => {
    if (!resource.favorites || resource.favorites.length === 0) {
      this.props.createUserFavorite(resource, !resource.resourceTypeId);
    }
  };
  handleRemoveFavorite = (resource: Resource) => {
    if (resource.favorites && resource.favorites.length > 0) {
      this.props.removeUserFavorite(resource.favorites[0].id);
    }
  };
  handleClosePlayer = (): void => {
    this.props.toggleResourcePlayerVisibility(false);
  };

  render() {
    const { assessmentResources } = this.props;
    const queryParam = parseQueryParams(this.props.location.search);

    const path = camelCase(queryParam?.activeTab);

    if (path === 'assessmentTools') {
      return (
        <div className={css(styles.contentPanel)}>
          <br />
          <span className={css(styles.resourceHeader)}>
            {translateAssessments('benchmarkAssessments')}
          </span>
          <ResourcesList
            listStyleOverrides={{
              style: {
                height: '100px'
              }
            }}
            resourcesItems={assessmentResources['benchmarkAssessments']}
            onSelectItem={this.handleSelectResource}
            onClickPinIcon={this.handlePinResource}
            onRemoveItem={this.handleRemoveFavorite}
            isAssessmentPage={true}
          />
          <span className={css(styles.resourceHeader)}>
            {translateAssessments('observationChecklists')}
          </span>
          <ResourcesList
            listStyleOverrides={{
              style: {
                height: '100px'
              }
            }}
            resourcesItems={assessmentResources['observationChecklists']}
            onSelectItem={this.handleSelectResource}
            onClickPinIcon={this.handlePinResource}
            onRemoveItem={this.handleRemoveFavorite}
            isAssessmentPage={true}
          />
        </div>
      );
    }

    return (
      <ResourcesList
        listStyleOverrides={{
          style: {
            height: '100px'
          }
        }}
        resourcesItems={assessmentResources[camelCase(queryParam?.activeTab)]}
        onSelectItem={this.handleSelectResource}
        onClickPinIcon={this.handlePinResource}
        onRemoveItem={this.handleRemoveFavorite}
        isAssessmentPage={true}
      />
    );
  }
}

const styles = StyleSheet.create({
  contentPanel: {
    height: '100%',
    overflow: 'auto'
  },
  resourceHeader: {
    paddingLeft: remCalc(50),
    fontSize: remCalc(20)
  }
});

const mapStateToProps = (state: AppState) => ({
  assessmentResources: state.assessment,
  selectedResource: state.resources.selectedResource,
  showResourcePlayer: state.resources.showResourcePlayer
});

export default compose(
  withModal,
  withRouter,
  withRoutePersistence('assessment'),
  connect(mapStateToProps, {
    selectResource,
    toggleResourcePlayerVisibility,
    createUserFavorite,
    removeUserFavorite
  })
)(AssessmentResources);
