import { LiteResource, FilterTag } from '../reducers/resource-reducer';
import * as resourceTypes from './resource-types';

//fetchFilterTags actions
export const fetchFilterTagsRequest = (): resourceTypes.FETCH_FILTER_TAGS_REQUEST_ACTION => ({
  type: resourceTypes.FETCH_FILTER_TAGS_REQUEST
});

export const fetchFilterTagsSuccess = (
  filterTags: Array<FilterTag>
): resourceTypes.FETCH_FILTER_TAGS_SUCCESS_ACTION => ({
  type: resourceTypes.FETCH_FILTER_TAGS_SUCCESS,
  filterTags
});

export const fetchFilterTagsFailure = (
  error: Error
): resourceTypes.FETCH_FILTER_TAGS_FAILURE_ACTION => ({
  type: resourceTypes.FETCH_FILTER_TAGS_FAILURE,
  error
});

export const setResourceFilters = (searchText: string, tagIds: any) => {
  return {
    type: resourceTypes.SET_FILTERS,
    data: { searchText, tagIds }
  };
};

export const clearResourceFilters = () => ({
  type: resourceTypes.CLEAR_RESOURCE_FILTERS
});

export const toggleTag = (tag: any) => ({
  type: resourceTypes.TOGGLE_TAG,
  tag
});

export const fetchResourcesRequest = (): resourceTypes.FETCH_RESOURCES_REQUEST_ACTION => ({
  type: resourceTypes.FETCH_RESOURCES_REQUEST
});

export const fetchResourcesSuccess = (
  resources: Array<any>
): resourceTypes.FETCH_RESOURCES_SUCCESS_ACTION => ({
  type: resourceTypes.FETCH_RESOURCES_SUCCESS,
  resources
});

export const fetchResourcesFailure = (
  error: Error
): resourceTypes.FETCH_RESOURCES_FAILURE_ACTION => ({
  type: resourceTypes.FETCH_RESOURCES_FAILURE,
  error: error
});

export const fetchClickAwaysRequest = (): resourceTypes.FETCH_CLICKAWAYS_REQUEST_ACTION => ({
  type: resourceTypes.FETCH_CLICKAWAYS_REQUEST
});

export const fetchClickAwaysSuccess = (clickaways: any) => ({
  type: resourceTypes.FETCH_CLICKAWAYS_SUCCESS,
  clickaways
});

export const fetchClickAwaysFailure = (
  error: Error
): resourceTypes.FETCH_CLICKAWAYS_FAILURE_ACTION => ({
  type: resourceTypes.FETCH_CLICKAWAYS_FAILURE,
  error: error
});

export const selectResource = (resource: LiteResource) => ({
  type: resourceTypes.SELECT_RESOURCE,
  selectedResource: resource
});

export const toggleResourcePlayerVisibility = (
  show: boolean
): resourceTypes.TOGGLE_RESOURCE_PLAYER_VISIBILITY_ACTION => ({
  type: resourceTypes.TOGGLE_RESOURCE_PLAYER_VISIBILITY,
  show
});

//fetch FavoriteResources actions
export const resetResources = (): resourceTypes.RESET_RESOURCES_ACTION => ({
  type: resourceTypes.RESET_RESOURCES
});
export const fetchFavoriteResourcesRequest =
  (): resourceTypes.FETCH_FAVORITE_RESOURCES_REQUEST_ACTION => ({
    type: resourceTypes.FETCH_FAVORITE_RESOURCES_REQUEST
  });

export const fetchFavoriteResourcesSuccess = (
  favoriteResources: any
): resourceTypes.FETCH_FAVORITE_RESOURCES_SUCCESS_ACTION => ({
  type: resourceTypes.FETCH_FAVORITE_RESOURCES_SUCCESS,
  favoriteResources
});

export const fetchFavoriteResourcesFailure = (error: Error) => ({
  type: resourceTypes.FETCH_FAVORITE_RESOURCES_FAILURE,
  error: error
});

//create User Favorite actions
export const createUserFavoriteRequest = (userFavorite: any) => ({
  type: resourceTypes.CREATE_USER_FAVORITE_REQUEST,
  userFavorite
});

export const createUserFavoriteSuccess = (
  userFavorite: any
): resourceTypes.CREATE_USER_FAVORITE_SUCCESS_ACTION => ({
  type: resourceTypes.CREATE_USER_FAVORITE_SUCCESS,
  userFavorite
});

export const createUserFavoriteFailure = (
  error: Error
): resourceTypes.CREATE_USER_FAVORITE_FAILURE_ACTION => ({
  type: resourceTypes.CREATE_USER_FAVORITE_FAILURE,
  error
});

//remove User Favorite
export const removeUserFavoriteRequest = (
  favoriteId: number
): resourceTypes.REMOVE_USER_FAVORITE_REQUEST_ACTION => ({
  type: resourceTypes.REMOVE_USER_FAVORITE_REQUEST,
  favoriteId
});

export const removeUserFavoriteSuccess = (favoriteId: number) => ({
  type: resourceTypes.REMOVE_USER_FAVORITE_SUCCESS,
  favoriteId
});

export const removeUserFavoriteFailure = (
  error: Error
): resourceTypes.REMOVE_USER_FAVORITE_FAILURE_ACTION => ({
  type: resourceTypes.REMOVE_USER_FAVORITE_FAILURE,
  error
});

export const updateCounter = (operationType: string): resourceTypes.UPDATE_COUNTER_ACTION => ({
  type: resourceTypes.UPDATE_COUNTER,
  operationType
});
