import {
  FETCH_WORKBOOK_CONTENT_FAILURE_ACTION,
  FETCH_PAGE_DETAILS_FAILURE_ACTION,
  FETCH_PDF_FAIL_ACTION,
  FETCH_WORKBOOKS_FAILURE_ACTION
} from '../workbook/workbook-types';
// The Resource Action Types are placed in here provisionally
// Will be removed when the Resources Redux logic
// will be refactored.
import { FETCH_RESOURCES_FAILURE, FETCH_FILTER_TAGS_FAILURE } from '../../actions/resource-types';
import { FETCH_CHARACTERS_FAILURE } from '../character-selection/types';
import { LOGIN_FAIL_ACTION } from '../auth/auth-types';

export const DISABLE_ERROR_ROUTE_REDIRECTION = 'DISABLE_ERROR_ROUTE_REDIRECTION';
export type DISABLE_ERROR_ROUTE_REDIRECTION_ACTION = {
  type: typeof DISABLE_ERROR_ROUTE_REDIRECTION;
};

// We will move these actions in the resources module directory,
// after refactoring the Resource Redux logic
type FETCH_RESOURCES_FAILURE_ACTION = {
  type: typeof FETCH_RESOURCES_FAILURE;
  error: any;
};

type FETCH_FILTER_TAGS_FAILURE_ACTION = {
  type: typeof FETCH_FILTER_TAGS_FAILURE;
  error: string;
};

type FETCH_CHARACTERS_FAILURE_ACTION = {
  type: typeof FETCH_CHARACTERS_FAILURE;
  error: string;
};

// todo: add resource action types
export type ErrorAction =
  | FETCH_WORKBOOK_CONTENT_FAILURE_ACTION
  | FETCH_PAGE_DETAILS_FAILURE_ACTION
  | FETCH_PDF_FAIL_ACTION
  | FETCH_WORKBOOKS_FAILURE_ACTION
  | LOGIN_FAIL_ACTION
  | DISABLE_ERROR_ROUTE_REDIRECTION_ACTION
  | FETCH_RESOURCES_FAILURE_ACTION
  | FETCH_FILTER_TAGS_FAILURE_ACTION
  | FETCH_CHARACTERS_FAILURE_ACTION;

export type ErrorState = {
  errors: Array<ErrorAction>;
  redirectToErrorRoute: boolean;
};
