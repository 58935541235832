import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchAssessmentResources } from '../../modules/assessment';

import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { buildSearchQueryParams, parseQueryParams } from '../../util/query-params-util';
import AssessmentSideMenu from './components/AssessmentSideMenu';
import ActiveAssessment from './components/ActiveAssessment';
import { setDocumentBodyColor } from '../../util/ui-util';

const Assessment = () => {
  const dispatch = useDispatch() as any;
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const queryParams = parseQueryParams(location.search);

  useEffect(() => {
    handleSetActiveTab('overview');
    setDocumentBodyColor();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchAssessmentResources());
  }, [dispatch]);

  const handleSetActiveTab = activeTab => {
    navigate({
      pathname: `/assessments`,
      search: buildSearchQueryParams({ activeTab })
    });
  };

  return (
    <Container className={classes.container} maxWidth={'xl'}>
      <AssessmentSideMenu />

      <Box role="main" display="flex" flexDirection="column" flex={1}>
        {queryParams?.activeTab && <ActiveAssessment />}
      </Box>
    </Container>
  );
};

const HEADER_HEIGHT = 60;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    paddingTop: 26,
    paddingBottom: 26,
    height: `calc(100vh - ${HEADER_HEIGHT}px)`
  }
}));
export default Assessment;
