import React from 'react';
import DoublePageViewer from './DoublePageViewer';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

type Props = {
  workbook: any;
  currentPage: number;
  iconSize: number;
  fingerprint?: string;
};

const DoublePageWorkbook = ({ workbook, currentPage, iconSize, fingerprint }: Props) => {
  const isStorybook = workbook.type === 'storybook';

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <DoublePageViewer currentPage={currentPage} workbook={workbook} fingerprint={fingerprint} />
      {isStorybook && (
        <AudioPlayer
          pages={workbook.pageDetails}
          iconSize={iconSize}
          isDouble={true}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default DoublePageWorkbook;
