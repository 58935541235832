import React from 'react';

type Props = {
  fill: string,
  borderColor: string
};

const PLIcon = ({ fill, borderColor }: Props) => (
  <svg viewBox="0 0 21.5 21.6" width="32" height="32">
    <path
      style={{ fill }}
      d="M6.3,20.6c-0.7-0.3-1.4-0.8-2-1.2c-0.6-0.5-1.1-1.1-1.6-1.7c-0.5-0.6-1-1.2-1.3-1.9c-0.3-0.7-0.5-1.5-0.7-2.2
			c-0.3-0.7-0.5-1.5-0.7-2.3c0.1-1.6,0.5-3.2,1.2-4.6c0.4-0.7,0.8-1.4,1.3-2c0.5-0.6,1-1.2,1.6-1.7c0.6-0.6,1.2-1,1.9-1.4
			C6.8,1,7.5,0.7,8.3,0.6C9.1,0.3,9.8,0.1,10.6,0C11.4,0,12.2,0.2,13,0.5c0.8,0.1,1.6,0.3,2.3,0.6c0.7,0.4,1.3,0.9,1.9,1.4
			c0.7,0.4,1.3,1,1.8,1.5c0.5,0.6,0.9,1.3,1.2,2.1c0.4,0.7,0.6,1.5,0.8,2.2c0.1,0.8,0.1,1.6,0.1,2.4c0.1,0.8,0.1,1.6,0,2.4
			c-0.3,1.6-1,3-2,4.3C18.6,18,18,18.5,17.3,19c-0.5,0.6-1.2,1.1-1.8,1.6c-0.7,0.4-1.5,0.7-2.2,0.9c-0.8,0.1-1.6,0.1-2.4,0.1
			C9.3,21.6,7.7,21.2,6.3,20.6L6.3,20.6z"
    />
    <path
      style={{ fill: borderColor }}
      d="M13.7,0.6c-0.9-0.3-1.8-0.5-2.8-0.5c-3.6,0-6.8,2.4-8.6,5.1C-0.3,9-0.2,14,2.7,17.7c2.8,3.5,7.6,4.8,11.8,3
			c2.7-1.2,4.9-3.3,6.3-6c0.3-0.8,0.5-1.7,0.5-2.5v-0.1l0-0.1c0,0,0.1-0.1,0.1-0.2c0.5-2.1-0.2-4.5-1.9-6.7C18,3.1,16,1.6,13.7,0.6z
      M19.7,7.1l0.7,1.4c0,0,0.7,3.4-0.6,5.8c-1.4,2.4-3.5,4.3-6,5.5c-1.1,0.5-2.3,0.7-3.5,0.7c-1.5,0-3.1-0.4-4.4-1.1
			c-2.4-1.3-4-3.6-4.5-6.2c-0.6-5,2.3-9.7,7-11.6c3-1,6.3-0.3,8.5,1.9C18.1,4.5,19,5.7,19.7,7.1L19.7,7.1z"
    />
    <g style={{ fill: borderColor }}>
      <path d="M14.4,5.5C14.4,5.5,14.4,5.5,14.4,5.5C14.4,5.5,14.4,5.5,14.4,5.5z" />
      <polygon points="13.7,5 13.6,5 13.6,5 	" />
      <path
        d="M12.5,11.2c0,0,0.1,0,0.1,0c0-0.1,0.1-0.2,0.1-0.2c0,0.1,0,0,0,0.1c0.1-0.1,0.1-0.1,0.2-0.2c0,0.1,0.1,0,0.1-0.1L13,10.8
        c0-0.1,0.1-0.1,0.1-0.2v0.1c0.2-0.2,0.2-0.5,0.4-0.6c0-0.1-0.1,0.1-0.1,0c0-0.1,0.1-0.1,0.2-0.1l0,0.1l0,0l0-0.1c0,0,0.1,0,0.1,0
        c0,0,0-0.2,0.1-0.3c0,0-0.1,0-0.1-0.1c0.1-0.2,0.2-0.1,0.2,0c0,0,0,0.1,0,0.1c0.1,0,0.2-0.2,0.2-0.1c0-0.1,0-0.1-0.1,0
        c0.1-0.1,0.2-0.2,0.3-0.3s-0.1,0,0,0c0.2-0.2,0-0.1,0.1-0.3c0.1-0.1,0.1,0.1,0.1-0.1s0.1,0,0.1,0.1c0-0.2,0.2-0.2,0.2-0.3v0.1
        c0,0,0-0.1,0.1-0.1h-0.1c0,0,0.1-0.1,0.1,0c0,0-0.1,0-0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c-0.1,0.2,0.1,0,0,0.2c0,0,0-0.1,0.1-0.1
        C15,8.2,14.9,8.1,15,8c0,0,0.1,0,0.1,0S15,8,15,7.9c0-0.1,0.1-0.1,0.1-0.2l-0.1,0c0,0,0-0.1,0.1-0.2c0.1-0.1,0,0.1,0,0.1
        c0,0,0.2-0.1,0.2-0.3c0-0.1,0.1,0,0.1,0s0.1,0,0.2-0.3c0,0,0,0-0.1,0c0,0.1-0.1,0.2-0.1,0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0
        c0-0.1,0.1-0.1,0.1-0.2h0.2c0.1-0.1,0.1-0.2,0.2-0.2c-0.2-0.1-0.4-0.2-0.6-0.3l0.1,0.1c-0.1,0-0.2-0.1-0.2-0.1s0-0.1,0-0.1
        c0,0-0.1-0.1-0.2,0l0,0L15,6C14.9,6,14.8,6,14.8,6l-0.2-0.1c0,0,0,0,0.1,0c0,0-0.1-0.1-0.1,0l-0.2-0.1c0,0,0,0,0,0L13.2,5l0,0.1
        l0,0c-0.1,0.1-0.1,0.1-0.1,0.2L13,5.4c0,0-0.1,0-0.1,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.4c0,0,0,0,0,0l-0.4,0.6
        c0,0,0,0,0,0l0.1-0.1c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.1l-0.3,0.5h0c0,0,0,0,0,0l-0.1,0.2c0-0.1,0-0.1,0-0.2
        c0,0.1-0.1,0.2-0.1,0.3c0,0,0-0.1,0-0.1c0,0.1,0,0.1,0,0.2L11.5,8c-0.1,0-0.2,0.3-0.4,0.4C11,8.6,10.8,8.8,10.7,9c0,0,0-0.1-0.1,0
        c0.1,0,0,0.1,0.1,0.1c0,0,0,0.1-0.1,0.1c0.2,0,0,0.1,0.1,0.1c0,0.1,0,0.1-0.1,0.1l-0.2,0.2c-0.1,0,0-0.1,0-0.1c0,0-0.1,0-0.2,0.1
        c-0.1,0.1,0,0.1,0,0.2l0.1-0.1c0,0.2-0.2,0.1-0.2,0.3V9.8c0,0.1-0.2,0.4-0.2,0.5c0,0-0.1,0-0.1,0c-0.2,0.2-0.2,0.6-0.4,0.8
        c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2v-0.1c-0.1,0.2-0.1,0.3-0.2,0.4l-0.1,0.2c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.3-0.2,0.4
        c0,0.1,0,0.1,0,0.2l0,0.1l-0.2-0.3c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1l0,0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3L8,12.3
        C8,12,7.5,11.8,7.5,11.5c-0.1,0-0.1-0.1-0.2-0.1h0.1C7.2,11.3,7.1,11.1,7,11h0.1C7,10.9,6.9,11,6.8,11l-0.1-0.1l0,0
        c-0.1-0.1-0.3-0.5-0.5-0.7c0,0,0,0,0,0.1l0,0L6,10.4v0l-0.3,0.3c0,0-0.1,0.1-0.1,0.1l-1.4,1.4l0.1,0.1c0,0.1-0.1,0.1-0.1,0.1
        c0,0-0.1,0.1-0.1,0.1L4,12.6c0,0,0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.1,0l-0.1,0l-0.2-0.2c0.1,0,0.2,0.1,0.2,0.2h0.2
        c0,0.1,0.1,0.3,0.1,0.4c0.2,0.1,0.4,0.3,0.5,0.3s0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0,0.3,0.2,0.3,0.2c0.1,0.1-0.1,0,0,0.1
        c0.2,0.4,0.6,0.3,0.8,0.7c0,0,0,0,0-0.1c0.3,0.4,0.8,0.7,1.1,1.1c0.1,0.1,0.1,0.1,0.2,0.1v0.1h0.2c0.2,0.1,0.1,0.4,0.4,0.5v-0.1
        c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0.1,0,0.1,0l0.2,0.1c0.1,0.1-0.1,0.1,0.1,0.3s0.3,0.1,0.3,0.1s-0.1-0.1,0-0.1h0.1
        C9,16.6,9.1,16.6,9,16.4l-0.2,0v-0.1l0.1,0.1c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0L9,16c0,0,0,0,0,0h0C9,16.1,9,16,9.1,16V16
        c0.1,0,0.1-0.2,0.2-0.3v-0.1c0,0,0.1,0,0.1,0.1c0,0,0-0.1,0.1-0.1v0v-0.1c0,0,0.1,0,0,0.1c0.2-0.1,0.1-0.3,0.2-0.3
        c0.1-0.2,0-0.1,0-0.3h0.2v-0.1c0,0,0-0.1,0.1,0c0.1,0.1,0,0,0-0.1c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0-0.1c0.1,0,0.1-0.1,0.2-0.2
        c0.1-0.1,0,0.1,0.1,0.1c0-0.1,0.1-0.2,0.2-0.2c0-0.1,0.1-0.2,0.2-0.3c0,0,0.2-0.2,0.3-0.3c-0.1-0.1,0-0.2,0.1-0.3
        c0.1-0.1,0,0.1,0,0.1l0.1-0.1c0,0,0,0,0-0.1c0.1-0.1,0.1-0.3,0.2-0.4c0,0-0.2,0-0.2-0.2c0,0,0.1,0,0.1,0.1c0,0,0-0.1,0-0.1v0.1v0
        c0.1,0,0-0.1,0-0.1s0,0,0.1,0c0,0,0,0,0,0.1c0-0.1,0.1-0.1,0.2-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0.1-0.1,0.1,0,0.1,0s0.1-0.1,0-0.1
        c-0.1,0,0,0,0.1,0c0,0,0.2-0.3,0.1-0.2c0.1-0.2,0.3-0.4,0.4-0.6C12.4,11.4,12.4,11.3,12.5,11.2z M10.2,14.3
        C10.2,14.3,10.1,14.3,10.2,14.3L10.2,14.3C10.2,14.3,10.2,14.3,10.2,14.3z M15.4,7.3C15.4,7.3,15.4,7.2,15.4,7.3
        C15.4,7.2,15.5,7.2,15.4,7.3C15.4,7.2,15.4,7.2,15.4,7.3z M6.8,14.6L6.8,14.6C6.8,14.6,6.8,14.6,6.8,14.6z M7.1,14.9L7.1,14.9
        C7.1,14.9,7.1,14.9,7.1,14.9C7.1,14.9,7.1,14.9,7.1,14.9z M9,15.9L9,15.9C9.1,15.9,9.1,15.9,9,15.9C9,15.9,9,15.9,9,15.9z
        M9.4,15.4L9.4,15.4C9.4,15.4,9.4,15.4,9.4,15.4C9.4,15.4,9.4,15.4,9.4,15.4z M10.5,13.8C10.5,13.8,10.5,13.8,10.5,13.8l0.1-0.2
        C10.6,13.7,10.6,13.8,10.5,13.8z M10.6,13.6L10.6,13.6C10.6,13.6,10.6,13.6,10.6,13.6C10.6,13.6,10.6,13.6,10.6,13.6z M10.9,13.2
        L10.9,13.2C10.9,13.2,10.9,13.2,10.9,13.2C10.9,13.2,10.9,13.2,10.9,13.2z"
      />
      <path d="M3.9,12.2L3.9,12.2L3.9,12.2C3.9,12.2,3.9,12.2,3.9,12.2z" />
      <path d="M10.2,14.6C10.2,14.6,10.2,14.6,10.2,14.6C10.2,14.7,10.3,14.7,10.2,14.6z" />
      <path d="M9.5,15.6C9.5,15.6,9.5,15.6,9.5,15.6C9.5,15.6,9.5,15.6,9.5,15.6z" />
      <path d="M11.4,12.8C11.4,12.8,11.4,12.8,11.4,12.8C11.4,12.8,11.4,12.8,11.4,12.8z" />
      <path d="M9.5,15.6C9.5,15.5,9.5,15.6,9.5,15.6L9.5,15.6z" />
      <path d="M15.6,7C15.7,7,15.7,7,15.6,7C15.6,7,15.6,7,15.6,7z" />
      <path d="M9.4,15.7C9.4,15.7,9.4,15.7,9.4,15.7C9.4,15.7,9.4,15.7,9.4,15.7z" />
      <polygon points="4.2,12.3 4.1,12.3 4.1,12.3 4.1,12.3 	" />
      <path d="M13.8,5L13.8,5C13.8,5.1,13.9,5.1,13.8,5z" />
      <path d="M11.9,6.9C11.9,6.9,11.9,6.9,11.9,6.9C11.9,6.9,11.9,6.9,11.9,6.9z" />
      <path d="M11.8,7C11.8,6.9,11.9,6.9,11.8,7C11.9,6.9,11.8,7,11.8,7z" />
      <polygon points="8,11.8 7.9,11.7 7.9,11.8 	" />
      <path d="M15.5,9.6L15.5,9.6C15.5,9.6,15.6,9.5,15.5,9.6z" />
      <path d="M16.3,11.5C16.3,11.6,16.2,11.6,16.3,11.5C16.2,11.6,16.2,11.6,16.3,11.5L16.3,11.5z" />
      <path d="M17.2,11.5C17.2,11.5,17.2,11.5,17.2,11.5C17.2,11.5,17.1,11.5,17.2,11.5z" />
      <path d="M16.2,9.7C16.3,9.7,16.3,9.7,16.2,9.7C16.3,9.6,16.3,9.6,16.2,9.7z" />
      <path d="M16.2,9.7C16.2,9.7,16.2,9.7,16.2,9.7C16.2,9.7,16.2,9.6,16.2,9.7C16.1,9.7,16.1,9.7,16.2,9.7z" />
      <path d="M16.4,11.6C16.4,11.6,16.4,11.5,16.4,11.6L16.4,11.6C16.4,11.6,16.4,11.6,16.4,11.6z" />
      <path
        d="M17.8,11.8c0.1,0-0.1,0-0.1-0.2S17.7,11.7,17.8,11.8c0-0.1,0-0.2,0-0.3h-0.1c-0.1,0-0.1,0.1-0.1,0.1h-0.1c0,0,0,0,0,0
        c0,0,0,0,0,0h-0.1c0,0,0,0,0-0.1c0.1-0.1-0.1,0-0.1-0.1s-0.1,0-0.2,0c0,0,0,0,0,0c0,0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.1-0.1,0.1
        h-0.1c0,0,0,0,0,0c0,0-0.1,0-0.2,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0.1h-0.3H16c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
        c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h-0.1v-0.1v-0.1v0v-0.1c0,0,0.1,0,0.1,0
        l0,0c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0l-0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0-0.1c0,0,0,0,0.1,0c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1v0
        c0,0,0.1-0.1,0.1-0.1c0,0-0.1-0.1-0.1-0.1c0.1,0.1,0-0.1,0.1-0.1c-0.1,0,0-0.2-0.1-0.3c0,0,0,0,0,0c0,0,0,0,0,0s0-0.1,0.1-0.1
        c0.1,0,0-0.1-0.1-0.1v0c0,0,0.1,0,0.1,0C16,10.1,16,10,15.9,10v0c0,0,0.1-0.1,0.1-0.1c-0.1,0,0-0.1-0.1-0.1c-0.1,0,0.1-0.1,0.1-0.2
        c0,0-0.1,0-0.1,0c-0.1,0.1-0.3,0-0.4,0.1c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0h-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0
        c0-0.1,0,0,0-0.1c0-0.1-0.1,0.1-0.2,0.1c-0.1,0,0.1,0,0-0.1h0.1c-0.1-0.1,0,0,0-0.1s-0.1,0-0.2,0c-0.1,0,0,0.1,0,0.1
        c0,0-0.1-0.2-0.2-0.1c-0.1,0.1,0,0.2-0.2,0.1V9.6c-0.2,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0l-0.1-0.1h-0.1c0,0.2,0.1,0.4,0.1,0.5
        c0,0,0-0.1-0.1,0c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.2l0.1,0.1c0,0,0,0,0,0c-0.1,0,0.1,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.1
        c0,0.1,0,0.1,0,0.2c0,0-0.1,0-0.1-0.1c0,0.1-0.1,0-0.1,0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.1v-0.1l-0.1,0.1v0
        c-0.1,0-0.1,0-0.2-0.1l-0.2,0.1c0,0,0,0,0.1-0.1s0-0.1-0.1,0c-0.1,0.1,0,0,0,0.1s-0.1,0-0.2,0v0c-0.2,0-0.4,0-0.7,0v0.1
        c0-0.1,0.1,0,0.1,0.1h-0.1c0,0.1,0,0,0.1,0.1c0.1,0.1-0.1,0-0.1-0.1v0.1c0,0,0,0-0.1-0.1c-0.1-0.1-0.1,0.2,0,0.3v0.3
        c0,0,0,0.1,0,0.1c0.1,0,0,0,0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0,0.1,0.1c0,0.1-0.1,0.2-0.1,0.1c0,0.2,0,0.3,0.1,0.5
        c0,0,0,0,0,0c0.3-0.1,0.7,0.2,0.8,0l0.2-0.1c0.1,0,0,0,0,0.1c0,0.1,0.2,0,0.4,0c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0v-0.1
        h0.1c0,0,0,0.1,0,0.1c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0.2,0.1,0.1,0.2h-0.1c0.1,0.3,0,0.4,0.1,0.8c-0.1,0.1,0,0.3,0,0.3
        c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0.1,0,0.2-0.1,0.2,0c0.1,0,0.2,0,0.2-0.1c0-0.1,0.3,0,0.4,0s0.1,0,0.1-0.1c0,0.1,0.1,0.1,0.2,0
        c0.1-0.1,0,0,0,0.1c0,0.1,0.1,0,0.1-0.1c0.1,0.1,0.3-0.1,0.4,0.1l0.3-0.1L16,14.6c0.1,0,0,0.3,0.1,0.3c-0.1-0.2-0.1-0.4-0.1-0.6
        c0,0,0,0,0.1,0c0-0.1,0-0.3,0-0.4v-0.1c-0.1,0-0.1-0.1-0.2-0.1c0.1,0,0-0.1,0.2-0.1c0.1,0-0.1-0.1-0.1-0.2c0-0.1,0,0,0.1,0
        S16,13.5,16,13.4c0,0-0.1-0.2-0.1-0.2c0.1,0.1,0,0,0.1,0.1c0,0,0,0,0.1,0c0,0-0.1-0.1,0-0.1c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0
        c0,0,0,0.1,0,0.1c0,0,0.2,0,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0s-0.1,0,0,0.1
        c0,0.1,0.3-0.1,0.4-0.1c0-0.1,0.1,0.1,0.2,0c0.1-0.1,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0v-0.2c0-0.1,0-0.2,0-0.3c0,0,0-0.1-0.1-0.1
        c0.2-0.2-0.1-0.4,0.1-0.5v-0.1c0,0,0,0-0.1,0v-0.1c0,0,0,0,0,0h0v0c0,0,0,0,0,0L17.8,11.8C17.7,11.8,17.7,11.8,17.8,11.8z M16,11.3
        L16,11.3L16,11.3L16,11.3z M14.3,10.3C14.3,10.3,14.3,10.2,14.3,10.3C14.3,10.2,14.3,10.3,14.3,10.3L14.3,10.3z M17.3,11.6
        L17.3,11.6C17.3,11.6,17.3,11.6,17.3,11.6z M17.2,11.6C17.3,11.6,17.2,11.6,17.2,11.6L17.2,11.6C17.2,11.6,17.2,11.6,17.2,11.6z
         M17.1,11.6C17.1,11.6,17.1,11.6,17.1,11.6C17.1,11.6,17.1,11.6,17.1,11.6L17.1,11.6C17.1,11.6,17.1,11.6,17.1,11.6
        C17.1,11.6,17.1,11.6,17.1,11.6l-0.2,0c0,0,0,0,0-0.1C16.9,11.5,17,11.6,17.1,11.6z M16.9,11.5L16.9,11.5L16.9,11.5
        C16.8,11.6,16.8,11.6,16.9,11.5z M15,9.8L15,9.8C15,9.8,15,9.8,15,9.8C15,9.7,15,9.8,15,9.8z M14.8,9.8L14.8,9.8
        C14.9,9.8,14.9,9.8,14.8,9.8z M14.5,9.9L14.5,9.9L14.5,9.9C14.5,9.8,14.5,9.9,14.5,9.9z M15.9,14.3v-0.1
        C15.9,14.2,15.9,14.2,15.9,14.3z"
      />
      <path d="M16.3,11.6L16.3,11.6C16.4,11.6,16.4,11.6,16.3,11.6c0-0.1,0-0.1,0-0.1L16.3,11.6C16.3,11.5,16.3,11.5,16.3,11.6L16.3,11.6z" />
      <path
        d="M16.1,11.5C16.1,11.5,16.1,11.6,16.1,11.5C16.1,11.6,16.1,11.6,16.1,11.5C16.1,11.6,16.1,11.6,16.1,11.5
        C16.1,11.6,16.1,11.6,16.1,11.5C16.1,11.6,16.1,11.6,16.1,11.5c0,0.1,0,0.1,0,0.1C16.1,11.6,16.1,11.6,16.1,11.5
        C16.2,11.6,16.2,11.6,16.1,11.5C16.2,11.6,16.2,11.5,16.1,11.5C16.2,11.5,16.2,11.5,16.1,11.5C16.1,11.5,16.2,11.5,16.1,11.5
        C16.2,11.5,16.1,11.5,16.1,11.5C16.1,11.5,16.1,11.5,16.1,11.5C16.1,11.5,16.1,11.5,16.1,11.5C16.1,11.5,16.1,11.5,16.1,11.5z"
      />
      <path d="M14.4,9.6C14.5,9.6,14.5,9.6,14.4,9.6C14.5,9.6,14.5,9.6,14.4,9.6C14.5,9.6,14.4,9.6,14.4,9.6z" />
      <path d="M14.3,15.3C14.3,15.3,14.3,15.3,14.3,15.3C14.3,15.3,14.3,15.3,14.3,15.3L14.3,15.3z" />
      <path d="M16,13.3L16,13.3C16.1,13.2,16.1,13.2,16,13.3C16,13.2,16,13.3,16,13.3z" />
      <path d="M15.4,9.8C15.4,9.8,15.4,9.8,15.4,9.8C15.4,9.8,15.4,9.8,15.4,9.8z" />
      <path d="M16,9.5C16,9.6,16,9.6,16,9.5C16,9.5,16,9.5,16,9.5C16,9.5,16,9.5,16,9.5z" />
      <path d="M16.3,9.5c-0.1,0-0.2,0-0.2,0.1c0,0,0.1,0,0.1,0.1C16.2,9.6,16.2,9.5,16.3,9.5z" />
      <polygon points="13.8,11.3 13.8,11.3 13.8,11.3 	" />
      <polygon points="16.2,11.4 16.2,11.4 16.2,11.4 	" />
      <path d="M16.7,11.5C16.7,11.5,16.7,11.4,16.7,11.5C16.6,11.5,16.7,11.5,16.7,11.5z" />
    </g>
  </svg>
);

PLIcon.defaultProps = {
  fill: '#FFF',
  borderColor: '#111'
};

export default PLIcon;
