//
import reduce from 'lodash/reduce';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { FtueState } from './types';
import { TourStep, ViewedTour } from 'types';

type FtueTourResponse = {
  name: string;
  steps: TourStep[];
};

const stepNamesEqual = stepA => stepB => stepB.name === stepA.name;

const shouldViewStep = (toursViewed, tour) => step => {
  const viewedTour = toursViewed[tour.name];
  if (isEmpty(viewedTour)) return true;

  const foundStep = viewedTour.steps.find(stepNamesEqual(step));

  return !foundStep || !foundStep.viewed;
};

export const filterViewedSteps =
  (toursViewed: ViewedTour) =>
  (tours: FtueTourResponse[]): FtueTourResponse[] => {
    if (isEmpty(toursViewed)) return tours;

    return tours
      .map(tour => ({
        ...tour,
        steps: tour.steps.filter(shouldViewStep(toursViewed, tour))
      }))
      .filter(tour => !isEmpty(tour.steps));
  };

export const updateWithViewedStep = (state: FtueState, viewedStep: TourStep) => {
  const { toursViewed, queuedTours } = state;
  const currentName = queuedTours[0];

  if (!toursViewed[currentName]) {
    return {
      ...toursViewed,
      [currentName]: {
        name: currentName,
        steps: [viewedStep]
      }
    };
  }

  return reduce(
    toursViewed,
    (update, tour) => {
      if (currentName !== tour.name) {
        update[tour.name] = tour;

        return update;
      }

      const updatedTour = cloneDeep(tour);
      updatedTour.steps.push(viewedStep);
      update[currentName] = updatedTour;

      return update;
    },
    {}
  );
};

const getUnviewedStepsFor =
  (name, toursViewed) =>
  (needViewed, step): number => {
    const foundStep = toursViewed[name].steps.find(stepNamesEqual(step));

    if (!foundStep || !foundStep.viewed) {
      return needViewed + 1;
    }

    return needViewed;
  };

export function processHasUnviewedSteps(
  name: string,
  { allTours, toursViewed }: FtueState
): boolean {
  if (isEmpty(allTours) || !allTours[name]) return false;

  if (isEmpty(toursViewed) || !toursViewed[name]) return true;

  const unviewedSteps = allTours[name].steps.reduce(getUnviewedStepsFor(name, toursViewed), 0);

  return unviewedSteps > 0;
}

export function removeFromTourQueue({ queuedTours }: FtueState, toRemove: string[] = []) {
  return queuedTours.reduce((queued, currTour) => {
    if (toRemove.includes(currTour)) return queued;

    (queued as any).push(currTour);

    return queued;
  }, []);
}
