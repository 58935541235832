import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { removeAuthCookies } from '../../util/auth-cookie-util';

const SSOLogoutFrame: React.FC = () => {
  const { instance } = useMsal();

  useEffect(() => {
    removeAuthCookies();
    instance.logoutRedirect();
  });

  return null;
};

export default SSOLogoutFrame;
