import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/Page/AnnotationLayer.css';
import { isSafari } from 'react-device-detect';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import flow from 'lodash/fp/flow';
import { isEmpty, isNumber } from 'lodash';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFullScreen } from 'state/providers/FullScreenProvider';
import { useZoom, DEFAULT_ZOOM_SCALE } from 'state/providers/ZoomProvider';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const t = key => translate('modals', key);

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  workbook: any;
  currentPage: number;
  fingerprint?: string | null;
  isTwoPages?: boolean;
};

const PdfViewer: any = ({ workbook, currentPage, isTwoPages = false }: Props) => {
  const navigate = useNavigate();
  const params = useParams();

  const renderedDocument = document.getElementsByClassName('react-pdf__Document')?.[0] as any;
  const renderedCanvas = Array.from(
    document.getElementsByClassName('react-pdf__Page__canvas')
  ) as any;
  const renderedWorkbook = document.getElementById('workbook') as any;

  const [documentHeight, setDocumentHeight] = useState<number>(
    renderedDocument?.clientHeight ?? 600
  );
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [{ isFullScreen }] = useFullScreen();
  const [{ zoomScale }] = useZoom();
  const [aspectRatio, setAspectRatio] = useState('1/1');

  const isZoomedOut = zoomScale < DEFAULT_ZOOM_SCALE;
  const isZoomedIn = zoomScale > DEFAULT_ZOOM_SCALE;

  const isPortrait = workbook.orientation === 'portrait';

  const {
    documentCSS,
    documentContainer,
    pageWrapper,
    container,
    pageContainer,
    left,
    right
  }: any = useStyles()({
    workbook,
    aspectRatio,
    zoom: zoomScale * 100,
    isZoomedOut,
    isZoomedIn,
    isTwoPages,
    isPortrait,
    isSafari,
    isFullScreen
  }) as any;

  const onPageLoadSuccess = ({ width, height }) => {
    setAspectRatio(`${width} / ${height}`);
  };

  const handlePageClick = pageNumber => {
    if (!isNumber(pageNumber)) return;

    const { bookCode, edition } = params as any;

    let currentPageNumber = pageNumber;

    if (!isTwoPages) currentPageNumber = pageNumber % 2 !== 0 ? pageNumber - 1 : pageNumber;

    const url = `/books/${String(bookCode)}/${String(edition)}/${currentPageNumber}${
      isTwoPages ? '/zoom' : ''
    }`;

    navigate(url);
  };

  const isElementInViewport = el => {
    const rect = el.getBoundingClientRect();

    return Math.abs(rect.left) + rect.width < window.innerWidth;
  };

  const loadPdfWorker = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  };

  const scrollToCenter = () => {
    if (!renderedWorkbook || !renderedCanvas?.length) return;
    const twoPageCanvasToUse = isElementInViewport(renderedCanvas[0])
      ? renderedCanvas[0]
      : renderedCanvas[1];

    const canvasToUse = !isTwoPages ? renderedCanvas[0] : twoPageCanvasToUse;
    canvasToUse.scrollIntoView({ block: 'nearest', inline: 'center' });
  };

  useEffect(() => loadPdfWorker(), [workbook?.id]);

  useEffect(() => {
    if (!isDocumentLoaded) return;
    setDocumentHeight(renderedDocument?.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen, renderedDocument?.clientHeight, isTwoPages, isDocumentLoaded]);

  useEffect(() => {
    scrollToCenter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomScale]);

  return (
    <Box className={container}>
      <Box
        className={documentContainer}
        style={{
          transform: `scale(${zoomScale})`,
          transformOrigin: 'center'
        }}
      >
        <Document
          className={documentCSS}
          file={workbook.bookUrl}
          loading={t('loading')}
          renderMode="canvas"
          onLoadSuccess={() => setIsDocumentLoaded(true)}
        >
          {!isEmpty(workbook.bookUrl) && (
            <Box id="pageContainer" className={pageContainer}>
              <Box
                className={`${left} ${pageWrapper}`}
                onDoubleClick={() => handlePageClick(currentPage)}
              >
                <Page
                  pageNumber={currentPage + workbook.pageOffset}
                  onLoadSuccess={onPageLoadSuccess}
                  height={documentHeight}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Box>
              {isTwoPages && (
                <Box
                  className={`${right} ${pageWrapper}`}
                  onDoubleClick={() => handlePageClick(currentPage + 1)}
                >
                  <Page
                    pageNumber={currentPage + 1 + workbook.pageOffset}
                    onLoadSuccess={onPageLoadSuccess}
                    height={documentHeight}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </Box>
              )}
            </Box>
          )}
        </Document>
      </Box>
    </Box>
  );
};

const useStyles = () =>
  makeStyles(() => ({
    container: (props: any) => ({
      position: 'relative',
      height: '100%',
      width: '100%',
      display: props.isZoomedOut ? 'flex' : 'unset',
      justifyContent: props.isZoomedOut ? 'center' : 'unset',
      alignItems: props.isZoomedOut ? 'center' : 'unset',
      cursor: props.isZoomedIn ? 'inherit' : 'default'
      //   overflow: 'auto'
    }),
    documentCSS: {
      transform: 'translateZ(0)',
      backfaceVisibility: 'hidden',
      perspective: 0
    },
    pageContainer: (props: any) => ({
      height: '95%',
      maxWidth: `${props.zoom - 5}%`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: props.isSafari && props.isTwoPages && !props.isPortrait ? '100%' : 'max-content'
    }),
    documentContainer: (props: any) => ({
      height: `${props.zoom}%`,
      width: `${props.zoom}%`,
      position: 'relative',
      overflow: 'auto',
      display: props.isZoomedOut ? 'flex' : 'unset',
      justifyContent: props.isZoomedOut ? 'center' : 'unset',
      alignItems: props.isZoomedOut ? 'center' : 'unset',
      '& > div:first-of-type': {
        height: `${props.zoom}%`,
        width: `${props.zoom}%`,
        position: 'relative',
        overflow: 'auto',
        minWidth: props.isTwoPages && !props.isPortrait ? 'unset' : `100%`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }),
    pageWrapper: (props: any) => ({
      height: 'min-content',
      width: 'auto',
      maxHeight: '100%',
      cursor: props.isZoomedIn ? 'inherit' : 'pointer',
      aspectRatio: props.aspectRatio,
      '& > div:first-of-type': {
        height: 'min-content !important',
        maxHeight: 'inherit',

        aspectRatio: props.aspectRatio,
        width: isSafari ? `${props.zoom - 5}vw` : 'unset',
        '& > canvas:first-of-type': {
          height: 'auto !important',
          maxHeight: 'inherit',
          maxWidth: '100%',
          width: 'auto !important'
        },
        '& > div:nth-of-type(1n)': {
          display: 'none'
        }
      }
    }),
    left: (props: any) => ({
      boxShadow: '0 0 25px 3px #888',
      padding: props.isTwoPages ? '0 35px 0 0' : '0',
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: isSafari ? (props.isTwoPages ? '50%' : 'max-content') : 'unset'
    }),
    right: (props: any) => ({
      boxShadow: '10px 0 25px 3px #888',
      padding: '0 0px 0 35px',
      background: props.isTwoPages ? 'linear-gradient(to left, white 96%, #efeded)' : 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: isSafari ? '50%' : 'unset'
    })
  }));

export default flow(onlyUpdateForKeys(['currentPage', 'fingerprint', 'workbook']))(
  PdfViewer
) as any;
