import React, { PureComponent } from 'react';
import withRouter from 'state/hooks/withRouter';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Typography } from '@mui/material';
import ResourcePlayer from '../../../components/Resources/ResourcePlayer/ResourcePlayer';
import PrekittApi from '../../../api';
import DownloadPdf from 'components/DownloadPdf';

import {
  setResourceFilters,
  resetResources,
  updateCounter
} from '../../../actions/resource-actions';
import {
  fetchResources,
  createUserFavorite,
  removeUserFavorite,
  fetchFavoriteResources,
  fetchFilterTags,
  fetchClickAways
} from '../../../actions/resource-operations';
import { getFilteredResources } from '../../../actions/resource-selectors';
import { getFilteredClickAways } from '../../../actions/clickaway-selectors';
import { selectResource, toggleResourcePlayerVisibility } from '../../../actions/resource-actions';
import ResourcesList from '../../../components/Resources/ResourcesList/ResourcesList';
import ResourceFilterBreadcrumbs from '../../../components/Resources/ResourceFilterBreadcrumbs';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

import { Resource, UserFavorite } from 'types';
import { AppState } from '../../../reducers';

import { ReactRouterProps } from '../../Main';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import { DraggableModalTitle } from '../../../components/core/Modals/DraggableModalTitle';
import { LiteResource, FilterGroup } from 'reducers/resource-reducer';
import { WorkbookState } from 'modules/workbook';
import { getPdfResourceUrlOrNull } from 'util/resource-player-util';

type Props = {
  clickAways: Array<Resource>;
  filterGroups: Array<FilterGroup>;
  locale: string;
  resources: Array<Resource>;
  selectedFilterTags: Array<number>;
  searchText: string;
  fetchResources: (type?: string) => Array<Resource>;
  fetchClickAways: (type?: string) => Array<Resource>;
  fetchFavoriteResources: () => Array<UserFavorite>;
  fetchFilterTags: () => Array<FilterGroup>;
  selectedResource: LiteResource;
  showResourcePlayer: boolean;
  counter: number;
  workbookState: WorkbookState;
  selectResource: (resource: LiteResource) => any;
  createUserFavorite: (resource: Resource, isClickAway: boolean) => any;
  removeUserFavorite: (favoriteId: number) => any;
  toggleResourcePlayerVisibility: (show: boolean) => any;
  setResourceFilters: (
    searchText?: string,
    tagIds?: Array<number>,
    resourcesSection?: string
  ) => any;
  resetResources: () => any;
  updateCounter: (operationType: string) => number;
} & ReactRouterProps &
  UseModalDispatch;

const t = (key, options?) => translate('resources', key, options);

class AllResources extends PureComponent<Props> {
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.fetchData();
    }
  }
  fetchData = () => {
    this.props.resetResources();
    this.props.updateCounter('reset');
    this.props.fetchFilterTags();
    if (Object.entries(this.props.workbookState.allWorkbooks).length > 0) {
      this.props.fetchResources();
    }
    this.props.fetchClickAways();
    this.props.fetchFavoriteResources();
  };
  resetFilters = () => {
    this.props.setResourceFilters('', []);
  };
  handleSelectResource = (resource: LiteResource): void => {
    this.props.selectResource(resource);
    this.props.toggleResourcePlayerVisibility(true);
    this.openResourcePlayer(resource);
  };
  handlePinResource = (resource: Resource): void => {
    if (!resource.favorites || resource.favorites.length === 0) {
      this.props.createUserFavorite(resource, !resource.resourceTypeId);
    }
  };
  handleRemoveFavorite = (resource: Resource) => {
    if (resource.favorites && resource.favorites.length > 0) {
      this.props.removeUserFavorite(resource.favorites[0].id);
    }
  };
  handleClosePlayer = (): void => {
    this.props.toggleResourcePlayerVisibility(false);
  };
  createEvent = (resource: LiteResource) => {
    const event = {
      section: 'resources',
      resourceId: resource.id,
      resourceType: resource.type
    };

    return () => PrekittApi.createEvent('accessResource', event);
  };

  openResourcePlayer = selectedResource => {
    const clickAway = getPdfResourceUrlOrNull('clickAway', selectedResource);

    const MaybeEndAdornment = !!clickAway && {
      EndAdornment: <DownloadPdf url={clickAway} name={selectedResource.title} />
    };

    this.props.openDraggableModal({
      Title: props => (
        <DraggableModalTitle title={selectedResource.title} {...MaybeEndAdornment} {...props} />
      ),
      body: (
        <ResourcePlayer
          selectedResource={selectedResource}
          loadedData={this.createEvent(selectedResource)}
        />
      ),
      ariaLabel: tA11y('aria.modal', { title: selectedResource.title }),
      options: { closeOnBackdropClick: true }
    });
  };

  searchText = (searchText: string) => {
    const oldValue = this.props.searchText;

    if (searchText.length > 2 || oldValue !== '') {
      this.props.setResourceFilters(searchText, []);
    }
  };

  getResources = () => {
    return this.props.selectedFilterTags.length && this.props.resources.length
      ? this.props.resources
      : this.props.resources.concat(this.props.clickAways);
  };

  render() {
    const { allResources } = styles;

    return (
      <div
        id="Resources Tab Panel"
        role="tabpanel"
        aria-labelledby="Resources Tab"
        className={css(allResources)}
      >
        <br />
        <div style={{ display: 'flex' }}>
          <Typography variant="h4" style={{ marginLeft: '30px', marginRight: '15px' }}>
            {t('filteredBy')}:
          </Typography>
          <ResourceFilterBreadcrumbs />
        </div>
        <ResourcesList
          resourcesItems={this.getResources()}
          onSelectItem={this.handleSelectResource}
          onClickPinIcon={this.handlePinResource}
          onRemoveItem={this.handleRemoveFavorite}
        />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  allResources: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }
});

const mapStateToProps = (state: AppState) => {
  return {
    clickAways: getFilteredClickAways(state.resources),
    counter: state.resources.counter,
    filterGroups: state.resources.filterGroups,
    locale: state.characterSelection.locale,
    resources: getFilteredResources(state.resources),
    searchText: state.resources.selectedFilters.searchText,
    selectedFilterTags: state.resources.selectedFilters.selectedTags,
    selectedResource: state.resources.selectedResource,
    showResourcePlayer: state.resources.showResourcePlayer,
    workbookState: state.workbook
  };
};

export default compose(
  withModal,
  withRouter,
  connect(mapStateToProps, {
    setResourceFilters,
    resetResources,
    updateCounter,
    fetchResources,
    fetchClickAways,
    fetchFilterTags,
    selectResource,
    toggleResourcePlayerVisibility,
    createUserFavorite,
    removeUserFavorite,
    fetchFavoriteResources
  })
)(AllResources);
