//
import React from 'react';
import Config from 'config';
import WebLink from '../../../components/core/WebLink';
import { StyleSheet, css } from 'aphrodite';
import { lnfBlue } from '../../../styles/style-constants';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const translateCopyright = (key, options?) => translate('copyright', key, options);

const { copyrightEmail } = Config;
type Props = {
  productTitle: number;
};

const getTitleFromProduct = {
  '20': 'Pre-K Interactive Teaching Tool – Readiness & Writing',
  '21': 'Pre-K Interactive Teaching Tool – Pre K System'
};

export const TrialUserCopyright = (props: Props) => (
  <div className={css(styles.modalText)}>
    <p>{translateCopyright('freeTrial1', { title: getTitleFromProduct[props.productTitle] })}</p>
    <p>
      {translateCopyright('freeTrial2')}
      <WebLink
        className={css(styles.anchor)}
        to={`mailto:${copyrightEmail}`}
        name="legal@LWTears.com"
      />
      {translateCopyright('phone')}
    </p>
  </div>
);

export const LicensedUserCopyright = (props: Props) => (
  <div className={css(styles.modalText)}>
    <p>{translateCopyright('fullLicense1', { title: getTitleFromProduct[props.productTitle] })}</p>
    <p>
      {translateCopyright('fullLicense2')}
      <WebLink
        className={css(styles.anchor)}
        to={`mailto:${copyrightEmail}`}
        name="legal@LWTears.com"
      />
      {translateCopyright('phone')}
    </p>
  </div>
);

const styles = StyleSheet.create({
  anchor: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: lnfBlue,
    ':hover': {
      textDecoration: 'none'
    }
  },
  modalText: {
    margin: 20,
    fontWeight: 400,
    textAlign: 'left',
    userSelect: 'none'
  }
});
