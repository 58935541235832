import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@mui/material/Button';
import FtueCallout from '../Ftue/FtueCalloutContainer';
import { remCalc } from '../../util/style-util';
import maybe from '../core/enhancers/maybe';
import LeftArrowIcon from '../icons/LeftArrowIcon';
import RightArrowIcon from '../icons/RightArrowIcon';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props =
  | {
      name: string;
      isDisabled: boolean;
      turnPage: (moveForward: boolean) => any;
    }
  | any;

const styles = StyleSheet.create({
  pageNavButton: {
    border: 'none',
    backgroundColor: 'transparent',
    maxWidth: remCalc(80),
    transition: 'transform 100ms ease-in',
    ':hover:enabled': {
      transform: 'scale(1.2)'
    },
    ':focus': {
      backgroundColor: 'transparent'
    },
    ':active': {
      backgroundColor: 'transparent'
    }
  }
});

const MaybeFtueCallout: any = maybe(FtueCallout);
const translateCommon = key => translate('common', key);
const PageNavButton = ({ name, isDisabled, turnPage }: Props) => {
  const moveForward = name === translateCommon('next');
  const ArrowIcon: any = name === translateCommon('next') ? RightArrowIcon : LeftArrowIcon;

  return (
    <div>
      <Button
        aria-label={name}
        name={name}
        size="large"
        className={`grow ${css(styles.pageNavButton)}`}
        disabled={isDisabled}
        onClick={turnPage}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <ArrowIcon direction={moveForward ? 'right' : 'left'} />
      </Button>
      <MaybeFtueCallout
        shouldRender={name === translateCommon('next')}
        step="pageNavArrows"
        name="bookNavigation"
        position="top"
        justifyPointer="center"
      />
    </div>
  );
};

export default PageNavButton;
