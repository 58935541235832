//
import React, { Component } from 'react';
import spinnerArrowsPath from '../../../images/Arrows.svg';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../../util/style-util';
import { spinningKeyframe } from '../../../styles/animations';
import { getTranslatedImage } from 'util/translation-util';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props = {
  show: boolean;
  wait?: number;
};

type State = {
  show: boolean;
};

class LoadingScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      show: false
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.wait) {
      if (prevProps.show !== this.props.show) {
        setTimeout(() => {
          this.toggleLoadingScreenVisibility();
        }, 2000);
      }
    }
  }

  toggleLoadingScreenVisibility = () => {
    this.setState({ show: this.props.show });
  };

  render(): any {
    const { loadingContainer, notebook, spinner } = styles;
    const showLoadingScreen = this.props.wait ? this.state.show : this.props.show;

    return showLoadingScreen ? (
      <div className={css(loadingContainer)}>
        <img
          src={getTranslatedImage('notepad.svg')}
          className={css(notebook)}
          alt={tA11y('alt.notepadIcon')}
        />
        <img src={spinnerArrowsPath} className={css(spinner)} alt={tA11y('alt.spinnerIcon')} />
      </div>
    ) : null;
  }
}

const styles = StyleSheet.create({
  loadingContainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 8,
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none'
  },
  notebook: {
    width: remCalc(150)
  },
  spinner: {
    animationName: spinningKeyframe,
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    width: remCalc(50),
    marginLeft: remCalc(-95)
  }
});

export default LoadingScreen;
