import flow from 'lodash/fp/flow';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'state/hooks/withRouter';
import Transition from 'react-transition-group/Transition';
import { StyleSheet, css } from 'aphrodite/no-important';
import SquareButton from '../SquareButton';
import PreKittMessageFrame from './PreKittMessageFrame';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { saveUserSettings, settingsTypes } from '../../../modules/user';
import { getCurrentStudentWorkbook } from '../../../modules/workbook';
import { remCalc } from '../../../util/style-util';
import { getClickAwayResourcesLink } from '../../../util/workbook-util';
import { black, white, darkGray, lightGray } from '../../../styles/style-constants';
import Config from 'config';
import alert from '../../../images/alert.svg';
import clickAway from '../../../images/a-click-away.svg';
import prekittDevices from '../../../images/prekitt-devices.png';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import Checkbox from '@mui/material/Checkbox';
import { UserSetting } from 'types';
import { AppState } from '../../../reducers';
import { UserState } from '../../../modules/user';
const translateModals = key => translate('modals', key);

const { companyURL } = Config;
type Props =
  | ({
      isClickAwayOnly: boolean;
      hasWorkbooks: boolean;
      router?: any;
      saveUserSettings?: (type: string, setting: UserSetting) => any;
      user?: UserState;
      workbook?: any;
    } & UseModalDispatch)
  | any;

const transitionStyles = {
  entering: {
    opacity: 0,
    transform: 'translateY(-50px)'
  },
  entered: {
    opacity: 1,
    transform: 'translateY(0)'
  },
  exiting: {
    opacity: 0,
    transform: 'translateY(-50px)'
  }
};

const duration = 500;

export class ExpiredLicenseModal extends Component<Props> {
  handleGotoClickAway = () => {
    const { closeModal, router } = this.props;
    router.navigate(getClickAwayResourcesLink());
    closeModal();
  };

  handleGotoWorkbookSelector = () => {
    const { closeModal, router } = this.props;
    router.navigate('/setup/workbook');
    closeModal();
  };

  handleChangeSuppressExpirationCheckbox = (e: any) =>
    this.props.saveUserSettings(settingsTypes.suppressedExpirationAlerts, e.currentTarget.checked);

  getExpirationInstructions = (hasClickAway: boolean) => {
    if (hasClickAway) {
      return (
        <p>
          {translateModals('licenseExpiredClickAway1')}
          <img src={clickAway} className={css(styles.clickAway)} alt={tA11y('alt.clickAway')} />
          {translateModals('licenseExpiredClickAway2')}
        </p>
      );
    } else {
      return <p className={css(styles.bold)}>{translateModals('licenseExpired')}</p>;
    }
  };

  getActions = () => {
    const { isClickAwayOnly, hasWorkbooks } = this.props;
    const actions = [
      <li key="purchase" className={css(styles.actionListItem)}>
        <a href={`${companyURL}/prekitt-purchase`}>
          <SquareButton text={translateModals('licensePurchase')} />
        </a>
      </li>
    ];

    if (isClickAwayOnly) {
      actions.push(
        <li key="clickaway" className={css(styles.actionListItem)}>
          <div onClick={this.handleGotoClickAway} className={css(styles.pointer)}>
            {translateModals('licenseNoThanksClickAway')}
          </div>
        </li>
      );
    } else if (hasWorkbooks) {
      actions.push(
        <li key="selector" className={css(styles.actionListItem)}>
          <div onClick={this.handleGotoWorkbookSelector} className={css(styles.pointer)}>
            {translateModals('licenseNoThanksWorkbook')}
          </div>
        </li>
      );
    }

    return actions;
  };

  render = () => {
    const { user, isClickAwayOnly } = this.props;

    return (
      <Transition in appear unmountOnExit timeout={duration}>
        {state => (
          <PreKittMessageFrame
            classList={[styles.modalPositioning]}
            style={{
              ...transitionStyles[state],
              transition: `all ${duration}ms ease-in-out`
            }}
          >
            <div className={css(styles.expiredLicenseModal)}>
              <h2 className={css(styles.modalHeading)}>
                <img src={alert} className={css(styles.alert)} alt={tA11y('alt.alert')} />
                {translateModals('prekittExpired')}
              </h2>
              {this.getExpirationInstructions(isClickAwayOnly)}
              <div className={css(styles.featuresContainer)}>
                <div className={css(styles.feature, styles.modalText)}>
                  {isClickAwayOnly && (
                    <span className={css(styles.bold)}>{translateModals('getFull')}</span>
                  )}
                  <ul className={css(styles.featureList)}>
                    <li>{translateModals('interactiveStudent')}</li>
                    <li>{translateModals('digitalTeacher')}</li>
                    <li>{translateModals('digitalMatMan')}</li>
                    <li>{translateModals('multimedia')}</li>
                  </ul>
                </div>
                <div className={css(styles.feature)}>
                  <img
                    src={prekittDevices}
                    className={css(styles.licenseImage)}
                    alt={tA11y('alt.prekittLogo')}
                  />
                </div>
              </div>
              <ul id="actions" className={css(styles.actionList)}>
                {this.getActions()}
              </ul>
              {isClickAwayOnly && (
                <div className={css(styles.suppressExpirationContainer)}>
                  <label htmlFor="suppressExpirationAlerts">
                    <Checkbox
                      id="suppressExpirationAlerts"
                      onChange={this.handleChangeSuppressExpirationCheckbox}
                      checked={user.settings[settingsTypes.suppressedExpirationAlerts] || false}
                      size="small"
                      color="default"
                    />
                    <span className={css(styles.suppressExpirationLabel)}>
                      {translateModals('dontShow')}
                    </span>
                  </label>
                </div>
              )}
            </div>
          </PreKittMessageFrame>
        )}
      </Transition>
    );
  };
}

const styles = StyleSheet.create({
  modalPositioning: {
    top: '10vh'
  },
  expiredLicenseModal: {
    textAlign: 'center',
    fontWeight: 500,
    width: remCalc(400),
    '@media(min-width: 1000px)': {
      width: remCalc(600)
    }
  },
  alert: {
    width: remCalc(40),
    padding: remCalc(0, 8, 4),
    '@media(min-width: 1000px)': {
      width: remCalc(50)
    }
  },
  clickAway: {
    width: remCalc(40),
    padding: remCalc(0, 2, 4, 8),
    '@media(min-width: 1000px)': {
      width: remCalc(50)
    }
  },
  modalHeading: {
    backgroundColor: black,
    color: white,
    fontFamily: 'futura-pt',
    fontWeight: 600,
    fontSize: remCalc(24),
    padding: remCalc(5),
    margin: remCalc(0, 0, 10),
    '@media(min-width: 1000px)': {
      fontSize: remCalc(30)
    }
  },
  modalText: {
    textAlign: 'left'
  },
  featuresContainer: {
    display: 'flex'
  },
  feature: {
    flex: 1,
    padding: remCalc(15)
  },
  featureList: {
    padding: remCalc(10, 25)
  },
  licenseImage: {
    maxWidth: '100%'
  },
  bold: {
    fontWeight: 600
  },
  pointer: {
    cursor: 'pointer',
    color: '#337ab7',
    ':hover': {
      color: '#26537B',
      textDecoration: 'underline'
    }
  },
  actionList: {
    listStyle: 'none'
  },
  actionListItem: {
    display: 'inline-block',
    padding: remCalc(0, 20)
  },
  suppressExpirationContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: lightGray,
    padding: remCalc(2),
    margin: '0px 1px'
  },
  suppressExpirationLabel: {
    color: darkGray,
    fontSize: remCalc(16),
    margin: remCalc(0, 0, 0, 10),
    fontWeight: 400
  }
});

const mapStateToProps = (state: AppState, { router: { params } }) => ({
  workbook: getCurrentStudentWorkbook(state, params),
  user: state.user
});

const withRedux = connect(mapStateToProps, {
  saveUserSettings
});

export default flow(withModal, withRedux, withRouter)(ExpiredLicenseModal);
