import * as types from './types';

export const fetchCharactersRequest = (
  application: string
): types.FETCH_CHARACTERS_REQUEST_ACTION => ({
  type: types.FETCH_CHARACTERS_REQUEST,
  application
});

export const fetchCharactersSuccess = (characters: any): types.FETCH_CHARACTERS_SUCCESS_ACTION => ({
  type: types.FETCH_CHARACTERS_SUCCESS,
  characters
});

export const fetchCharactersFailure = (error: Error): types.FETCH_CHARACTERS_FAILURE_ACTION => ({
  type: types.FETCH_CHARACTERS_FAILURE,
  error
});

export const selectCharacter = (selectedCharacter: any): types.SELECT_CHARACTER_ACTION => ({
  type: types.SELECT_CHARACTER,
  character: selectedCharacter
});

export const changeAppLocale: any = (locale: string) => ({
  type: types.CHANGE_APP_LOCALE,
  locale
});

export const toggleAppModalVisibility = (): types.TOGGLE_APP_MODAL_VISIBILITY_ACTION => ({
  type: types.TOGGLE_APP_MODAL_VISIBILITY
});

export const toggleAppLoadingScreenVisibility = (): types.TOGGLE_APP_LOADING_SCREEN_VISIBILITY_ACTION => ({
  type: types.TOGGLE_APP_LOADING_SCREEN_VISIBILITY
});
