import { PureComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import withRouter from 'state/hooks/withRouter';
import { getLastPathFor } from '../util/local-storage-util';
import { queueTour } from '../modules/ftue';
import { sendEmailWelcome } from '../modules/user';
import { Workbook, BookCode } from 'types';
import { UserState } from '../modules/user';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import flow from 'lodash/fp/flow';
import { toggleSlideoutMenu, toggleSlideoutMenuTutorial } from '../actions/ui-actions';
import { getCurrentWorkbook } from '../modules/workbook/workbook-selectors';

type LocationState = {
  referrer: {
    pathname: string;
  };
  code: BookCode;
};

type PreKittLocation = {
  search: string;
  state?: LocationState;
};

type WorkbooksCollection = any;

type Props = {
  location: PreKittLocation;
  isDeviceCompatible: boolean;
  isAuthenticated: boolean;
  allWorkbooks: WorkbooksCollection;
  studentWorkbooks: Workbook[];
  user: UserState;
  getUserSettings: (id: number) => any;
  sendEmailWelcome: () => any;
  queueTour: (name: string) => any;
} & UseModalDispatch &
  any;

type State = {
  redirectPath?: string;
};
class LoginPage extends PureComponent<Props, State> {
  state = {
    redirectPath: ''
  };

  componentDidMount() {
    this.validateUser();
  }

  validateUser = async () => {
    this.getRedirect();
  };

  getRedirect(): void {
    const { isAuthenticated, isDeviceCompatible, user, location, allWorkbooks } = this.props;
    if (isAuthenticated) {
      const hasClickAway = user.licenses.filter(license => license.clickAwayAccess).length !== 0;
      if (!isDeviceCompatible) {
        this.setState({ redirectPath: 'setup/device' });

        return;
      }
      // Determine if the user entered a direct URL other than /login
      if (location.state && location.state.referrer) {
        this.setState({ redirectPath: location.state.referrer.pathname });

        return;
      } else if (isEmpty(allWorkbooks)) {
        this.setState({ redirectPath: hasClickAway ? 'resources' : 'expired' });
        return;
      }

      let redirect;
      if (user.settings.licensing && user.id) {
        redirect = getLastPathFor(user.id);
      }

      if (!redirect) {
        // commented out for now

        // const firstTeachersGuide = values(allWorkbooks).filter(
        //   workbook => workbook.type === 'teachersGuide'
        // )[0];
        // redirect = getPathForWorkbook(firstTeachersGuide);
        redirect = '/home';
      }
      const hasActiveLicense =
        user.licenses.filter(license => license.licenseTypeId && !license.expired).length !== 0;
      redirect = !hasActiveLicense && hasClickAway ? '/home' : redirect;
      this.setState({ redirectPath: redirect });

      return;
    }
    this.setState({ redirectPath: '' });
  }

  render() {
    const { redirectPath } = this.state;
    const { state } = this.props.location;
    if (redirectPath) {
      this.props.router.navigate({
        pathname: redirectPath,
        state: {
          referrer: this.props.location,
          code: state ? state.code : undefined
        }
      });
    }
    return null;
  }
}

const mapStateToProps = (state, { router: { params } }) => ({
  isDeviceCompatible: state.ui.isDeviceCompatible,
  isAuthenticated: state.auth.isAuthenticated,
  allWorkbooks: state.workbook.allWorkbooks,
  user: state.user,
  currentWorkbook: getCurrentWorkbook(state, params)
});

const withRedux = connect(mapStateToProps, {
  toggleSlideoutMenu,
  toggleSlideoutMenuTutorial,
  sendEmailWelcome,
  queueTour
});

export default flow(withRedux, withModal, withRouter)(LoginPage);
