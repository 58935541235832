import { fetchCharactersRequest, fetchCharactersSuccess, fetchCharactersFailure } from './actions';

export const fetchCharacters = (applicationId: string) => async (dispatch, _, api) => {
  dispatch(fetchCharactersRequest(applicationId));
  try {
    const { data } = await api.getApplicationResources(applicationId);
    dispatch(fetchCharactersSuccess(data));
  } catch (error) {
    dispatch(fetchCharactersFailure(error));
  }
};
