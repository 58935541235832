import { userLoginStarted, getUserSuccess, getUserFailure } from './auth-actions';
import { changeAppLocale } from '../../modules/character-selection';

import Api from '../../api';
import { setLocale } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { ThunkAction, Dispatch } from './auth-types';

export { logoutUser } from './auth-actions';

export function getUser(): ThunkAction {
  return async (dispatch: Dispatch) => {
    dispatch(userLoginStarted());

    try {
      const params = {
        include: ['licenses', 'settings', 'clickAwayAccess']
      };

      const response = await Api.getUserProfile(params);
      const tours = await Api.getUserTourSteps();

      if (response.data.settings.locale) {
        setLocale(response.data.settings.locale);
        dispatch(changeAppLocale(response.data.settings.locale));
      }
      const updatedLicenses = response.data.licenses.map(license => {
        if (license.dateExpiration) {
          license.expired = new Date(license.dateExpiration) < new Date();
        } else {
          license.expired = false;
        }

        return license;
      });
      response.data.licenses = updatedLicenses;
      response.data.settings.tours = tours.data;

      return dispatch(getUserSuccess(response.data));
    } catch (err) {
      dispatch(getUserFailure(err));
    }
  };
}
