//
import React from 'react';
import maybe from '../../../components/core/enhancers/maybe';
import FavoritesBadge from '../../../components/Badges/FavoritesBadge';
import { StyleSheet, css } from 'aphrodite/no-important';
import { black, lwtBlue, darkGray, lwtDarkBlue } from '../../../styles/style-constants';
import { remCalc } from '../../../util/style-util';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { ComponentType } from 'react';

type Props = {
  label: string;
  ariaLabel: string;
  active: boolean;
  top: number;
  icon?: ComponentType<any>;
  link: string;
  sideMargin?: number;
  favoritesBadge?: boolean;
  pinnedItems: number;
  otherLink: any;
};

function TabLinkButton(props: Props): any {
  const {
    icon,
    active,
    link,
    otherLink,
    label,
    ariaLabel,
    sideMargin,
    pinnedItems,
    favoritesBadge
  } = props;
  const { tabLinkButton, activeTabLinkButton, sideMarginValues, iconContainer } = getStyles(props);
  const navigate = useNavigate();

  const getFillIconColor = (): string => {
    return !active ? darkGray : active ? lwtDarkBlue : black;
  };

  const renderIconComponent = (IconComponent: ComponentType<any>, fill: string): any => {
    return IconComponent ? <IconComponent fill={fill} /> : null;
  };

  function handleKeyDown(e) {
    if (e.which === 37 || e.which === 39) {
      const newTab = document.getElementById(
        label === 'Resources' ? 'Pinned Resources Tab' : 'Resources Tab'
      );
      if (newTab) newTab.focus();
      navigate(otherLink);
    }
  }

  const MaybeFavoritesBadge: any = maybe(FavoritesBadge);

  return (
    <Link
      className={css(tabLinkButton, active && activeTabLinkButton, !!sideMargin && sideMarginValues)}
      to={link}
      aria-label={ariaLabel}
      role="tab"
      aria-selected={active}
      id={label + ' Tab'}
      aria-controls={label + ' Tab Panel'}
      tabIndex={active ? 0 : -1}
      onKeyDown={handleKeyDown}
    >
      <div className={css(iconContainer)}>{renderIconComponent(icon as any, getFillIconColor())}</div>
      <span>{label}</span>
      <MaybeFavoritesBadge
        shouldRender={!!favoritesBadge && pinnedItems !== 0}
        pinnedItems={pinnedItems}
      />
    </Link>
  );
}

TabLinkButton.defaultProps = {
  top: 0
};

function getStyles(props: Props) {
  function getMargin(): string {
    return `
      0
      ${props.sideMargin ? `${props.sideMargin}px` : '0'}
      ${props.active ? '0' : '3px'}
      ${props.sideMargin ? `${props.sideMargin}px` : '0'}
    `;
  }

  return StyleSheet.create({
    tabLinkButton: {
      position: 'relative',
      top: props.top,
      fontSize: remCalc(20),
      padding: remCalc(6),
      color: black,
      userSelect: 'none',
      marginBottom: 3,
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 400,
      minWidth: 150,
      zIndex: 1,
      ':focus': {
        color: lwtBlue
      },
      ':visited': {
        textDecoration: 'none'
      },
      ':active': {
        textDecoration: 'none',
        color: lwtBlue
      },
      ':hover': {
        textDecoration: 'none',
        color: black
      },
      ':link': {
        textDecoration: 'none'
      }
    },
    activeTabLinkButton: {
      color: lwtBlue,
      borderBottom: `3px solid ${lwtBlue}`,
      fontWeight: 500,
      ':hover': {
        textDecoration: 'none',
        color: lwtBlue
      }
    },
    iconContainer: {
      position: 'relative',
      width: remCalc(24),
      height: remCalc(24),
      marginRight: 10
    },
    sideMarginValues: {
      margin: getMargin()
    },
    disabledLink: {
      pointerEvents: 'none',
      display: 'none'
    }
  });
}

export default TabLinkButton;
