import { getSpecificTranslation } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { getLastPathFor } from './local-storage-util';
import { Workbook } from 'types';
import { UserState } from 'modules/user';
const translateNavForLocale = (locale, text) => getSpecificTranslation(locale, 'nav', text);

type NextRouteInfo = {
  path: string;
  replace: boolean;
};

export function getRoutingForPage(
  newPageNumber: number,
  match: any,
  withZoom = false,
  direct = true
): NextRouteInfo {
  const { bookCode, edition } = match.params;

  // Route to first page of pdf from cover
  if (Number.isNaN(newPageNumber)) {
    return {
      path: `${match.location.pathname}/0`,
      replace: false
    };
  }

  // Route to cover
  if (Number.isInteger(newPageNumber) && newPageNumber < 0) {
    return {
      path: `/books/${String(bookCode)}/${String(edition)}`,
      replace: false
    };
  }

  // Route to single page
  if (withZoom) {
    return {
      path: `/books/${String(bookCode)}/${String(edition)}/${newPageNumber}/zoom`,
      replace: false
    };
  }

  // Create path from scratch to be pushed to history
  if (direct) {
    return {
      path: `/books/${String(bookCode)}/${String(edition)}/${newPageNumber}`,
      replace: false
    };
  }

  // Turn the page
  return {
    path: String(newPageNumber),
    replace: true
  };
}

export function getRoutingForNewBookPage(
  PageNumber: number,
  match: any,
  newBookCode: any
): NextRouteInfo {
  const { edition } = match.params;

  // Route to coresponding page in different book
  return {
    path: `/books/${String(newBookCode)}/${String(edition)}/${PageNumber}/zoom`,
    replace: true
  };
}

export function hasActiveTrialLicenses(licenses: any[]) {
  return licenses.find(license => license.licenseTypeId === 6 && license.licenses);
}

export function getWorkbookLink({ bookCode = '', edition }: any) {
  return `/books/${bookCode}/${edition}`;
}
export function getBookPageLink(pageNumber: number, edition: string, bookCode: string) {
  return `/books/${String(bookCode)}/${String(edition)}/${pageNumber}/zoom`;
}

export function getClickAwayResourcesLink() {
  return '/resources';
}

export function getTeacherGuideLinkFor(name?: string) {
  let tgBookCode;

  return function (workbook: any) {
    if (!workbook || !workbook.bookCode) return '';

    if (workbook.type === 'storybook') {
      let id;
      for (id in workbook.allWorkbooks) {
        const book = workbook.allWorkbooks[id];
        if (
          book.type === 'teachersGuide' &&
          book.products[0].productId === workbook.products[0].productId
        ) {
          tgBookCode = book.bookCode;
        }
      }
      if (!tgBookCode) return '';
    } else {
      tgBookCode =
        workbook.type === 'teacherGuide' || !workbook.teachersGuides || !workbook.teachersGuides[0]
          ? workbook.bookCode
          : workbook.teachersGuides[0].bookCode;
    }
    const routing = `${name ? `/${name}` : ''}`;

    return `/books/${tgBookCode}/${workbook.edition}${routing}`;
  };
}

export function getGradeLevelDescription(gradeLevel: any, locale: string): string {
  switch (gradeLevel) {
    case 'TK':
      return translateNavForLocale(locale, 'gradeLevelTK');
    case 'K':
      return translateNavForLocale(locale, 'gradeLevelK');
    case '1':
      return translateNavForLocale(locale, 'gradeLevel1');
    case '2':
      return translateNavForLocale(locale, 'gradeLevel2');
    case '3':
      return translateNavForLocale(locale, 'gradeLevel3');
    case '4':
      return translateNavForLocale(locale, 'gradeLevel4');
    default:
      return '';
  }
}

// if the user only has access to "A Click Away", send them there regardless
export const getRouteForBook = (workbook: Workbook, user?: UserState): string => {
  const hasLicensesAndClickaway = user?.licenses?.length && user?.clickAwayAccess.length;
  const expired = user?.licenses?.find(x => x.expired === true);

  if (hasLicensesAndClickaway && expired) return getClickAwayResourcesLink();

  const bookParams = { bookCode: workbook.bookCode, edition: workbook.edition };
  const path = user?.id ? getLastPathFor(user.id, { ...bookParams }) : null;
  return path ?? getWorkbookLink(bookParams);
};
