import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import BasicButton from '../core/BasicButton';
import DrawnArrowCircle from '../icons/DrawnArrowCircle';
import selectorBackground from '../../images/counter.png';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { remCalc } from '../../util/style-util';
const translateBookMenus = key => translate('bookMenus', key);

type Props = {
  totalPages: number;
  newPageNumber: string;
  handleInputChange: (e: Event) => any;
  handleDelete: () => any;
  currentPage: string | number;
};

const styles = StyleSheet.create({
  menuHeader: {
    margin: remCalc(20, 'auto'),
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  inputInstructions: {
    verticalAlign: remCalc(5),
    fontWeight: 'normal'
  },
  pageNumberInput: {
    paddingTop: remCalc(2),
    display: 'inline-block',
    backgroundImage: `url(${selectorBackground})`,
    backgroundSize: remCalc(93, 47),
    backgroundRepeat: 'no-repeat',
    fontSize: remCalc(32),
    width: remCalc(98),
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    fontFamily: 'futura-pt',
    fontWeight: 700,
    '-moz-appearance': 'textfield',
    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  deleteButton: {
    padding: `${remCalc(4)} ${remCalc(5)} 0`
  }
});

class GoToPageHeader extends PureComponent<Props> {
  pageInput?: HTMLInputElement;

  componentDidMount() {
    if (this.pageInput) {
      this.pageInput.addEventListener('keypress', this.handleKeypress);
    }
  }

  componentWillUnmount() {
    if (this.pageInput) {
      this.pageInput.removeEventListener('keypress', this.handleKeypress);
    }
  }

  /* We only want to allow numbers and backspace for the page input.
     type="number" still allows math operators like +, -, *, e, etc. */
  handleKeypress(e: KeyboardEvent) {
    const keyCode = e.keyCode;

    // Backspace/Delete keyCode
    if (keyCode === 8) return;

    // keyCode for 0 is 48, keyCode for 9 is 57
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
    }
  }

  render() {
    const { totalPages, currentPage, newPageNumber, handleInputChange, handleDelete } = this.props;

    return (
      <header className={css(styles.menuHeader)}>
        <span className={css(styles.inputInstructions)}>{translateBookMenus('goToPage')}</span>
        <input
          aria-placeholder={currentPage as any}
          type="number"
          min="0"
          max={totalPages - 1}
          maxLength={totalPages.toString().length}
          placeholder={currentPage as any}
          className={css(styles.pageNumberInput)}
          onChange={handleInputChange as any}
          value={newPageNumber}
          aria-label={tA11y('aria.enterPageNumber')}
          ref={input => (this.pageInput = input as any)}
        />
        <BasicButton
          aria-label={tA11y('aria.deletePageEntryButton')}
          buttonStyles={[styles.deleteButton]}
          tabIndex={0}
          onClick={handleDelete}
          icon={DrawnArrowCircle}
        />
      </header>
    );
  }
}

export default GoToPageHeader;
