import React from 'react';
import { makeStyles } from '@mui/styles';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { useFullScreen } from 'state/providers/FullScreenProvider';
import selectorBackground from 'images/counter.png';
import fullscreenImage from 'images/fullscreen.png';
import { remCalc } from 'util/style-util';
import { Typography } from '@mui/material';
import { PREKIT_FONT } from 'styles/style-constants';

const t = (key, options?) => translate('resources', key, options);

const FullScreenButton = () => {
  const classes = useStyles();
  const [{ screen1Handle }, { setFullScreen }] = useFullScreen();

  const handleFullScreenCick = () => {
    setFullScreen(true);
    screen1Handle.enter();
  };
  return (
    <div id="fullScreenButton" onClick={handleFullScreenCick} className={classes.button}>
      <img src={fullscreenImage} className={classes.iconImage} alt={t('present')} />
      <Typography className={classes.text}>{t('present')}</Typography>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  text: {
    color: 'black',
    marginLeft: '-10px',
    fontWeight: 600,
    fontFamily: PREKIT_FONT
  },
  iconImage: {
    height: remCalc(20)
  },
  button: {
    cursor: 'pointer',
    width: '165px',
    height: remCalc(48),
    backgroundColor: 'transparent',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${selectorBackground})`,
    marginTop: remCalc(2),
    paddingTop: remCalc(2),
    marginRight: remCalc(20),
    textAlign: 'center',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    display: 'flex',
    '&:hover': {
      transform: 'scale(1.05, 1.05)'
    }
  } as any
}));

export default FullScreenButton;
