import React from 'react';
import Main from '../../containers/Main';
import NavigationDrawer from '../AppMenu/NavigationDrawer';
import Header from './PageHeader';

const MainHeaderLayout = ({ children, ...props }) => {
  return (
    <>
      <Header {...props} />
      <NavigationDrawer />
      <Main>{children}</Main>
    </>
  );
};

export default MainHeaderLayout;
