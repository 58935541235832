import React from 'react';
import { makeStyles } from '@mui/styles';
import { remCalc } from '@lwtears/lwt-common-frontend/lib/@common';
import ZoomIn from '../../images/icons/plus.png';
import ZoomOut from '../../images/icons/minus.png';
import ZoomReset from '../../images/icons/resize.png';
import { ZoomTypes } from '../../state/providers/ZoomProvider';

type Props = {
  type: ZoomTypes;
  style?: React.CSSProperties;
  onClick: () => void;
  isDisabled?: boolean;
};

const ZoomButton = ({ type, onClick, style = {}, isDisabled = false }: Props) => {
  const classes = useStyles()();

  const buttonImages = {
    [ZoomTypes.IN]: ZoomIn,
    [ZoomTypes.OUT]: ZoomOut,
    [ZoomTypes.RESET]: ZoomReset
  };
  return (
    <button disabled={isDisabled} className={classes.button} onClick={onClick} style={style}>
      <img src={buttonImages[type]} className={classes.iconImage} alt={`Zoom ${type}`} />
    </button>
  );
};

const useStyles = () =>
  makeStyles(() => ({
    button: {
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'transparent',
      maxWidth: remCalc(80),
      transition: 'transform 100ms ease-in',
      aspectRatio: '38/37',
      '&:hover': {
        transform: 'scale(1.2)'
      },
      '&:focus': {
        outline: 'none'
      },
      '&:disabled': {
        opacity: 0.25,
        transform: 'none !important'
      },
      height: remCalc(60),
      width: remCalc(60)
    },
    iconImage: {
      height: remCalc(40)
    }
  }));

export default ZoomButton;
