//
import React, { Component } from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';

import { AppState } from '../reducers';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import flow from 'lodash/fp/flow';
import RefreshModal from './core/Modals/RefreshModal';
import { clearLocalStorage } from '../util/local-storage-util';
import BaseModalTitle from './core/Modals/BaseModalTitle';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props = {
  children: any;
} & UseModalDispatch;

class SWNotifier extends Component<Props> {
  refreshPage() {
    window.location.reload();
    clearLocalStorage();
  }

  handleContentUpdate = () => {
    this.props.openModal({
      Title: props => <BaseModalTitle title="" {...props} />,
      ariaLabel: tA11y('aria.prekittUpdated'),
      body: <RefreshModal handleRefresh={this.refreshPage} />
    } as any);
  };

  componentDidMount() {
    document.addEventListener('updateDetected', this.handleContentUpdate);
  }

  componentWillUnmount() {
    document.removeEventListener('updateDetected', this.handleContentUpdate);
  }

  render() {
    return <div className={css(styles.refreshModalContainer)}>{this.props.children}</div>;
  }
}

const styles = StyleSheet.create({
  refreshModalContainer: {
    width: '100%',
    height: '100%'
  }
});

const mapStateToProps = (state: AppState) => ({
  messaging: state.messaging
});

const withRedux = connect(mapStateToProps);

export default flow(withRedux, withModal)(SWNotifier);
