import {
  fetchResourcesRequest,
  fetchResourcesSuccess,
  fetchResourcesFailure,
  fetchClickAwaysRequest,
  fetchClickAwaysSuccess,
  fetchClickAwaysFailure,
  fetchFilterTagsRequest,
  fetchFilterTagsSuccess,
  fetchFilterTagsFailure,
  fetchFavoriteResourcesRequest,
  fetchFavoriteResourcesSuccess,
  fetchFavoriteResourcesFailure,
  createUserFavoriteRequest,
  createUserFavoriteSuccess,
  createUserFavoriteFailure,
  removeUserFavoriteRequest,
  removeUserFavoriteSuccess,
  removeUserFavoriteFailure,
  updateCounter
} from './resource-actions';
import type { BookCode, Resource, UserFavorite } from 'types';

export const fetchResources = (type?: string) => async (dispatch, _, api) => {
  dispatch(fetchResourcesRequest());
  try {
    const response = await api.getResources({ type, include: ['tags'] }); // CSC - need to re-add pages later...
    const filteredResponse = response.data.filter(resource => resource.resourceTypeId !== 9); //remove support resources
    dispatch(fetchResourcesSuccess(filteredResponse));
  } catch (error) {
    dispatch(fetchResourcesFailure(error));
  }
};
export const fetchClickAways = () => async (dispatch, _, api) => {
  dispatch(fetchClickAwaysRequest());
  try {
    const response = await api.getClickAways({ include: ['tags'] }); // CSC - need to re-add pages later...
    dispatch(fetchClickAwaysSuccess(response.data));
  } catch (error) {
    dispatch(fetchClickAwaysFailure(error));
  }
};

// As we're dealing with regular Resource Models, but from another endpoint,
// we can reuse the regular Resources Redux actions
export const fetchMobileResources = (bookCode?: BookCode) => async (
  dispatch,
  _,
  api
) => {
  dispatch(fetchResourcesRequest());
  try {
    const params = bookCode ? { bookCode, tag: 'mobile' } : { tag: 'mobile' };
    const response = await api.getMobileResources(params);
    dispatch(fetchResourcesSuccess(response.data));
  } catch (error) {
    dispatch(fetchResourcesFailure(error));
  }
};

export const fetchFilterTags = () => async (dispatch, _, api) => {
  dispatch(fetchFilterTagsRequest());
  try {
    const response = await api.getBookFilters();
    dispatch(fetchFilterTagsSuccess(response.data));
  } catch (error) {
    dispatch(fetchFilterTagsFailure(error));
  }
};
export const fetchFavoriteResources = () => async (dispatch, _, api) => {
  const params = { include: ['resource', 'clickAway'] };
  dispatch(fetchFavoriteResourcesRequest());
  try {
    const response = await api.getUserFavorites(params);
    dispatch(fetchFavoriteResourcesSuccess(response.data));
  } catch (error) {
    dispatch(fetchFavoriteResourcesFailure(error));
  }
};

export const createUserFavorite = (resource: Resource, isClickAway = false) => async (
  dispatch,
  _,
  api
) => {
  const favorite: UserFavorite = {};
  const favoriteWithResource: UserFavorite = {};
  if (isClickAway) {
    favorite['clickAwayId'] = resource.id;
    favoriteWithResource['clickAwayId'] = resource.id;
    favoriteWithResource['clickAway'] = resource;
  } else {
    favorite['resourceId'] = resource.id;
    favoriteWithResource['resourceId'] = resource.id;
    favoriteWithResource['resource'] = resource;
  }

  dispatch(createUserFavoriteRequest(favoriteWithResource));
  try {
    const response = await api.createUserFavorite(favorite);
    dispatch(
      createUserFavoriteSuccess({
        ...favoriteWithResource,
        ...response.data
      })
    );
    dispatch(updateCounter('increment'));
  } catch (error) {
    dispatch(createUserFavoriteFailure(error));
  }
};

export const removeUserFavorite = (favoriteId: number) => async (
  dispatch,
  _,
  api
) => {
  if (favoriteId) {
    dispatch(removeUserFavoriteRequest(favoriteId));
    try {
      await api.deleteUserFavorite(favoriteId);
      dispatch(removeUserFavoriteSuccess(favoriteId));
      dispatch(updateCounter('decrement'));
    } catch (error) {
      dispatch(removeUserFavoriteFailure(error));
    }
  }
};

export const fetchSupportResources = (resourceId?: number) => async (
  dispatch,
  _,
  api
) => {
  return api.getSupportResources(resourceId);
};
