import * as actions from './actions';
import type { ThunkAction, Dispatch } from './types';
import PrekittApi from '../../api';

export function fetchAssessmentResources(): ThunkAction {
  return async (dispatch: Dispatch, _, api: typeof PrekittApi) => {
    dispatch(actions.fetchAssessmentResourcesRequest());

    try {
      const response = await api.getAssessmentResources();
      dispatch(actions.fetchAssessmentResourcesSuccess(response.data) as any);
    } catch (err) {
      dispatch(actions.fetchAssessmentResourcesFailure(err));
    }
  };
}
