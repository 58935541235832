import messagingReducer from './reducer';
import * as selectors from './selectors';
export * from './types';
export * from './actions';

export default messagingReducer;

export const isShowClickAwayAlert = (state, routeParams) =>
  selectors.isShowClickAwayAlert(state, routeParams);

export const isShowLicenseExpirationAlert = (state, routeParams) =>
  selectors.isShowLicenseExpirationAlert(state, routeParams);

export const isShowTermsConditionsCheckbox = state =>
  selectors.isShowTermsConditionsCheckbox(state);

export const isBookCopyrightTermsViewed = (state, routeParams) =>
  selectors.isBookCopyrightTermsViewed(state, routeParams);
