import React from 'react';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const t = key => translate('assessments', key);

type Props = {
  fill: string;
  borderColor: string;
};

const Apple = ({ fill, borderColor, ...props }: Props) => (
  <svg viewBox="0 0 19.02 22.16" {...props}>
    <title>{t('header')}</title>
    <path
      style={{ fill }}
      d="M25,35.28l1.47.06H28l.93-1.06,1.41-.83,1.19-2.08,1.86-2.92.38-4.29-1.12-2.72L29,19.8l.38-.83,1.35-1.83L31,
    14.52l-2.79.77-2.4,1.6L25.23,19c0-.16-.8-2.82-.8-2.82l-1.06.67L24.56,20s-2-.26-2.15-.29-2.72.54-2.95.64-2.82,
    1.92-2.82,1.92l-1,2.76.7,3.75,3.43,5.51a28.37,28.37,0,0,1,2.56,1.31c.29.26,1.92.16,1.92.16Z"
      transform="translate(-15.49 -13.92)"
    />
    <path
      style={{ fill: borderColor }}
      d="M29.2,19.07c-.93.61-1.76,0-2.72-.06h-.16a7,7,0,0,1,1-1,14.92,14.92,0,0,1,1.67-1.09,5.08,5.08,0,0,0,
    2.21-2.05c0-.06-.06-.13-.13-.1-.42.35-.8.77-1.25,1.09s-1.09.74-1.63,1.09a6.86,6.86,0,0,0-2,
    1.73c-.29-.67-.48-1.28,0-1.89A4,4,0,0,1,28,15.73c.67-.26,1.38-.45,2.05-.67a5.91,5.91,0,0,0,1.12-.48C31,
    16.34,30.87,18,29.2,19.07Zm2.34,2.11a4.65,4.65,0,0,1,2.18,5.42,14,14,0,0,1-3.08,5.93,10.19,10.19,0,0,
    1-2.24,2.15,5,5,0,0,1-3.78.58c-.06,0-.13,0-.16.06A4.34,4.34,0,0,1,21.35,35a7.17,7.17,0,0,1-2.21-2.18,
    16.08,16.08,0,0,1-2.76-5.61c-.51-2.15-.35-4.45,1.38-6,1.92-1.67,4.81-1.63,7-.74.06,0,.1,0,.16,
    0l.16-.06s.06,0,.06,0h0A7.58,7.58,0,0,1,31.54,
    21.18Zm-7-2.15c0-.16-.29-.42-.32-.58s-.8-1.47-.45-1.63c.19-.1.54-.64.67-.45s.64,1.76.7,2a5.71,5.71,0,0,
    1,0,1.47.11.11,0,0,0,0,.06c0,.06,0,.1,0,.16s0,.06,0,.1a.67.67,0,0,0-.19.13l-.22-.1A6.35,6.35,0,0,0,24.59,
    19Zm2,.35a5.13,5.13,0,0,1,.67.16,4.87,4.87,0,0,0-1.51.32A.82.82,0,0,1,26.54,19.39ZM26,
    18.84h0c-.06.1-.1.19-.16.29a0,0,0,0,0,0,0A3,3,0,0,0,26,18.84Zm5.22-4.68a26.64,26.64,0,0,0-4.13,
    1.54c-1.22.61-2.15,1.76-1.25,3a4.58,4.58,0,0,0-.45.64v0a7.74,7.74,0,0,0-.93-3.56c-.35,0-.9.7-1.12.93A15.65,
    15.65,0,0,1,24.43,20a6.89,6.89,0,0,0-6.66.7c-1.89,1.35-2.31,3.68-2,5.86a15.32,15.32,0,0,0,3.08,6.57,7.87,
    7.87,0,0,0,2.27,2.21,4.7,4.7,0,0,0,3.36.32s.06,0,.1-.06a5.54,5.54,0,0,0,3.65-.45,8.68,8.68,0,0,0,
    2.53-2.31,15,15,0,0,0,3.33-6.54,5.08,5.08,0,0,0-2.21-5.45,9.56,9.56,0,0,0-3.2-1.25,2.93,2.93,0,0,0,1-.51,
    5.21,5.21,0,0,0,1.7-4.77v-.16C31.41,14.1,31.32,14.1,31.25,14.16Z"
      transform="translate(-15.49 -13.92)"
    />
    <path
      style={{ fill: borderColor }}
      d="M31,14.45c-.74.22-1.51.48-2.24.77.26-.06.48-.16.74-.22l.54-.16.16-.06A3,3,0,0,0,31,14.45Zm-6.54,
    1.63s0,0-.06,0h.1Zm5.8-.35-.35.29a1.78,1.78,0,0,0-.26.19C29.84,16.05,30,15.89,30.23
    15.73Zm-.06-.51-.54.16c-.51.16-1,.32-1.51.51a4.36,4.36,0,0,0-1.73,1,1.2,1.2,0,0,0-.16,1.35A8.15,8.15,0,
    0,1,28,16.76c.13-.1.26-.16.38-.26.42-.26.83-.54,1.22-.83A6.73,6.73,0,0,0,30.16,15.22Zm-3.43,3.59.61.13a2,
    2,0,0,0,1.73-.06,4,4,0,0,0,1.76-3.14A7.72,7.72,0,0,1,29.17,17l-.1.06-.32.19a11.61,11.61,0,0,0-1.31.87A7.31,
    7.31,0,0,0,26.74,18.81Zm1,.67.35.13.19-.06A2.83,2.83,0,0,1,27.76,19.48Zm-2.34.29v.06l.1-.13a.85.85,0,0,1,
    .16-.19l-.16.1ZM24.81,19c.06.35.1.67.13,1a0,0,0,0,0,0,0v0a.19.19,0,0,1,0-.13c0-.13,0-.29,0-.45a3.21,3.21,
    0,0,0,0-1c0-.19-.45-1.35-.61-1.76l-.13.13c-.1.1-.19.22-.32.26a3.55,3.55,0,0,0,.35,1,3.66,3.66,0,0,1,
    .19.45s.1.13.13.19A1.2,1.2,0,0,1,24.81,19Zm6.86,2a5.7,5.7,0,0,1,1,.74,5.4,5.4,0,0,0-.8-.64,8.68,8.68,0,
    0,0-3.14-1.22l-.8-.13-.7,0a4.56,4.56,0,0,0-1.35.26A8,8,0,0,1,31.67,21ZM21.8,19.61a6.73,6.73,0,0,0-3.91,
    1.22,4.8,4.8,0,0,0-1.86,3,4.89,4.89,0,0,1,1.57-2.72c1.92-1.67,4.58-1.6,6.57-1A6.37,6.37,0,0,0,21.8,
    19.61Zm-.13.54a5.76,5.76,0,0,0-3.78,1.28c-1.92,1.67-1.67,4.29-1.31,5.7a15.34,15.34,0,0,0,2.69,5.54,
    7.49,7.49,0,0,0,2.15,2.11,4.18,4.18,0,0,0,2.92.26.33.33,0,0,1,.29-.06,4.69,4.69,0,0,0,3.62-.51,10.2,
    10.2,0,0,0,2.18-2.11,14.45,14.45,0,0,0,3-5.83,4.43,4.43,0,0,0-2.08-5.16,7.45,7.45,0,0,
    0-6.12-.74.24.24,0,0,1-.16.06h0s0,0-.06,0a.34.34,0,0,1-.35,0A7.05,7.05,0,0,0,21.67,20.16Zm-.1,
    15.19a4.75,4.75,0,0,0,2.88.16A5,5,0,0,1,21.58,35.34Zm1.57.74a4.17,4.17,0,0,1-2.08-.51,7.94,7.94,0,0,
    1-2.34-2.28,15.92,15.92,0,0,1-3.11-6.66c-.45-2.63.29-4.87,2-6.12a7.22,7.22,0,0,1,6.47-.9,13.43,13.43,
    0,0,0-.93-2.72l-.1-.16.13-.13.16-.16c.42-.42.77-.8,1.09-.83h.1l.06.1a5.08,5.08,0,0,1,.67,1.63v-.06A3,
    3,0,0,1,27,15.51,27.92,27.92,0,0,1,31.09,14a.47.47,0,0,1,.35,0,.33.33,0,0,1,.19.26v0a.15.15,0,0,1,0,
    .16c.16,2.24-.42,3.81-1.76,4.9l-.42.29a9.7,9.7,0,0,1,2.63,1.12,5.28,5.28,0,0,1,2.31,5.67A14.91,14.91,
    0,0,1,31,33a9.19,9.19,0,0,1-2.6,2.37,5.46,5.46,0,0,1-3.68.48s-.06,0-.1,0A6.84,6.84,0,0,1,23.15,36.08Z"
      transform="translate(-15.49 -13.92)"
    />
  </svg>
);

Apple.defaultProps = {
  fill: '#FFF',
  borderColor: '#111'
};

export default Apple;
