import React from 'react';
import _ from 'lodash';
import { memo } from 'react';
import ResourcePlayer, {
  ResourcePlayerProps
} from 'components/Resources/ResourcePlayer/ResourcePlayer';

export const propsFunctionsAreEqual = (prevProps, nextProps) => {
  const ret = _.isEqual(
    _.omit(prevProps, _.functions(prevProps)),
    _.omit(nextProps, _.functions(nextProps))
  );
  return ret;
};

const MemoPlayer = memo(({ selectedResource }: ResourcePlayerProps) => {
  return <ResourcePlayer selectedResource={selectedResource} />;
}, propsFunctionsAreEqual);

export default MemoPlayer;
