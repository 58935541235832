import React from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import resourcesPng from 'images/icons/resources-white.png';
import { useResources } from 'state/providers/ResourceProvider';
import { remCalc } from 'util/style-util';
const translateResources = key => translate('resources', key);

const ResourcesButton = ({ buttonClass = {} }) => {
  const classes = useStyles();
  const [{ isOpen }, { setIsOpen }] = useResources();

  if (isOpen) return null;

  return (
    <Button
      id="resourcesButton"
      onClick={() => setIsOpen(!isOpen)}
      className={clsx(classes.button, buttonClass)}
    >
      <div className={classes.content}>
        <ChevronLeftIcon htmlColor="black" />
        <img src={resourcesPng} className={classes.iconImage} alt={translateResources('title')} />
      </div>
    </Button>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    zIndex: 9999,
    backgroundColor: 'transparent',
    textAlign: 'center',
    marginRight: 10,
    '&:hover': {
      transform: 'scale(1.05, 1.05)'
    }
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconImage: {
    height: remCalc(40)
  }
}));

export default ResourcesButton;
