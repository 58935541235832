import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../util/style-util';
import { PREKIT_FONT } from '../../styles/style-constants';

type Props = {
  children?: any;
  text?: string;
  disabled?: boolean;
  icon?: any;
  color?: string;
  fill?: string;
  hoverFill?: string;
  buttonStyles?: Array<any>;
  onClick?: any;
} & Partial<React.ButtonHTMLAttributes<HTMLButtonElement>>;

const getStyles = (color: string, fill: string, hoverFill?: string) =>
  StyleSheet.create({
    basicButton: {
      fontFamily: PREKIT_FONT,
      backgroundColor: fill,
      border: 'none',
      padding: remCalc(10),
      color,
      ':hover': {
        backgroundColor: hoverFill,
        cursor: 'pointer'
      },
      ':focus': {
        color
      }
    },
    disabled: {
      cursor: 'not-alowed',
      pointerEvents: 'none',
      opacity: 0.6,
      ':hover': {
        backgroundColor: fill
      }
    }
  });

const BasicButton: React.FC<Props> = ({
  children,
  text,
  icon: Icon,
  buttonStyles,
  disabled,
  color = 'black',
  fill = 'transparent',
  hoverFill = 'transparent',
  ...props
}) => {
  const buttonContent = text || children;
  const { basicButton, disabled: disabledClass } = getStyles(color, fill, hoverFill);

  return (
    <button
      className={css(basicButton, disabled && disabledClass, ...(buttonStyles ?? []))}
      {...props}
    >
      {Icon ? <Icon /> : buttonContent}
    </button>
  );
};

BasicButton.defaultProps = {
  color: 'black',
  fill: 'transparent',
  buttonStyles: []
};

export default BasicButton;
