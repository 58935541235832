//
import keyBy from 'lodash/keyBy';
import {
  FETCH_TOURS,
  FETCH_TOURS_SUCCESS,
  QUEUE_TOUR,
  DEQUEUE_TOUR,
  INCREMENT_CURRENT_STEP,
  COMPLETE_CURRENT_TOUR,
  PRIORITIZE_TOUR
} from './types';
import { LOGIN_SUCCESS } from '../auth';
import { updateWithViewedStep, removeFromTourQueue } from './util';
import { FtueAction, FtueState } from './types';

const initialState = {
  toursViewed: {},
  allTours: {},
  queuedTours: [],
  currentStep: 0,
  fetching: false,
  error: false
};

function tourReducer(state: FtueState = initialState, action: FtueAction) {
  switch (action.type) {
    case FETCH_TOURS:
      return {
        ...state,
        fetching: true,
        error: false
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        toursViewed: keyBy(action.user.settings.tours, 'name')
      };
    case FETCH_TOURS_SUCCESS:
      return {
        ...state,
        fetching: false,
        allTours: action.tours
      };
    case QUEUE_TOUR:
      return {
        ...state,
        queuedTours: [...state.queuedTours, action.name]
      };
    case DEQUEUE_TOUR:
      return {
        ...state,
        queuedTours: removeFromTourQueue(state, action.names)
      };
    case PRIORITIZE_TOUR:
      return {
        ...state,
        queuedTours: [action.name, ...state.queuedTours]
      };
    case INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
        toursViewed: updateWithViewedStep(state, action.step)
      };
    case COMPLETE_CURRENT_TOUR: {
      const queuedTours = state.queuedTours.slice(1);

      return {
        ...state,
        currentStep: 0,
        queuedTours
      };
    }

    default:
      return state;
  }
}

export default tourReducer;
