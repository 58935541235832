import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../util/style-util';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import PdfViewer from './PdfViewer';

type Props = {
  workbook: any;
  currentPage: number;
  iconSize: number;
};

const getStyles = () =>
  StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      margin: 'auto'
    },
    audioContainer: {
      margin: remCalc(0, 20),
      width: '5%',
      alignSelf: 'flex-end',
      textAlign: 'center',
      position: 'absolute'
    }
  });

const SinglePageWorkbook = ({ workbook, currentPage, iconSize }: Props) => {
  const isStorybook = workbook.type === 'storybook';
  const isLeftPage = currentPage % 2 === 0;

  const styles = getStyles();

  return (
    <div className={css(styles.wrapper)}>
      {isLeftPage && isStorybook ? (
        <div className={css(styles.audioContainer)} style={{ left: '0px' }}>
          <AudioPlayer pages={workbook.pageDetails} iconSize={iconSize} currentPage={currentPage} />
        </div>
      ) : null}
      <PdfViewer workbook={workbook} currentPage={currentPage} />
      {!isLeftPage && isStorybook ? (
        <div className={css(styles.audioContainer)} style={{ right: '0px' }}>
          <AudioPlayer pages={workbook.pageDetails} iconSize={iconSize} currentPage={currentPage} />
        </div>
      ) : null}
    </div>
  );
};

export default SinglePageWorkbook;
