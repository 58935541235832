//
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import MenuGroupButton from './MenuGroupButton';
import RepeatingCirclesIcon from '../icons/RepeatingCirclesIcon';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { remCalc } from '../../util/style-util';

type Props = {
  activeGroup: string;
  content: any[];
  handleTOCGroupChange: (groupName: string) => () => any;
};

const styles = StyleSheet.create({
  sectionTabs: {
    gridArea: 'header',
    alignSelf: 'center',
    margin: `0 ${remCalc(32)}`
  }
});

const getAriaLabel = name => {
  if (name === '123') return tA11y('aria.numbers');
  if (name === 'ABC') return tA11y('aria.capitalLetters');
  if (name === 'abc') return tA11y('aria.lowercaseLetters');
  return name;
};

const MenuGroupTabs = ({ activeGroup, content, handleTOCGroupChange }: Props) => (
  <div className={css(styles.sectionTabs)}>
    {content.map((section, i) => {
      return (section.categories && section.categories.length > 0) ||
        (section.characters && section.characters.length > 0) ? (
        <MenuGroupButton
          tabIndex={0}
          isActive={activeGroup === section.grouping}
          key={i}
          onClick={handleTOCGroupChange(section.grouping)}
          name={section.grouping}
          aria-label={getAriaLabel(section.grouping)}
        />
      ) : null;
    })}
    <MenuGroupButton
      tabIndex={0}
      isActive={activeGroup === 'search'}
      onClick={handleTOCGroupChange('search')}
      icon={RepeatingCirclesIcon}
      name={'search'}
      iconSize={'28px'}
      aria-label={tA11y('aria.goToPageButton')}
    />
  </div>
);

export default MenuGroupTabs;
