import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import TabLinkButton from '../TabLinkButton/TabLinkButton';
import FtueCallout from '../../Ftue/FtueCalloutContainer';
import { resourceDarkGray } from '../../../styles/style-constants';

export type RouteTabMenuItem = {
  label: string;
  ariaLabel: string;
  link: string;
  paramPath: string;
  component: any;
  icon?: any;
  exactRoute?: boolean;
  hasBadge?: boolean;
};

function RouteTabMenu(props: any) {
  const { routeTabMenu } = styles;

  function renderButtons(): Array<any> {
    return props.items.map((tabMenuItem: RouteTabMenuItem, index: number) => {
      return (
        <TabLinkButton
          key={index}
          top={3}
          sideMargin={20}
          icon={tabMenuItem.icon}
          active={tabMenuItem.link === props.activeRoute}
          link={tabMenuItem.link}
          otherLink={props.items[(index + 1) % 2].link}
          label={tabMenuItem.label}
          favoritesBadge={tabMenuItem.hasBadge}
          pinnedItems={props.counter}
          ariaLabel={tabMenuItem.ariaLabel}
        />
      );
    });
  }

  return (
    <div className={css(routeTabMenu)} role="tablist">
      {renderButtons()}
      <FtueCallout name="resources" step="navigation" position="bottom" justifyPointer="center" />
    </div>
  );
}

export const styles = StyleSheet.create({
  routeTabMenu: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${resourceDarkGray}`,
    borderBottom: `1px solid ${resourceDarkGray}`
  },
  tabButton: {
    marginRight: 20
  }
});

export default RouteTabMenu;
