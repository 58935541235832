import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Config from 'config';
import withRouter from 'state/hooks/withRouter';

const { gATrackingId } = Config;

ReactGA.initialize(gATrackingId ?? '');
ReactGA.timing({
  category: 'JS Libraries',
  variable: 'load',
  value: 20 // in milliseconds
});

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };
  const HOC = class extends Component<any, any> {
    componentDidMount() {
      const page = this.props.router.location.pathname;

      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const prevPage = prevProps.router.location.pathname;
      const currentPage = this.props.router.location.pathname;

      if (prevPage !== currentPage) {
        trackPage(currentPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return withRouter(HOC);
};

export default withTracker;
