//
import React from 'react';
import castArray from 'lodash/castArray';
import { StyleSheet, css } from 'aphrodite/no-important';

type Props =
  | {
      children: any;
      position: string;
      classList?: Array<any | false>;
    }
  | any;

const getStyles = () =>
  StyleSheet.create({
    prekittMessageFrame: {
      // position: position,
      zIndex: 10,
      display: 'inline-block',
      backgroundColor: 'white',
      border: '5px solid black',
      borderRadius: '0px 0px 225px 5px/8px 225px 5px 255px',
      boxShadow: '0 4px 20px 5px rgba(55, 55, 55, .5)',
      userSelect: 'none',
      width: 'max-content'
    }
  });

const PreKittMessageFrame = ({ children, position, classList, ...props }: Props) => (
  <div
    id="preKittMessageFrame"
    className={css(getStyles().prekittMessageFrame, ...castArray(classList))}
    {...props}
  >
    {children}
  </div>
);

PreKittMessageFrame.defaultProps = {
  position: 'relative',
  classList: []
};

export default PreKittMessageFrame;
