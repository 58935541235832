//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

import { remCalc } from '../../../util/style-util';
import checkboxActivePath from '../../../images/resources/checkbox-active.svg';
import checkboxPath from '../../../images/resources/checkbox.svg';
import { gray3 } from '../../../styles/style-constants';

import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props =
  | {
      checked: boolean;
      label: string;
      ariaLabel: string;
      onChange: () => any;
    }
  | any;

function CheckBox(props: Props): any {
  const { checkbox, icon, label } = styles;

  function renderBox() {
    return props.checked ? (
      <img alt={tA11y('alt.checkboxChecked')} src={checkboxActivePath} className={css(icon)} />
    ) : (
      <img alt={tA11y('alt.checkboxUnchecked')} src={checkboxPath} className={css(icon)} />
    );
  }

  return (
    <div
      className={css(checkbox)}
      onClick={props.onChange}
      onKeyPress={props.onChange}
      aria-label={props.ariaLabel}
      role="option"
      aria-checked={props.checked}
      aria-selected={props.checked}
      tabIndex={0}
    >
      {renderBox()}
      <span className={css(label)}>{props.label}</span>
    </div>
  );
}

const styles = StyleSheet.create({
  checkbox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    userSelect: 'none'
  },
  icon: {
    width: 15,
    marginRight: 10,
    cursor: 'pointer'
  },
  label: {
    color: gray3,
    fontSize: remCalc(18),
    fontWeight: 400,
    cursor: 'pointer'
  }
});

export default CheckBox;
