import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const useIdleTimeout = ({ idleTime = 2.5 }) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => setIdle(true);
  const handleActive = () => setIdle(false);

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    onActive: handleActive,
    debounce: 500
  });
  return {
    isIdle,
    setIdle,
    idleTimer
  };
};
export default useIdleTimeout;
