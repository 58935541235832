//
import * as types from './auth-types';

export const userLoginStarted = (): types.USER_LOGIN_STARTED_ACTION => ({
  type: types.USER_LOGIN_STARTED
});

export const getUserSuccess = (user): any => ({
  type: types.LOGIN_SUCCESS,
  user
});

export const getUserFailure = (error: Error): types.LOGIN_FAIL_ACTION => ({
  type: types.LOGIN_FAIL,
  error
});

export const logoutUser = () => ({
  type: types.LOGOUT_USER
});
