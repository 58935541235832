import React from 'react';
import { useFullScreen } from 'state/providers/FullScreenProvider';

const withFullScreen = Component => {
  function ComponentWithFullscreenProps(props) {
    const [state, dispatch] = useFullScreen();

    return <Component {...props} fullScreen={{ ...state, ...dispatch }} />;
  }

  return ComponentWithFullscreenProps;
};

export default withFullScreen;
