//
import React from 'react';

type Props = {
  fill: string;
};

const AppsIcon = ({ fill }: Props) => (
  <svg viewBox="0 0 16.16 13.87">
    <path d="M3.77,1A4.39,4.39,0,0,0,3.7.27.11.11,0,0,0,3.6.18c-.29,0-.58.06-.88,0S2.24.13,2,.13C1.41.12.83.18.25.18A.13.13,0,0,0,.12.29l0,.07c0,.62,0,1.23,0,1.85,0,.27-.08.84.25,1a1.71,1.71,0,0,0,.55.09,4.6,4.6,0,0,0,.83,0c.62-.07,1.23,0,1.85-.06a.14.14,0,0,0,.13-.12c0-.49-.08-1,0-1.49C3.72,1.41,3.76,1.2,3.77,1Z" />
    <path
      d="M2.12,3a6.47,6.47,0,0,1-1.39,0c-.2,0-.3,0-.35-.23a1.49,1.49,0,0,1,0-.21c0-.25,0-.5,0-.75,0-.48,0-1,0-1.45C.86.4,1.41.35,2,.36c.25,0,.49.07.74.09a5.4,5.4,0,0,0,.8,0c0,.17,0,.34.06.52s-.07.53-.09.79c0,.43,0,.85,0,1.27C3,3,2.58,3,2.12,3Z"
      style={{ fill }}
    />
    <path d="M9.93,1A4.39,4.39,0,0,0,9.86.27.1.1,0,0,0,9.77.18c-.3,0-.59.06-.89,0S8.4.13,8.15.13c-.58,0-1.16,0-1.74,0a.14.14,0,0,0-.13.11.19.19,0,0,0,0,.07c0,.62,0,1.23,0,1.85,0,.27-.09.84.24,1a1.71,1.71,0,0,0,.55.09,4.6,4.6,0,0,0,.83,0c.62-.07,1.23,0,1.85-.06a.14.14,0,0,0,.13-.12c0-.49-.08-1,0-1.49C9.88,1.41,9.92,1.2,9.93,1Z" />
    <path
      d="M8.28,3a6.47,6.47,0,0,1-1.39,0c-.2,0-.29,0-.35-.23a1.49,1.49,0,0,1,0-.21l0-.75c0-.48,0-1,0-1.45C7,.4,7.57.35,8.11.36c.25,0,.49.07.74.09a5.35,5.35,0,0,0,.8,0,4.35,4.35,0,0,1,.06.52c0,.26-.07.53-.09.79,0,.43,0,.85,0,1.27C9.2,3,8.74,3,8.28,3Z"
      style={{ fill }}
    />
    <path d="M16.09,1A4.39,4.39,0,0,0,16,.27a.1.1,0,0,0-.09-.09c-.3,0-.59.06-.88,0s-.49-.09-.74-.09c-.58,0-1.16,0-1.74,0a.12.12,0,0,0-.12.11.11.11,0,0,0,0,.07c0,.62,0,1.23,0,1.85,0,.27-.09.84.25,1a1.61,1.61,0,0,0,.54.09,4.6,4.6,0,0,0,.83,0c.62-.07,1.24,0,1.85-.06A.14.14,0,0,0,16,3.11c0-.49-.08-1,0-1.49C16,1.41,16.09,1.2,16.09,1Z" />
    <path
      d="M14.44,3a6.45,6.45,0,0,1-1.39,0c-.2,0-.29,0-.35-.23a1.49,1.49,0,0,1,0-.21l0-.75c0-.48,0-1,0-1.45.54,0,1.09-.06,1.63,0,.25,0,.49.07.74.09a5.35,5.35,0,0,0,.8,0,3,3,0,0,1,.06.52,5.31,5.31,0,0,1-.09.79c0,.43.05.85,0,1.27C15.36,3,14.9,3,14.44,3Z"
      style={{ fill }}
    />
    <path d="M3.77,6.21A4.27,4.27,0,0,0,3.7,5.5a.11.11,0,0,0-.1-.09c-.29,0-.58.06-.88,0s-.48-.09-.74-.1c-.57,0-1.15.06-1.73.06a.13.13,0,0,0-.13.11l0,.07c0,.62,0,1.23,0,1.85,0,.26-.08.84.25,1a1.47,1.47,0,0,0,.55.08,3.84,3.84,0,0,0,.83,0c.62-.07,1.23,0,1.85-.06a.14.14,0,0,0,.13-.12c0-.5-.08-1,0-1.49C3.72,6.64,3.76,6.43,3.77,6.21Z" />
    <path
      d="M2.12,8.24a5.88,5.88,0,0,1-1.39,0c-.2,0-.3,0-.35-.22a1.49,1.49,0,0,1,0-.21c0-.25,0-.5,0-.75,0-.48,0-1,0-1.45.54,0,1.09-.06,1.63,0,.25,0,.49.07.74.09a7.22,7.22,0,0,0,.8,0c0,.17,0,.34.06.51s-.07.54-.09.8c0,.43,0,.85,0,1.27C3,8.26,2.58,8.2,2.12,8.24Z"
      style={{ fill }}
    />
    <path d="M9.93,6.21a4.27,4.27,0,0,0-.07-.71.1.1,0,0,0-.09-.09c-.3,0-.59.06-.89,0s-.48-.09-.73-.1c-.58,0-1.16.06-1.74.06a.14.14,0,0,0-.13.11.19.19,0,0,0,0,.07c0,.62,0,1.23,0,1.85,0,.26-.09.84.24,1a1.47,1.47,0,0,0,.55.08,3.84,3.84,0,0,0,.83,0c.62-.07,1.23,0,1.85-.06a.14.14,0,0,0,.13-.12c0-.5-.08-1,0-1.49C9.88,6.64,9.92,6.43,9.93,6.21Z" />
    <path
      d="M8.28,8.24a5.88,5.88,0,0,1-1.39,0c-.2,0-.29,0-.35-.22a1.49,1.49,0,0,1,0-.21l0-.75c0-.48,0-1,0-1.45.54,0,1.09-.06,1.63,0,.25,0,.49.07.74.09a7.13,7.13,0,0,0,.8,0,4.43,4.43,0,0,1,.06.51c0,.27-.07.54-.09.8,0,.43,0,.85,0,1.27C9.2,8.26,8.74,8.2,8.28,8.24Z"
      style={{ fill }}
    />
    <path d="M16.09,6.21A4.27,4.27,0,0,0,16,5.5a.1.1,0,0,0-.09-.09c-.3,0-.59.06-.88,0s-.49-.09-.74-.1c-.58,0-1.16.06-1.74.06a.12.12,0,0,0-.12.11.11.11,0,0,0,0,.07c0,.62,0,1.23,0,1.85,0,.26-.09.84.25,1a1.39,1.39,0,0,0,.54.08,3.84,3.84,0,0,0,.83,0c.62-.07,1.24,0,1.85-.06A.14.14,0,0,0,16,8.34c0-.5-.08-1,0-1.49C16,6.64,16.09,6.43,16.09,6.21Z" />
    <path
      d="M14.44,8.24a5.87,5.87,0,0,1-1.39,0c-.2,0-.29,0-.35-.22a1.49,1.49,0,0,1,0-.21l0-.75c0-.48,0-1,0-1.45.54,0,1.09-.06,1.63,0,.25,0,.49.07.74.09a7.13,7.13,0,0,0,.8,0,3,3,0,0,1,.06.51,5.29,5.29,0,0,1-.09.8c0,.43.05.85,0,1.27C15.36,8.26,14.9,8.2,14.44,8.24Z"
      style={{ fill }}
    />
    <path d="M3.77,11.44a4.27,4.27,0,0,0-.07-.71.11.11,0,0,0-.1-.09c-.29,0-.58.06-.88,0s-.48-.09-.74-.1c-.57,0-1.15.06-1.73.06a.13.13,0,0,0-.13.11l0,.07c0,.62,0,1.23,0,1.85,0,.26-.08.84.25,1a1.47,1.47,0,0,0,.55.08,3.84,3.84,0,0,0,.83,0c.62-.08,1.23,0,1.85-.07a.12.12,0,0,0,.13-.11c0-.5-.08-1,0-1.49C3.72,11.87,3.76,11.66,3.77,11.44Z" />
    <path
      d="M2.12,13.47a5.88,5.88,0,0,1-1.39,0c-.2,0-.3,0-.35-.22a1.65,1.65,0,0,1,0-.21c0-.25,0-.5,0-.75,0-.48,0-1,0-1.45.54,0,1.09-.06,1.63,0,.25,0,.49.07.74.09a7.22,7.22,0,0,0,.8,0c0,.17,0,.34.06.51s-.07.53-.09.8c0,.42,0,.85,0,1.27C3,13.49,2.58,13.43,2.12,13.47Z"
      style={{ fill }}
    />
    <path d="M9.93,11.44a4.27,4.27,0,0,0-.07-.71.1.1,0,0,0-.09-.09c-.3,0-.59.06-.89,0s-.48-.09-.73-.1c-.58,0-1.16.06-1.74.06a.14.14,0,0,0-.13.11.14.14,0,0,0,0,.07c0,.62,0,1.23,0,1.85,0,.26-.09.84.24,1a1.47,1.47,0,0,0,.55.08,3.84,3.84,0,0,0,.83,0c.62-.08,1.23,0,1.85-.07a.12.12,0,0,0,.13-.11c0-.5-.08-1,0-1.49C9.88,11.87,9.92,11.66,9.93,11.44Z" />
    <path
      d="M8.28,13.47a5.88,5.88,0,0,1-1.39,0c-.2,0-.29,0-.35-.22a1.65,1.65,0,0,1,0-.21l0-.75c0-.48,0-1,0-1.45.54,0,1.09-.06,1.63,0,.25,0,.49.07.74.09a7.13,7.13,0,0,0,.8,0,4.43,4.43,0,0,1,.06.51c0,.27-.07.53-.09.8,0,.42,0,.85,0,1.27C9.2,13.49,8.74,13.43,8.28,13.47Z"
      style={{ fill }}
    />
    <path d="M16.09,11.44a4.27,4.27,0,0,0-.07-.71.1.1,0,0,0-.09-.09c-.3,0-.59.06-.88,0s-.49-.09-.74-.1c-.58,0-1.16.06-1.74.06a.12.12,0,0,0-.12.11.09.09,0,0,0,0,.07c0,.62,0,1.23,0,1.85,0,.26-.09.84.25,1a1.39,1.39,0,0,0,.54.08,3.84,3.84,0,0,0,.83,0c.62-.08,1.24,0,1.85-.07a.12.12,0,0,0,.13-.11c0-.5-.08-1,0-1.49C16,11.87,16.09,11.66,16.09,11.44Z" />
    <path
      d="M14.44,13.47a5.87,5.87,0,0,1-1.39,0c-.2,0-.29,0-.35-.22a1.65,1.65,0,0,1,0-.21l0-.75c0-.48,0-1,0-1.45.54,0,1.09-.06,1.63,0,.25,0,.49.07.74.09a7.13,7.13,0,0,0,.8,0,3,3,0,0,1,.06.51,5.44,5.44,0,0,1-.09.8c0,.42.05.85,0,1.27C15.36,13.49,14.9,13.43,14.44,13.47Z"
      style={{ fill }}
    />
  </svg>
);

AppsIcon.defaultProps = {
  fill: '#FFF'
};

export default AppsIcon;
