import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet } from 'aphrodite/no-important';
import CharacterSelector from '../components/CharacterLists/CharacterSelector';
import ApplicationIFrame from '@lwtears/lwt-common-frontend/lib/ApplicationFrame';
import { selectCharacter, toggleAppLoadingScreenVisibility } from '../modules/character-selection';
import { fetchCharacters } from '../modules/character-selection/operations';
import PrekittApi from '../api';
import { appendSessionToUrl, getLiteSession } from '../api/lite-session';
import debounce from 'lodash/debounce';
import { AppNames } from '../types';

import { white } from '../styles/style-constants';
import chalkboardBackground from '../images/chalkboard-background.jpg';
import { DraggableIframeWrapper } from '../components/core/Modals/DraggableIframeWrapper';
import { useModal } from '@lwtears/lwt-common-frontend/lib/Modal';
import { makeStyles } from '@mui/styles';
import { DraggableModalTitle } from '../components/core/Modals/DraggableModalTitle';
import { setDocumentBodyColor } from '../util/ui-util';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { AppState } from 'reducers';
import { getZoomFactor } from '@lwtears/lwt-common-frontend/lib/@common/util/zoom-util';

const WetDryTry = () => {
  const dispatch = useDispatch() as any;
  const [{ isOpen }, { openDraggableModal, closeModal }] = useModal();
  const classes = useStyles();

  const { app: charactersApp, allCharacters: items, locale, selectedCharacter } = useSelector(
    (state: AppState) => state.characterSelection
  );

  useEffect(() => {
    dispatch(fetchCharacters('wdt'));
    setDocumentBodyColor('');
  }, [dispatch]);

  const isSelectedCharacterEmpty = selectedCharacter => selectedCharacter?.url === '#';

  const onWindowResize = () => {
    if (isSelectedCharacterEmpty(selectedCharacter)) {
      return;
    }
    closeModal();
    handleClickItem(selectedCharacter, () => removeWindowResizeEvents() as any);
  };

  const debouncedOnWindowResize = debounce(onWindowResize, 1000);

  const registerWindowResizeEvents = useCallback(() => {
    window.addEventListener('resize', debouncedOnWindowResize);
  }, [debouncedOnWindowResize]);

  const removeWindowResizeEvents = useCallback(() => {
    window.removeEventListener('resize', debouncedOnWindowResize);
  }, [debouncedOnWindowResize]);

  useEffect(() => {
    if (isSelectedCharacterEmpty(selectedCharacter) || !isOpen) {
      return;
    }

    registerWindowResizeEvents();
    return () => removeWindowResizeEvents();
  }, [selectedCharacter, registerWindowResizeEvents, removeWindowResizeEvents, isOpen]);

  const createEvent = resource => {
    const event = {
      section: 'wdt',
      resourceId: resource.id,
      resourceType: resource.type.type
    };

    PrekittApi.createEvent('accessResource', event);
  };

  const toggleLoading = () => {
    dispatch(toggleAppLoadingScreenVisibility());
  };

  const buildActivityUrl = selectedCharacterUrl =>
    selectedCharacterUrl + `&zoom=${getZoomFactor()}`;

  const handleClickItem = async (selectedCharacter, handleAfterClose = () => null) => {
    const ratio = selectedCharacter.characterCase === 'connections' ? '13/10' : '3/4';

    createEvent(selectedCharacter);
    dispatch(selectCharacter(selectedCharacter));

    const { sessionId } = await getLiteSession(AppNames.GSS);
    const url = appendSessionToUrl(buildActivityUrl(selectedCharacter.url), sessionId);

    openDraggableModal({
      Title: props => <DraggableModalTitle title={selectedCharacter.title} {...props} />,
      body: (
        <DraggableIframeWrapper>
          <ApplicationIFrame
            onMount={toggleLoading}
            onLoad={toggleLoading}
            aspectRatio={ratio}
            heightAdjust={125}
            url={url}
            title={selectedCharacter.title}
          />
        </DraggableIframeWrapper>
      ),
      ariaLabel: tA11y('aria.modal', { title: selectedCharacter.title }),
      options: { closeOnBackdropClick: true },
      handleAfterClose
    });
  };

  return (
    <div className={classes.container}>
      <CharacterSelector
        items={charactersApp === 'wdt' ? items : []}
        onClickItem={handleClickItem}
        styleOverrides={WDTStyle}
        application={'WDT'}
        characterType={'UpperCase'}
        locale={locale}
        containerStyles={{
          style: { background: `url(${chalkboardBackground}) fixed`, backgroundSize: 'cover' }
        }}
      />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: `url(${chalkboardBackground}) fixed`,
    backgroundSize: 'cover',
    color: white
  }
});

// using only because <CharacterSelector is using aphrodite atm
const WDTStyle = StyleSheet.create({
  heading: {
    color: white
  },
  listElement: {
    ':hover': {
      backgroundColor: 'rgba(0,0,0,0.5)'
    }
  }
});

export default WetDryTry;
