import React from 'react';
import { Resource } from 'types';

export const DRAWER_WIDTH = 290;

export type UseResourcesState = {
  isOpen: boolean;
  resources: Resource[];
};

export const mockedResource = {
  title: '',
  resourceType: '',
  aspectRatio: '',
  url: ''
};

type Action =
  | { type: 'SET_IS_OPEN'; payload: boolean }
  | { type: 'SET_RESOURCES'; payload: Resource[] };

export type UseResourcesDispatch = {
  setIsOpen: (isOpen: boolean) => void;
  setResources: (resources: Resource[]) => void;
};

type UseResources = [UseResourcesState, UseResourcesDispatch];

export const getDefaultResourcesPayload = (): UseResourcesState => ({
  isOpen: false,
  resources: []
});

const resourcesReducer = (state: UseResourcesState, action: Action): UseResourcesState => {
  switch (action.type) {
    case 'SET_IS_OPEN':
      return {
        ...state,
        isOpen: action.payload
      };
    case 'SET_RESOURCES':
      return {
        ...state,
        resources: action.payload
      };
    default:
      return getDefaultResourcesPayload();
  }
};

const ResourcesContext = React.createContext<any>(getDefaultResourcesPayload());

export const ResourcesProvider = (props: any) => {
  const [state, dispatch] = React.useReducer<React.Reducer<UseResourcesState, Action>>(
    resourcesReducer,
    getDefaultResourcesPayload()
  );

  const setIsOpen = (isOpen: boolean) => {
    dispatch({ type: 'SET_IS_OPEN', payload: isOpen });
  };

  const setResources = (resources: Resource[]) => {
    dispatch({ type: 'SET_RESOURCES', payload: resources });
  };

  return (
    <ResourcesContext.Provider value={[state, { setIsOpen, setResources }]}>
      {props.children}
    </ResourcesContext.Provider>
  );
};

export const useResources = (): UseResources => {
  const [state, dispatch] = React.useContext<UseResources>(ResourcesContext);
  return [state, dispatch];
};
