import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import '../node_modules/plyr/dist/plyr.css';
import { unregister } from './registerServiceWorker';
import App from './App';
import AuthConfig from './config/auth';
import './styles/index.css';

const msalInstance = new PublicClientApplication(AuthConfig);

const initializeApp = async () => {
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) msalInstance.setActiveAccount(accounts[0]);

  const container = document.getElementById('root');
  const root = ReactDOM.createRoot(container!);

  root.render(<App msalInstance={msalInstance} />);
};

initializeApp();
unregister();
