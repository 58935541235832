import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../util/style-util';
import { getTranslatedImage } from 'util/translation-util';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import closeX from '../../images/close-x.png';
import Overlay from '../core/TutorialScreenOverlay';
import { FOCUS_ACTIVE_WHITE_BORDER } from '../../styles/style-constants';
import Box from '@mui/material/Box';
import { onEscapeKeydown, onlyOnSpaceAndEnter } from '../../util/accessibility-util';
import TrapFocus from '../@layout/TrapFocus';
import { toggleGlobalMenu, toggleSlideoutMenuTutorial } from '../../actions/ui-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showTourOverlay } from '../../modules/ftue/selectors';
import { mapProductIdToProductCode } from 'util/license-util';
import { getProductCodeFromLicense } from 'modules/user/selectors';
import { AppStoreState } from 'types';

const SlideoutMenuTutorialOverlay = () => {
  const params = useParams();
  const dispatch = useDispatch() as any;
  const license = useSelector((state: AppStoreState) => getProductCodeFromLicense(state));
  const { slideoutTutorial, clickToCloseCSS, closeXCSS } = getStyles();
  const productCode = mapProductIdToProductCode(license.productId);
  const openTutorial = useSelector(state => showTourOverlay(state, params));

  const dismissOverlay = () => {
    dispatch(toggleGlobalMenu(false));
    dispatch(toggleSlideoutMenuTutorial(false));
  };

  return (
    <Overlay zIndex={9999} show={openTutorial}>
      <TrapFocus open={openTutorial}>
        <Box tabIndex={-1} onKeyDown={onEscapeKeydown(dismissOverlay)}>
          <img
            tabIndex={0}
            src={getTranslatedImage('click-to-close.png')}
            className={css(clickToCloseCSS)}
            alt={tA11y('alt.clickToClose')}
            onClick={dismissOverlay}
            onKeyDown={onlyOnSpaceAndEnter(dismissOverlay)}
          />

          <img
            tabIndex={0}
            src={closeX}
            className={css(closeXCSS)}
            alt={tA11y('alt.closeIcon')}
            onClick={dismissOverlay}
            onKeyDown={onlyOnSpaceAndEnter(dismissOverlay)}
          />
          <img
            alt={tA11y('alt.tutorialMenu')}
            src={getTranslatedImage(`feature-callout-${productCode}.png`)}
            className={css(slideoutTutorial)}
          />
        </Box>
      </TrapFocus>
    </Overlay>
  );
};

export const getStyles = () =>
  StyleSheet.create({
    slideoutTutorial: {
      backgroundSize: 'cover',
      zIndex: 11,
      position: 'absolute',
      left: remCalc(-175),
      top: remCalc(22),
      flexFlow: 'column nowrap'
    },
    clickToCloseCSS: {
      position: 'absolute',
      marginTop: remCalc(100),
      marginLeft: remCalc(275),
      top: '25%',
      left: '45%',
      zIndex: 12,
      cursor: 'pointer',
      ...FOCUS_ACTIVE_WHITE_BORDER
    },
    closeXCSS: {
      position: 'absolute',
      height: remCalc(40),
      width: remCalc(40),
      top: '1%',
      right: '2%',
      zIndex: 12,
      cursor: 'pointer',
      ...FOCUS_ACTIVE_WHITE_BORDER
    }
  });

export default SlideoutMenuTutorialOverlay;
