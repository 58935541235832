import PreKittApi from '../../api';
import type { ProductCode, BookCode, License, UserSettings, ProductCopyrightTerms } from 'types';
import { LOGIN_SUCCESS_ACTION } from '../auth';

export const settingsTypes = {
  clickAwayAccess: 'ClickAwayAccess',
  copyrightTerms: 'CopyrightTerms',
  locale: 'locale',
  licensing: 'licensing',
  productAccess: 'ProductAccess',
  suppressedExpirationAlerts: 'suppressedExpirationAlerts',
  assessmentsToGuideInstructions: 'assessmentsToGuideInstructions'
};

export type UserState = {
  id: number | null;
  authToken: string;
  firstName: string;
  lastName: string;
  licenses: License[];
  username: string;
  settings: UserSettings;
  clickAwayAccess: any[];
};

export type ClickAwayAccess = Array<{ productCode: ProductCode }>;
export type CopyrightTerms = Array<ProductCopyrightTerms>;

export const FETCHING_USER_SETTINGS = 'FETCHING_USER_SETTINGS';
export const FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS';
export const FETCH_USER_SETTINGS_FAIL = 'FETCH_USER_SETTINGS_FAIL';
export type FETCHING_USER_SETTINGS_ACTION = { type: typeof FETCHING_USER_SETTINGS };
export type FETCH_USER_SETTINGS_SUCCESS_ACTION = {
  type: typeof FETCH_USER_SETTINGS_SUCCESS;
  settings: UserSettings;
};
export type FETCH_USER_SETTINGS_FAIL_ACTION = {
  type: typeof FETCH_USER_SETTINGS_FAIL;
  error: Error;
};

export const SAVING_USER_SETTINGS = 'SAVING_USER_SETTINGS';
export const SAVE_USER_SETTINGS_SUCCESS = 'SAVE_USER_SETTINGS_SUCCESS';
export const SAVE_USER_SETTINGS_FAIL = 'SAVE_USER_SETTINGS_FAIL';
export type SAVING_USER_SETTINGS_ACTION = {
  type: typeof SAVING_USER_SETTINGS;
  settings: UserSettings;
};
export type SAVE_USER_SETTINGS_SUCCESS_ACTION = { type: typeof SAVE_USER_SETTINGS_SUCCESS };
export type SAVE_USER_SETTINGS_FAIL_ACTION = { type: typeof SAVE_USER_SETTINGS_FAIL; error: Error };

export const SAVING_CLICKAWAY_ACCESS = 'SAVING_CLICKAWAY_ACCESS';
export const SAVE_CLICKAWAY_ACCESS_SUCCESS = 'SAVE_CLICKAWAY_ACCESS_SUCCESS';
export const SAVE_CLICKAWAY_ACCESS_FAIL = 'SAVE_CLICKAWAY_ACCESS_FAIL';
export type SAVING_CLICKAWAY_ACCESS_ACTION = {
  type: typeof SAVING_CLICKAWAY_ACCESS;
  bookCode: BookCode;
  edition: number;
};
export type SAVE_CLICKAWAY_ACCESS_SUCCESS_ACTION = {
  type: typeof SAVE_CLICKAWAY_ACCESS_SUCCESS;
  clickAwayAccess: ClickAwayAccess;
};
export type SAVE_CLICKAWAY_ACCESS_FAIL_ACTION = {
  type: typeof SAVE_CLICKAWAY_ACCESS_FAIL;
  error: Error;
};

export const SAVING_COPYRIGHT_TERMS = 'SAVING_COPYRIGHT_TERMS';
export const SAVE_COPYRIGHT_TERMS_SUCCESS = 'SAVE_COPYRIGHT_TERMS_SUCCESS';
export const SAVE_COPYRIGHT_TERMS_FAIL = 'SAVE_COPYRIGHT_TERMS_FAIL';
export type SAVING_COPYRIGHT_TERMS_ACTION = {
  type: typeof SAVING_COPYRIGHT_TERMS;
  productCopyrightTerms: ProductCopyrightTerms;
};
export type SAVE_COPYRIGHT_TERMS_SUCCESS_ACTION = {
  type: typeof SAVE_COPYRIGHT_TERMS_SUCCESS;
  copyrightTerms: CopyrightTerms;
};
export type SAVE_COPYRIGHT_TERMS_FAIL_ACTION = {
  type: typeof SAVE_COPYRIGHT_TERMS_FAIL;
  error: Error;
};

export type UserAction =
  | FETCHING_USER_SETTINGS_ACTION
  | FETCH_USER_SETTINGS_SUCCESS_ACTION
  | FETCH_USER_SETTINGS_FAIL_ACTION
  | SAVING_USER_SETTINGS_ACTION
  | SAVE_USER_SETTINGS_SUCCESS_ACTION
  | SAVE_USER_SETTINGS_FAIL_ACTION
  | SAVING_CLICKAWAY_ACCESS_ACTION
  | SAVE_CLICKAWAY_ACCESS_SUCCESS_ACTION
  | SAVE_CLICKAWAY_ACCESS_FAIL_ACTION
  | SAVING_COPYRIGHT_TERMS_ACTION
  | SAVE_COPYRIGHT_TERMS_SUCCESS_ACTION
  | SAVE_COPYRIGHT_TERMS_FAIL_ACTION
  | LOGIN_SUCCESS_ACTION;

/* eslint-disable no-use-before-define */
type PromiseAction = Promise<UserAction>;
export type GetState = () => { user: UserState };
export type Dispatch = (
  action: UserAction | ThunkAction | PromiseAction | Array<UserAction>
) => any;
export type ThunkAction = (dispatch: Dispatch, getState: GetState, api: typeof PreKittApi) => any;
