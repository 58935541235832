import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const getSalesforceChatWidgetEl = (): Partial<HTMLElement> => {
  return document.querySelector('#fileUploadForm') as any;
};

const hideSalesforceChatWidgetEl = () => {
  const el = getSalesforceChatWidgetEl() as any;

  if (el) {
    el.style.display = 'none';
  }
};

const showSalesforceChatWidgetEl = () => {
  const el = getSalesforceChatWidgetEl() as any;

  if (el) {
    el.style.display = 'block';
  }
};

export const useToggleSalesforceChatWidget = () => {
  const location = useLocation();

  useEffect(() => {
    location.pathname === '/support' ? showSalesforceChatWidgetEl() : hideSalesforceChatWidgetEl();
  }, [location.pathname]);
};
