import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomeGrid from './components/HomeGrid';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { getUsersWorkbooks } from '../../modules/workbook';
import { Box, Button, Typography, Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getUser, logoutUser } from '../../modules/auth';
import { isClickAwayUser } from '../../modules/user';
import LocaleDropdown from '../../components/AppMenu/LocaleDropdown';
import { setDocumentBodyColor } from '../../util/ui-util';
import { PREKIT_FONT } from '../../styles/style-constants';
import { AppState } from 'reducers';

const translateHome = (key, options?) => translate('home', key, options);

const Home = () => {
  const dispatch = useDispatch() as any;
  const user = useSelector(({ user }: AppState) => user);
  const allBooks = useSelector(({ workbook }: AppState) => Object.values(workbook.allWorkbooks));
  const classes = useStyles();
  const maybeFetchUser = async () => !user.authToken && (await dispatch(getUser()));
  const maybeFetchWorkbooks = async () =>
    !isClickAwayUser(user) && !allBooks?.length ? await dispatch(getUsersWorkbooks()) : null;

  useEffect(
    () => {
      setDocumentBodyColor('#F2F8FF');
      maybeFetchUser();
      maybeFetchWorkbooks();
    }, // eslint-disable-next-line
    []
  );

  const logout = () => dispatch(logoutUser());
  const username = `${user.firstName} ${user.lastName}`;

  return (
    <Container className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Box display="flex" flexDirection="row">
            <Typography style={{ fontFamily: PREKIT_FONT }} variant="h3" component="h2">
              {translateHome('hi')}
            </Typography>
            <Typography
              variant="h3"
              style={{ fontWeight: 'bold', fontFamily: PREKIT_FONT }}
              component="h2"
            >
              , {username}
            </Typography>
          </Box>
          <Typography variant="h6" component="h3">
            {translateHome('greeting')}
          </Typography>
        </Box>
        <Box display="flex" flex=".5" justifyContent="flex-end">
          <div style={{ position: 'relative', top: 45, right: 30 }}></div>
          <LocaleDropdown
            styleOverrides={{
              style: { flexDirection: 'column', marginTop: 5, justifyContent: 'flex-end' }
            }}
          />
          <Button
            aria-label={tA11y('aria.logoutButton')}
            onClick={logout}
            variant="contained"
            className={classes.logoutBtn}
          >
            {translateHome('logout')}
          </Button>
        </Box>
      </Box>
      <Divider variant="fullWidth" className={classes.dividerStyle} />
      <HomeGrid />
    </Container>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: `1600px !important`,
    padding: 18
  },
  dividerStyle: {
    margin: '24px 0px !important',
    backgroundColor: '#0171BB'
  },
  logoutBtn: {
    backgroundColor: '#0278C0',
    color: '#FFFFFF',
    width: 130,
    height: 48,
    alignSelf: 'flex-end',
    textTransform: 'none',
    fontWeight: 'bold',
    margin: '0 12px',
    '&:hover': {
      backgroundColor: '#005FA7',
      color: '#FFFFFF'
    }
  }
}));

export default Home;
