import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../util/style-util';
import { getSpecificTranslation } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const translateSelectForLocale = (locale, text) => getSpecificTranslation(locale, 'select', text);

type Props = {
  children: any | Array<any>;
  textLocale: string;
};

const { subheader } = StyleSheet.create({
  subheader: {
    width: remCalc(800)
  }
});

const FullAccessWorkbook = ({ children, textLocale }: Props) => (
  <div>
    <hr className={css(subheader)} />
    <h3>{translateSelectForLocale(textLocale, 'subHeader')}</h3>
    {children}
  </div>
);

export default FullAccessWorkbook;
