import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../util/style-util';
import { oopsGreen, white, pinRed } from '../../styles/style-constants';

type Props = {
  pinnedItems: number
};
const FavoritesBadge = ({ pinnedItems }: Props) => {
  const { badgeCircle } = getStyles(pinnedItems);

  return <div className={css(badgeCircle)}>{Math.abs(pinnedItems)}</div>;
};

const getStyles = pinnedItems =>
  StyleSheet.create({
    badgeCircle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: remCalc(18),
      height: remCalc(18),
      borderRadius: '50%',
      backgroundColor: pinnedItems > 0 ? oopsGreen : pinRed,
      fontSize: 14,
      color: white,
      fontWeight: 500,
      margin: '0 0 10px 5px'
    }
  });

export default FavoritesBadge;
