import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'state/hooks/withRouter';
import { StyleSheet, css } from 'aphrodite/no-important';
import find from 'lodash/find';
import { sendEmailLink } from '../../../modules/user';
import { remCalc } from '../../../util/style-util';
import BasicButton from '../../../components/core/BasicButton';
import Button from '../../Button';
import ResourcePlayer from '../../Resources/ResourcePlayer/ResourcePlayer';
import maybe from '../../core/enhancers/maybe';
import {
  activeGreen,
  errorRed,
  gssGreen,
  lightGray,
  white,
  buttonBlue
} from '../../../styles/style-constants';
import stopCrossingGuard from '../../../images/stop-crossing-guard.jpg';
import gssLogo from '../../../images/gss-logo-black.svg';
import { fetchMobileResources } from '../../../actions/resource-operations';
import { selectResource, toggleResourcePlayerVisibility } from '../../../actions/resource-actions';
import PrekittApi from '../../../api';
import Config from 'config';
import WebLink from '../../core/WebLink';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

import { Resource, BookCode } from 'types';
import { AppState } from '../../../reducers';
const translateErrors = key => translate('errors', key);

const { supportEmail } = Config;


type WorkbooksCollection = any;

type Props = {
  resourcePath: string;
  emailAddress: string;
  allWorkbooks: WorkbooksCollection;
  mobileResources: Array<Resource>;
  selectedResource: any;
  showResourcePlayer: boolean;
  isDeviceCompatible: boolean;
  sendEmailLink: () => any;
  selectResource: (resource: Resource) => any;
  toggleResourcePlayerVisibility: (show: boolean) => any;
  fetchMobileResources: (bookCode?: BookCode) => any;
} | any;

type State = {
  hasError: boolean;
  isEmailSent: boolean;
};

export class OptimalExperiencePage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      isEmailSent: false
    };
  }

  componentDidMount() {
    const { state } = this.props.router.location;

    if (this.props.isDeviceCompatible) {
      this.props.router.navigate('/');
    } else {
      this.props.fetchMobileResources(state ? state.code : undefined);
    }
  }

  handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      await this.props.sendEmailLink();
      this.setState({ hasError: false, isEmailSent: true });
    } catch (err) {
      this.setState({ isEmailSent: false, hasError: true });
    }
  };

  createEvent(resource: any) {
    let currentBook;

    const { state } = this.props.router.location;
    if (state) {
      currentBook = find(this.props.allWorkbooks, book => book.bookCode === state.code);
    }

    const event = {
      ...(currentBook ? { bookId: currentBook.id } : {}),
      section: 'mobileExperience',
      resourceId: resource.id,
      resourceType: resource.type
    };

    PrekittApi.createEvent('accessResource', event);
  }

  handleSelectResource = (resource: Resource) => (): void => {
    const liteResource: any = {
      id: resource.id,
      title: resource.title,
      resourceType: resource.type.type,
      mediaType: resource.data.mediaType,
      url: resource.src,
      aspectRatio: resource.data.aspectRatio,
      heightAdjust: resource.data.heightAdjust,
      fileExtension: resource.data.fileExtension
    };

    this.props.selectResource(liteResource);
    this.props.toggleResourcePlayerVisibility(true);
    this.createEvent(liteResource);
  };

  handleClosePlayer = (): void => {
    this.props.toggleResourcePlayerVisibility(false);
  };

  renderButtons() {
    const buttons: Array<any> = this.props.mobileResources.map((resource: Resource) => (
      <Button
        key={resource.id}
        id={resource.id}
        title={resource.title}
        icon={resource.type.icon.default}
        size={50}
        onClick={this.handleSelectResource(resource)}
      />
    ));

    return buttons;
  }

  render() {
    const { isEmailSent } = this.state;
    const {
      url,
      title,
      mediaType,
      resourceType,
      aspectRatio,
      heightAdjust
    } = this.props.selectedResource;
    const {
      container,
      containerBody,
      stopImage,
      emailForm,
      statusMessage,
      successMessage,
      button,
      buttonMenu,
      footerContainer,
      footer,
      heading1,
      heading3,
      accessDetails,
      supportDetails,
      link
    } = styles;
    const MaybeResourcePlayer: any = maybe(ResourcePlayer);

    return (
      <div className={css(container)}>
        <MaybeResourcePlayer
          shouldRender={!!url}
          src={url}
          show={this.props.showResourcePlayer}
          title={title}
          mediaType={mediaType}
          resourceType={resourceType}
          aspectRatio={aspectRatio}
          heightAdjust={heightAdjust}
          onClose={this.handleClosePlayer}
        />
        <div className={css(containerBody)}>
          <img className={css(stopImage)} src={stopCrossingGuard} alt={tA11y('alt.prekittStop')} />
          <h1 className={css(heading1)}>{translateErrors('mobileHeader')}</h1>
          <h3 className={css(heading3)}>{translateErrors('recommend')}</h3>
          {isEmailSent && (
            <span className={css(statusMessage, successMessage)}>
              {translateErrors('confirmationText')}
            </span>
          )}
          <form className={css(emailForm)} onSubmit={this.handleSubmit}>
            <BasicButton buttonStyles={[button]} type="submit">
              {translateErrors('emailYourself')}
            </BasicButton>
          </form>
          <div className={css(accessDetails)}>
            <h4>{translateErrors('howToAccess')}</h4>
            <ol>
              <li>
                <span onClick={this.handleSubmit} className={css(link)}>
                  {translateErrors('accessStep1a')}
                </span>{' '}
                {translateErrors('accessStep1b')}
              </li>
              <li>{translateErrors('accessStep2')}</li>
            </ol>
          </div>
          <section className={css(buttonMenu)}>{this.renderButtons()}</section>
          <div className={css(supportDetails)}>
            {translateErrors('needHelp')}
            <WebLink to={`mailto:${supportEmail}`} name={supportEmail} />
          </div>
        </div>
        <footer className={css(footerContainer)}>
          <img src={gssLogo} className={css(footer)} alt={tA11y('alt.prekittLogo')} />
        </footer>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    textAlign: 'center',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderTop: `10px solid ${gssGreen}`
  },
  containerBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: remCalc(20, 20, 5)
  },
  heading1: {
    fontWeight: 800,
    fontSize: remCalc(30),
    padding: remCalc(0, 0, 10, 0),
    margin: 0
  },
  heading3: {
    fontWeight: 500,
    fontSize: remCalc(24),
    padding: remCalc(10, 0, 20),
    margin: remCalc(10, 0)
  },
  button: {
    fontWeight: 400,
    fontSize: remCalc(18),
    color: white,
    backgroundColor: buttonBlue,
    padding: remCalc(15, 45),
    border: 'none',
    borderRadius: remCalc(4),
    margin: 0
  },
  accessDetails: {
    fontWeight: 400,
    fontSize: remCalc(18),
    padding: remCalc(10, 0),
    margin: 0,
    textAlign: 'left'
  },
  supportDetails: {
    fontWeight: 400,
    fontSize: remCalc(14),
    paddingTop: remCalc(5),
    margin: 0,
    textAlign: 'left'
  },
  stopImage: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  statusMessage: {
    fontWeight: 400
  },
  errorMessage: {
    color: errorRed
  },
  successMessage: {
    color: activeGreen,
    padding: remCalc(10, 0, 20, 0)
  },
  emailForm: {
    fontWeight: 400,
    width: '100%'
  },
  buttonMenu: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexFlow: 'row wrap'
  },
  footerContainer: {
    backgroundColor: lightGray
  },
  footer: {
    padding: remCalc(10),
    maxWidth: 280,
    width: '65%'
  },
  link: {
    color: buttonBlue,
    ':hover': {
      // color: activeColor,
      textDecoration: 'none',
      cursor: 'pointer'
    }
  }
});

const mapStateToProps = (state: AppState) => {
  return {
    allWorkbooks: state.workbook.allWorkbooks,
    isDeviceCompatible: state.ui.isDeviceCompatible,
    emailAddress: state.user.username,
    mobileResources: state.resources.allResources,
    selectedResource: state.resources.selectedResource,
    showResourcePlayer: state.resources.showResourcePlayer
  };
};

const withRedux = connect(mapStateToProps, {
  sendEmailLink,
  fetchMobileResources,
  selectResource,
  toggleResourcePlayerVisibility
});

export default compose(withRedux, withRouter)(OptimalExperiencePage);
