import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Transition from 'react-transition-group/Transition';
import { TweenMax, Circ } from 'gsap';
import CharacterMenuGroup from './CharacterMenuGroup';
import CategoryMenuGroup from './CategoryMenuGroup';
import GoToPage from './GoToPage';
import MenuGroupTabs from './MenuGroupTabs';
import BasicButton from '../core/BasicButton';
import DrawnCircleXIcon from '../icons/DrawnCircleXIcon';
import { remCalc } from '../../util/style-util';

import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import TrapFocus from '../@layout/TrapFocus';

type Props = {
  show: boolean;
  content: Array<any>;
  primaryBookColor: string;
  totalPages: number;
  currentPageNumber: number;
  workbookCode: string;
  toggleMenu: (open?: boolean) => any;
};

type State = {
  activeGroup: string;
  duration: number;
  workbookCode: string;
};

const enterDuration = 1000;
const exitDuration = 500;

class PageSelectorMenu extends React.Component<Props, State> {
  state = {
    activeGroup: 'search',
    duration: enterDuration,
    workbookCode: ''
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    state.duration = props.show ? enterDuration : exitDuration;
    if (state.workbookCode !== props.workbookCode) {
      return { activeGroup: 'search', workbookCode: props.workbookCode };
    }

    return null;
  }

  buildMenuGroups = () => {
    const { content, currentPageNumber, totalPages } = this.props;
    const sections: any = {};

    content.forEach(section => {
      sections[section.grouping] = this.createGroup(section);
    });

    sections.search = (
      <GoToPage
        currentPage={currentPageNumber}
        totalPages={totalPages}
        closeMenu={this.closeMenu}
      />
    );

    return sections;
  };

  createGroup = (section: any) => {
    return section.grouping === '123' && section.categories.length === 1 ? (
      <CharacterMenuGroup
        section={section}
        characters={section.categories[0].characters}
        color={this.props.primaryBookColor}
        closeMenu={this.closeMenu}
        name={section.grouping}
        type={section.grouping}
      />
    ) : (
      <CategoryMenuGroup
        categories={section.categories}
        color={this.props.primaryBookColor}
        closeMenu={this.closeMenu}
        name={section.grouping}
        categoryType={section.type}
        hasIcon={section.hasIcon}
      />
    );
  };

  changeTOCGroup = (groupName: string) => () => {
    this.setState({ activeGroup: groupName });
  };

  closeMenu = () => this.props.toggleMenu(false);

  animate = (menu: HTMLElement): TweenMax => {
    TweenMax.fromTo(
      menu,
      this.state.duration / 1000,
      {
        y: this.props.show ? 0 : -25,
        opacity: this.props.show ? 0 : 1
      },
      {
        y: this.props.show ? -25 : 0,
        opacity: this.props.show ? 1 : 0,
        ease: Circ.easeOut
      }
    );
  };

  render() {
    const { activeGroup: active } = this.state;
    const { pageMenu, closeButton } = styles;
    const menuGroups = this.buildMenuGroups();

    const transitionProps: any = {
      in: this.props.show,
      timeout: this.state.duration,
      onEnter: this.animate,
      onExit: this.animate,
      mountOnEnter: true,
      unmountOnExit: true
    };
    return (
      <Transition {...transitionProps}>
        <TrapFocus open={this.props.show}>
          <nav className={css(pageMenu)}>
            <BasicButton
              icon={DrawnCircleXIcon}
              onClick={this.closeMenu}
              buttonStyles={[closeButton]}
              aria-label={tA11y('aria.closeMenu')}
              tabIndex={0}
            />
            <MenuGroupTabs
              activeGroup={active}
              content={this.props.content}
              handleTOCGroupChange={this.changeTOCGroup}
            />
            {menuGroups[active]}
          </nav>
        </TrapFocus>
      </Transition>
    );
  }
}

const styles = StyleSheet.create({
  pageMenu: {
    display: 'grid',
    gridTemplateColumns: remCalc(100, 'auto', 100),
    gridTemplateRows: remCalc(100, 'max-content', 25),
    gridTemplateAreas: "'header header header' '. main .'",
    backgroundColor: 'white',
    border: '3px solid black',
    borderRadius: '15px',
    position: 'absolute',
    bottom: '7vh',
    fontFamily: 'GSS Print Wide',
    minHeight: remCalc(536),
    zIndex: 7,
    ':after': {
      content: "''",
      position: 'absolute',
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderTop: '20px solid white',
      width: 0,
      bottom: -15,
      right: '50%',
      transform: 'translateX(20px)'
    }
  },
  closeButton: {
    width: remCalc(30),
    height: remCalc(30),
    padding: '0',
    position: 'absolute',
    top: 10,
    right: 10,
    ':active': {
      transform: 'translateY(3px)'
    }
  }
});

export default PageSelectorMenu;
