import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../../util/style-util';
import chevronPath from '../../../images/resources/chevron.svg';
import { onlyOnSpaceAndEnter } from '../../../util/accessibility-util';
import { Box } from '@mui/material';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const ArrowDropdown = ({ onClick, open, accessibilityLabelButton }) => {
  const styles = StyleSheet.create(getStyles(open) as any) as any;

  return (
    <Box
      className={css(styles.arrowContainer)}
      role="button"
      aria-label={accessibilityLabelButton(open)}
      aria-pressed={open}
      onKeyDown={onlyOnSpaceAndEnter(onClick)}
    >
      <img className={css(styles.icon)} alt={tA11y('alt.chevronIcon')} src={chevronPath} />
    </Box>
  );
};

const getStyles = open => ({
  icon: {
    width: remCalc(20),
    transform: open ? 'rotate(180deg)' : 'rotate(0)',
    transformOrigin: 'center center',
    transition: 'transform 300ms linear'
  },
  arrowContainer: {
    display: 'flex',
    width: '20%',
    height: remCalc(40),
    top: remCalc(6),
    cursor: 'pointer',
    justifyContent: 'flex-end',
    paddingRight: 5,
    paddingLeft: 5,
    border: 'none',
    userSelect: 'none',
    backgroundColor: 'transparent'
  }
});

export default ArrowDropdown;
