//
import React from 'react';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { StyleSheet, css } from 'aphrodite/no-important';
import selectorBackground from '../../images/counter.png';
import FtueCallout from '../Ftue/FtueCalloutContainer';
import { remCalc } from '../../util/style-util';
import { Button, Typography } from '@mui/material';
import { PREKIT_FONT } from '../../styles/style-constants';
const translateFooter = key => translate('footer', key);
type Props = {
  currentPage: number;
  totalPages: number | string;
  pageOffset: number;
  isZoom: boolean;
  toggleMenu?: (open?: boolean) => any;
  singlePage: boolean;
};

const { pageSelector, pointerCursor } = StyleSheet.create({
  pageSelector: {
    width: remCalc(170),
    height: remCalc(53),
    backgroundColor: 'transparent',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${selectorBackground})`,
    padding: remCalc(12),
    textAlign: 'center',
    transform: 'translateY(-5%)',
    ':hover': {
      transform: 'scale(1.05, 1.05)'
    }
  },
  pointerCursor: {
    cursor: 'pointer'
  }
});

const PageSelector = ({
  currentPage,
  totalPages,
  pageOffset,
  isZoom,
  toggleMenu,
  singlePage
}: Props) => {
  const currentPageRange = (): string | number => {
    if (Number.isNaN(currentPage)) return translateFooter('cover');
    if (currentPage === 0) {
      if (pageOffset === 0) {
        return '1';
      } else {
        //[TODO]: here needs to be implemented pageOffset>0 iteration
        return 'i';
      }
    }
    if (currentPage === totalPages || isZoom || singlePage) return currentPage;

    return `${currentPage} - ${currentPage + 1}`;
  };

  const handleClick = () => toggleMenu && toggleMenu();

  const footerText = `${currentPageRange()} ${translateFooter('of')} ${totalPages}`;

  return (
    <Button
      tabIndex={0}
      aria-label={`${tA11y('aria.pageSelector')} ${currentPageRange()} ${translateFooter(
        'of'
      )} ${totalPages}`}
      onClick={handleClick}
      className={`${css(pageSelector, toggleMenu && pointerCursor)}`}
      disableFocusRipple
      disableRipple
      disableTouchRipple
    >
      <Typography
        variant="body2"
        style={{
          fontFamily: PREKIT_FONT,
          textTransform: 'none',
          color: 'black',
          fontSize: '15px',
          fontWeight: 700
        }}
      >
        {footerText}
      </Typography>
      <FtueCallout
        name="bookNavigation"
        step="pageSelector"
        position="top"
        justifyPointer="center"
      />
    </Button>
  );
};

PageSelector.defaultProps = {
  totalPages: '-'
};

export default PageSelector;
