import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { toggleTag } from '../../actions/resource-actions';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const StyledBreadcrumb: any = withStyles(() => {
  const theme = useTheme();
  return ({
  root: () => ({
    backgroundColor: '#000000',
    height: theme.spacing(3),
    color: '#FFFFFF',
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'none',
    marginTop: '5px',
    '&:hover, &:focus': {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      textDecoration: 'none'
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: '#000000',
      textDecoration: 'none'
    }
  })
})})(Chip);

const ResourceFilterBreadcrumbs = () => {
  const { selectedTags } = useSelector(({ resources }: any) => resources.selectedFilters);
  const dispatch = useDispatch() as any;

  const handleClick = (tag: any) => {
    dispatch(toggleTag(tag));
  };

  return (
    <Breadcrumbs separator="" aria-label="breadcrumb">
      {selectedTags.map(tag => (
        <StyledBreadcrumb
          key={tag.value}
          component="a"
          href="#"
          label={tag.value}
          aria-label={`${tA11y('aria.removeFilterFor')} ${tag.value}`}
          role="button"
          onClick={() => handleClick(tag)}
          onDelete={() => handleClick(tag)}
          deleteIcon={
            <FontAwesomeIcon
              style={{ marginLeft: '5px', marginRight: '10px', color: '#FFFFFF' }}
              icon={faTimes}
            />
          }
        />
      ))}
    </Breadcrumbs>
  );
};

export default ResourceFilterBreadcrumbs;
