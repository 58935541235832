import { sortBy, uniqBy } from 'lodash';
import { Resource } from 'types';
import { ResourcesState, FilterTag } from '../reducers/resource-reducer';

function _searchInAttribute(value: any, searchText: string): boolean {
  return value ? value.toString().toLowerCase().indexOf(searchText.toLowerCase()) >= 0 : false;
}
function _searchInArray(array: Array<any>, attributes: Array<string>, searchText: string): boolean {
  return (
    array !== undefined &&
    array !== null &&
    array.some(item => {
      return attributes.some(attribute => {
        return _searchInAttribute(item[attribute], searchText);
      });
    })
  );
}
function _searchResources(resources: Array<Resource>, searchText: string): Array<Resource> {
  return resources.filter(resource => {
    return (
      _searchInAttribute(resource.title, searchText) ||
      _searchInAttribute(resource.description, searchText) ||
      _searchInArray(resource.tags, ['tag', 'value'], searchText)
    );
  });
}
function _resourcesHasASelectedTag(
  resource: Resource,
  selectedFilterTags: Array<FilterTag>
): boolean {
  //if the resource has any tag
  if (resource.tags && resource.tags.length > 0) {
    let intersects = false;

    for (let i = 0, len = resource.tags.length; i < len; i++) {
      const x = resource.tags[i];

      if (selectedFilterTags.findIndex(tag => tag.id === x.id) >= 0) {
        intersects = true;
      }
    }

    return intersects;
  }

  return false;
}

export function filterResources(
  allResources: Resource[],
  selectedFilterTags: any,
  searchText: string
): Resource[] {
  // if there is any filtering on tags
  let filtered = Array.from(allResources);
  if (searchText) {
    filtered = _searchResources(filtered, searchText);
  }

  if (selectedFilterTags && selectedFilterTags.length > 0) {
    return filtered.filter((resource: Resource) =>
      _resourcesHasASelectedTag(resource, selectedFilterTags)
    );
  }

  return filtered;
}

export function toggleFilterTag(
  selectedFilterTags: Array<FilterTag>,
  selectedTag: any
): Array<FilterTag> {
  const tagIndex = selectedFilterTags.findIndex(tag => tag.id === selectedTag.id);

  if (tagIndex >= 0) {
    selectedFilterTags.splice(tagIndex, 1);
  } else {
    selectedFilterTags.push(selectedTag);
  }

  return selectedFilterTags;
}

export function updateSelectedFilters(state: ResourcesState, { searchText, selectedTags }: any) {
  const newSelectedFilters = {
    searchText: searchText !== undefined ? searchText : state.selectedFilters.searchText,
    selectedTags:
      selectedTags !== undefined ? [...selectedTags] : [...state.selectedFilters.selectedTags]
  };

  return newSelectedFilters;
}
export function getPageNumber(resource: Resource): number {
  if (resource.pages && resource.pages.length > 0) {
    if (resource.pages[0].workbookPage) {
      return resource.pages[0].workbookPage;
    } else {
      return resource.pages[0].teacherGuidePage;
    }
  }

  return 0;
}
export function getPageId(resource: Resource): number {
  if (resource.pages && resource.pages.length > 0) {
    return resource.pages[0].id;
  }

  return 0;
}

// just creating a flat list in alphabetical order for now until categories are set up
export const getGroupedResources = pagesDetails => {
  return uniqBy(
    sortBy(
      pagesDetails
        ?.map(({ resources }) => (resources ? Object.values(resources) : []))
        ?.flat()
        ?.flat(),
      'title'
    ),
    'id'
  );
  // return pagesDetails?.reduce((acc, { resources }) => {
  //   if (!resources) return acc;
  //   Object.entries(resources)?.map(([category, resources]) => {
  //     acc[category] = sortBy(
  //       uniqBy([...(acc[category] ?? []), ...(resources as any[])], 'id'),
  //       'title'
  //     );
  //     return acc[category];
  //   });
  //   return acc;
  // }, {});
};
