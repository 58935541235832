import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Page404 from './components/core/Page404/Page404';
import rootRoutes from './routes';
import MainHeaderLayout from './components/@layout/MainHeaderLayout';

const AppRouter = () => {
  return (
    <Routes>
      {rootRoutes.map(({ component: Component, ...x }) => (
        <Route
          key={x.path}
          path={x.path}
          element={
            x?.withMainHeader ? (
              <MainHeaderLayout {...x}>
                <Component {...x} />
              </MainHeaderLayout>
            ) : (
              <Component {...x} />
            )
          }
        />
      ))}
      <Route path="*" element={<Navigate to={'/home'} replace />} />
      <Route element={<Page404 />} />
    </Routes>
  );
};

export default AppRouter;
