import storage from '../util/local-storage-util';
import Config from 'config';

const FailureCodes = [401, 404];

const { companyURL } = Config;
const isAuthFailureAction = action =>
  action.error && action.error.response && FailureCodes.includes(action.error.response.status);

const authFailure = () => next => action => {
  if (isAuthFailureAction(action)) {
    const redirectURL = storage.get('loginRedirect') || window.location.href;
    const loginURL = `${companyURL}/prekitt-login?returnURL=${redirectURL}`;

    window.location.assign(loginURL);
  } else {
    return next(action);
  }
};

export default authFailure;
