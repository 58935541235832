//
import * as types from './types';
import { TourStep } from 'types';

export const fetchTours = (): types.FETCH_TOURS_ACTION => ({
  type: types.FETCH_TOURS
});

export const fetchToursSuccess = (tours: types.FtueState): types.FETCH_TOURS_SUCCESS_ACTION => ({
  type: types.FETCH_TOURS_SUCCESS,
  tours
});

export const fetchToursFailure = (error: Error): types.FETCH_TOURS_FAILURE_ACTION => ({
  type: types.FETCH_TOURS_FAILURE,
  error
});

export const puttingTourSettings = (): types.SAVING_COMPLETED_TOUR_ACTION => ({
  type: types.SAVING_COMPLETED_TOUR
});

export const putTourSettingsSuccess = (): types.SAVE_COMPLETED_TOUR_SUCCESS_ACTION => ({
  type: types.SAVE_COMPLETED_TOUR_SUCCESS
});

export const putTourSettingsFailure = (): types.SAVE_COMPLETED_TOUR_FAILURE_ACTION => ({
  type: types.SAVE_COMPLETED_TOUR_FAILURE
});

export const addToTourQueue = (name: string): types.QUEUE_TOUR_ACTION => ({
  type: types.QUEUE_TOUR,
  name
});

export const removeFromTourQueue = (names: string[]): types.DEQUEUE_TOUR_ACTION => ({
  type: types.DEQUEUE_TOUR,
  names
});

export const prioritizeTour = (name: string): types.PRIORITIZE_TOUR_ACTION => ({
  type: types.PRIORITIZE_TOUR,
  name
});

export const incrementTourStep = (step: TourStep): types.INCREMENT_CURRENT_STEP_ACTION => ({
  type: types.INCREMENT_CURRENT_STEP,
  step
});

export const completeCurrentTour = (): types.COMPLETE_CURRENT_TOUR_ACTION => ({
  type: types.COMPLETE_CURRENT_TOUR
});
