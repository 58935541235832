//
import React from 'react';
import { StyleSheet } from 'aphrodite';
import BasicButton from '../core/BasicButton';
import { remCalc } from '../../util/style-util';
import { PREKIT_FONT, black } from '../../styles/style-constants';
import type { ComponentType } from 'react';

type Props =
  | {
      isActive: boolean;
      name: string;
      icon?: ComponentType<any>;
      iconSize?: string | number;
    }
  | any;

export const styles = StyleSheet.create({
  menuGroupButton: {
    color: '#757575',
    fill: '#757575',
    fontSize: remCalc(22),
    fontFamily: PREKIT_FONT,
    fontWeight: 700,
    minWidth: remCalc(40),
    maxHeight: remCalc(52),
    margin: remCalc(0, 14),
    ':hover': {
      color: black,
      fill: black
    },
    transition: 'color 150ms, fill 150ms'
  },
  activeButton: {
    color: black,
    fill: black
  }
});

const MenuGroupButton = ({ isActive, name, icon: Icon, iconSize, ...props }: Props) => {
  return (
    <BasicButton
      buttonStyles={[styles.menuGroupButton, isActive && styles.activeButton]}
      {...props}
    >
      {Icon ? <Icon width={iconSize && remCalc(iconSize)} /> : name}
    </BasicButton>
  );
};

export default MenuGroupButton;
