export const getLicense = (licenses, products) =>
  licenses.find(l => products.map(product => product.productId).includes(l.productId));

export const hasFullLicense = user => {
  return user.licenses.some(x => x.licenseTypeId === 1);
};

export const mapProductIdToProductCode = licenseId => {
  const productMap = {
    20: 'PREKITT-RW',
    21: 'PREKITT-GSS',
    24: 'PREKITT-GSS' // 'PREKITT-TX'
  };

  return productMap[licenseId];
};
