import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import ChevronIcon from '../../icons/ChevronIcon';
import { remCalc } from '../../../util/style-util';
import { gray3 } from '../../../styles/style-constants';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import TrapFocus from 'components/@layout/TrapFocus';

type Props = {
  label: string;
  openByDefault?: boolean;
  openFilter: string;
  setOpenFilter: any;
  selectedTags: Array<any>;
  filterGroupValues: Array<any>;
  children: any | Array<any>;
  classes?: string;
  applySectionClass?: any;
};

const translateResources = key => translate('resources', key);
class FilterDropDown extends Component<Props> {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  wrapperRef: any = null;
  parentWrapperRef: any = null;

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  setParentWrapperRef = node => {
    this.parentWrapperRef = node;
  };
  handleKeyDown = event => {
    const key = event.keyCode;
    if (key === 32 || key === 13) {
      this.handleClick();
    }
  };
  handleOnEscape = event => {
    const key = event.keyCode;
    if (key === 27) {
      this.handleClick();
    }
  };
  handleClick = () => {
    const { label, openFilter, setOpenFilter } = this.props;
    setOpenFilter(openFilter !== label ? label : '');
  };
  handleClickOutside = event => {
    //Mouse click is not on the dropdown menu
    if (this.wrapperRef && !this.wrapperRef?.contains(event.target)) {
      //And mouse click is not in the parent pulldown -- if the click is there, it will
      //call handleClick on its own
      if (this.parentWrapperRef && !this.parentWrapperRef.contains(event.target)) {
        this.handleClick();
      }
    }
  };

  renderContent() {
    const { content } = styles;
    const { openFilter, label } = this.props;

    return openFilter === label ? (
      <TrapFocus open={true}>
        <div role="menu" ref={this.setWrapperRef} className={css(content)}>
          {this.props.children}
        </div>
      </TrapFocus>
    ) : null;
  }

  render() {
    const { dropdown, enclosingDiv, head, headOpen, rotate180, iconContainer, legend } = styles;
    const { openFilter, label, selectedTags, filterGroupValues, classes, applySectionClass } =
      this.props;
    const isOpen = openFilter === label;

    let filterText = translateResources('allOption');
    if (selectedTags) {
      if (label === translateResources('resourceType')) {
        const relevantTags = selectedTags.filter(selectedFilter =>
          filterGroupValues.find(filter => filter.id === selectedFilter.id)
        );
        if (relevantTags.length > 0) {
          filterText =
            relevantTags.length === 1 ? relevantTags[0].value : relevantTags.length.toString();
        }
      } else {
        filterText = selectedTags.reduce((acc, selectedFilter) => {
          const matchingFilter = filterGroupValues.find(filter => filter.id === selectedFilter.id);
          if (matchingFilter !== undefined) {
            if (acc === translateResources('allOption')) {
              return (acc = matchingFilter.value);
            } else {
              return (acc = acc + ', ' + matchingFilter.value);
            }
          }
          return acc;
        }, filterText);
      }
    }

    return (
      <fieldset ref={this.setParentWrapperRef} className={css(enclosingDiv)}>
        <legend className={css(legend)}>{this.props.label}</legend>
        <div
          className={classes || css(dropdown)}
          onKeyDown={this.handleOnEscape}
          role="listbox"
          aria-multiselectable={true}
        >
          <section
            className={`${css(head, isOpen && headOpen)} ${applySectionClass}`}
            onClick={this.handleClick}
            tabIndex={0}
            aria-label={`${tA11y('aria.filterFor')} ${label}`}
            role="menu"
            aria-expanded={isOpen}
            onKeyDown={this.handleKeyDown}
          >
            <span>{filterText}</span>
            <div className={css(iconContainer)}>
              <div className={css(isOpen && rotate180)}>
                <ChevronIcon />
              </div>
            </div>
          </section>
          {this.renderContent()}
        </div>
      </fieldset>
    );
  }
}

const styles: any = StyleSheet.create({
  enclosingDiv: {
    padding: '10px 0px 15px 0px',
    color: '#202020',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '18px',
    fontFamily: 'GSS Futura',
    border: 'none'
  },
  dropdown: {
    width: remCalc(190),
    userSelect: 'none',
    border: '1px solid #000000',
    backgroundColor: '#FFFFFF',
    marginRight: '5px',
    marginTop: '2px',
    marginBottom: '3px',
    marginLeft: '-2px',
    borderRadius: '8px',
    height: 40
  },
  head: {
    position: 'relative',
    fontFamily: 'GSS Futura',
    fontSize: remCalc(14),
    paddingTop: '5px',
    paddingLeft: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    color: gray3
  },
  legend: {
    marginBottom: '-10px',
    paddingTop: '10px',
    fontSize: '13px',
    borderBottom: 'none'
  },
  content: {
    position: 'absolute',
    padding: '1rem',
    border: '1px solid #000000',
    backgroundColor: '#FFFFFF',
    zIndex: 5,
    lineHeight: 1.5
  },
  rotate180: {
    transition: 'transform 0.1s ease-in-out',
    transform: 'rotate(180deg)'
  },
  iconContainer: {
    paddingBottom: remCalc(10),
    paddingRight: remCalc(20),
    marginTop: remCalc(5),
    width: 14
  }
});

export default FilterDropDown;
