import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { remCalc } from 'util/style-util';

type Props = {
  show?: boolean;
  zIndex: number;
  children?: any;
  onClick?: () => any;
  classList: Array<any>;
};

const MENU_WIDTH = 288;

const getBaseStyle = (zIndex: number) => ({
  position: 'fixed',
  zIndex,
  display: 'flex',

  justifyContent: 'center',
  alignItems: 'flex-start',
  transition: 'all 100ms linear'
});

export const styles = StyleSheet.create({
  showOverlay: {
    top: -60,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    transition: 'all 100ms linear 0s',
    height: '100vh',
    width: '100vw'
  },
  showOverContent: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    transition: 'all 100ms linear 0s',
    height: '100vh',
    width: `calc(100vw - ${remCalc(MENU_WIDTH)})`,
    left: remCalc(MENU_WIDTH)
  },
  hide: {
    visibility: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '@media print': {
      display: 'none !important'
    }
  }
});

const TutorialScreenOverlay = ({ show, zIndex, children, classList, onClick, ...props }: Props) => (
  <div
    tabIndex={-1}
    style={getBaseStyle(zIndex) as any}
    className={css(show ? styles.showOverlay : styles.hide, classList)}
    onClick={onClick}
    {...props}
  >
    <div tabIndex={-1} className={css(show ? styles.showOverContent : styles.hide, classList)}>
      {children}
    </div>
  </div>
);

TutorialScreenOverlay.defaultProps = {
  zIndex: 8,
  show: true,
  classList: []
};

export default TutorialScreenOverlay;
