//
import { createSelector } from 'reselect';
import { filterResources } from '../util/resource-util';
import { ResourcesState } from '../reducers/resource-reducer';
import { ResourcesSections } from '../constants/Resources';
import { Resource, UserFavorite } from 'types';

const clickAways = (state: ResourcesState) => state.clickAways;
const userFavorites = (state: ResourcesState) => state.favoriteResources;
const getSelectedTags = (state: ResourcesState) => state.selectedFilters.selectedTags;
const getSearchText = (state: ResourcesState) => state.selectedFilters.searchText;

export const getFavoriteClickAways = createSelector(
  [clickAways, userFavorites],
  (clickAways: Array<Resource>, userFavorites: Array<UserFavorite>) =>
    clickAways
      .filter(clickAway =>
        userFavorites.find(userFavorite => userFavorite.clickAwayId === clickAway.id)
      )
      .map(clickAway => {
        const favorites = userFavorites.filter(
          userFavorite => userFavorite.clickAwayId === clickAway.id
        );
        clickAway.favorites = favorites !== undefined ? favorites : [];

        return clickAway;
      })
);

export const getAllClickAwaysWithFavorites = createSelector(
  [clickAways, userFavorites],
  (clickAways: Array<Resource>, userFavorites: Array<UserFavorite>) => {
    return clickAways.map(clickAway => {
      const favorites = userFavorites.filter(
        userFavorite => userFavorite.clickAwayId === clickAway.id
      );
      if (favorites !== undefined) {
        clickAway.favorites = userFavorites.filter(
          userFavorite => userFavorite.clickAwayId === clickAway.id
        );
      } else clickAway.favorites = [];

      return clickAway;
    });
  }
);

export const getFilteredClickAways: any = createSelector(
  [
    getFavoriteClickAways,
    getAllClickAwaysWithFavorites,
    getSelectedTags,
    getSearchText,
    (_, resourcesSection) => resourcesSection
  ],
  (favoriteClickAways, allClickAwaysWithFavorites, selectedTags, searchText, resourcesSection) => {
    return filterResources(
      resourcesSection === ResourcesSections.MYSTUFF.code
        ? favoriteClickAways
        : allClickAwaysWithFavorites,
      selectedTags,
      searchText
    );
  }
);
