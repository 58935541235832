//
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PageCategory from './PageCategory';
import { remCalc } from '../../util/style-util';

type Props =
  | {
      categories: any[];
      color: string;
      closeMenu: () => any;
      categoryType: string;
      hasIcon: boolean;
    }
  | any;

const styles = (isMultiColumn: boolean) =>
  StyleSheet.create({
    groupCategory: {
      listStyle: 'none',
      paddingLeft: 0,
      display: 'grid',
      gridTemplateRows: '1fr 1fr 1fr',
      gridAutoFlow: 'column',
      gridColumnGap: '20%',
      justifyContent: 'space-evenly',
      gridArea: 'main',
      ...(isMultiColumn && { margin: remCalc(0, 50) })
    }
  });

const CategoryMenuGroup = ({ categories, color, closeMenu, categoryType, hasIcon }: Props) => {
  const isMultiColumn = categories.length > 3;
  const isCursiveCategory = categoryType === 'cursive';

  return (
    <ul className={css(styles(isMultiColumn).groupCategory)}>
      {categories.map((category, i) =>
        category.characters ? (
          <PageCategory
            showBottomBorder={(i + 1) % 3 !== 0}
            key={`${category.category} - ${i}`}
            title={category.category}
            color={color}
            characters={category.characters}
            closeMenu={closeMenu}
            multiColumn={isMultiColumn}
            isCursive={isCursiveCategory}
            hasIcon={hasIcon}
          />
        ) : null
      )}
    </ul>
  );
};

export default CategoryMenuGroup;
