import { KEY_CODES } from '../constants/Keyboard-Codes';

export const onlyOnSpaceAndEnter = cb => event => {
  if (event.keyCode === KEY_CODES.ENTER || event.keyCode === KEY_CODES.SPACE) {
    cb();
  }
};

export const onEscapeKeydown = cb => event => {
  if (event.keyCode === KEY_CODES.ESCAPE) {
    cb();
  }
};
