//
import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite';
import withRouter from 'state/hooks/withRouter';
import Keypad from './Keypad';
import GoToPageHeader from './GoToPageHeader';
import { getRoutingForPage } from '../../util/workbook-util';
import { remCalc } from '../../util/style-util';
import { lightGray } from '../../styles/style-constants';

type State = {
  keyedPageNumber: string;
};

type Props = {
  currentPage: number;
  totalPages: number;
  closeMenu: () => any;
  router: any;
  match: any;
};

const styles = StyleSheet.create({
  goToPage: {
    gridArea: 'main',
    backgroundColor: lightGray,
    borderRadius: 20,
    padding: 0,
    margin: 'auto',
    fontFamily: 'futura-pt',
    maxWidth: remCalc(330)
  }
});

class GoToPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      keyedPageNumber: ''
    };
  }

  deletePageNumberInput = () => {
    const newPage = this.state.keyedPageNumber.slice(0, -1);

    this.setState({ keyedPageNumber: newPage });
  };

  changePageNumber = (newPage: string) => {
    if (this.isValidPageNumber(newPage)) {
      this.setState({ keyedPageNumber: newPage });
    }
  };

  updatePageNumberInput = (page?: string) => {
    return (e: { target: { value: string } } & Event) => {
      if (!page) {
        return this.changePageNumber(e.target.value);
      }

      const currentPage = this.state.keyedPageNumber || '';

      this.changePageNumber(currentPage + page);
    };
  };

  isValidPageNumber = (page: string): boolean => {
    const pageNumber = parseInt(page, 10);

    return (
      page === '' ||
      (Number.isInteger(pageNumber) && pageNumber >= 0 && pageNumber <= this.props.totalPages)
    );
  };

  goToPage = () => {
    const { router, closeMenu } = this.props;
    const nextPage = parseInt(this.state.keyedPageNumber, 10);

    if (!nextPage) return;

    const pageRoute = getRoutingForPage(nextPage, router, true, true);

   router.navigate(pageRoute.path);
    closeMenu();
  };

  render() {
    const pageNumberDisplay = Number.isNaN(this.props.currentPage) ? '-' : this.props.currentPage;

    return (
      <div className={css(styles.goToPage)}>
        <GoToPageHeader
          totalPages={this.props.totalPages}
          newPageNumber={this.state.keyedPageNumber}
          handleInputChange={this.updatePageNumberInput()}
          handleDelete={this.deletePageNumberInput}
          currentPage={pageNumberDisplay}
        />
        <Keypad
          newPage={this.state.keyedPageNumber}
          makeKeyInputHandler={this.updatePageNumberInput}
          handleGoClick={this.goToPage}
        />
      </div>
    );
  }
}

const withRouterGoToPage: any = withRouter(GoToPage as any);
export default withRouterGoToPage;
