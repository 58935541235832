//
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const translateResources = key => translate('resources', key);
export const TYPOGRAPHY_TYPES = {
  ASCENDER: 'ascender',
  DESCENDER: 'descender',
  PLAIN: 'plain'
};

export const ResourcesSections = {
  ALLRESOURCES: {
    code: 'allResources',
    label: translateResources('allResources')
  },
  MYSTUFF: {
    code: 'myStuff',
    label: translateResources('myStuff')
  }
};

export const Tags = {
  ACLICKAWAY: 'A Click Away'
};

export const DOC_SRC_PREFIX = 'https://view.officeapps.live.com/op/embed.aspx?src=';
