//
import { USER_LOGIN_STARTED, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_USER } from './auth-types';
import { AuthAction, AuthState } from './auth-types';

const initialState = {
  isLoggingIn: false,
  isAuthenticated: false,
  hasLoggedOut: false
};

function auth(state: AuthState = initialState, action: AuthAction) {
  switch (action.type) {
    case USER_LOGIN_STARTED:
      return {
        ...state,
        isLoggingIn: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoggingIn: false,
        user: action.user
      };
    case LOGIN_FAIL:
      return {
        ...state,
        initialState
      };
    case LOGOUT_USER:
      // hasLoggedOut will trigger a redirect away from app so don't worry about user and isAuthenticated now.
      return {
        ...state,
        hasLoggedOut: true
      };
    default:
      return state;
  }
}

export default auth;
