import React from 'react';
import { StyleSheet } from 'aphrodite';
import BasicButton from '../core/BasicButton';
import circleBackground from '../../images/drawn-circle.png';
import { remCalc } from '../../util/style-util';

type Props =
  | {
      number: number;
    }
  | any;

const styles = StyleSheet.create({
  keypadNumber: {
    backgroundImage: `url(${circleBackground})`,
    backgroundSize: 'cover',
    fontSize: remCalc(36),
    margin: remCalc(5, 3),
    minWidth: remCalc(64),
    maxWidth: remCalc(64),
    height: remCalc(64),
    fontFamily: 'GSS Futura',
    fontWeight: 700
  }
});

const NumberPadButton = ({ number, buttonStyles, ...props }: Props) => (
  <BasicButton buttonStyles={[styles.keypadNumber, ...buttonStyles]} {...props}>
    {number}
  </BasicButton>
);

export default NumberPadButton;
