//
import React, { Component } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import FtueBubble from './FtueCalloutBubble';
import { getTourStepByName, advanceTourStep, showFtueCallout } from '../../modules/ftue';

export type Props = {
  show: boolean;
  position: any;
  justifyPointer?: any;
  step: string;
  name: string;
  advanceTourStep: () => any;
  noFlex?: boolean;
};

const enterDuration = 500;
const exitDuration = 200;

export class FtueCallout extends Component<Props> {
  render() {
    const { show, position, justifyPointer, step, advanceTourStep, noFlex, ...rest } = this.props;
    const duration = show ? enterDuration : exitDuration;

    return (
      <Transition
        in={show}
        appear={true}
        timeout={duration}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        {state => (
          <FtueBubble
            position={position}
            justifyPointer={justifyPointer}
            transition={{ state, duration }}
            show={show}
            step={step}
            advanceTourStep={advanceTourStep}
            noFlex={noFlex}
            {...rest}
          />
        )}
      </Transition>
    );
  }
}

const globalMenuOpen = (ui, name) => ui.globalMenuOpen && name !== 'globalMenu';

const shouldHideCallout = createSelector(
  [state => state, (_, props) => props.name],
  ({ auth, ftue, messaging, ui, user }, name) =>
    !auth.isAuthenticated ||
    messaging.showAppModal ||
    !ftue.allTours[name] ||
    globalMenuOpen(ui, name) ||
    !user.settings.copyrightTerms
);

const mapStateToProps = (state, props) => {
  if (shouldHideCallout(state, props)) {
    return { show: false };
  }

  return {
    show: showFtueCallout(state, props),
    step: getTourStepByName(state, props)
  };
};

export default connect(
  mapStateToProps,
  { advanceTourStep }
)(FtueCallout);
