import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated = false, component: Component, ...rest }) => (
  <Route
    {...rest}
    element={
      isAuthenticated ? (
        <Component />
      ) : (
        <Route
          path="*"
          element={
            <Navigate
              state={{
                // referrer: props.location,
                // code: props.match.params.bookCode,
                // workbookParams: props.match.params
              }}
              to={{
                pathname: '/login'
              }}
              replace
            />
          }
        />
      )
    }
  />
);

export default PrivateRoute;
