import Cookies from 'js-cookie';

const AUTH_TOKEN_COOKIE = 'authToken';
const AUTH_TOKEN_HEADER = 'x-authtoken';
const USERNAME_COOKIE = 'ssoUserName';
const USERNAME_HEADER = 'x-ssousername';

const COOKIE_ATTRIBUTES = { domain: '.lwtears.com', path: '/' };

const getAuthToken = () => Cookies.get(AUTH_TOKEN_COOKIE);
const getSsoUserName = () => Cookies.get(USERNAME_COOKIE);

export const hasAuthCookies = () => !!getAuthToken() && !!getSsoUserName();

export const extractAuthHeadersFromCookies = () =>
  hasAuthCookies()
    ? { [USERNAME_HEADER]: getSsoUserName(), [AUTH_TOKEN_HEADER]: getAuthToken() }
    : null;

export const removeAuthCookies = () => {
  Cookies.remove(AUTH_TOKEN_COOKIE, COOKIE_ATTRIBUTES);
  Cookies.remove(USERNAME_COOKIE, COOKIE_ATTRIBUTES);
};
