//
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { queueTour, dequeueTour } from '../../../modules/ftue';
import { ComponentType } from 'react';

type Props = {
  queueTour: typeof queueTour;
  dequeueTour: typeof dequeueTour;
};

/**
 * This HOC manages the queueing and dequeuing of tour names used by the FTUE framework.
 * When a wrapped component is mounted, it will queue the tours associated with it or its
 * children. The FtueContainer component needs to be implemented where the FTUE bubble is
 * intended to appear. On unmounting, we dequeue the tour(s) so that the correct tour bubbles
 * will appear if the user navigates to other areas of the app.
 *
 * @param {string[]} tours - A list of tour names to be managed
 */
const withFtueTour = (...tours: string[]) => (WrappedComponent: ComponentType<any>) => {
  class TourWrapper extends Component<Props> {
    componentDidMount() {
      // queueTour can take 2 args. We get process so we don't pass the index as arg #2
      tours.forEach(process => this.props.queueTour(process));
    }

    componentWillUnmount() {
      this.props.dequeueTour(...tours);
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { queueTour, dequeueTour, ...rest } = this.props;

      return <WrappedComponent {...rest} />;
    }
  }

  return connect(
    null,
    {
      queueTour,
      dequeueTour
    }
  )(TourWrapper);
};

export default withFtueTour;
