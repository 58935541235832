import React from 'react';
import { Box, NativeSelect } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getLocale, setLocale } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { localeToLanguage, translateBookCode } from '../../translations';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { remCalc } from '../../util/style-util';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { batch, useDispatch } from 'react-redux';
import { changeAppLocale } from '../../modules/character-selection';
import { getUsersWorkbooks } from '../../modules/workbook';
import { fetchAssessmentResources } from '../../modules/assessment';
import { getTours } from '../../modules/ftue';
import { saveUserSettings, settingsTypes } from '../../modules/user';

type LocaleDropDownProps = {
  styleOverrides?: { style: Partial<React.CSSProperties> };
};

const LocaleDropdown: React.FC<LocaleDropDownProps> = ({ styleOverrides }) => {
  const capitalize = str => str.charAt(0).toUpperCase() + str.substr(1);
  const dispatch = useDispatch() as any;
  const classes = useStyles();
  const locales = Object.keys(localeToLanguage);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const selectLocale = async newLocale => {
    const oldLocale = getLocale();

    await batch(async () => {
      setLocale(newLocale);
      dispatch(changeAppLocale(newLocale));
      dispatch(saveUserSettings(settingsTypes.locale, newLocale) as any);
      await dispatch(getUsersWorkbooks() as any);
      dispatch(fetchAssessmentResources() as any);
      dispatch(getTours() as any);
    });

    const newBookCode = translateBookCode(oldLocale, newLocale, params.bookCode ?? '');

    const newUrl = location?.pathname?.replace(params.bookCode ?? '', newBookCode);
    navigate(newUrl, {replace: true})
  };

  return (
    <Box display="flex" flex="inherit" {...styleOverrides}>
      <NativeSelect
        variant="outlined"
        className={classes.languageToggle}
        aria-label={tA11y('aria.language')}
        onChange={({ target }) => selectLocale(target.value)}
        value={getLocale()}
      >
        {locales.map((locale, idx) => (
          <option
            aria-label={tA11y('aria.localeSelected', { locale: localeToLanguage[locale] })}
            tabIndex={idx}
            selected={getLocale() === locale}
            aria-selected={getLocale() === locale}
            key={`${locale}_${idx}`}
            onClick={() => selectLocale(locale)}
            className={classes.languageDropdownItem}
            value={locale}
          >
            {capitalize(localeToLanguage[locale])}
          </option>
        ))}
      </NativeSelect>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  languageDropdownItem: {
    width: remCalc(130),
    backgroundColor: '#ffffff',
    color: '#000000',
    fontFamily: 'GSS Futura',
    fontSize: remCalc(14),
    textAlign: 'left',
    padding: `${remCalc(3)} ${remCalc(12)}`,
    fontWeight: 400
  },
  languageToggle: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: remCalc(150),
    height: remCalc(45),
    padding: '0px 10px',
    borderRadius: 4,
    backgroundColor: '#ffffff',
    color: '#000000',
    fontWeight: 400,
    fontFamily: 'GSS Futura',
    fontSize: remCalc(14),
    '&:focus': { backgroundColor: 'grey' }
  },
  textField: {
    backgroundColor: '#fff',
    '&:focus': {
      border: '2px solid #fff',
      outline: 'none'
    }
  },
  inputStyle: {
    fontSize: '15px',
    fontWeight: 400,
    '&:focus': {
      color: '#fff'
    }
  }
}));

export default LocaleDropdown;
