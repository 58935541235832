import React, { Component } from 'react';
import { compose } from 'redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import withRouter from 'state/hooks/withRouter';
import Config from 'config';
import { lightGray, white } from '../styles/style-constants';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const { wmUrl } = Config;
class WorksheetMaker extends Component {
  render() {
    const { worksheet, content, applicationFrame } = style;
    return (
      <div className={css(worksheet)}>
        <div className={css(content)}>
          <iframe
          // eslint-disable-next-line react/no-string-refs
            ref="iframe"
            title={tA11y('title.aPlusApp')}
            src={wmUrl}
            className={css(applicationFrame)}
          />
        </div>
      </div>
    );
  }
}

export const style = StyleSheet.create({
  worksheet: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: white
  },
  content: {
    overflowY: 'auto',
    backgroundColor: lightGray,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start'
  },
  applicationFrame: {
    width: '100%',
    height: '100%'
  }
});

export default compose(withRouter)(WorksheetMaker as any);
