//
import { LOGIN_FAIL } from '../auth/auth-types';
import {
  FETCH_PAGE_DETAILS_FAILURE,
  FETCH_WORKBOOK_CONTENT_FAILURE,
  FETCH_PDF_FAIL,
  FETCH_WORKBOOKS_FAILURE
} from '../workbook/workbook-types';
import { FETCH_RESOURCES_FAILURE, FETCH_FILTER_TAGS_FAILURE } from '../../actions/resource-types';
import { FETCH_CHARACTERS_FAILURE } from '../character-selection/types';
import { DISABLE_ERROR_ROUTE_REDIRECTION } from './error-types';

import { ErrorAction, ErrorState } from './error-types';

const initialState: ErrorState = {
  errors: [],
  redirectToErrorRoute: false
};

const errorLogger = (state: ErrorState = initialState, action: ErrorAction) => {
  switch (action.type) {
    case LOGIN_FAIL:
    case FETCH_PAGE_DETAILS_FAILURE:
    case FETCH_WORKBOOKS_FAILURE:
    case FETCH_RESOURCES_FAILURE:
    case FETCH_FILTER_TAGS_FAILURE:
    case FETCH_PDF_FAIL:
    case FETCH_WORKBOOK_CONTENT_FAILURE:
    case FETCH_CHARACTERS_FAILURE: {
      const errors: Array<ErrorAction> = state.errors.concat([action]);
      return {
        redirectToErrorRoute: true,
        errors
      };
    }

    case DISABLE_ERROR_ROUTE_REDIRECTION: {
      return {
        ...state,
        redirectToErrorRoute: false
      };
    }

    default:
      return state;
  }
};

export default errorLogger;
