import { getDemo } from './demo';
import { getDev } from './dev';
import { getDevNext } from './dev-next';
import { getQA01 } from './qa01';
import { getProd } from './prod';
import { getStage } from './stage';

type Config = {
  application?: string;
  pliURL?: string;
  agiURL?: string;
  apiURL?: string;
  companyURL?: string;
  storeURL?: string;
  surveyURL?: string;
  gATrackingId?: string;
  supportEmail?: string;
  wmUrl?: string;
  copyrightEmail?: string;
  title?: string;
  digitalProductSupportUrl?: string;
};

const config = {
  dev: 'dev',
  qa01: 'qa01',
  stage: 'stage',
  production: 'production',
  dev_next: 'dev-next',
  demo: 'demo'
};

const commonConfig = {
  title: 'PreKITT: The Pre-K Interactive Teaching Tool'
};

function getConfig(env: string | any): Config {
  return {
    ...commonConfig,
    ...(env === config.demo && getDemo()),
    ...(env === config.dev && getDev()),
    ...(env === config.dev_next && getDevNext()),
    ...(env === config.qa01 && getQA01()),
    ...(env === config.stage && getStage()),
    ...(env === config.production && getProd())
  };
}

export default getConfig(process.env.REACT_APP_BUILD_ENV);
