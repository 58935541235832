import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import PreKittApi from '../api';
import { createBrowserHistory as createHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import authFailure from '../middleware/authFailure';
import reducers from '../reducers';
const history = createHistory();

const middleware = routerMiddleware(history);

const createStoreWithThunk = compose(
  applyMiddleware(thunkMiddleware.withExtraArgument(PreKittApi), authFailure, middleware)
)(createStore);

export const getProdStore = () => createStoreWithThunk(reducers);
