//
import * as types from './types';

export const showAppModal = (): types.SHOW_APP_MODAL_ACTION => ({
  type: types.SHOW_APP_MODAL
});

export const closeAppModal = (): types.CLOSE_APP_MODAL_ACTION => ({
  type: types.CLOSE_APP_MODAL
});

// export const showLicensingModal = (
//   type: string,
//   props: any
// ): types.SHOW_LICENSING_MODAL_ACTION => ({
//   type: types.SHOW_LICENSING_MODAL,
//   modalType: type,
//   modalProps: props
// });

// export const closeLicensingModal = (): types.CLOSE_LICENSING_MODAL_ACTION => ({
//   type: types.CLOSE_LICENSING_MODAL
// });

// export const showOnboardingModal = (name: string): types.SHOW_ONBOARDING_MODAL_ACTION => ({
//   type: types.SHOW_ONBOARDING_MODAL,
//   onboarding: util.licenseModals[name]
// });

// export const showNotificationModal = (name: string): types.SHOW_NOTIFICATION_MODAL_ACTION => ({
//   type: types.SHOW_NOTIFICATION_MODAL,
//   notifications: util.notificationModal[name]
// });

export const toggleClickAwayAlertDisplay = (
  productId: number,
  showExpiredLicenseAlert: boolean
): types.TOGGLE_CLICK_AWAY_ALERT_DISPLAY_ACTION => ({
  type: types.TOGGLE_CLICK_AWAY_ALERT_DISPLAY,
  productId,
  showExpiredLicenseAlert
});

// export const showCopyrightModal = (name: string): types.SHOW_COPYRIGHT_MODAL_ACTION => ({
//   type: types.SHOW_COPYRIGHT_MODAL,
//   copyright: util.licenseModals[name]
// });

// export const showSupportModal = (name: string, iconLegendURL?: string): types.SHOW_SUPPORT_MODAL_ACTION => ({
//   type: types.SHOW_SUPPORT_MODAL,
//   support: { ...util.supportModals[name], iconLegendURL }
// });
