import React from 'react';
import { TitleProps } from '@lwtears/lwt-common-frontend/lib/Modal';

type Props = TitleProps &
  Partial<{
    title: string;
    ariaLabel: string;
  }>;
const BaseModalTitle = ({ title, ariaLabel }: Props) => {
  return (
    <div
      aria-label={ariaLabel}
      style={{
        padding: 15,
        textAlign: 'center',
        backgroundColor: '#000000',
        color: '#fff',
        border: '2px solid black',
        borderRadius: '255px 8px 0px 5px/8px 255px 5px 255px',
        boxShadow: '0 4px 20px 5px rgba(55, 55, 55, .5)',
        fontSize: '1.625rem'
      }}
    >
      {title}
    </div>
  );
};
export default BaseModalTitle;
