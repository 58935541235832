export const getStage = () => ({
  application: 'prekitt',
  pliURL: 'https://stage-pli.lwtears.com',
  agiURL: 'https://stage-agi.lwtears.com',
  apiGatewayURL: 'https://stage-api.lwtears.com',
  apiURL: `https://stage-api.lwtears.com/prekitt`,
  companyURL: 'https://stage-www.lwtears.com',
  storeURL: 'https://stage-www.lwtears.com/lwt-store-stub',
  surveyURL: 'https://www.surveymonkey.com/r/PreKittFreeTrialExtension',
  gATrackingId: 'UA-106790492-4',
  supportEmail: '0c0f3614.lwtears.com@amer.teams.ms',
  wmUrl: 'https://stage-worksheet-maker.lwtears.com/0?lite=true',
  copyrightEmail: 'legal@lwtears.com',
  digitalProductSupportUrl: 'https://stage-www.lwtears.com/contact-us/digital-product-support'
});
