//
import React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import BasicButton from './BasicButton';
import { lwtBlue, lwtDarkBlue } from '../../styles/style-constants';
import { remCalc } from '../../util/style-util';

type Props =
  | {
      text: string;
      color: string;
      fill: string;
      buttonStyles: Array<any>;
      hoverFill: string;
    }
  | any;

const { squareButton, defaultPadding } = StyleSheet.create({
  squareButton: {
    borderRadius: 3
  },
  defaultPadding: {
    padding: remCalc(12, 33)
  }
});

const SquareButton = ({ text, color, fill, hoverFill, buttonStyles, ...props }: Props) => (
  <BasicButton
    color={color}
    fill={fill}
    hoverFill={hoverFill}
    text={text}
    buttonStyles={[defaultPadding, ...buttonStyles, squareButton]}
    {...props}
  />
);

SquareButton.defaultProps = {
  color: 'white',
  fill: lwtBlue,
  buttonStyles: [],
  hoverFill: lwtDarkBlue
};

export default SquareButton;
