import { TYPOGRAPHY_TYPES } from '../constants/Resources';

const ascenderCharacters: Array<string> = ['b', 'f', 'd', 'h', 'k', 'l', 't'];
const descenderCharacters: Array<string> = ['g', 'p', 'q', 'j', 'y'];

export function hasAscender(character: string): boolean {
  for (let i = 0, len: number = ascenderCharacters.length; i < len; i++) {
    if (ascenderCharacters[i] === character) {
      return true;
    }
  }

  return false;
}

export function hasDescender(character: string): boolean {
  for (let i = 0, len: number = descenderCharacters.length; i < len; i++) {
    if (descenderCharacters[i] === character) {
      return true;
    }
  }

  return false;
}

export function getTypographyType(character: string) {
  if (hasAscender(character)) {
    return TYPOGRAPHY_TYPES.ASCENDER;
  }

  if (hasDescender(character)) {
    return TYPOGRAPHY_TYPES.DESCENDER;
  }

  return TYPOGRAPHY_TYPES.PLAIN;
}
