import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CharacterSelector from '../components/CharacterLists/CharacterSelector';
import ApplicationIFrame from '@lwtears/lwt-common-frontend/lib/ApplicationFrame';
import { selectCharacter, toggleAppLoadingScreenVisibility } from '../modules/character-selection';
import { fetchCharacters } from '../modules/character-selection/operations';
import PrekittApi from '../api';
import { DraggableModalTitle } from '../components/core/Modals/DraggableModalTitle';
import { Box } from '@mui/material';
import { useModal } from '@lwtears/lwt-common-frontend/lib/Modal';
import { setDocumentBodyColor } from '../util/ui-util';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { AppState } from 'reducers';

const WoodPieces = () => {
  const dispatch = useDispatch() as any;
  const [, { openDraggableModal }] = useModal();
  const { locale, allCharacters: items, app: charactersApp } = useSelector(
    ({ characterSelection }: AppState) => characterSelection
  );

  useEffect(() => {
    dispatch(fetchCharacters('woodPieces'));
    setDocumentBodyColor();
  }, [locale, dispatch]);

  const createEvent = resource => {
    const event = {
      section: 'woodPieces',
      resourceId: resource.id,
      resourceType: resource.type.type,
      locale: locale
    };

    PrekittApi.createEvent('accessResource', event);
  };

  const handleClickItem = character => {
    createEvent(character);
    dispatch(selectCharacter(character));
    openModal(character);
  };

  const toggleLoading = () => {
    dispatch(toggleAppLoadingScreenVisibility());
  };

  const openModal = selectedCharacter => {
    openDraggableModal({
      Title: props => <DraggableModalTitle title={selectedCharacter.title} {...props} />,
      body: (
        <Box bgcolor="#fff">
          <ApplicationIFrame
            onMount={toggleLoading}
            onLoad={toggleLoading}
            aspectRatio={selectedCharacter.data.aspectRatio}
            heightAdjust={selectedCharacter.data.heightAdjust}
            url={selectedCharacter.url}
            title={selectedCharacter.title}
          />
        </Box>
      ),
      ariaLabel: tA11y('aria.copyright'),
      options: { closeOnBackdropClick: true }
    } as any);
  };

  return (
    <div style={{ paddingTop: 32 }}>
      <CharacterSelector
        items={charactersApp === 'woodPieces' ? items : []}
        onClickItem={handleClickItem}
        application={'WoodPieces'}
        locale={locale}
      />
    </div>
  );
};

export default WoodPieces;
