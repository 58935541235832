import React from 'react';
import { Box, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import useFocus from '@lwtears/lwt-common-frontend/lib/@common/hooks/app/use-focus';
const getTranslation = grouping => (grouping === '123' ? tA11y('aria.numbers') : grouping);

const AppsToggleHeader = ({ selected, handleSelected, values, application }) => {
  const classes = useStyles();
  const [focusRef]: any = useFocus([selected]);

  const ACTIVE_STYLES = {
    borderBottom: ' 4px solid #2878C1',
    color: '#000000',
    fontWeight: 'bold'
  };

  const DEFAULT_STYLES = {
    borderBottom: ' 1px solid #E0E0E0',
    borderLeft: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0',
    color: '#6A6A6A',
    fontWeight: 'bold'
  };

  const getTitle = title => {
    switch (title) {
      case 'CAPITALS':
        return 'ABC';
      case 'lowercase':
        return 'abc';
      default:
        return title;
    }
  };

  const handleKeydown = ({ value, idx }) => e => {
    const arrow = e.key;
    if (arrow === 'ArrowLeft') handleSelected(value?.[idx - 1 < 0 ? value.length - 1 : idx - 1]);
    if (arrow === 'ArrowRight') handleSelected(value?.[idx + 1]);
  };

  return (
    application !== 'WoodPieces' && (
      <Box className={classes.container}>
        <ToggleButtonGroup role="tablist" style={{ display: 'flex', flex: 'auto' }}>
          {values?.map((x, idx) => (
            <ToggleButton
              ref={x.grouping === selected?.grouping ? focusRef : undefined}
              id={x.grouping}
              role="tab"
              aria-label={getTranslation(x.grouping)}
              key={x.grouping}
              value={x.grouping}
              aria-controls={x.grouping}
              tabIndex={x.grouping === selected?.grouping ? 0 : -1}
              size="large"
              selected={x.grouping === selected?.grouping}
              className={classes.toggleBtn}
              style={selected?.grouping === x.grouping ? ACTIVE_STYLES : (DEFAULT_STYLES as any)}
              onClick={() => handleSelected(x)}
              onKeyDown={handleKeydown({ value: values, idx })}
            >
              <Typography className={classes.btnTitle} variant="button">
                {getTitle(x.grouping)}
              </Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    )
  );
};

const useStyles = makeStyles(() => ({
  container: {
    margin: '0px 0px 34px',
    backgroundColor: '#fff'
  },
  toggleBtn: {
    display: 'flex',
    flex: 1,
    textTransform: 'none',
    width: 100,
    border: 'none',
    '&:hover': {
      backgroundColor: 'unset !important'
    },
    '&:disabled': {
      border: 'none'
    }
  },
  btnTitle: {
    fontWeight: 'bold',
    textTransform: 'none'
  }
}));

export default AppsToggleHeader;
