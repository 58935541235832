import { PREKITT_BG_COLOR } from '../styles/style-constants';
export const pause = fn => {
  setTimeout(() => {
    fn();
  }, 500);
};

export const setDocumentBodyColor = (color = PREKITT_BG_COLOR) => {
  document.body.style.background = color;
};
