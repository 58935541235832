import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Divider, Grid, Paper, Typography, Link as MaterialLink } from '@mui/material';
import HomeGridCard from './HomeGridCard';
import HomeGridCardContent from './HomeGridCardContent';
import PrekittApi from '../../../api';
import { AppNavigationItems, AssessmentsAndResources } from './HomeGridMapping';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { hasClickAwayOnly, settingsTypes } from '../../../modules/user';
import Config from 'config';
import { PREKIT_FONT } from '../../../styles/style-constants';
import { AppState } from 'reducers';
import AgiNewIndicator from './AgiNewIndicator';
import { saveUserSettings } from '../../../modules/user';
import { connect } from 'react-redux';
import flow from 'lodash/fp/flow';
import { UserSettings, AgiSettingsType } from 'types';

const { pliURL } = Config;
const translateHome = (key, options?) => translate('home', key, options);
const translateApps = (key, options?) => translate('apps', key, options);

const styleOverride = {
  style: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  }
};

type Props = {
  saveUserSettings?: (type: string, setting: UserSettings) => any;
};
/* eslint-disable react-hooks/exhaustive-deps */
const HomeGrid: React.FC<Props> = ({ saveUserSettings }) => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const allBooks = useSelector(({ workbook }: AppState) => Object.values(workbook.allWorkbooks));
  const teacherGuides = allBooks.filter((book: any) => book.type === 'teachersGuide');
  const activityBooks = allBooks.filter((book: any) => book.type === 'activityBook');
  const matmanBooks = allBooks.filter((book: any) => book.type === 'storybook');
  const isClickAwayOnly = useSelector(state => hasClickAwayOnly(state, params));
  const user = useSelector(({ user }: any) => user);
  const agiSettings = user.settings[
    settingsTypes.assessmentsToGuideInstructions
  ] as AgiSettingsType;

  const createEvent = appName => {
    const event = {
      section: 'apps',
      application: appName
    };

    PrekittApi.createEvent('externalApp', event);
  };

  const displayApps = isClickAwayOnly ? [] : AppNavigationItems;

  const agiOnClickCallback = () => {
    const updatedAgiSettings = { ...agiSettings } as AgiSettingsType;
    if (!updatedAgiSettings || !saveUserSettings) return;
    if (!updatedAgiSettings.firstAccess) updatedAgiSettings.firstAccess = new Date().toISOString();
    updatedAgiSettings.lastAccess = new Date().toISOString();
    saveUserSettings(settingsTypes.assessmentsToGuideInstructions, updatedAgiSettings);
  };

  const displayAssessmentsAndResources = isClickAwayOnly
    ? AssessmentsAndResources(agiOnClickCallback).filter(x => x.clickAway)
    : AssessmentsAndResources(agiOnClickCallback);

  useEffect(() => {
    if (!agiSettings?.firstViewed) {
      const updatedAgiSettings = { ...agiSettings } as AgiSettingsType;
    if (!updatedAgiSettings || !saveUserSettings) return;
      updatedAgiSettings.firstViewed = new Date().toISOString();
      saveUserSettings(settingsTypes.assessmentsToGuideInstructions, updatedAgiSettings);
    }
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        {teacherGuides.length > 0 ? (
          <HomeGridCard xs={6}>
            <Typography className={classes.headerText} component="h4">
              {translateHome('teachersGuide')}
            </Typography>
            <Divider />
            <Box display="flex" flexDirection="row" flex={1} justifyContent="space-evenly">
              <HomeGridCardContent {...styleOverride} data={teacherGuides} />
            </Box>
          </HomeGridCard>
        ) : (
          <Grid className={classes.grid} item xs={3} />
        )}
        {displayAssessmentsAndResources.map(
          ({ icon: Icon, title, link, onClick, MaybeShowNew }: any, idx) => (
            <HomeGridCard
              xs={displayAssessmentsAndResources.length < 2 ? 12 : 3}
              key={`grid_card_${idx}`}
            >
              <Typography className={classes.headerText} component="h4">
                {translateHome(title)}
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column" flex={1}>
                <Box display="flex" flexDirection="row" flex={1} justifyContent="space-evenly">
                  <Link tabIndex={0} className={classes.cardSection} to={link} onClick={onClick}>
                    <Paper
                      tabIndex={-1}
                      aria-label={title}
                      component="div"
                      className={classes.cardRow}
                      variant="outlined"
                    >
                      {MaybeShowNew && <AgiNewIndicator />}
                      <Icon height="5rem" width="5rem" />
                    </Paper>
                  </Link>
                </Box>
              </Box>
            </HomeGridCard>
          )
        )}
      </Grid>
      <Grid container spacing={3}>
        {activityBooks.length > 0 ? (
          <HomeGridCard xs={6}>
            <Typography className={classes.headerText} component="h4">
              {translateHome('activityBooks')}
            </Typography>
            <Divider />
            <Box display="flex" flexDirection="row" flex={1} justifyContent="center">
              <HomeGridCardContent data={activityBooks} />
            </Box>
          </HomeGridCard>
        ) : (
          <Grid className={classes.grid} item xs={3} />
        )}
        {matmanBooks.length > 0 ? (
          <HomeGridCard xs={6}>
            <Typography className={classes.headerText} component="h4">
              {translateHome('matmanBooks')}
            </Typography>
            <Divider />
            <Box display="flex" flexDirection="row" flex={1} justifyContent="center">
              <HomeGridCardContent data={matmanBooks} />
            </Box>
          </HomeGridCard>
        ) : (
          <Grid className={classes.grid} item xs={3} />
        )}
      </Grid>
      <Grid container>
        {displayApps.length > 0 && (
          <HomeGridCard>
            <Typography className={classes.headerText} component="h4">
              {translateHome('apps')}
            </Typography>
            <Divider />
            <Box display="flex" flexDirection="row" flex={1} justifyContent="center">
              {displayApps?.map(({ icon: Icon, name, link }: any, idx) => (
                <Link
                  key={`app_navigation_link_${idx}`}
                  tabIndex={0}
                  className={classes.cardSection}
                  to={link}
                >
                  <Paper
                    tabIndex={-1}
                    aria-label={translateApps(name)}
                    key={`app_navigation_item_${idx}`}
                    component="div"
                    className={classes.cardRow}
                    variant="outlined"
                    onClick={() => navigate(link)}
                  >
                    <Icon height="4rem" width="4rem" />
                    <span style={{ paddingLeft: '12px' }}>{translateApps(name)}</span>
                  </Paper>
                </Link>
              ))}
            </Box>
          </HomeGridCard>
        )}
        <HomeGridCard gridStyles={{ maxHeight: 100 }}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.tileText} style={{ alignSelf: 'center' }}>
              {translateHome('access')}
              <span style={{ fontWeight: 'bold' }}> {translateHome('pli')} </span>
              {translateHome('toGetMore')}
            </Typography>

            <MaterialLink
              style={{ display: 'flex' }}
              tabIndex={0}
              href={pliURL}
              target="_blank"
              onClick={() => createEvent('PLI')}
            >
              <Box tabIndex={-1} aria-label={tA11y('aria.pliButton')} className={classes.pliBtn}>
                <Typography className={classes.tileText}>
                  {translateHome('access')} {translateHome('pli')}
                </Typography>
              </Box>
            </MaterialLink>
          </Box>
        </HomeGridCard>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
          <Typography style={{ fontWeight: 300, margin: '0px 10px', fontFamily: PREKIT_FONT }}>
            {translateHome('needHelp')}
          </Typography>
          <Link
            style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#2F6FA7' }}
            to="/support"
          >
            {translateHome('viewHelpAndSupport')}
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  cardSection: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    margin: 10,
    height: 112,
    alignItems: 'center',
    position: 'relative',
    '&:hover': {
      transform: 'scale(1.05, 1.05)',
      boxShadow: 'rgb(176 176 176) 0px 3px 7px 1px'
    }
  },
  headerText: {
    fontFamily: PREKIT_FONT,
    fontWeight: 600,
    paddingTop: 8
  },
  tileText: {
    fontFamily: PREKIT_FONT,
    fontWeight: 300
  },
  grid: {
    display: 'flex',
    flexDirection: 'column'
  },
  pliBtn: {
    border: '3px solid #2671BA',
    borderRadius: 5,
    color: '#2671BA',
    textTransform: 'none',
    fontFamily: PREKIT_FONT,
    padding: '5px 35px'
  }
}));

const mapStateToProps = (state: AppState) => ({
  user: state.user
});
const withRedux = connect(mapStateToProps, {
  saveUserSettings
});

export default flow(withRedux)(HomeGrid);
