import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import freeTrial from '../../images/trial-badge.png';
import clickAway from '../../images/a-click-away.svg';
import { BadgeType } from 'types';
import { remCalc } from '../../util/style-util';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props = {
  styles?: any;
  badgeType: BadgeType;
};

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    width: remCalc(35),
    bottom: remCalc(55),
    right: remCalc(10)
  }
});

const TrialLicenseBadge = ({ styles: styleOverrides, badgeType }: Props) => {
  const badgeSrc = badgeType === 'freeTrial' ? freeTrial : clickAway;

  return (
    <img
      className={css(styles.badge, styleOverrides.badge)}
      src={badgeSrc}
      alt={tA11y('alt.activityBookBadge')}
    />
  );
};

TrialLicenseBadge.defaultProps = {
  styles: {}
};

export default TrialLicenseBadge;
