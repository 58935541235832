//
import React from 'react';

type Props = {
  fill: string;
};

const QuestionMark = ({ fill }: Props) => (
  <svg viewBox="0 0 476.5 476.5">
    <ellipse style={{ fill }} cx="244.5" cy="238.1" rx="228.2" ry="219.3" />
    <g>
      <path
        d="M218.4,335.5c-8.7,2.5-14.4,9.6-18.3,17.4c-4.1,8.2-7,17.5-5.2,26.7c1,5.2,3.3,11.1,6.2,15.5c12.5,19.2,36.8,21.7,56,11.9
        c19.8-10.2,29.8-33.9,14.5-52.2C260.1,340.9,236.5,330.2,218.4,335.5z M247.6,390.6c-10.8,6.5-24.4,3.7-31.2-6.9
        c-4.4-6.7-3.7-16.9,0.5-23.4c4.8-7.5,13.7-6.9,21.3-4.9c5.7,1.5,11.1,4.2,15.6,8C265.7,373.3,259,383.8,247.6,390.6z"
      />
      <path
        d="M174.3,171.2c5.6,8.4,16.7,5.6,20.4-1c15.3-12.5,47-28.6,57.4-2.7c11.3,28.5-25.5,42.2-47,45.1c-6.4,0.9-8.8,5.7-8.2,10.6
        c-0.2,0.9-0.2,1.8-0.2,2.7c1.1,27.4,1.1,54.8,1.1,82.3c0,1.2,0.2,2.3,0.5,3.3c-0.6,6.1,3,12.7,10.9,12.7c17.2,0,34.3-0.2,51.4-2.3
        c1.8-0.2,3.5-0.8,4.8-1.6c5.2-0.6,10-4.2,10-10.9v-50.5c46.4-27.9,92.3-85.9,67.1-142c-33.5-74.6-143.6-46.1-197.4-16.2
        c-3.2,1.7-4.8,4.3-5.4,6.9c-3.3,4.1-4.4,10.3-0.3,15.6C151.7,138.8,163.3,154.8,174.3,171.2z M321.6,125.3
        c24.1,46.4-27.1,97.4-63.2,117.1c-4.6,2.5-6.1,6.6-5.5,10.6c-0.1,0.5-0.2,1-0.2,1.5v45.3c-10.6,1-21.3,1.4-32,1.4
        c0-22.7-0.1-45.4-0.9-68.1c19.1-4,36.7-12.6,48.5-28.9c9.8-13.5,10.8-32.1,4.2-47c-6.7-15-21.4-24.8-37.6-26.3
        c-18-1.7-34.7,6.2-48.9,16.9c-7.3-10.5-15-20.8-22.7-31C207.9,94.3,292.9,70.1,321.6,125.3z"
      />
      <path
        d="M239.1,474.2c130.9,0,237.5-106.5,237.5-237.5c0-29.5-8.9-67.4-23.9-101.2C415.6,52.1,337,2.3,242.3,2.3
        c-42.7,0-85.8,10.6-124.5,30.8C43.7,71.6-2.5,152.7,0.1,239.7c1.2,41.4,14.6,81.9,38.7,117.1C92.8,435.8,158.3,474.2,239.1,474.2z
         M132.2,60.8c34.3-17.8,72.4-27.3,110.1-27.3c82,0,149.9,42.9,181.8,114.7c13.1,29.6,21.2,63.5,21.2,88.5
        c0,113.7-92.5,206.3-206.3,206.3c-69.7,0-126.7-34-174.5-103.8c-20.7-30.3-32.2-65-33.3-100.4C29.1,164.9,69.6,93.3,132.2,60.8z"
      />
    </g>
  </svg>
);

QuestionMark.defaultProps = {
  fill: '#FFF'
};

export default QuestionMark;
