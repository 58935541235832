type BookParamOptions = {
  edition: string;
  bookCode: string;
  pageNumber?: string;
  isZoom?: string;
};

export function getPathForWorkbook(options: BookParamOptions | any): string {
  const { bookCode, edition, pageNumber, isZoom } = options;

  let path = `/books/${bookCode}/${edition}`;

  path = pageNumber ? `${path}/${pageNumber}` : path;
  path = pageNumber && isZoom ? `${path}/zoom` : path;

  return path;
}

export function changePageNumberInPath(
  { bookCode, edition }: BookParamOptions,
  pageNumber: string | number,
  isZoom: boolean
): string {
  const basePath = `/books/${bookCode}/${edition}/${pageNumber}`;

  return basePath + (isZoom ? '/zoom' : '');
}

export function getPathFromExternalLink(url: string): string {
  return url.replace('/ext', '/books');
}
