//
import {
  SHOW_APP_MODAL,
  CLOSE_APP_MODAL,
  SHOW_LICENSING_MODAL,
  CLOSE_LICENSING_MODAL,
  SHOW_ONBOARDING_MODAL,
  TOGGLE_CLICK_AWAY_ALERT_DISPLAY,
  SHOW_NOTIFICATION_MODAL,
  SHOW_COPYRIGHT_MODAL,
  SHOW_SUPPORT_MODAL
} from './types';
import { MessagingState, MessagingAction } from './types';

const initialState = {
  showAppModal: false,
  onboarding: [],
  licensing: {},
  dismissedClickAwayAlerts: [],
  notifications: [],
  support: [],
  modalType: 'onboarding'
};

const messagingReducer = (state: MessagingState = initialState, action: MessagingAction) => {
  switch (action.type) {
    case SHOW_APP_MODAL:
      return {
        ...state,
        showAppModal: true
      };
    case CLOSE_APP_MODAL:
      return {
        ...state,
        showAppModal: false
      };
    case SHOW_ONBOARDING_MODAL:
      return {
        ...state,
        showAppModal: true,
        onboarding: action.onboarding,
        modalType: 'onboarding'
      };
    case SHOW_NOTIFICATION_MODAL:
      return {
        ...state,
        showAppModal: true,
        notifications: action.notifications,
        modalType: 'notifications'
      };
    case SHOW_COPYRIGHT_MODAL:
      return {
        ...state,
        showAppModal: true,
        copyright: action.copyright,
        modalType: 'copyright'
      };
    case SHOW_LICENSING_MODAL:
      return {
        ...state,
        licensing: { type: action.modalType, props: action.modalProps }
      };
    case CLOSE_LICENSING_MODAL:
      return {
        ...state,
        licensing: {}
      };
    case SHOW_SUPPORT_MODAL:
      return {
        ...state,
        support: action.support,
        showAppModal: true,
        modalType: 'support'
      };
    case TOGGLE_CLICK_AWAY_ALERT_DISPLAY: {
      const productIdToDismiss = action.productId;
      const dismissedClickAwayAlerts: Array<number> = action.showExpiredLicenseAlert
        ? state.dismissedClickAwayAlerts.filter(productId => productId !== productIdToDismiss)
        : [...state.dismissedClickAwayAlerts, productIdToDismiss];

      return {
        ...state,
        dismissedClickAwayAlerts
      };
    }
    default:
      return state;
  }
};

export default messagingReducer;
