import React from 'react';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const t = key => translate('apps', key);
type Props =
  | {
      fill: string;
    }
  | any;

const WorksheetMakerIcon = ({ fill, height, width, ...props }: Props) => (
  <svg width={width ?? '32'} height={height ?? '32'} {...props} viewBox="0 0 152.33 153">
    <title>{t('worksheetMaker')}</title>
    <circle
      cx="76.2"
      cy="76.48"
      r="74.12"
      fill="#fff"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="3px"
    />
    <path
      d="M98.49,118.44c-3.42-9.77-10-25.21-15.25-37.72-1-2.35-1.76-4.69-2.83-6.94h-29c-1.08,2.74-2.15,6.26-3.32,9.29C43.37,95.28,37.5,111.7,35.45,118.44H26.16c8.9-22.86,31-80.43,36.26-97.73,0-.1,5.77,0,5.77,0l39.09,97.73ZM65.65,36.84c-4,9.58-7.53,18.86-11.14,28.54H77.18C74,56.87,69.75,46,65.65,36.84Z"
      style={{ fill }}
    />
    <path
      d="M140.32,67.33c0,3.42-.4,3.71-1,4.2a13.76,13.76,0,0,0-1.47,1.37H123.7c.1,1.17,0,2.93,0,4.69v2.25c0,2.93-.1,7.33-.19,9.19-.69.78-3.23,1.27-5.09,1.27,0,0,0-.1.2-.1h-.69c-.58-.1-2.44-.59-2.83-.68-.1-1.76-.1-5.87-.1-8.31V73h-2.64c-2.64,0-7.81-.1-10.55-.2l-2-2-.19-2.93.19-2.25,3.23-1.76,12.12-.2V56.78c0-3.23,0-6.85.19-9.29.2-2.05,1.67-3,3.82-3s4.59,1.57,4.59,3.13V60c0,1.37,0,2.35.1,4h14Z"
      style={{ fill }}
    />
  </svg>
);

WorksheetMakerIcon.defaultProps = {
  fill: '#000'
};

export default WorksheetMakerIcon;
