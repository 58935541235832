//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import range from 'lodash/range';
import NumberPadButton from './NumberPadButton';
import GoButton from './GoButton';
import { remCalc } from '../../util/style-util';

type Props = {
  makeKeyInputHandler: (inputNumber: string) => (e: Event) => any;
  newPage: string;
  handleGoClick: () => any;
};

const styles = StyleSheet.create({
  keypad: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    width: remCalc(240),
    margin: '0 auto'
  },
  keypadButton: {
    flex: 1,
    userSelect: 'none'
  }
});

const Keypad = ({ makeKeyInputHandler, newPage, handleGoClick }: Props) => (
  <div className={css(styles.keypad)}>
    {[...range(1, 10), 0].map(i => (
      <NumberPadButton
        number={i}
        buttonStyles={[styles.keypadButton]}
        onClick={makeKeyInputHandler(i.toString())}
        key={i}
      />
    ))}
    <GoButton onClick={handleGoClick} disabled={!newPage} buttonStyles={[styles.keypadButton]} />
  </div>
);

export default Keypad;
