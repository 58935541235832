//
import React from 'react';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../../util/style-util';
import closeX from '../../../images/close-x.png';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import { Button } from '@mui/material';

type Props = {
  title: string;
  backgroundColor: string;
  isDismissOnClick: boolean;
  handleDismiss: (e: any) => any;
  closeModal: any;
};

const getStyles = (backgroundColor: string) =>
  StyleSheet.create({
    modalHeader: {
      backgroundColor,
      marginTop: 0,
      color: 'white',
      textAlign: 'center',
      fontSize: remCalc(20),
      minHeight: remCalc(50),
      padding: remCalc(20, 20, 10),
      userSelect: 'none',
      fontWeight: 500,
      border: '2px solid black',
      borderRadius: '100px 8px 225px 5px/8px 225px 5px 255px',
      boxShadow: '0 4px 20px 5px rgba(55, 55, 55, .5)',
      '@media(min-width: 1000px)': {
        fontSize: remCalc(26)
      },
      marginBottom: '-7px'
    },
    smallerFont: {
      fontSize: remCalc(16),
      '@media(min-width: 1000px)': {
        fontSize: remCalc(20)
      }
    },
    closeButton: {
      float: 'right'
    }
  });

const ModalHeader = ({ title, backgroundColor, isDismissOnClick, closeModal }: Props) => {
  const [namespace, key] = title.split(':');
  const translatedTitle = translate(namespace, key);
  const bigTitle = translatedTitle.length > 30;
  const { smallerFont, modalHeader, closeButton } = getStyles(backgroundColor);

  return (
    <>
      <h1 className={css(modalHeader, bigTitle && smallerFont)}>
        {translatedTitle}
        {isDismissOnClick && (
          <Button
            aria-label={tA11y('aria.closeModal')}
            tabIndex={-1}
            className={css(closeButton)}
            onClick={closeModal}
          >
            <img tabIndex={0} src={closeX} alt={tA11y('alt.closeIcon')} />
          </Button>
        )}
      </h1>
    </>
  );
};

ModalHeader.defaultProps = {
  backgroundColor: 'black'
};

export default withModal(ModalHeader);
