import React from 'react';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props =
  | {
      fill: string;
      fillPin?: string;
      opacity: string;
    }
  | any;

const PinIcon = ({ fill, fillPin, opacity }: Props) => (
  <svg aria-labelledby="title" viewBox="0 0 411.67 412" xmlns="http://www.w3.org/2000/svg">
    <text style={{ visibility: 'hidden' }} id="title">
      {tA11y('alt.unpinIcon')}
    </text>
    <g style={{ opacity }}>
      <path
        d="M284.67,113.67l78,75.33L376,181.24l24.67-21.57V145l-126-118.67L251.33,11.67,234.67,32.33,226,49.67l4.67,14L191.33,115,159.7,149.67l-87,10L41.33,189l59.34,72-22,34-50,82.67,128-68.67,54.66,62,27.34-9.33s26-64,26.66-67.34S266.67,249,266.67,249L338,174.33Z"
        style={{ fill: fillPin }}
      />
      <path
        style={{ fill }}
        d="M344.37,189.07a30.3,30.3,0,0,0,42.82,0l14.55-14.55a30.33,30.33,0,0,0,0-42.83h0L280.25,10.21a30.3,30.3,0,0,0-42.82,0L222.88,24.76a30.3,30.3,0,0,0,0,42.82L224.26,69l-70.54,70.55A145.84,145.84,0,0,0,44,164.63a30.31,30.31,0,0,0-3.77,46L90.6,261,2.92,393.08A11.48,11.48,0,0,0,18.84,409l132.09-87.68,50.35,50.35a30.23,30.23,0,0,0,21.4,8.87c.82,0,1.65,0,2.47-.1a30.28,30.28,0,0,0,22.17-12.53,145.85,145.85,0,0,0,25.12-109.68M53.5,358.42l53.68-80.86,27.17,27.18ZM248.63,60.84l-9.51-9.51a7.31,7.31,0,0,1,0-10.33l14.56-14.55a7.31,7.31,0,0,1,10.33,0L385.5,147.94a7.31,7.31,0,0,1,0,10.33h0L371,172.82a7.32,7.32,0,0,1-10.34,0L293.79,106a11.49,11.49,0,0,0-16.25,16.25l49.2,49.2L251.88,246.3a11.46,11.46,0,0,0-3.1,10.57,122.87,122.87,0,0,1-20.12,97.64,7.31,7.31,0,0,1-5.37,3,7.23,7.23,0,0,1-5.76-2.12l-57-57-.07-.06-46.92-46.93-57-57a7.18,7.18,0,0,1-2.11-5.76,7.26,7.26,0,0,1,3-5.37,123.09,123.09,0,0,1,97.64-20.12,11.44,11.44,0,0,0,10.56-3.1m219.86-1.8"
      />
      <path d="M150.65,183.57a102.76,102.76,0,0,0-49.74,1.57,11.49,11.49,0,1,0,6.29,22.1A79.68,79.68,0,0,1,145.77,206a11.49,11.49,0,1,0,4.88-22.45Zm0,0" />
    </g>
  </svg>
);

PinIcon.defaultProps = {
  fillPin: 'transparent',
  fill: '#d5d5d5',
  opacity: '1'
};

export default PinIcon;
