import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentWorkbook } from '../../../modules/workbook';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import PrekittApi from '../../../api';
import { hasClickAwayOnly } from '../../../modules/user';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import AppleIcon from '../../icons/AppleIcon';
import AppleInBoxIcon from '../../icons/AppleInBoxIcon';
import AppsIcon from '../../icons/AppsIcon';
import WorkbookIcon from '../../icons/WorkbookIcon';
import PliIcon from '../../icons/PLIcon';
import WorksheetMakerIcon from '../../icons/WorksheetMakerIcon';
import ResourcesIcon from '../../icons/ToolsIcon';
import LogoutIcon from '../../icons/LogoutIcon';
import HelpIcon from '../../icons/QuestionMark';
import WDTIcon from '../../icons/WDTIcon';
import WPIcon from '../../icons/WPIcon';
import LNFIcon from '../../icons/LNFIcon';
import HomeIcon from '../../icons/Home.png';
import Config from 'config';
import { logoutUser } from '../../../modules/auth';
import { getRouteForBook } from '../../../util/workbook-util';
import { getLicense } from '../../../util/license-util';
// import { useModal } from '@lwtears/lwt-common-frontend/lib/Modal';
// import {
//   A11ySettingsModalBody,
//   A11ySettingsIcon
// } from '@lwtears/lwt-common-frontend/lib/@a11y/A11ySettings';

const { pliURL, agiURL } = Config;
const translateApps = key => translate('apps', key);
const translateNav = key => translate('nav', key);
const translateNavWithSub = (key, options?) => translate('nav', key, options);

const MenuListItems = () => {
  const params = useParams();
  const dispatch = useDispatch() as any;
  // const [, { openModal, closeModal }] = useModal();

  const { allWorkbooks, lastTeachersGuide, lastWorkbook, lastStorybook } = useSelector(
    ({ workbook }: any) => workbook
  );
  const user = useSelector(({ user }: any) => user);
  const workbooks = Object.values(allWorkbooks);
  const currentWorkbook = useSelector(state => getCurrentWorkbook(state, params));
  const isClickAwayOnly = useSelector(state => hasClickAwayOnly(state, params));
  const teachersGuides: any = workbooks?.filter((x: any) => x.type === 'teachersGuide');

  const createEvent = appName => {
    const event = {
      section: 'apps',
      application: appName
    };

    PrekittApi.createEvent('externalApp', event);
  };

  return [
    {
      name: translateNav('home'),
      link: '/home',
      icon: () => <img src={HomeIcon} alt={tA11y('alt:homeImg')} height="50px" width="50px" />,
      linkType: 'static',
      clickAway: true,
      accessibilityLabelDescription: tA11y('aria.home')
    },
    {
      name: translateNav('teachersGuide'),
      link:
        teachersGuides?.length > 1
          ? '#'
          : teachersGuides?.length === 1
          ? getRouteForBook(
              teachersGuides[0],
              getLicense(user.licenses, teachersGuides?.[0].products)
            )
          : '',
      linkType: teachersGuides?.length > 1 ? 'static' : 'prekitt',
      icon: lastTeachersGuide
        ? () => <img src={lastTeachersGuide.thumbnailUrl} alt={tA11y('alt:teachersGuideImg')} />
        : teachersGuides?.length === 1
        ? () => <img src={teachersGuides?.[0].thumbnailUrl} alt={tA11y('alt:teachersGuideImg')} />
        : AppleIcon,
      clickAway: false,
      accessibilityLabelDescription:
        currentWorkbook.type === 'teachersGuide'
          ? translateNavWithSub('teacherGuideSelectedTag', {
              bookName: currentWorkbook.title
            })
          : translateNav('noTeacherGuideSelectedTag'),
      accessibilityLabelButton: open =>
        tA11y(open ? 'aria.closeTeacherGuideMenuTag' : 'aria.openTeacherGuideMenuTag'),
      children:
        teachersGuides?.length > 1
          ? sortBy(
              filter(allWorkbooks, workbook => workbook.type === 'teachersGuide'),
              tg => tg.sortIndex
            ).map(tg => ({
              name: !user?.licenses?.[0]?.licenseTypeId ? tg.title + 'A Click Away' : tg.title,
              link: getRouteForBook(tg, user),
              linkType: 'prekitt',
              icon: () => <img src={tg.thumbnailUrl} alt={tA11y('alt.teachersGuideImg')} />,
              clickAway: false
            }))
          : teachersGuides?.length === 1
          ? null
          : []
    },
    {
      name: translateNav('activityBook'),
      link: '#',
      linkType: 'static',
      icon: lastWorkbook
        ? () => <img src={lastWorkbook.thumbnailUrl} alt={tA11y('alt.activityBookImg')} />
        : WorkbookIcon,
      clickAway: false,
      accessibilityLabelDescription:
        currentWorkbook.type === 'activityBook'
          ? translateNavWithSub('activityBookSelectedTag', {
              bookName: currentWorkbook.title
            })
          : translateNav('noActivityBookSelectedTag'),
      accessibilityLabelButton: open =>
        tA11y(open ? 'aria.closeActivityBookMenuTag' : 'aria.openActivityBookMenuTag'),
      children: sortBy(
        filter(allWorkbooks, workbook => workbook.type === 'activityBook'),
        workbook => workbook.sortIndex
      ).map(workbook => ({
        name: workbook.title,
        link: getRouteForBook(workbook, user),
        linkType: 'prekitt',
        icon: () => <img src={workbook.thumbnailUrl} alt={tA11y('alt.activityBookImg')} />,
        clickAway: false
      }))
    },
    {
      name: translateNav('storybook'),
      link: '#',
      linkType: 'static',
      icon: lastStorybook
        ? () => <img src={lastStorybook.thumbnailUrl} alt={tA11y('alt.storyBookImg')} />
        : WorkbookIcon,
      clickAway: false,
      accessibilityLabelDescription:
        currentWorkbook.type === 'storybook'
          ? translateNavWithSub('matManBookSelectedTag', {
              bookName: currentWorkbook.title
            })
          : translateNav('noMatManBookSelectedTag'),
      accessibilityLabelButton: open =>
        tA11y(open ? 'aria.closeMatManBookMenuTag' : 'aria.openMatManBookMenuTag'),
      children: sortBy(
        filter(allWorkbooks, workbook => workbook.type === 'storybook'),
        storybook => storybook.sortIndex
      ).map(storybook => ({
        name: storybook.title,
        link: getRouteForBook(storybook, user),
        linkType: 'prekitt',
        icon: () => <img src={storybook.thumbnailUrl} alt={tA11y('alt.storyBookImg')} />,
        clickAway: false
      }))
    },
    {
      name: translateNav('assessmentsToGuideInstruction2Line'),
      link: '',
      linkType: 'web',
      icon: AppleInBoxIcon,
      clickAway: false,
      accessibilityLabelDescription: translateNav('assessmentsToGuideInstruction'),
      onClick: () => {
        window.open(agiURL, '_blank');
        createEvent('AGI');
      }
    },
    {
      name: translateNav('resources'),
      link: '/resources',
      linkType: 'prekitt',
      icon: ResourcesIcon,
      clickAway: true,
      accessibilityLabelDescription: translateNav('resources')
    },
    {
      name: translateNav('apps'),
      link: '#',
      linkType: 'static',
      icon: AppsIcon,
      clickAway: true,
      accessibilityLabelDescription: translateNav('appsTag'),
      accessibilityLabelButton: open =>
        tA11y(open ? 'aria.closeAppsMenuTag' : 'aria.openAppsMenuTag'),
      children: [
        {
          name: translateApps('lnf'),
          link: '/apps/letter-number-formations',
          linkType: 'prekitt',
          icon: LNFIcon,
          clickAway: false
        },
        {
          name: translateApps('wdt'),
          link: '/apps/wet-dry-try',
          linkType: 'prekitt',
          icon: WDTIcon,
          clickAway: false
        },
        {
          name: translateApps('woodPieces'),
          link: '/apps/wood-pieces',
          linkType: 'prekitt',
          icon: WPIcon,
          clickAway: false
        },
        {
          name: translateApps('worksheetMaker'),
          link: '/apps/worksheet-maker',
          linkType: 'prekitt',
          icon: WorksheetMakerIcon,
          clickAway: false,
          onClick: () => createEvent('worksheetMaker')
        },
        {
          name: translateApps('plusLive'),
          link: ``,
          linkType: 'web',
          icon: PliIcon,
          clickAway: true,
          onClick: () => {
            window.open(pliURL, '_blank');
            createEvent('PLI');
          }
        }
      ].filter(app => (isClickAwayOnly ? app.clickAway : true))
    },
    {
      name: translateNav('support'),
      link: '/support',
      linkType: 'prekitt',
      icon: HelpIcon,
      clickAway: true,
      accessibilityLabelDescription: translateNav('support')
    },
    // {
    //   name: 'Accessibility Settings',
    //   link: '#',
    //   linkType: 'prekitt',
    //   icon: A11ySettingsIcon,
    //   clickAway: false,
    //   onClick: () => {
    //     openModal({
    //       options: { closeOnBackdropClick: true },
    //       body: (
    //         <A11ySettingsModalBody
    //           onCancel={async () => closeModal()}
    //           onSubmit={async () => closeModal()}
    //         />
    //       )
    //     });
    //   },
    //   accessibilityLabelDescription: translateNav('support')
    // },
    {
      name: translateNav('logout'),
      link: '#',
      linkType: 'prekitt',
      icon: LogoutIcon,
      onClick: () => dispatch(logoutUser()),
      clickAway: true,
      accessibilityLabelDescription: translateNav('logout')
    }
  ]
    .filter(menuItem => (isClickAwayOnly ? menuItem.clickAway : true))
    .filter(menuItem => (menuItem.children ? menuItem.children.length !== 0 : true));
};

export default MenuListItems;
