import React from 'react';
export type MaybeProps = {
  shouldRender?: boolean;
  buttonStyles?: any[];
  styles?: any;
} & Partial<React.HTMLAttributes<HTMLElement>>;

const maybe = (Component: any) => {
  return ({ shouldRender, ...props }: MaybeProps) =>
    shouldRender ? <Component {...props} /> : null;
};

/** renders children with a conditional wrapper */
export const Maybe = (Component: React.FC<MaybeProps>) => {
  return ({ shouldRender, children, ...props }) =>
    shouldRender ? <Component {...props}>{children}</Component> : children;
};

export default maybe;
