//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import CharacterTile from '../../../images/CharacterTile/CharacterTile';

import { FilterTag } from '../../../reducers/resource-reducer';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props = {
  items: Array<FilterTag>;
  selectedItems: Array<number>;
  onSelectItem: (item: FilterTag) => any;
};

function CharacterGrid(props: Props): any {
  const { characterGrid } = styles;

  function isItemSelected(item: any): boolean {
    return props.selectedItems.indexOf(item) !== -1;
  }

  function handleSelectItem(item: any) {
    return function () {
      props.onSelectItem(item);
    };
  }

  function renderItems(): Array<any> {
    return props.items.map(function (item: FilterTag, index: number) {
      return (
        <CharacterTile
          key={index}
          size={40}
          character={item}
          selected={isItemSelected(item)}
          onClick={handleSelectItem(item)}
          ariaLabel={`${tA11y('aria.filterFor')} ${item.value}`}
        />
      );
    });
  }

  return <div className={css(characterGrid)}>{renderItems()}</div>;
}

const styles = StyleSheet.create({
  characterGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '40px 40px 40px 40px 40px',
    gridGap: '10px 10px'
  }
});

export default CharacterGrid;
