import React, { PureComponent } from 'react';
import withRouter from 'state/hooks/withRouter';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Typography } from '@mui/material';
import ResourceFilterBreadcrumbs from '../../../components/Resources/ResourceFilterBreadcrumbs';
import ResourcePlayer from '../../../components/Resources/ResourcePlayer/ResourcePlayer';
import { setResourceFilters, toggleTag } from '../../../actions/resource-actions';
import {
  fetchResources,
  fetchFavoriteResources,
  fetchFilterTags,
  removeUserFavorite
} from '../../../actions/resource-operations';
import {
  selectResource,
  toggleResourcePlayerVisibility,
  resetResources,
  updateCounter
} from '../../../actions/resource-actions';
import { filterResources } from '../../../util/resource-util';
import ResourcesList from '../../../components/Resources/ResourcesList/ResourcesList';
import PrekittApi from '../../../api';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { ReactRouterProps } from '../../Main';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import { DraggableModalTitle } from '../../../components/core/Modals/DraggableModalTitle';
import { FilterGroup, LiteResource } from 'reducers/resource-reducer';
import { WorkbookState } from 'modules/workbook';
import { Resource, UserFavorite } from 'types';
import { ResourcesSections } from 'constants/Resources';
import { AppState } from 'reducers';
import DownloadPdf from 'components/DownloadPdf';
import { getPdfResourceUrlOrNull } from 'util/resource-player-util';
const t = (key, options?) => translate('resources', key, options);

type Props = {
  favoriteResources: Array<UserFavorite>;
  filterGroups: Array<FilterGroup>;
  locale: string;
  selectedFavoriteFilterTags: Array<number>;
  searchFavoriteText: string;
  selectedResource: LiteResource;
  showResourcePlayer: boolean;
  workbookState: WorkbookState;
  selectResource: (resource: LiteResource) => any;
  toggleResourcePlayerVisibility: (show: boolean) => any;
  setResourceFilters: (
    searchText?: string,
    tagIds?: Array<number>,
    resourcesSection?: string
  ) => any;
  toggleTag: (tagId: number, resourcesSection: string) => any;
  resetResources: () => any;
  updateCounter: (operationType: string) => number;
  fetchResources: (type?: string) => Array<Resource>;
  fetchFavoriteResources: () => Array<UserFavorite>;
  fetchFilterTags: () => Array<FilterGroup>;
  removeUserFavorite: (favoriteId: number) => any;
} & ReactRouterProps &
  UseModalDispatch;

class MyStuff extends PureComponent<Props> {
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.fetchData();
    }
  }
  fetchData = () => {
    this.props.resetResources();
    this.props.updateCounter('reset');
    this.props.fetchFilterTags();
    if (Object.entries(this.props.workbookState.allWorkbooks).length > 0) {
      this.props.fetchResources();
    }
    this.props.fetchFavoriteResources();
  };
  resetFilters = () => {
    this.props.setResourceFilters('', []);
  };

  handleSelectResource = (resource: LiteResource): void => {
    this.props.selectResource(resource);
    this.props.toggleResourcePlayerVisibility(true);
    this.openResourcePlayer(resource);
  };

  handleRemoveFavorite = (resource: Resource) => {
    const favorite = this.props.favoriteResources.filter(favorite =>
      favorite.clickAway ? favorite.clickAway === resource : favorite.resource === resource
    );
    this.props.removeUserFavorite(favorite[0].id);
  };

  handleClosePlayer = (): void => {
    this.props.toggleResourcePlayerVisibility(false);
  };

  createEvent = (resource: LiteResource) => {
    const event = {
      section: 'myStuff',
      resourceId: resource.id,
      resourceType: resource.type
    };

    return () => PrekittApi.createEvent('accessResource', event);
  };

  openResourcePlayer = selectedResource => {
    const clickAway = getPdfResourceUrlOrNull('clickAway', selectedResource);

    const MaybeEndAdornment = !!clickAway && {
      EndAdornment: <DownloadPdf url={clickAway} name={selectedResource.title} />
    };

    this.props.openDraggableModal({
      Title: props => (
        <DraggableModalTitle title={selectedResource.title} {...MaybeEndAdornment} {...props} />
      ),
      body: (
        <ResourcePlayer
          selectedResource={selectedResource}
          loadedData={this.createEvent(selectedResource)}
        />
      ),
      ariaLabel: tA11y('aria.modal', { title: selectedResource.title }),
      options: { closeOnBackdropClick: true }
    } as any);
  };
  searchText = (searchText: string) => {
    const oldValue = this.props.searchFavoriteText;

    if (searchText.length > 2 || oldValue !== '') {
      this.props.setResourceFilters(searchText, []);
    }
  };

  onToggleTag = tagId => {
    this.props.toggleTag(tagId, ResourcesSections.MYSTUFF.code);
  };

  render() {
    const { favoriteResourcesStyle } = styles;
    const { selectedFavoriteFilterTags, searchFavoriteText } = this.props;
    const resources = this.props.favoriteResources.map(favorite =>
      favorite.clickAway ? favorite.clickAway : favorite.resource
    );
    const filteredFavoriteResources = filterResources(
      resources,
      selectedFavoriteFilterTags,
      searchFavoriteText
    );

    return (
      <div
        id="Pinned Resources Tab Panel"
        role="tabpanel"
        aria-labelledby="Pinned Resources Tab"
        className={css(favoriteResourcesStyle)}
      >
        <br />
        <div style={{ display: 'flex', flex: 1 }}>
          <Typography variant="h4" style={{ marginLeft: '30px', marginRight: '15px' }}>
            {t('filteredBy')}:
          </Typography>
          <ResourceFilterBreadcrumbs />
        </div>
        <ResourcesList
          resourcesItems={filteredFavoriteResources}
          isFavoritePage={true}
          onSelectItem={this.handleSelectResource}
          onRemoveItem={this.handleRemoveFavorite}
        />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  favoriteResourcesStyle: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection:'column',
    overflow: 'auto'
  }
});

const mapStateToProps = (state: AppState) => ({
  favoriteResources: state.resources.favoriteResources,
  filterGroups: state.resources.filterGroups,
  locale: state.characterSelection.locale,
  searchFavoriteText: state.resources.selectedFilters.searchText,
  selectedFavoriteFilterTags: state.resources.selectedFilters.selectedTags,
  selectedResource: state.resources.selectedResource,
  showResourcePlayer: state.resources.showResourcePlayer,
  workbookState: state.workbook
});

export default compose(
  withRouter,
  withModal,
  connect(mapStateToProps, {
    setResourceFilters,
    toggleTag,
    resetResources,
    updateCounter,
    fetchResources,
    fetchFavoriteResources,
    removeUserFavorite,
    fetchFilterTags,
    selectResource,
    toggleResourcePlayerVisibility
  })
)(MyStuff);
