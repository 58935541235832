import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toggleSlideoutMenu } from '../../actions/ui-actions';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import store from 'store';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import FullScreenButton from 'components/core/FullScreenButton';
import ResourcesButton from 'components/Button/ResourcesButton';
import { remCalc } from '../../util/style-util';
import { resolveRouteTitle } from '../../routes';
import { FOCUS_ACTIVE_WHITE_BORDER_MUI } from '../../styles/style-constants';
import { DRAWER_WIDTH, useResources } from 'state/providers/ResourceProvider';

const MenuButton = () => {
  const classes = useStyles();
  const menuOpen = useSelector(({ ui }: any) => ui.globalMenuOpen);
  const dispatch = useDispatch() as any;

  return (
    <button
      aria-expanded={menuOpen}
      aria-label={tA11y('aria.menu')}
      tabIndex={0}
      className={classes.menuButton}
      onClick={() => dispatch(toggleSlideoutMenu() as any)}
    >
      <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} size="xl" color="white" />
    </button>
  );
};

const HomeButton = () => {
  const classes = useStyles();

  if (window.location.pathname.includes('/home')) return null;

  return (
    <Link tabIndex={0} to={'/home'} color={'#fff'} className={classes.border}>
      <Box
        component="div"
        tabIndex={-1}
        aria-label={tA11y('aria.home')}
        className={`${classes.verticalLine} ${classes.homeContainer}`}
      >
        <FontAwesomeIcon color="#fff" icon={faHome} size="lg" />
      </Box>
    </Link>
  );
};

const Title = ({ title }) => {
  const classes = useStyles();

  return <h1 className={classes.heading1}>{title}</h1>;
};

const Header: React.FC<any> = ({ getTitle, getBookType, allowFullScreen }) => {
  const [{ isOpen }] = useResources();
  const bookType = getBookType && getBookType(store.getState());

  const classes = useStyles({ isOpen, bookType });
  const title = resolveRouteTitle({ getTitle, store: store.getState() });

  return (
    <Box
      id={'mainNavbar'}
      display="flex"
      justifyContent="space-between"
      className={classes.headerContainer}
      sx={{
        width: isOpen && !!bookType ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%'
      }}
    >
      <div aria-label={tA11y('aria.navbar')} className={classes.header}>
        <MenuButton />
        <HomeButton />
        {title && <Title title={title} />}
      </div>
      {allowFullScreen && (
        <Box className={classes.rightAdornment}>
          <FullScreenButton />
          <Divider
            orientation="vertical"
            style={{ borderRight: '1px solid black', width: '1px', height: '100%' }}
          />
          {bookType !== 'storybook' && <ResourcesButton />}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  headerContainer: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    whiteSpace: 'nowrap',
    transition: '0.3s',
    height: remCalc(60),
    backgroundColor: '#0171BB',
    color: '#fff',
    zIndex: 9
  },
  endAdornmentClass: {
    zIndex: 9
  },
  header: {
    display: 'flex',
    '@media print': {
      display: 'none'
    }
  },
  heading1: {
    margin: 0,
    fontSize: '28px',
    fontWeight: 500,
    paddingLeft: '27px',
    paddingTop: '12px'
  },
  heading2: {
    margin: '0.3% 0 0.3% 0',
    fontSize: remCalc(24),
    fontWeight: 200
  },
  rightAdornment: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  verticalLine: {
    backgroundColor: '#0171BB',
    borderLeft: '1px solid #0171BB',
    borderRight: '1px solid black',
    borderTop: '#0171BB',
    borderBottom: '#0171BB',
    width: '68px',
    height: '100%',
    ...FOCUS_ACTIVE_WHITE_BORDER_MUI
  },
  menuButton: {
    width: '68px',
    height: '100%',
    padding: '10px',
    backgroundColor: '#0171BB',
    borderTop: 'none',
    borderLeft: 'none',
    borderBottom: 'none',
    borderRight: '1px solid black',
    ...FOCUS_ACTIVE_WHITE_BORDER_MUI
  },
  homeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  border: {
    ...FOCUS_ACTIVE_WHITE_BORDER_MUI
  }
}));

export default Header;
