import React from 'react';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const t = key => translate('resources', key);

type Props = {
  fill: string;
  borderColor: string;
};

const ToolsIcon = ({ fill, borderColor, ...props }: Props) => (
  <svg viewBox="0 0 21.9 21.2" {...props}>
    <title>{t('title')}</title>
    <path
      style={{ fill }}
      d="M0.4,1.2c0,0,0.3,10.8,0.3,10.9s0,3.2,0,3.5C0.8,16,1,19.5,1,19.5s0.3,0.6,0.6,0.6s1.3,0.2,1.7,0.2
      c0.4,0,8.3,0.1,8.4,0c0.1-0.1,9.5-0.1,9.5-0.2s0-6.6,0-6.6V8.6l-0.3-7.9H10.4H2.7L0.4,1.2z"
    />
    <path
      style={{ fill: borderColor }}
      d="M21.7,12.2c-0.1-1.2,0.2-2.3,0.2-3.5c0-1.3-0.2-6.7-0.3-7.9c0-0.2-0.2-0.5-0.4-0.5c-1.4,0-7.7,0.1-9,0
      C11,0.2,9.9,0,8.7,0C6,0,3.3,0.3,0.7,0.3c-0.3,0-0.5,0.3-0.6,0.6C0,1,0,1.1,0,1.3c0,3.3,0,10.7,0.1,14c0,1.4-0.4,4.5,1.1,5.4
      C2,20.9,2.9,21.1,3.8,21c1.3,0.2,2.6,0.2,3.9,0c2.9-0.4,10.7,0,13.5-0.3c0.3,0,0.6-0.3,0.6-0.6C21.9,17.4,21.4,14.8,21.7,12.2z
       M20.7,19.4c-2.2,0.2-9.3-0.1-11.4,0.2C7.2,19.9,5,20,2.9,19.8c-0.9-0.1-1.4-0.1-1.6-1.2c0-0.4,0-0.7,0-1.1c-0.1-1.3-0.1-2.6-0.1-4
      C0.9,10.8,1,4.1,1,1.5c2.5,0,5-0.3,7.5-0.3c1.2,0,2.3,0.2,3.4,0.3c1.2,0.1,7.4-0.1,8.7-0.2c0.1,0.9,0.3,6.2,0.3,7.1
      c0,1.4-0.3,2.8-0.4,4.2C20.4,14.9,20.7,17.1,20.7,19.4z"
    />
    <path
      style={{ fill: borderColor }}
      d="M17.6,7.6c-0.1-0.3-0.2-0.6-0.3-1c0-0.1,0-0.2,0-0.3C17,6,16.8,5.7,16.5,5.5l-0.8-0.8L14.9,4
      c-0.3-0.3-0.6-0.5-0.9-0.7c-0.2-0.1-0.4,0-0.4,0.2c-0.2,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.5,0.5-0.8,0.8c-0.2,0.2,0,0.6,0.2,0.6
      c0.3,0.3,0.6,0.7,0.9,1c-0.4,0.3-0.8,0.7-1.2,1.1l-1.5,1.4c-0.4-0.4-0.8-0.8-1.2-1.2C9.1,7.5,8.8,7.2,8.6,7C8.5,6.9,8.4,6.8,8.3,6.7
      c0,0,0.1-0.1,0.1-0.2C8.7,5.4,8.1,4.2,7,3.9c-0.3,0-0.5-0.1-0.7-0.1c-0.1,0-0.2,0-0.3,0.1C6,4,5.9,4,5.9,4C5.8,4,5.7,4,5.7,4.1
      C5.6,4.2,5.5,4.3,5.5,4.4s0.1,0.3,0.2,0.3h0.1l0,0l0.1,0.1c0.4,0.1,0.6,0.5,0.4,0.9c0,0.1-0.1,0.2-0.2,0.3C5.9,6.4,5.3,6.5,4.9,6.2
      C4.9,6.1,4.8,6,4.7,6C4.6,5.8,4.4,5.7,4.2,5.8c0,0.1-0.1,0.1-0.1,0.2C3.9,6.1,3.8,6.3,3.9,6.6l0,0C3.9,6.8,4,7,4,7.2
      C4.2,7.6,4.4,7.8,4.8,8c0.6,0.5,1.4,0.5,2.1,0.2C7,8.3,7.1,8.4,7.2,8.5l0.7,0.7c0.4,0.4,0.8,0.8,1.2,1.2c-0.7,0.7-1.4,1.4-2.1,2.1
      l-1,1c-0.3,0.3-0.6,0.6-0.9,0.9v0.1c-0.1,0-0.1,0.1-0.2,0.1v0.1C5,15,5.2,15.2,5.4,15.4s0.4,0.4,0.7,0.4l0,0c0.1,0.1,0.2,0.1,0.4,0
      c1.4-1.2,2.8-2.5,4.1-3.8l0.6,0.6l0.7,0.8c0.2,0.2,0.4,0.4,0.6,0.5c-0.3,0.6-0.2,1.3,0.1,1.9c0.4,0.7,1.1,1.1,1.9,1.1
      c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0,0.3,0v-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2S15,16,14.9,15.9c-0.4-0.1-0.6-0.5-0.4-0.9
      c0-0.1,0.1-0.2,0.2-0.3c0.3-0.4,0.9-0.4,1.3-0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.2,0.3,0.2,0.5,0.1l0.1-0.1c0.2-0.1,0.3-0.4,0.2-0.6
      l0,0c0-0.5-0.4-1.1-0.8-1.3c-0.6-0.4-1.5-0.5-2.2-0.2c-0.6-0.7-1.2-1.4-1.8-2.1c0.5-0.5,1-1,1.5-1.5c0.4-0.4,0.8-0.8,1.1-1.2
      c0.7,0.6,1.4,1.3,2.1,1.9c0.1,0.1,0.2,0.1,0.3,0.1l0,0h0.1c0.1,0,0.2-0.1,0.3-0.3l0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3
      c-0.1-0.1,0-0.2,0-0.3C17.8,8.4,17.7,8,17.6,7.6z M6.2,15.3C6.1,15.2,6,15.1,5.9,15s-0.2-0.1-0.3-0.2c0.3-0.3,0.5-0.6,0.8-0.8l1.1-1
      c0.7-0.7,1.4-1.4,2.1-2.1l0,0l0.5,0.5C8.8,12.7,7.5,14,6.2,15.3z M13.5,13l0.1,0.1c0.1,0.2,0.3,0.3,0.5,0.2c0.5-0.2,1-0.2,1.5,0.1
      c0.1,0.1,0.2,0.2,0.3,0.3c-0.5-0.3-1.1-0.3-1.6,0c-0.1,0.1-0.3,0.2-0.4,0.3c-0.5,0.4-0.6,1.1-0.4,1.6c-0.1-0.1-0.2-0.2-0.3-0.3
      c-0.2-0.4-0.2-0.8,0-1.2c0.1-0.2-0.1-0.5-0.3-0.6l0,0c-0.2-0.3-0.4-0.5-0.6-0.7l-0.7-0.7c-0.3-0.3-0.6-0.6-0.9-0.9l0,0l0,0
      c-0.3-0.3-0.5-0.5-0.8-0.8L8.4,8.8L7.7,8C7.5,7.8,7.3,7.7,7.1,7.5C7,7.4,6.8,7.3,6.6,7.5c-0.5,0.2-1,0.2-1.5-0.1
      C5,7.2,4.9,7.1,4.8,7c0.5,0.3,1.1,0.3,1.7,0c0.1-0.1,0.3-0.2,0.4-0.3C7.4,6.3,7.5,5.6,7.3,5c0.3,0.2,0.5,0.5,0.5,0.9
      c0,0.2,0,0.3-0.1,0.5c-0.1,0.1,0,0.3,0,0.4l0,0C7.8,7,8,7.2,8.2,7.4c0.2,0.2,0.5,0.5,0.7,0.8l1.6,1.7C11.5,10.9,12.5,11.9,13.5,13z
       M13.1,8.5c-0.5,0.5-1,1-1.5,1.5c-0.2-0.2-0.4-0.4-0.5-0.6c0.5-0.5,1-1,1.5-1.5c0.4-0.4,0.8-0.8,1.1-1.2c0.2,0.3,0.4,0.5,0.6,0.7
      C13.9,7.7,13.5,8.1,13.1,8.5z M17.1,8.9L17,9c-0.6-0.7-1.3-1.3-2-2s-1.4-1.3-2.2-1.8C13,5,13.2,4.8,13.4,4.6
      c0.2-0.2,0.3-0.4,0.4-0.7c0.1,0.2,0.3,0.4,0.5,0.5c0.3,0.3,0.6,0.6,0.9,0.8c0.5,0.6,1.1,1.1,1.7,1.6c0,0.3,0,0.6,0.1,0.9
      c0,0.4,0.1,0.7,0.3,1C17.2,8.8,17.1,8.8,17.1,8.9z"
    />
  </svg>
);

ToolsIcon.defaultProps = {
  fill: '#FFF',
  borderColor: '#111'
};

export default ToolsIcon;
