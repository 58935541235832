import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import withTracker from 'components/Analytics';
import { clearLocalStorage } from '../../../util/local-storage-util';
import { getTranslatedImage } from 'util/translation-util';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { DomUtil } from '@lwtears/lwt-common-frontend/lib/@common';
import { StyleSheet, css } from 'aphrodite';
import { remCalc } from '../../../util/style-util';
import { oopsGreen, gray4, white } from '../../../styles/style-constants';
const translateErrors = key => translate('errors', key);

const NotFoundRoute = () => {
  const {
    presenterWrapper,
    leftContainer,
    rightContainer,
    goHome,
    heading,
    para1,
    para2,
    leftImg
  } = styles;

  useEffect(() => {
    DomUtil.changeTitle(`PreKITT: ${translateErrors('404Title')}`);
  }, []);

  return (
    <div className={css(presenterWrapper)}>
      <div className={css(leftContainer)}>
        <img
          className={css(leftImg)}
          src={getTranslatedImage('404-sorry-kid.png')}
          alt={tA11y('alt.img404')}
        />
      </div>
      <div className={css(rightContainer)}>
        <h1 className={css(heading)}>{translateErrors('404Title')}</h1>
        <p className={css(para1)}>{translateErrors('notFound')}</p>
        <p className={css(para2)}>{translateErrors('cantFind')}</p>
        <Link to="/login" className={css(goHome)} onClick={clearLocalStorage}>
          {translateErrors('goHome')}
        </Link>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  presenterWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: white,
    fontWeight: 300,
    fontSize: remCalc(26),
    color: gray4
  },
  leftContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '50%',
    padding: remCalc(50)
  },
  rightContainer: {
    width: '50%'
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 300
  },
  para1: {
    fontSize: '3rem',
    margin: '0.5rem 0',
    fontWeight: 500
  },
  para2: {
    fontSize: '1.5rem',
    margin: 0,
    fontWeight: 300
  },
  goHome: {
    color: white,
    backgroundColor: oopsGreen,
    borderRadius: '5px',
    padding: `${remCalc(5)} ${remCalc(20)}`,
    marginTop: remCalc(10),
    fontSize: remCalc(14),
    textDecoration: 'none'
  },
  leftImg: {
    height: remCalc(550)
  }
});

export default withTracker(NotFoundRoute) as any;
