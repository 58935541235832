//
import ftueReducer from './reducer';
import * as selectors from './selectors';
export * from './types';
export * from './operations';

export default ftueReducer;

export { hideCallout } from './selectors';

export const getTourStepByName = (state: any, props: any) =>
  selectors.getTourStepByName(state.ftue, props);

export const isCurrentStep = (state: any, props: any) => selectors.isCurrentStep(state.ftue, props);

export const showFtueCallout = (state: any, props: any) =>
  selectors.showFtueCallout(state.ftue, props);
