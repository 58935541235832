import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { getCurrentWorkbook } from '../workbook';
import { AppState } from '../../reducers';
import { ProductCode } from 'types/enums';
import { AppStoreState } from 'types';

const makeLicenseSelectorForProp = (licenseProp: string) =>
  createSelector(
    (state: AppState) => state.user.licenses,
    (state: AppState, routeParams: any) => getCurrentWorkbook(state, routeParams),
    (usersLicenses, currentWorkbook) =>
      !isEmpty(
        flow(
          filter(
            license =>
              currentWorkbook.products &&
              currentWorkbook.products.map(product => product.productId).includes(license.productId)
          ),
          filter(license => license[licenseProp])
        )(usersLicenses)
      )
  );

const checkForTrialLicense = () =>
  createSelector(
    (state: AppState) => state.user.licenses,
    (state: AppState, routeParams: any) => getCurrentWorkbook(state, routeParams),
    usersLicenses =>
      isEmpty(flow(filter(license => license.licenseTypeId !== 6))(usersLicenses)) &&
      !isEmpty(flow(filter(license => license.licenseTypeId === 6))(usersLicenses))
  );

const checkForExpiredLicense = () =>
  createSelector(
    (state: AppState) => state.user.licenses,
    usersLicenses =>
      isEmpty(flow(filter(license => license.licenseTypeId && !license.expired))(usersLicenses))
  );

export const hasClickAway = createSelector(
  (state: AppState) => state.user,
  user => !!user.clickAwayAccess.length
);

export const getClickAwayStatus: any = makeLicenseSelectorForProp('clickAwayAccess');
export const getLicenseIsExpired: any = checkForExpiredLicense();
export const getLicenseIsTrial: any = checkForTrialLicense();
export const getLicenseCount: any = makeLicenseSelectorForProp('licenses');
export const hasClickAwayOnly: any = createSelector(
  [hasClickAway, getLicenseIsExpired],
  (hasClickAway, isExpiredLicense) => {
    return hasClickAway && isExpiredLicense;
  }
);

export const getProductCodeFromLicense = createSelector(
  (state: AppStoreState) => state.user,
  user => {
    const activeLicenses = user.licenses.filter(l => l.licenseTypeId === 1 && l.expired === false);

    return (
      activeLicenses.find(
        l => l.productId === ProductCode.PREKITT_GSS || l.productId === ProductCode.PREKITT_GSS_TX
      ) ?? user.licenses[0]
    );
  }
);
