import React from 'react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider
} from '@azure/msal-react';
import SettingsApi from '@lwtears/lwt-common-frontend/lib/@api/settings-api';
import LwtApp from '@lwtears/lwt-common-frontend/lib/LwtApp';
import { getLoginRequest } from 'config/auth';
import { i18n } from 'translations';
import GatedApp from './GatedApp';
import AppState from './state/providers';
import { hasAuthCookies } from './util/auth-cookie-util';

interface AppProps {
  msalInstance: PublicClientApplication;
}

const USER_CANCELLED_AUTH_CODE = 'AADB2C90091';

const ErrorRedirect: React.FC<MsalAuthenticationResult> = ({ error }) => {
  if (!error || error.errorMessage?.includes(USER_CANCELLED_AUTH_CODE)) {
    window.location.replace('/');
    return null;
  }

  return <div>An error occurred: {error.errorMessage}</div>;
};

const AuthWrapper: React.FC<{ useAuth?: boolean; children: any }> = ({ useAuth, children }) => {
  if (!useAuth) return children;
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={getLoginRequest()}
      errorComponent={ErrorRedirect}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

const LwtApp1 = LwtApp as any;

const App = ({ msalInstance }: AppProps) => (
  <MsalProvider instance={msalInstance}>
    <AppState>
      <LwtApp1 api={{ saveSettings: SettingsApi.saveUserSettings }} i18n={i18n}>
        <AuthWrapper useAuth={!hasAuthCookies()}>
          <GatedApp />
        </AuthWrapper>
      </LwtApp1>
    </AppState>
  </MsalProvider>
);

export default App;
