import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import SquareButton from '../SquareButton';
import { TrialUserCopyright, LicensedUserCopyright } from './CopyrightContent';
import maybe from '../enhancers/maybe';
import CheckBox from '../../core/CheckBox/CheckBox';
import { remCalc } from '../../../util/style-util';
import { saveCopyrightTerms } from '../../../modules/user';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { ProductCopyrightTerms } from 'types';
import { UserState } from '../../../modules/user';
import { getCurrentWorkbook } from '../../../modules/workbook/workbook-selectors';
import withRouter from 'state/hooks/withRouter';
import { compose } from 'redux';
import { withModal } from '@lwtears/lwt-common-frontend/lib/Modal';
const translateCopyright = key => translate('copyright', key);

type Props =
  | {
      handleDismiss: (e: any) => any;
      currentWorkbook: any;
      user: UserState;
      saveCopyrightTerms: (productCopyrightTerms: Array<ProductCopyrightTerms>) => any;
      showCheckbox: boolean;
      isTrialLicense: boolean;
      closeModal: () => any;
    }
  | any;

type State = {
  checked: boolean;
};

const MaybeCheckbox: any = maybe(CheckBox);

class CopyrightModal extends Component<Props, State> {
  state = {
    checked: false
  };

  handleCheck = () => this.setState({ checked: !this.state.checked });

  handleSubmit = () => {
    if (this.state.checked) {
      const { currentWorkbook, saveCopyrightTerms, showCheckbox, productId } = this.props;
      const date = new Date().toJSON();
      const seen = showCheckbox ? 'accepted' : 'viewed';

      const copyrightTermsPerBook = [
        {
          productId: currentWorkbook?.user?.settings?.productAccess?.[0].productId ?? productId,
          [seen]: date
        }
      ];

      saveCopyrightTerms(copyrightTermsPerBook);
      this.props.closeModal();
    }
  };

  render() {
    const { user, showCheckbox, isTrialLicense } = this.props;

    return (
      <div className={css(styles.copyrightModal)}>
        {isTrialLicense ? (
          <TrialUserCopyright productTitle={user.licenses?.[0].productId} />
        ) : (
          <LicensedUserCopyright productTitle={user.licenses?.[0].productId} />
        )}
        <hr className={css(styles.divider)} style={{ marginBottom: 0 }} />
        <div className={css(styles.modalFooter)}>
          <MaybeCheckbox
            shouldRender={showCheckbox}
            label={translateCopyright('checkbox')}
            checked={this.state.checked}
            onChange={this.handleCheck}
            role={'checkbox'}
            aria-checked={this.state.checked}
            aria-label={tA11y('aria.checkbox')}
          />
          <SquareButton
            buttonStyles={[styles.modalButton]}
            text={showCheckbox ? translateCopyright('submit') : translateCopyright('gotIt')}
            onClick={this.handleSubmit}
            disabled={showCheckbox ? !this.state.checked : false}
            aria-label={tA11y('aria.submit')}
          />
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  copyrightModal: {
    textAlign: 'center',
    maxWidth: remCalc(250),
    overflow: 'auto',
    backgroundColor: '#fff',
    padding: '0px 25px',
    '@media(min-width: 1000px)': {
      maxWidth: remCalc(500)
    }
  },
  modalButton: {
    margin: remCalc(20, 0)
  },
  divider: {
    // margin: remCalc(30, 0, 0, 0),
    border: '1px solid black'
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const mapStateToProps = (state: any, { match: { params } }) => ({
  user: state.user,
  currentWorkbook: getCurrentWorkbook(state, params),
  productId: state.workbook?.currentWorkbook?.productId
});

const withRedux = connect(mapStateToProps, {
  saveCopyrightTerms
});

export default compose(withRouter, withModal, withRedux)(CopyrightModal) as any;
