//
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'state/hooks/withRouter';
import { StyleSheet, css } from 'aphrodite';
import flow from 'lodash/fp/flow';
import { oopsGreen, white } from '../../../styles/style-constants';
import { DomUtil } from '@lwtears/lwt-common-frontend/lib/@common';
import WebLink from '../WebLink';
import Config from 'config';
import { disableErrorRouteRedirection } from '../../../modules/error';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { getTranslatedImage } from 'util/translation-util';
import { ReactRouterProps } from '../../../containers/Main';
const translateErrors = key => translate('errors', key);

const { supportEmail } = Config;

type Props = {
  disableErrorRouteRedirection?: () => any;
} & ReactRouterProps;

export class Page500 extends Component<Props> {
  componentDidMount() {
    if (this.props.disableErrorRouteRedirection) {
      this.props.disableErrorRouteRedirection();
    }
  }

  handleGoBack = () => {
    this.props.router.navigate(-2);
  };

  renderButtons() {
    const { buttons, anchor } = styles;

    return (
      <div className={css(buttons)}>
        <button className={css(anchor)} onClick={this.handleGoBack}>
          {translateErrors('goBack')}
        </button>
        <WebLink
          className={css(anchor)}
          to={`mailto:${supportEmail}`}
          name={translateErrors('contact')}
        />
        <p style={{ marginTop: 30 }}>Version: {process.env.REACT_APP_APP_VERSION}</p>
      </div>
    );
  }

  render() {
    const { page500, image, textSection, heading, para1, para2 } = styles;

    DomUtil.changeTitle(`PreKITT: ${translateErrors('500Title')}`);

    return (
      <div className={css(page500)}>
        <img
          alt={tA11y('alt.img500')}
          src={getTranslatedImage('500-oops-kid.png')}
          className={css(image)}
        />
        <section className={css(textSection)}>
          <h1 className={css(heading)}>{translateErrors('500Title')}</h1>
          <p className={css(para1)}>{translateErrors('wrong')}</p>
          <p className={css(para2)}>{translateErrors('internalError')}</p>
          {this.renderButtons()}
        </section>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  page500: {
    fontFamily: 'futura-pt, sans-serif',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem',
    backgroundColor: white
  },
  image: {
    height: '80%',
    marginRight: '5%'
  },
  textSection: {
    position: 'relative',
    top: '-8%'
  },
  heading: {
    fontSize: '1.4rem',
    fontWeight: 300
  },
  para1: {
    fontSize: '3rem',
    margin: '0.5rem 0',
    fontWeight: 500
  },
  para2: {
    fontSize: '1.5rem',
    margin: 0,
    fontWeight: 300
  },
  buttons: {
    margin: '1.2rem 0'
  },
  anchor: {
    border: 'none',
    borderRadius: '0.2rem',
    backgroundColor: oopsGreen,
    color: white,
    margin: '0 0.5rem 0 0',
    padding: '0.35rem 1.2rem',
    fontSize: '0.8rem',
    fontWeight: 500,
    cursor: 'pointer',
    ':visited': {
      backgroundColor: oopsGreen,
      color: white,
      textDecoration: 'none'
    },
    ':active': {
      backgroundColor: oopsGreen,
      color: white,
      textDecoration: 'none'
    },
    ':hover': {
      color: white,
      textDecoration: 'none'
    },
    ':link': {
      backgroundColor: oopsGreen,
      color: white,
      textDecoration: 'none'
    }
  }
});

const withRedux = connect(null, { disableErrorRouteRedirection });

export default flow(withRedux, withRouter)(Page500);
