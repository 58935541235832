import Login from '../containers/Login';
import ExternalLink from '../containers/ExternalLink';
import Setup from '../containers/Setup';
import Expired from '../containers/Expired';
import Page500 from '../components/core/Page500/Page500';
import withTracker from '../components/Analytics';
import Workbook from '../containers/Workbook';
import Resources from '../containers/Resources/Resources';
import Assessment from '../containers/Assessments/Assessment';
import Support from '../containers/Support';
import LetterNumberFormations from '../containers/LetterNumberFormations';
import WoodPieces from '../containers/WoodPieces';
import WetDryTry from '../containers/WetDryTry';
import WorksheetMaker from '../containers/WorksheetMaker';
import Home from '../containers/Home/Home';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { DomUtil } from '@lwtears/lwt-common-frontend/lib/@common';

const rootRoutes = [
  {
    path: '/oops',
    component: withTracker(Page500),
    getTitle: () => 'Oops'
  },
  {
    path: '/login',
    component: Login,
    getTitle: () => 'Login'
  },
  {
    path: '/home',
    component: withTracker(Home),
    withMainHeader: true,
    getTitle: () => translate('home', 'title')
  },
  {
    path: '/ext/:bookCode/:edition/:pageNumber?',
    component: withTracker(ExternalLink),
    withMainHeader: true,
    getTitle: () => '',
    allowFullScreen: true
  },
  {
    path: '/EXT/:bookCode/:edition/:pageNumber?',
    component: withTracker(ExternalLink),
    withMainHeader: true,
    getTitle: () => '',
    allowFullScreen: true
  },
  {
    path: '/books/:bookCode/:edition/:pageNumber?/:isZoom?',
    component: withTracker(Workbook),
    getTitle: ({ store }: any) => {
      const book: any =
        (Object.values(store.workbook.allWorkbooks) as any).find(({ bookCode }) =>
          window.location.pathname.includes(`/${bookCode}/`)
        ) ?? false;
      const title = translate('nav', book?.type);

      return `${title} - ${book?.title}`;
    },
    withMainHeader: true,
    allowFullScreen: true,
    getBookType: store => {
      const book: any =
        (Object.values(store.workbook.allWorkbooks) as any).find(({ bookCode }) =>
          window.location.pathname.includes(`/${bookCode}/`)
        ) ?? false;
      return book?.type;
    }
  },
  {
    path: '/resources/:section?',
    component: withTracker(Resources),
    getTitle: () => translate('resources', 'title'),
    withMainHeader: true
  },
  {
    path: '/assessments/:activeTab?',
    component: withTracker(Assessment),
    getTitle: () => translate('assessments', 'header'),
    withMainHeader: true
  },
  {
    path: '/apps/letter-number-formations',
    component: withTracker(LetterNumberFormations),
    getTitle: () => translate('apps', 'lnf'),
    withMainHeader: true
  },
  {
    path: '/apps/wood-pieces',
    component: withTracker(WoodPieces),
    getTitle: () => translate('apps', 'woodPieces'),
    withMainHeader: true
  },
  {
    path: '/apps/wet-dry-try',
    component: withTracker(WetDryTry),
    getTitle: () => translate('apps', 'wdt'),
    withMainHeader: true
  },
  {
    path: '/apps/worksheet-maker',
    component: withTracker(WorksheetMaker),
    getTitle: () => translate('apps', 'worksheetMaker'),
    withMainHeader: true
  },
  {
    path: '/support',
    component: Support,
    getTitle: () => translate('support', 'header'),
    withMainHeader: true
  },
  {
    path: '/setup',
    component: withTracker(Setup),
    getTitle: () => 'Setup'
  },
  {
    path: '/expired',
    component: Expired,
    getTitle: () => 'Expired'
  }
];

export const resolveRouteTitle = ({ getTitle, store }) => {
  const title = getTitle({ store });
  DomUtil.changeTitle(`PreKITT: ${title}`);

  return title;
};

export default rootRoutes;
