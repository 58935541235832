import React, { useEffect } from 'react';
import flow from 'lodash/fp/flow';
import { connect } from 'react-redux';
import withRouter from 'state/hooks/withRouter';
import SWNotifier from '../components/SWNotifier';
import LogoutFrame from '../components/core/LogoutFrame';
import maybe from '../components/core/enhancers/maybe';
import { logoutUser } from '../modules/auth';
import {
  getLicenseIsExpired,
  hasClickAway,
  hasClickAwayOnly,
  saveUserSettings
} from '../modules/user';
import { closeAppModal, MessagingState } from '../modules/messaging';
import { UserState } from '../modules/user';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import ExpiredLicenseModal from '../components/core/Modals/ExpiredLicenseModal';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { ReactRouterProps } from './Main';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import { Workbook, UserSettingTypes, UserSetting } from 'types';
import { getClickAwayResourcesLink } from '../util/workbook-util';

type Props = {
  hasClickAway: boolean;
  hasClickAwayOnly: boolean;
  hasLoggedOut: boolean;
  isExpiredLicense: boolean;
  messaging: MessagingState;
  user: UserState;
  workbooks: { [id: string]: Workbook };
  closeAppModal: () => any;
  logoutUser: () => any;
  saveUserSettings: (type: UserSettingTypes, settings: UserSetting) => any;
} & UseModalDispatch &
  ReactRouterProps;

const MaybeLogout: any = maybe(LogoutFrame);

const Expired = (props: Props) => {
  useEffect(() => {
    maybeNavigate();
  }, []);

  const maybeNavigate = () => {
    if (props.hasClickAway && props.user.settings.suppressedExpirationAlerts) {
      props.router.navigate(getClickAwayResourcesLink());
    } else {
      props.openModal({
        body: (
          <ExpiredLicenseModal
            isClickAwayOnly={props.hasClickAway}
            hasWorkbooks={Object.keys(props.workbooks).length > 0}
          />
        ),
        ariaLabel: tA11y('aria.expiredLicense')
      });
    }
  };

  return (
    <SWNotifier>
      <MaybeLogout shouldRender={props.hasLoggedOut} />
    </SWNotifier>
  );
};
const mapStateToProps = (state, { router: { params } }) => ({
  hasClickAway: hasClickAway(state),
  hasClickAwayOnly: hasClickAwayOnly(state, params),
  hasLoggedOut: state.auth.hasLoggedOut,
  isExpiredLicense: getLicenseIsExpired(state, params),
  messaging: state.messaging,
  user: state.user,
  workbooks: state.workbook.allWorkbooks
});

const withRedux = connect(mapStateToProps, {
  closeAppModal,
  logoutUser,
  saveUserSettings
});

export default flow(withModal, withRedux, withRouter)(Expired);
