import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import withRouter from 'state/hooks/withRouter';
import { lightGray, white } from '../../styles/style-constants';

import Footer from '../../components/core/PageFooter';
import RouteTabMenu from '../../components/Resources/RouteTabMenu/RouteTabMenu';
import AllResources from './containers/AllResources';
import MyStuff from './containers/MyStuff';
import ResourcesFilter from '../../components/Resources/ResourcesFilter';

import AllResourcesIcon from '../../components/icons/AllResourcesIcon';
import MyStuffIcon from '../../components/icons/MyStuffIcon';
import ExpiredLicenseAlert from '../../components/core/ExpiredLicenseAlert/ExpiredLicenseAlert';
import withFtueTour from '../../components/core/enhancers/withFtueTour';
import { toggleClickAwayAlertDisplay, isShowClickAwayAlert } from '../../modules/messaging';
import { setResourceFilters } from '../../actions/resource-actions';
import { getLicenseIsTrial, getTrialDaysRemaining, hasClickAwayOnly } from '../../modules/user';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

import { UserState } from '../../modules/user';
import { ComponentType } from 'react';
import { ReactRouterProps } from '../Main';
import { AppState } from '../../reducers';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';

const translateResources = key => translate('resources', key);

export type ResourcesSubRoute = {
  label: string;
  ariaLabel: string;
  endLink: string;
  component: ComponentType<any & ReactRouterProps>;
  icon: ComponentType<any>;
  exact?: boolean;
  hasBadge?: boolean;
} & UseModalDispatch;

type Props = {
  counter: number;
  isClickAwayOnly: boolean;
  isShowClickAwayAlert: boolean;
  isTrialLicense: boolean;
  locale: string;
  router: any;
  setResourceFilters: typeof setResourceFilters;
  textColor: string;
  themeColor: string;
  toggleClickAwayAlertDisplay: typeof toggleClickAwayAlertDisplay;
  trialDaysLeft: number;
  user: UserState;
  setSearchFavoritesFilter: (searchText: string) => any;
} & ReactRouterProps &
  UseModalDispatch;

class Resources extends Component<Props> {
  tabMenuItems;

  constructor(props: Props) {
    super(props);

    this.tabMenuItems = this.getTabMenuItems(props);
  }

  componentDidUpdate(prevProps: Props) {
    this.tabMenuItems = this.getTabMenuItems(this.props);
    if (prevProps.locale !== this.props.locale) {
      this.resetFilters();
    }
  }

  resetFilters = () => {
    this.props.setResourceFilters('', []);
  };
  getTabLink = (fullUrl: string, endLink: string): string => {
    return fullUrl.split('resources')[0] + 'resources' + endLink;
  };

  getTabMenuItems = (props: Props) => {
    const { pathname }: { pathname: string } = props.router.location;

    const resourcesSubRoutes = [
      {
        label: translateResources('allResources'),
        endLink: '',
        component: AllResources,
        icon: AllResourcesIcon,
        exact: true,
        ariaLabel: tA11y('aria.allResourcesRoute')
      },
      {
        label: translateResources('myStuff'),
        endLink: '/my-stuff',
        icon: MyStuffIcon,
        component: MyStuff,
        hasBadge: true,
        ariaLabel: tA11y('aria.pinnedResourcesRoute')
      }
    ];

    return resourcesSubRoutes.map(item => {
      return {
        label: item.label,
        link: this.getTabLink(pathname, item.endLink),
        paramPath: this.getTabLink(props.router.location.pathname, item.endLink),
        component: item.component,
        icon: item.icon,
        exactRoute: item.exact,
        hasBadge: item.hasBadge,
        ariaLabel: item.ariaLabel
      };
    });
  };

  getContent = () => {
    const url = this.props.router.location.pathname;
    if (url.includes('my-stuff')) return <MyStuff />;
    return <AllResources />;
  };

  handleCloseAlert = () => {
    this.props.toggleClickAwayAlertDisplay(this.props.user.licenses[0].productId, false);
  };

  render() {
    const {
      counter,
      isClickAwayOnly,
      isShowClickAwayAlert,
      isTrialLicense,
      router,
      trialDaysLeft
    } = this.props;

    const { content, resources } = style;
    const colors = {
      theme: '#0171BB',
      text: '#FFFFFF'
    };

    return (
      <div className={css(resources)}>
        <ExpiredLicenseAlert show={isShowClickAwayAlert} onClose={this.handleCloseAlert} />
        <RouteTabMenu
          overflowUnderline
          items={this.tabMenuItems}
          activeRoute={router.location.pathname}
          isClickAwayOnly={isClickAwayOnly}
          counter={counter}
        />
        <ResourcesFilter />
        <div className={css(content)}>{this.getContent()}</div>
        <Footer
          themeColor={colors.theme}
          textColor={colors.text}
          isTrialLicense={isTrialLicense}
          trialDaysLeft={trialDaysLeft}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, { router: { params } }: Props) => ({
  counter: state.resources.counter,
  isClickAwayOnly: hasClickAwayOnly(state, params),
  isShowClickAwayAlert: isShowClickAwayAlert(state, params),
  isTrialLicense: getLicenseIsTrial(state, params),
  locale: state.characterSelection.locale,
  trialDaysLeft: getTrialDaysRemaining(state.user),
  user: state.user
});

export const style = StyleSheet.create({
  resources: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: white,
    overflowY: 'auto'
  },
  content: {
    backgroundColor: lightGray,
    borderTop: '1px solid #B0B0B0',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
});

export default compose(
  withRouter,
  withModal,
  withFtueTour('resources', 'resourceFilters'),
  connect(mapStateToProps, {
    setResourceFilters,
    toggleClickAwayAlertDisplay
  })
)(Resources);
