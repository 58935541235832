import React from 'react';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const tApps = key => translate('apps', key);

const WPIcon = props => (
  <svg width="32" height="32" viewBox="0 0 76.23 57.6" {...props}>
    <title>{tApps('woodPieces')}</title>
    <polygon
      points="17.29 54.81 7.35 2.98 1.78 3.96 11.44 56.03 17.29 54.81"
      style={{ fill: '#e0e0e0' }}
    />

    <path d="M7.35,3l.91,5.1L9.1,12.8l3.68,18.14L16.63,55.1,11.44,56,9.15,43.2,6.75,31,4.43,18,2.51,7.54,2.16,3.91,7.35,3m0-1.81-.23,0-4.41.9c-.35.06-.34-.26-1.53.07-.66.2-.92.38-.86.73V7.41L2.71,20.93,5.18,34l4.6,20.26.35,2a1.34,1.34,0,0,0,.55.86,1.36,1.36,0,0,0,.76.23.93.93,0,0,0,.23,0l3.05-.54,2.14-.38a1.33,1.33,0,0,0,1.07-1.54l-4-23L13,23.42,10.84,13,9.6,4.22,8.81,2c-.12-.64-.37-.6-1-.6Z" />

    <path
      d="M3.35,3.91c.09.6.21,1.2.34,1.79S4,6.86,4.17,7.46c.36,1.17.64,2.35.92,3.54h0c.22,2.54.7,5,1,7.57s.77,5,1,7.57h0c-.28-1.25-.49-2.51-.73-3.76s-.44-2.51-.6-3.77c-.31-2.53-.46-5.08-.87-7.6h0c-.36-1.16-.73-2.33-1-3.51A17.9,17.9,0,0,1,3.5,5.74a16.19,16.19,0,0,1-.18-1.83Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M7.56,22.1c.31,1.26.53,2.54.75,3.81s.31,2.58.53,3.86l.71,3.82c.22,1.27.42,2.55.59,3.84h0l.13,1.89v0c.11.4.19.8.29,1.2h0c-.14-.39-.29-.77-.41-1.16h0l-.25-1.88h0c-.24-1.27-.43-2.55-.61-3.84s-.31-2.57-.51-3.85S8.23,27.24,8,26s-.39-2.56-.5-3.85Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M10.23,41.8A12.64,12.64,0,0,1,11,44.64a3.8,3.8,0,0,1,.06.75,1.46,1.46,0,0,1-.07.4,2.19,2.19,0,0,0-.1.34,2.53,2.53,0,0,0,.22,1.4h0c.14.89.32,1.78.44,2.68v0c.23.64.44,1.29.64,1.94v0h0c.15,1,.3,2,.38,3h0c-.27-1-.47-1.93-.67-2.9v0c-.22-.65-.43-1.29-.62-1.95v0h0c-.11-.89-.15-1.8-.24-2.69h0a3.5,3.5,0,0,1-.28-1.47,1.51,1.51,0,0,1,.07-.37,1.77,1.77,0,0,0,0-.33q0-.36-.09-.72c-.15-1-.39-1.9-.57-2.87Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M6.62,3.2a11,11,0,0,1,.54,2,11,11,0,0,0,.39,2.06,19.25,19.25,0,0,1,.8,4.12h0a19.64,19.64,0,0,1-.94-4.08,10.23,10.23,0,0,0-.47-2A11.13,11.13,0,0,1,6.58,3.2Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M5.64,3.5c.42,1.64.75,3.3,1.07,5s.52,3.34.87,5,.69,3.31,1,5,.79,3.28,1.12,4.94h0l.09,1.49v0c.17.52.32,1,.49,1.57h0c-.21-.51-.43-1-.62-1.53h0L9.46,23.4v0C9.24,22.6,9,21.78,8.81,21a23.31,23.31,0,0,1-.54-2.48c-.29-1.66-.5-3.34-.8-5s-.71-3.3-1-5-.61-3.33-.83-5Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M6.59,11.16c.22.85.37,1.72.53,2.58s.2,1.75.41,2.6.44,1.71.59,2.58.25,1.74.32,2.62v0c.16.84.33,1.69.44,2.54h0c-.27-.82-.5-1.65-.72-2.48h0v0L7.83,19a19,19,0,0,0-.4-2.6c-.19-.86-.45-1.7-.59-2.57a21.13,21.13,0,0,1-.28-2.63Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M12.48,37.3c.29,1.09.5,2.2.71,3.31v0c.2.72.37,1.45.53,2.18s.36,1.45.58,2.16h0c.28,1.53.56,3.06.78,4.6s.4,3.08.54,4.63h0c-.32-1.53-.61-3-.84-4.59s-.4-3.09-.57-4.63h0c-.18-.72-.38-1.44-.61-2.15s-.46-1.42-.65-2.14h0c-.18-1.12-.36-2.23-.46-3.35Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M9.73,27.07c.3,1.23.55,2.47.78,3.71h0c0,.63.05,1.27.07,1.91h0c.15,1.25.26,2.51.44,3.75s.39,2.5.58,3.74v0Q12.34,42.57,13,45h0c.24,1.6.51,3.2.71,4.81h0c-.38-1.58-.67-3.18-1-4.77v0q-.73-2.4-1.39-4.83v0h0c-.15-1.26-.26-2.51-.43-3.76s-.4-2.49-.6-3.74h0c0-.64-.05-1.28-.06-1.91v0c-.21-1.25-.4-2.5-.55-3.75Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M13.87,54.61a4.71,4.71,0,0,1-.42-1.3A4,4,0,0,0,13.19,52a8.35,8.35,0,0,1-.54-2.66h0A7.69,7.69,0,0,1,13.36,52a4.25,4.25,0,0,0,.35,1.3,4.74,4.74,0,0,1,.19,1.35Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M14.68,54.46a1.46,1.46,0,0,1-.21-.72,1.46,1.46,0,0,0-.05-.75,2.48,2.48,0,0,1-.13-1.48h0A2.56,2.56,0,0,1,14.59,53a1.31,1.31,0,0,0,.14.73,1.41,1.41,0,0,1,0,.76Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M9.7,19.25c.33,1.15.58,2.32.84,3.49s.4,2.35.63,3.53h0a23.87,23.87,0,0,0,.17,2.93c.19.95.43,1.91.63,2.87s.4,1.92.56,2.89.35,1.93.48,2.9h0q-.75-2.85-1.34-5.74c-.17-1-.39-1.92-.51-2.91s0-2-.07-2.95h0c-.25-1.17-.57-2.32-.8-3.5s-.44-2.35-.59-3.54Z"
      style={{ fill: '#b2b2b2' }}
    />

    <polygon
      points="17.16 55.8 26.32 3.11 20.54 2.23 11.46 54.74 17.16 55.8"
      style={{ fill: '#e0e0e0' }}
    />

    <path d="M21.13,2.19l5.19.92L20.71,31.58,17.54,51.75,17,55.47l-1.46-.13L13.38,55l-2-.8.8-3.25,1.39-8.55,2.25-11.6,2.56-13.59L19.88,7.14l1.25-4.95M21,.61c-.63,0-1.07.71-1.18,1.35L18.65,7.64,16.85,18l-2,9.55L12.56,43.21l-1,5.58-.94,5.31a1.33,1.33,0,0,0,.22,1,1.32,1.32,0,0,0,.86.55l2.46.43,2.73.48a.93.93,0,0,0,.23,0,1.34,1.34,0,0,0,1.31-1.09l.31-1.81.69-3.88,1.09-8,1.26-7.49,1.46-7.76L27.63,3.34a1.32,1.32,0,0,0-.22-1,1.34,1.34,0,0,0-.86-.55L21.4.58a.93.93,0,0,0-.23,0Z" />

    <path
      d="M22.24,2.6c-.12.6-.21,1.2-.29,1.8s-.1,1.21-.15,1.82c-.07,1.22-.21,2.43-.35,3.64h0c-.66,2.47-1.06,5-1.63,7.47s-1,5-1.62,7.46h0c.17-1.27.41-2.52.61-3.78s.45-2.51.73-3.75c.57-2.48,1.31-4.93,1.79-7.43h0c.06-1.22.11-2.44.25-3.65a16.59,16.59,0,0,1,.22-1.83,16,16,0,0,1,.45-1.78Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M20,21.13c-.14,1.29-.37,2.57-.6,3.84s-.6,2.53-.82,3.8l-.65,3.84c-.23,1.27-.48,2.54-.77,3.8h0l-.52,1.83v0c0,.41-.09.82-.14,1.22h0c0-.41,0-.82,0-1.23h0l.41-1.85h0c.21-1.27.47-2.54.74-3.81s.6-2.53.85-3.8.39-2.56.64-3.83.51-2.54.85-3.79Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M15.7,40.55a13.24,13.24,0,0,1-.21,3,3.79,3.79,0,0,1-.21.73,2.08,2.08,0,0,1-.2.35,1.46,1.46,0,0,0-.21.28,2.36,2.36,0,0,0-.27,1.39h0c-.18.89-.31,1.78-.5,2.66v0c0,.68,0,1.36-.07,2h0c-.19,1-.39,1.94-.65,2.9h0c.08-1,.22-2,.37-3v0c0-.68,0-1.37.09-2h0c.21-.88.48-1.75.7-2.62v0a3.44,3.44,0,0,1,.24-1.48,3.54,3.54,0,0,1,.19-.33,1,1,0,0,0,.13-.3c.06-.23.12-.46.16-.7.19-1,.29-1.93.46-2.9Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M25.55,3.06a10.48,10.48,0,0,1-.18,2.09A11,11,0,0,0,25,7.22a19.33,19.33,0,0,1-.66,4.13h0a20.07,20.07,0,0,1,.52-4.16,10.37,10.37,0,0,0,.25-2.08,12.12,12.12,0,0,1,.41-2.06Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M24.54,3c-.17,1.68-.43,3.35-.7,5s-.66,3.32-.9,5-.49,3.34-.77,5-.38,3.35-.64,5h0L21.1,24.5v0c0,.55-.05,1.09-.08,1.64h0c0-.55,0-1.09,0-1.64h0L21.3,23h0c.07-.84.14-1.68.23-2.52A19.34,19.34,0,0,1,21.87,18c.29-1.66.67-3.31,1-5s.47-3.35.73-5,.57-3.33,1-5Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M22.8,10.52c-.09.88-.25,1.74-.39,2.61s-.41,1.71-.51,2.58-.17,1.76-.33,2.63-.36,1.72-.6,2.57v0c-.13.85-.26,1.7-.45,2.54h0c0-.87.1-1.72.17-2.57h0v0l.62-2.55a19,19,0,0,0,.51-2.58c.11-.87.16-1.76.33-2.62a23,23,0,0,1,.64-2.57Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M19.35,37.1c-.1,1.13-.28,2.24-.46,3.35h0c-.06.74-.15,1.48-.25,2.22s-.15,1.49-.2,2.23h0c-.26,1.53-.52,3.07-.84,4.59s-.68,3-1.09,4.54h0c.22-1.54.48-3.08.79-4.6s.68-3,1.05-4.55h0c.08-.74.14-1.48.17-2.23s.06-1.49.12-2.24h0c.21-1.11.42-2.21.71-3.31Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M20.29,26.55q-.22,1.89-.54,3.75h0c-.19.61-.39,1.21-.6,1.81h0c-.3,1.23-.62,2.45-.89,3.68s-.48,2.47-.73,3.71v0c-.07,1.68-.17,3.35-.29,5h0c-.32,1.59-.61,3.19-1,4.77h0c.19-1.61.46-3.21.7-4.81h0q.13-2.51.36-5v0h0c.29-1.23.61-2.45.89-3.68s.48-2.48.72-3.72h0c.19-.61.39-1.21.6-1.82v0c.23-1.24.49-2.47.78-3.7Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M14.72,53.83a4.76,4.76,0,0,1,0-1.36A4.35,4.35,0,0,0,15,51.14a8.43,8.43,0,0,1,.41-2.68h0a8.17,8.17,0,0,1-.26,2.7A4.36,4.36,0,0,0,15,52.51a4.81,4.81,0,0,1-.29,1.33Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M15.53,54a1.46,1.46,0,0,1,.05-.75,1.43,1.43,0,0,0,.21-.72,2.53,2.53,0,0,1,.39-1.43h0A2.53,2.53,0,0,1,16,52.54a1.34,1.34,0,0,0-.12.74,1.46,1.46,0,0,1-.28.7Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M22.94,19.19c-.09,1.2-.25,2.38-.41,3.56s-.43,2.36-.62,3.54h0a23.23,23.23,0,0,0-.84,2.81c-.16.95-.26,1.94-.4,2.91s-.28,1.94-.47,2.91-.33,1.93-.54,2.89h0c.18-2,.41-3.91.71-5.85.17-1,.29-1.94.52-2.91s.64-1.86.95-2.8h0c.16-1.18.25-2.38.44-3.56s.39-2.36.66-3.53Z"
      style={{ fill: '#b2b2b2' }}
    />

    <polygon
      points="36.92 54.41 27.17 2.17 21.11 2.96 30.42 55.59 36.92 54.41"
      style={{ fill: '#e0e0e0' }}
    />

    <path d="M27,2.83,29.2,13.32l2,13.43,5.44,27.66-6,1.2-1-8.19-.34-3s-1.13-6.1-1.33-7.5-1.86-11.86-2.17-13S23.24,9.07,22.71,7.08c-.21-.77-.5-3.33-.5-3.33l4.86-1.21M27.5,1c-.07,0-1-.3-1-.28L21.64,2.14a1.34,1.34,0,0,0-1.07,1.54l9.16,52.14a1.34,1.34,0,0,0,.55.86,1.37,1.37,0,0,0,.76.24l.23,0L36.46,56a1.33,1.33,0,0,0,1.08-1.53l-5.6-34.28-1.39-7.72L28.43,2c-.11-.64.1-1.06-.53-1.06Z" />

    <path
      d="M23.06,3.45c.09.6.21,1.2.34,1.8s.32,1.16.48,1.75c.35,1.17.63,2.36.91,3.55h0c.22,2.54.7,5,1,7.57s.75,5,1,7.57h0c-.27-1.25-.48-2.51-.71-3.76s-.44-2.51-.6-3.77c-.31-2.53-.45-5.08-.85-7.6h0c-.36-1.17-.72-2.33-1-3.52a17.28,17.28,0,0,1-.42-1.79A15.75,15.75,0,0,1,23,3.45Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M27.24,21.65c.3,1.26.52,2.54.73,3.82s.31,2.57.53,3.85l.7,3.82c.22,1.28.42,2.56.58,3.84h0l.13,1.9v0c.11.4.19.8.28,1.19h0c-.13-.38-.28-.76-.41-1.15h0L29.5,37h0c-.23-1.27-.42-2.56-.6-3.84s-.3-2.58-.5-3.85-.5-2.55-.7-3.83-.39-2.56-.5-3.85Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M29.86,41.36a12.64,12.64,0,0,1,.81,2.84,4.73,4.73,0,0,1,0,.75,1.46,1.46,0,0,1-.07.4,2.19,2.19,0,0,0-.1.34,2.53,2.53,0,0,0,.22,1.4h0c.13.9.31,1.78.43,2.68v0c.22.64.43,1.29.63,1.94v0h0c.15,1,.29,2,.37,3h0c-.26-1-.46-1.93-.66-2.9v0c-.22-.64-.42-1.29-.61-1.95v0h0c-.1-.89-.15-1.8-.24-2.69h0a3.5,3.5,0,0,1-.28-1.47c0-.12.05-.26.07-.37a1.25,1.25,0,0,0,0-.34,6,6,0,0,0-.09-.71c-.14-1-.38-1.9-.56-2.87Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M26.33,2.75a10.4,10.4,0,0,1,.54,2,10.86,10.86,0,0,0,.39,2.06A19.87,19.87,0,0,1,28.05,11h0a19.23,19.23,0,0,1-.93-4.09,11,11,0,0,0-.47-2,11.12,11.12,0,0,1-.32-2.08Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M25.36,3.05c.41,1.64.74,3.3,1.06,5s.5,3.34.85,5,.69,3.3,1,5,.79,3.28,1.11,4.93v0h0l.08,1.49v0c.17.52.32,1.05.48,1.58h0c-.2-.51-.42-1-.61-1.53h0L29.14,23v0c-.22-.81-.45-1.63-.65-2.45A21,21,0,0,1,28,18c-.29-1.67-.5-3.35-.8-5s-.7-3.31-1-5-.6-3.33-.81-5Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M26.29,10.71c.22.85.36,1.72.52,2.58s.2,1.75.4,2.6.45,1.71.59,2.58.25,1.75.32,2.63v0c.17.84.33,1.68.44,2.53h0c-.27-.81-.49-1.64-.71-2.47h0l-.29-2.62a19.2,19.2,0,0,0-.39-2.6c-.19-.85-.45-1.7-.59-2.57a23.22,23.22,0,0,1-.28-2.63Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M32.12,36.86c.29,1.1.49,2.2.71,3.31h0c.2.72.37,1.45.53,2.17s.36,1.45.57,2.17h0c.28,1.53.56,3.06.78,4.6s.39,3.08.52,4.63h0c-.31-1.53-.6-3.06-.82-4.59s-.4-3.09-.57-4.64h0c-.18-.72-.37-1.44-.6-2.15s-.46-1.43-.65-2.15h0c-.18-1.11-.36-2.22-.46-3.35Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M29.4,26.63c.29,1.23.54,2.47.77,3.71h0c0,.63,0,1.27.06,1.91v0c.14,1.26.25,2.51.42,3.76s.39,2.5.58,3.74v0c.51,1.6,1,3.21,1.44,4.83h0c.24,1.61.51,3.21.7,4.82h0c-.37-1.58-.66-3.17-1-4.76h0q-.72-2.4-1.37-4.83v0h0c-.14-1.26-.25-2.51-.42-3.76s-.39-2.49-.59-3.74h0c0-.64-.05-1.28-.06-1.91h0c-.21-1.24-.4-2.49-.54-3.74Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M33.47,54.17a5,5,0,0,1-.41-1.29,4.55,4.55,0,0,0-.26-1.33,8.28,8.28,0,0,1-.53-2.65h0A8,8,0,0,1,33,51.51a4.31,4.31,0,0,0,.35,1.31,5,5,0,0,1,.19,1.34Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M34.28,54a1.61,1.61,0,0,1-.21-.73,1.25,1.25,0,0,0,0-.74,2.58,2.58,0,0,1-.12-1.48h0a2.59,2.59,0,0,1,.26,1.47,1.35,1.35,0,0,0,.14.73,1.59,1.59,0,0,1,0,.75Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M29.38,18.81c.32,1.15.58,2.32.83,3.49s.4,2.35.63,3.53h0A23.87,23.87,0,0,0,31,28.77c.18.95.43,1.91.62,2.87s.4,1.92.56,2.89.34,1.93.47,2.91h0q-.75-2.85-1.32-5.74c-.18-1-.39-1.92-.51-2.91s0-2-.06-2.95h0c-.25-1.17-.57-2.32-.79-3.5s-.44-2.35-.59-3.54Z"
      style={{ fill: '#b2b2b2' }}
    />

    <polygon
      points="37.31 55.37 46.1 2.88 40.98 1.57 31.28 54.54 37.31 55.37"
      style={{ fill: '#e0e0e0' }}
    />

    <path d="M41.1,1.73c.25,0,2.74.62,2.74.62l2,.48s.24,0,.24,0a1.39,1.39,0,0,1-.14.51l-1.47,8.53L43,19.9,40.11,36.57,38,48.18l-.59,4.22-.53,2.74H35.68L34,54.88l-1.82-.2-.83-.25.52-1.68,1.34-5.33,3-16.67,2.24-15S41,1.73,40.91,2C40.91,2,40.84,1.77,41.1,1.73ZM40.5.45c-.63,0-.78.63-.9,1.28L36.92,14.57,34.73,29.62,31.61,48.05l-1.28,5.8a1.33,1.33,0,0,0,1.07,1.54L35,56l1.6.28.24,0a1.32,1.32,0,0,0,.76-.24,1.35,1.35,0,0,0,.54-.85l.93-5.19,2-10.82L43.28,27l1.66-11.14,1.7-7.33.26-1.42.51-4a1.36,1.36,0,0,0-.22-1,3.15,3.15,0,0,0-1.23-1L44,.54,41.33.29c-.15,0-.42.11-.58.15Z" />

    <path
      d="M42,2.37c-.12.6-.21,1.2-.29,1.8s-.11,1.2-.16,1.82c-.07,1.21-.21,2.43-.36,3.64h0c-.67,2.46-1.07,5-1.64,7.46s-1,5-1.63,7.46h0c.18-1.27.41-2.52.62-3.77s.45-2.51.73-3.76c.58-2.48,1.33-4.92,1.81-7.42h0c.07-1.22.13-2.44.26-3.65a16.34,16.34,0,0,1,.23-1.83A16,16,0,0,1,42,2.36Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M39.7,20.9c-.14,1.29-.38,2.56-.61,3.84s-.6,2.52-.83,3.8l-.66,3.83c-.23,1.27-.48,2.54-.77,3.8h0L36.31,38v0c0,.41-.1.81-.14,1.22h0c0-.41,0-.82,0-1.23h0l.41-1.86h0c.21-1.27.47-2.54.75-3.81s.6-2.52.85-3.79.4-2.57.65-3.84.52-2.53.86-3.79Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M35.41,40.31a13.28,13.28,0,0,1-.21,2.95A4.6,4.6,0,0,1,35,44a1.33,1.33,0,0,1-.19.35,1.79,1.79,0,0,0-.22.28A2.56,2.56,0,0,0,34.3,46h0c-.19.88-.32,1.78-.52,2.66v0c0,.68,0,1.36-.08,2v0h0c-.2,1-.4,1.94-.66,2.9h0c.09-1,.23-2,.38-3v0q0-1,.09-2v0h0c.21-.88.48-1.74.7-2.62v0a3.53,3.53,0,0,1,.24-1.47,2.06,2.06,0,0,1,.2-.33,1.44,1.44,0,0,0,.13-.31c.06-.23.12-.46.16-.7.19-1,.29-1.92.46-2.89Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M45.34,2.83a12.12,12.12,0,0,1-.19,2.09A10.4,10.4,0,0,0,44.81,7a19.44,19.44,0,0,1-.67,4.14h0A19.41,19.41,0,0,1,44.63,7a9.71,9.71,0,0,0,.26-2.08,12,12,0,0,1,.41-2Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M44.32,2.78c-.18,1.68-.44,3.35-.71,5s-.67,3.32-.91,5-.49,3.34-.77,5-.39,3.35-.65,5h0l-.43,1.43v0c0,.55-.05,1.1-.08,1.65h0c0-.55,0-1.1,0-1.65h0L41,22.77v0c.07-.84.13-1.69.23-2.53a19.43,19.43,0,0,1,.35-2.51c.3-1.66.68-3.32,1-5s.48-3.35.75-5,.57-3.33.95-5Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M42.56,10.29c-.08.88-.25,1.74-.39,2.61s-.41,1.71-.51,2.58-.17,1.76-.34,2.63-.36,1.72-.6,2.57v0c-.13.85-.26,1.69-.45,2.53h0c0-.86.1-1.71.17-2.57h0L41,18.05a20.68,20.68,0,0,0,.52-2.58c.11-.87.16-1.76.33-2.62a22.44,22.44,0,0,1,.64-2.57Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M39.07,36.86c-.1,1.13-.29,2.24-.47,3.35h0c-.07.74-.16,1.48-.26,2.22s-.15,1.49-.2,2.23h0c-.26,1.53-.53,3.07-.85,4.59s-.69,3-1.1,4.53h0c.23-1.54.48-3.08.8-4.6s.68-3,1.06-4.54h0c.08-.74.14-1.48.17-2.23s.06-1.49.12-2.24h0c.22-1.1.43-2.21.72-3.3Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M40,26.32c-.16,1.25-.34,2.5-.55,3.74h0c-.19.61-.39,1.21-.6,1.82v0c-.3,1.23-.63,2.45-.89,3.68s-.5,2.47-.75,3.71v0c-.08,1.68-.18,3.35-.31,5h0c-.33,1.59-.62,3.18-1,4.76h0c.2-1.61.47-3.21.71-4.82v0c.1-1.67.22-3.35.37-5h0c.3-1.23.62-2.45.9-3.68s.49-2.48.73-3.71h0c.19-.6.39-1.21.6-1.81v0c.23-1.24.48-2.48.77-3.71Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M34.41,53.59a4.75,4.75,0,0,1,.05-1.36,4.37,4.37,0,0,0,.22-1.34,7.84,7.84,0,0,1,.41-2.67h0a8.11,8.11,0,0,1-.27,2.69,4.39,4.39,0,0,0-.12,1.35,4.73,4.73,0,0,1-.29,1.33Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M35.22,53.73a1.45,1.45,0,0,1,.05-.75,1.34,1.34,0,0,0,.21-.72,2.45,2.45,0,0,1,.4-1.43h0a2.55,2.55,0,0,1-.26,1.47,1.34,1.34,0,0,0-.12.74,1.5,1.5,0,0,1-.28.7Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M42.69,19c-.09,1.19-.25,2.38-.41,3.56s-.44,2.36-.63,3.54h0a23.28,23.28,0,0,0-.85,2.81c-.16.95-.26,1.94-.4,2.9s-.29,1.95-.47,2.91-.34,1.93-.55,2.9h0c.18-2,.42-3.9.72-5.85.17-1,.29-1.93.52-2.9s.65-1.87,1-2.8h0c.17-1.18.26-2.38.46-3.56s.39-2.36.66-3.53Z"
      style={{ fill: '#b2b2b2' }}
    />

    <polygon
      points="57.31 56.47 57.77 20.61 57.78 2.54 51.41 2.76 51.41 56.47 57.31 56.47"
      style={{ fill: '#e0e0e0' }}
    />

    <path d="M57.16,2.9l.15.53.22,4.18.2,12.92-.42,17.82V54.26L57,55.42l-1.5.16H52.21L52,53.9l.33-5V23.75L52.21,2.92l4.51-.16h.59M57,1.17c-.3,0-2.71,0-2.88,0s-1.75,0-1.75,0a1.86,1.86,0,0,0-1.62,1.59s-.27,4.65-.18,6.41-.35,13.62-.35,15.21.18,16.79.26,15,0,9.1.27,10.07a49,49,0,0,1,0,6.22A1.34,1.34,0,0,0,52,57h5.27c.73,0,1.48-.59,1.33-1.33s.7-2.86.55-3.56-.49-6.81-.55-7.78.53-8.67.38-9.73.14-20.16.08-20.51c-.11-.65-.53-8.83-.53-8.83l.07-2.53a1.33,1.33,0,0,0-1.33-1.32S57.16,1.18,57,1.17Z" />

    <path
      d="M53.21,3c0,.61,0,1.22,0,1.83s.11,1.2.17,1.81c.14,1.21.21,2.43.28,3.65h0c-.23,2.54-.18,5.09-.31,7.63s-.13,5.09-.3,7.64h0c0-1.28,0-2.55,0-3.83s0-2.54.06-3.82c.14-2.54.44-5.08.47-7.63h0c-.15-1.21-.3-2.42-.38-3.63A16.08,16.08,0,0,1,53,4.8,14.09,14.09,0,0,1,53.18,3Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M54.17,21.62c.08,1.29.07,2.59.06,3.89s-.14,2.59-.14,3.88l0,3.89c0,1.29,0,2.59-.09,3.88h0l-.2,1.89v0c0,.41,0,.82.07,1.23h0c-.06-.4-.14-.8-.2-1.21h0l.08-1.9h0c0-1.3,0-2.59.07-3.89s.15-2.59.17-3.88,0-2.59,0-3.89.07-2.59.19-3.88Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M53.34,41.48a13.23,13.23,0,0,1,.31,2.94,4.78,4.78,0,0,1-.08.75,2,2,0,0,1-.14.38,2.29,2.29,0,0,0-.16.31,2.45,2.45,0,0,0,0,1.42h0c0,.9,0,1.8,0,2.71v0c.12.67.21,1.35.29,2v0h0c0,1-.05,2-.15,3h0c-.09-1-.12-2-.14-3v0c-.1-.68-.19-1.35-.26-2v0h0c.06-.9.17-1.8.24-2.7v0a3.56,3.56,0,0,1,0-1.49,1.69,1.69,0,0,1,.14-.36,1.6,1.6,0,0,0,.07-.33c0-.23,0-.47,0-.71,0-1,0-2-.06-2.93Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M56.55,2.84a11.35,11.35,0,0,1,.18,2.09,10.53,10.53,0,0,0,0,2.1,19.56,19.56,0,0,1,.06,4.19h0A19.51,19.51,0,0,1,56.58,7a10.57,10.57,0,0,0-.11-2.1,10.57,10.57,0,0,1,.05-2.1Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M55.54,3c.12,1.69.16,3.38.18,5.07s-.08,3.38,0,5.07.1,3.38.11,5.07.21,3.36.24,5.05v0h0l-.17,1.48v0c.08.55.14,1.09.21,1.64h0c-.11-.54-.24-1.07-.34-1.61h0l.1-1.49v0c-.08-.84-.16-1.69-.21-2.53a21.49,21.49,0,0,1-.1-2.54c0-1.69.09-3.38.09-5.07S55.47,9.73,55.44,8s0-3.38.07-5.07Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M55.13,10.67c.07.89.06,1.76.07,2.64s-.11,1.76-.06,2.63.14,1.76.13,2.65-.05,1.76-.14,2.64v0c0,.85,0,1.71,0,2.57h0c-.14-.85-.21-1.7-.28-2.56h0v0L55,18.58A19.26,19.26,0,0,0,55.05,16c0-.88-.16-1.76-.14-2.64a23.43,23.43,0,0,1,.19-2.64Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M56.34,37.45c.1,1.12.11,2.25.13,3.38v0c.07.75.11,1.49.14,2.24s.1,1.49.19,2.23h0c0,1.55,0,3.11,0,4.66s-.15,3.11-.29,4.66h0c-.05-1.56-.06-3.11,0-4.67s.14-3.1.25-4.66h0c0-.74-.12-1.49-.22-2.23s-.21-1.48-.27-2.22h0c0-1.13,0-2.26.12-3.38Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M55.44,26.9c.07,1.26.1,2.52.11,3.78h0q-.12.94-.27,1.89h0c-.08,1.26-.19,2.52-.23,3.78S55,38.88,55,40.14v0c.22,1.66.41,3.33.58,5h0c0,1.62-.06,3.25-.15,4.87h0c-.09-1.62-.1-3.25-.15-4.87v0c-.2-1.66-.38-3.33-.52-5v0h0c.08-1.26.18-2.52.24-3.78s0-2.52.06-3.78h0c.09-.63.17-1.26.28-1.89v0c0-1.26,0-2.52.11-3.78Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M54.68,54.73a4.82,4.82,0,0,1-.18-1.35,4.7,4.7,0,0,0,0-1.35,8,8,0,0,1-.07-2.71h0a8.19,8.19,0,0,1,.21,2.7,4.41,4.41,0,0,0,.11,1.35,4.78,4.78,0,0,1,0,1.36Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M55.51,54.73a1.52,1.52,0,0,1-.09-.75,1.35,1.35,0,0,0,.08-.74,2.63,2.63,0,0,1,.14-1.49h0a2.41,2.41,0,0,1,0,1.48,1.28,1.28,0,0,0,0,.75,1.49,1.49,0,0,1-.15.74Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M56.77,19.19c.12,1.19.17,2.38.22,3.58s0,2.39,0,3.59h0a23.82,23.82,0,0,0-.35,2.91c0,1,.09,2,.12,2.93s.06,2,.05,2.95,0,2,0,3h0c-.16-2-.27-3.92-.31-5.89,0-1,0-2,0-2.95s.31-1.95.45-2.92h0c0-1.2-.16-2.39-.17-3.58s0-2.4,0-3.59Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M61.62,2.37c7.19,0,13.17,6.28,13.17,13.57A13.3,13.3,0,0,1,61.58,29.16V23.67A7.59,7.59,0,0,0,69.11,16a7.6,7.6,0,0,0-7.54-7.68Z"
      style={{ fill: '#e0e0e0' }}
    />

    <path d="M61.62,2.37c7.19,0,13,6.28,13,13.57A13.19,13.19,0,0,1,61.58,29.16V23.67A7.59,7.59,0,0,0,69.11,16a7.67,7.67,0,0,0-4.26-6.92,7.5,7.5,0,0,0-3.28-.76V2.76m.09-2c-.73,0-1.19,1.25-1.19,2l0,5.43c0,.35,0,.81.2,1.06a1.33,1.33,0,0,0,.94.39,5.69,5.69,0,0,1,4.23,1.71,7.62,7.62,0,0,1,2,4.64,6,6,0,0,1-4.18,5.79,6.45,6.45,0,0,1-2,.56c-.35,0-.36.06-.61.31a1.15,1.15,0,0,0-.35.88l0,2.83.09,2.83c0,.73.2,1.29.93,1.29a15.33,15.33,0,0,0,5.92-.9A17.74,17.74,0,0,0,73.33,25c2-2.52,2.66-5.58,2.66-9.2a19.56,19.56,0,0,0-.53-5.3C74.65,8,72.66,6,70.77,4.31a14.15,14.15,0,0,0-5.57-3C64,1,64.37.78,63.08.78Z" />

    <path
      d="M62.18,3.49a5.14,5.14,0,0,1,1.24.24,2.66,2.66,0,0,0,1.2.3,7,7,0,0,1,2.49.27v0A6.83,6.83,0,0,1,64.6,4.2,6.66,6.66,0,0,0,63.35,4a3.74,3.74,0,0,1-1.18-.47Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M61.82,5.09A7.46,7.46,0,0,1,63.71,5c.63.06,1.26.16,1.89.19a15.75,15.75,0,0,1,3.73.58v0a15.77,15.77,0,0,1-3.75-.45,6,6,0,0,0-.93-.15c-.32,0-.63,0-1,0a10.81,10.81,0,0,1-1.88-.09Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M62.18,5.83a3.58,3.58,0,0,1,1.13.09,5.61,5.61,0,0,1,1.08.32,3.2,3.2,0,0,0,1.06.28,1.72,1.72,0,0,0,1-.25l0,0h0c.62,0,1.23.07,1.84.14h0c.42.14.83.29,1.24.45h0c.35.09.69.19,1,.31v0c-.36,0-.72-.06-1.08-.1h0c-.42-.11-.85-.23-1.27-.37h0c-.61-.07-1.22-.16-1.83-.28h.06a2.86,2.86,0,0,1-1.14.2,5.46,5.46,0,0,1-1.13-.14c-.72-.21-1.4-.47-2.13-.63Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M61.85,7.68a13.32,13.32,0,0,1,2.51-.29c.43,0,.85,0,1.27,0a10.76,10.76,0,0,1,1.27,0,28,28,0,0,1,4.95,1.06v0a28.86,28.86,0,0,1-5-.91,7.51,7.51,0,0,0-2.5.06,19.39,19.39,0,0,1-2.53.06Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M65.27,9a9.67,9.67,0,0,1,1.94,0c.64.09,1.28.2,1.92.28a16.36,16.36,0,0,1,3.78.82v0a16.91,16.91,0,0,1-3.81-.67,4.4,4.4,0,0,0-.94-.21c-.32,0-.65,0-1,0A9.7,9.7,0,0,1,65.27,9Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M63,24a15.9,15.9,0,0,1,2.36-.47,14.63,14.63,0,0,0,2.37-.3h0a5.81,5.81,0,0,1,.92-.5,2.54,2.54,0,0,1,.51-.17,3.49,3.49,0,0,1,.53-.05c.68,0,1.36,0,2,0h0c.27-.09.53-.16.8-.23l0,0-.75.38h0c-.68.08-1.36.13-2,.16a3.78,3.78,0,0,0-.49,0,2,2,0,0,0-.46.13,8.33,8.33,0,0,0-.94.37h0a14.88,14.88,0,0,0-2.37.41A15.73,15.73,0,0,1,63,24Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M62.19,24.41c.31.13.62.28.92.42h-.06a11.45,11.45,0,0,1,1.55,0h0a10.25,10.25,0,0,0,2.14-.24c.7-.13,1.39-.31,2.1-.42h0a18.07,18.07,0,0,1,2.32.15v0a18.07,18.07,0,0,1-2.32.15h0a17.17,17.17,0,0,1-2.14.28,10.91,10.91,0,0,0-2.13.28h0a13.36,13.36,0,0,1-1.55,0h0l0,0a9.45,9.45,0,0,1-.82-.59Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M62.17,26.14a3.07,3.07,0,0,1,.26-.25h0a8.7,8.7,0,0,1,1.84,0h0a8.33,8.33,0,0,0,1.11.2c.37,0,.75,0,1.12.07h0a15.25,15.25,0,0,1,3.1-.19v0a14.55,14.55,0,0,1-3.08.42h0c-.37-.06-.73-.17-1.1-.23a7,7,0,0,0-1.12-.11h0a8.08,8.08,0,0,1-1.84,0h0l-.32.16Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M67.69,20.75a6.32,6.32,0,0,1,.6-.25c.21-.05.41-.13.62-.17s.43,0,.65-.08a3.6,3.6,0,0,0,.63-.15h0c.42,0,.84,0,1.26,0h0a7.66,7.66,0,0,1,1.85,0v0a8.85,8.85,0,0,1-1.83.25h0c-.42,0-.84-.12-1.25-.2h0a4.15,4.15,0,0,0-.63.16,6.32,6.32,0,0,1-.61.22,4.7,4.7,0,0,1-.63.13,4.76,4.76,0,0,1-.66.06Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M68.37,12.23a4.8,4.8,0,0,1,1.49.62h0a3.76,3.76,0,0,0,1.06,0,9.47,9.47,0,0,1,1.06-.09h0a7.72,7.72,0,0,1,.93.2,4.75,4.75,0,0,1,.88.35v0a9.33,9.33,0,0,1-.94-.1c-.32,0-.62-.09-.93-.16h0A9.47,9.47,0,0,1,70.88,13a3.76,3.76,0,0,0-1.06,0h0a4.94,4.94,0,0,1-1.41-.8Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M69.53,15a2.68,2.68,0,0,1,.75,0,2.92,2.92,0,0,1,.71.22h0A4.24,4.24,0,0,0,72,15.15h0a5,5,0,0,1,1.6.82v0a4.79,4.79,0,0,1-1.7-.6H72A4.24,4.24,0,0,0,71,15.35h0a3.05,3.05,0,0,1-.74-.08,3.74,3.74,0,0,1-.7-.27Z"
      style={{ fill: '#b2b2b2' }}
    />

    <path
      d="M69.14,17.56a3.76,3.76,0,0,1,.84-.33h0a8,8,0,0,1,1,0h0a3.8,3.8,0,0,0,.75.06,4.7,4.7,0,0,1,.74.1,6,6,0,0,1,1.41.48v0a5.54,5.54,0,0,1-1.47-.19l-.72-.19a3.55,3.55,0,0,0-.71-.19h0c-.32,0-.63.18-1,.19h0a3.82,3.82,0,0,1-.9.09Z"
      style={{ fill: '#b2b2b2' }}
    />
  </svg>
);

export default WPIcon;
