//
import * as types from './types';

export const fetchAssessmentResourcesRequest = (): types.FETCH_ASSESSMENT_RESOURCES_REQUEST_ACTION => ({
  type: types.FETCH_ASSESSMENT_RESOURCES_REQUEST
});
export const fetchAssessmentResourcesSuccess = (assessmentResources: any) => ({
  type: types.FETCH_ASSESSMENT_RESOURCES_SUCCESS,
  assessmentResources
});
export const fetchAssessmentResourcesFailure = (
  error: any
): types.FETCH_ASSESSMENT_RESOURCES_FAILURE_ACTION => ({
  type: types.FETCH_ASSESSMENT_RESOURCES_FAILURE,
  error
});
