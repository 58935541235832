import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import WorkbookTourModal from './WorkbookTourModal';
import { LIGHT_ME_UP, getElement } from '../util';
import { getCurrentStep } from 'modules/ftue/selectors';
import { advanceTourStep, queueTour } from 'modules/ftue';
import ScreenOverlay from 'components/core/ScreenOverlay';

export type ZindexState = [string, string];

enum TOUR_DOM_IDS {
  MAIN_NAV_BAR = 'mainNavbar',
  BOTTOM_NAV_BAR = 'bottomNavbar'
}

const WorkbookTour = () => {
  const [prevElZindex, setZindexOverrides] = useState<ZindexState>([] as any);

  const tourState = useSelector(({ ftue }: any) => ftue);
  const dispatch = useDispatch() as any;

  const currentStep = getCurrentStep(tourState);

  const handleNextStep = () => {
    const nextEl = getElement(currentStep?.name, STEP_MAP);
    const prevEl = getElement(prevElZindex[0], STEP_MAP);

    if (nextEl) nextEl.style.pointerEvents = 'unset';
    if (prevEl) prevEl.style.zIndex = String(prevElZindex[1]);
    dispatch(advanceTourStep());
  };

  const STEP_MAP = {
    present: { domId: TOUR_DOM_IDS.MAIN_NAV_BAR },
    resources: { domId: TOUR_DOM_IDS.MAIN_NAV_BAR },
    bottomNav: { domId: TOUR_DOM_IDS.BOTTOM_NAV_BAR }
  };

  const resetAllElements = () => {
    const domIdObjArray = Object.values(STEP_MAP);
    const domIds = domIdObjArray.map(domIdObj => domIdObj.domId);

    domIds?.forEach(domId => {
      const el = document.getElementById(domId);
      if (!el) return;

      el.style.pointerEvents = 'unset';
      el.style.zIndex = domId === TOUR_DOM_IDS.BOTTOM_NAV_BAR ? '7' : 'unset';
    });
  };

  useEffect(() => {
    if (!tourState.queuedTours.includes('workbook')) {
      dispatch(queueTour('workbook', true));
    }
  }, []);

  useEffect(() => {
    if (!currentStep) {
      resetAllElements();
      return;
    }

    const { name } = currentStep;
    const prevEl = getElement(prevElZindex[0], STEP_MAP);

    if (prevEl && prevElZindex[0] !== name) {
      prevEl.style.zIndex = String(prevElZindex[1]);
      prevEl.style.pointerEvents = 'none';

      setZindexOverrides([] as any);
    }

    const nextEl = getElement(name, STEP_MAP);

    if (nextEl && String(nextEl.style.zIndex) !== String(LIGHT_ME_UP)) {
      setZindexOverrides([name, nextEl.style.zIndex ?? 'inherit']);
      if (prevEl) prevEl.style.pointerEvents = 'unset';
      nextEl.style.zIndex = String(LIGHT_ME_UP);
      nextEl.style.pointerEvents = 'none';
    }

    return () => resetAllElements();
  }, [currentStep]);

  if (!currentStep) return null;

  return (
    <ScreenOverlay show>
      <WorkbookTourModal handleNextStep={handleNextStep} step={currentStep} />
    </ScreenOverlay>
  );
};

export default WorkbookTour;
