//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../../util/style-util';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import downloadIcon from '../../../images/icons/downloads-icon.png';
import animation from '../../../images/icons/prekitt-animation.png';
import clickAway from '../../../images/icons/prekitt-clickaway.png';
import LNF from '../../../images/icons/prekitt-lnf.png';
import music from '../../../images/icons/prekitt-music.png';
import natureVideo from '../../../images/icons/prekitt-nature-video.png';
import teacherVideo from '../../../images/icons/prekitt-teacher-video.png';
import WDT from '../../../images/icons/prekitt-wdt.png';
import woodPieces from '../../../images/icons/prekitt-wood-pieces.png';
import audio from '../../../images/icons/prekitt-audio.png';
import PreKittMessageFrame from './PreKittMessageFrame';
const translateSupport = key => translate('support', key);

type IconListItem = {
  id: string;
  title: string;
  description: string;
  icon: string;
};

const styles = StyleSheet.create({
  iconLegend: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100vh',
    width: remCalc(600),
    maxHeight: remCalc(600),
    padding: '0 20px 20px'
  },
  iconList: {
    maxHeight: remCalc(550),
    listStyle: 'none',
    padding: 0,
    margin: 0,
    overflow: 'auto'
  },
  iconListItem: {
    display: 'flex',
    alignItems: 'center',
    padding: remCalc(10),
    marginBottom: remCalc(25)
  },
  iconText: {
    marginLeft: remCalc(10)
  },
  iconTitle: {
    fontWeight: 'bold',
    margin: 0
  },
  download: {
    textAlign: 'right',
    fontWeight: 500,
    fontSize: 20,
    margin: 0
  },
  downloadLink: {
    padding: remCalc(0, 8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  downloadIcon: {
    width: remCalc(32),
    height: remCalc(32),
    marginRight: remCalc(2)
  }
});

const ICON_ITEMS = [
  {
    id: 'clickAway',
    title: 'clickAway',
    description: 'clickAwayDescription',
    icon: clickAway
  },
  {
    id: 'animation',
    title: 'animations',
    description: 'animationsDescription',
    icon: animation
  },
  {
    id: 'connectionVideos',
    title: 'connections',
    description: 'connectionsDescription',
    icon: natureVideo
  },
  {
    id: 'letterNumberFormation',
    title: 'letterFormation',
    description: 'letterFormationDescription',
    icon: LNF
  },
  {
    id: 'liveTeachingVideos',
    title: 'liveTeaching',
    description: 'liveTeachingDescription',
    icon: teacherVideo
  },
  {
    id: 'matman',
    title: 'matman',
    description: 'matmanDescription',
    icon: audio
  },
  {
    id: 'music',
    title: 'music',
    description: 'musicDescription',
    icon: music
  },
  {
    id: 'wetDryTry',
    title: 'wdt',
    description: 'wdtDescription',
    icon: WDT
  },
  {
    id: 'woodPieces',
    title: 'woodPieces',
    description: 'woodPiecesDescription',
    icon: woodPieces
  }
];

const renderIconItem = ({ id, title, description, icon }: IconListItem) => (
  <li key={id} id={id} className={css(styles.iconListItem)}>
    <img src={icon} alt={translateSupport(title)} />
    <div className={css(styles.iconText)}>
      <h3 className={css(styles.iconTitle)}>{translateSupport(title)}</h3>
      <p>{translateSupport(description)}</p>
    </div>
  </li>
);

const IconModal = (props: any) => {
  return (
    <PreKittMessageFrame>
      <div className={css(styles.iconLegend)}>
        <p className={css(styles.download)}>
          <a
            href={props.iconLegendURL}
            className={css(styles.downloadLink)}
            target="multimediaIconsPdf"
            download
          >
            <img src={downloadIcon} className={css(styles.downloadIcon)} alt="" />
            <p>{translateSupport('download')}</p>
          </a>
        </p>
        <ul className={css(styles.iconList)} tabIndex={0}>
          {ICON_ITEMS.map(renderIconItem)}
        </ul>
      </div>
    </PreKittMessageFrame>
  );
};

export default IconModal;
