import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isTrialUser, sendEmailWelcome } from '../../modules/user';
import { AppStoreState } from 'types';
import { translate, tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import BaseModalTitle from '../core/Modals/BaseModalTitle';
import Welcome from '../core/Modals/Welcome';
import { useModal, TitleProps } from '@lwtears/lwt-common-frontend/lib/Modal';
import { queueTour } from 'modules/ftue';
import { toggleSlideoutMenu, toggleSlideoutMenuTutorial } from 'actions/ui-actions';
import ExpiredLicenseModal from 'components/core/Modals/ExpiredLicenseModal';

type TUseLoginMessaging = [(handleAfterClose: () => void) => void];
const translateModals = key => translate('modals', key);

const useLoginMessaging = (): TUseLoginMessaging => {
  const dispatch = useDispatch() as any;
  const user = useSelector(({ user }: AppStoreState) => user);
  const lastLoginDate = !!user.settings?.lastLoginDate ?? false;
  const [, { openModal }] = useModal();

  const handleAfterClose = useCallback(() => {
    if (!lastLoginDate) {
      dispatch(toggleSlideoutMenu(true));
      dispatch(toggleSlideoutMenuTutorial(true));
    }

    dispatch(sendEmailWelcome());
    dispatch(queueTour('intro'));
  }, [dispatch, lastLoginDate]);

  const invokeModalMessaging = useCallback(() => {
    const returningUser = user?.settings?.licensing;
    const viewedWelcome = user.settings?.licensing?.viewedWelcome ?? false;
    const hasExpiredLicense = user.licenses.filter(license => license.expired === true).length > 0;
    const hasValidLicenseAndReturning = !hasExpiredLicense && (returningUser || viewedWelcome);

    if (!user?.licenses.length) return;
    if (hasValidLicenseAndReturning) return;

    if (hasExpiredLicense) {
      if (!user.settings?.suppressedExpirationAlerts) {
        openModal({
          body: (
            <ExpiredLicenseModal
              isClickAwayOnly={!!user.clickAwayAccess.length}
              hasWorkbooks={!hasExpiredLicense}
            />
          ),
          ariaLabel: tA11y('aria.expiredLicense')
        });
      }
      return;
    }

    if (isTrialUser(user)) {
      openModal({
        Title: (props: TitleProps) => (
          <BaseModalTitle title={translateModals('trialWelcome')} {...props} />
        ),
        body: <Welcome />,
        handleAfterClose,
        ariaLabel: tA11y('aria.modal', { title: translateModals('trialWelcome') })
      });
      return;
    }

    openModal({
      Title: (props: TitleProps) => (
        <BaseModalTitle title={translateModals('welcome')} {...props} />
      ),
      body: <Welcome />,
      ariaLabel: tA11y('aria.modal', { title: translateModals('welcome') }),
      handleAfterClose
    });
  }, [user, openModal, handleAfterClose]);

  return [invokeModalMessaging];
};

export type TLoginMessagingDispatch = {
  Component: any;
  loginMessagingDispatch: { invokeModalMessaging: () => void };
};

const WrappedComponent: React.FC<TLoginMessagingDispatch> = ({ Component, ...props }) => {
  const [invokeModalMessaging] = useLoginMessaging();

  return <Component {...props} loginMessagingDispatch={{ invokeModalMessaging }} />;
};

const withLoginMessaging = Component => props => {
  return <WrappedComponent {...props} Component={Component} />;
};

export { useLoginMessaging, withLoginMessaging };
