import { UNAUTHORIZED, FORBIDDEN } from 'http-status';
import LwtApi, { AuthStrategy } from '@lwtears/lwt-common-frontend/lib/@common/util/api-util';
import { Header } from '@lwtears/lwt-common-frontend/lib/@common/util/header-util';
import { getLocale } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { hasAuthCookies, removeAuthCookies } from '../util/auth-cookie-util';

const getHeaders = () => ({ [Header.LOCALE]: getLocale() });

const Api = LwtApi()
  .addDefaultAuthStrategy(
    hasAuthCookies() ? AuthStrategy.AUTH_TOKEN : AuthStrategy.HTTP_AUTHENTICATION
  )
  .addHeaders(getHeaders)
  .options({ throwErrors: true })
  .onError(error => {
    if ([UNAUTHORIZED, FORBIDDEN].includes(error?.status) && hasAuthCookies()) {
      removeAuthCookies();
      window.location.reload();
      return;
    }

    throw error;
  });

export default {
  Api,
  getUserProfile(params) {
    return Api.get('/users/me', { params });
  },
  getUserFavorites(params) {
    return Api.get('/users/me/favorites', { params });
  },
  createUserFavorite(favorite) {
    return Api.post('/users/me/favorites', { data: favorite });
  },
  deleteUserFavorite(id) {
    return Api.destroy(`/users/me/favorites/${id}`);
  },
  getUserSettings() {
    return Api.get('/users/me/settings');
  },
  putUserSettings(type, newSettings) {
    return Api.put('/users/me/settings', { data: newSettings });
  },
  putUserSetting(type, newSetting) {
    return Api.put(`/users/me/settings/${type}`, { data: newSetting });
  },
  putUserClickAwayAccess(bookCode, edition) {
    return Api.put('/users/me/access/click-aways', {
      data: { bookCode, edition }
    });
  },
  putProductAccess(bookId) {
    return Api.put('/users/me/access/products', { data: { bookId } });
  },
  getCopyrightTerms() {
    return Api.get('/users/me/copyright-terms');
  },
  putCopyrightTerms(productCopyrightTerms) {
    return Api.put('/users/me/copyright-terms', {
      data: productCopyrightTerms
    });
  },
  sendEmail(emailConfig) {
    return Api.post('/email', { data: emailConfig });
  },
  sendEmailLink() {
    return Api.post('/email/link');
  },
  sendEmailWelcome() {
    return Api.post('/email/free-trial-welcome');
  },
  getTourSteps(params = {}) {
    return Api.get('/tours', { params });
  },
  getUserTourSteps() {
    return Api.get('/users/me/tour');
  },
  putTourStep(tour) {
    return Api.put('/users/me/tour', { data: tour });
  },
  getBooks() {
    const params = { include: ['teachersGuides', 'activityBooks', 'products'] };
    return Api.get('/books', { params });
  },
  getBookContent(bookId, params = {}) {
    return Api.get(`/books/${bookId}/content`, { params });
  },
  getBookFilters(params = {}) {
    return Api.get('/filters', { params });
  },
  getBookPages(bookId, params = {}) {
    return Api.get(`/books/${bookId}/pages`, { params });
  },
  getResources(params = {}) {
    return Api.get('/resources', { params });
  },
  getApplicationResources(applicationId) {
    return Api.get(`/applications/${applicationId}/resources`);
  },
  getMobileResources(params = {}) {
    return Api.get('/resources', { params });
  },
  getSupportResources(resourceId) {
    const url = resourceId ? `/support-resources/${resourceId}` : '/support-resources';
    return Api.get(url);
  },
  getAssessmentResources(params = {}) {
    return Api.get('/assessments', { params });
  },
  getClickAways(params = {}) {
    return Api.get('/click-aways', { params });
  },
  createEvent(type, data) {
    Api.createTelemetryEvent({ payload: { type, data } });
  }
};
