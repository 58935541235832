import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Drawer, List, ListItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { dequeueTour, queueTour } from '../../../modules/ftue';

import getMenuListItems from './MenuListItems';
import { getCurrentWorkbook } from '../../../modules/workbook';
import { useParams } from 'react-router-dom';
import { toggleGlobalMenu, toggleSlideoutMenu, toggleSubmenu } from '../../../actions/ui-actions';
import LocaleDropdown from '../LocaleDropdown';
import GssLogo from '../../../images/gss-logo-white.svg';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { remCalc } from '../../../util/style-util';
import MenuList from '../MenuList';
import { onEscapeKeydown } from '../../../util/accessibility-util';
import { FOCUS_ACTIVE_WHITE_BORDER_MUI, PREKIT_FONT } from '../../../styles/style-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SlideoutMenuTutorialOverlay from '../SlideoutMenuTutorialOverlay';
import { showTourOverlay } from '../../../modules/ftue/selectors';
import useTabIndex from '@lwtears/lwt-common-frontend/lib/@common/hooks/app/use-tab-index';

const translateCommon = key => translate('common', key);
const NavigationDrawer = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch() as any;
  const uiState = useSelector(({ ui }: any) => ui);
  const currentWorkbook = useSelector(state => getCurrentWorkbook(state, params));
  const displayTourOverlay = useSelector(state => showTourOverlay(state, params));
  const [tabIndex, { setTabIndexOnKeyUp, setTabIndex }] = useTabIndex();

  useEffect(() => {
    if (uiState.globalMenuOpen) queueTour('globalNav', true);
    if (!uiState.globalMenuOpen) dequeueTour('globalNav');
    return () => setTabIndex(-1);
  }, [uiState.globalMenuOpen, setTabIndex]);

  const MaybeTutorialBackdropTour = displayTourOverlay
    ? {
        ModalProps: {
          disableEnforceFocus: true,
          disableAutoFocus: true,
          BackdropComponent: SlideoutMenuTutorialOverlay
        }
      }
    : undefined;

  return (
    <Drawer
      {...MaybeTutorialBackdropTour}
      aria-label={tA11y('aria.menu')}
      anchor="left"
      open={uiState.globalMenuOpen}
      onClose={() => dispatch(toggleGlobalMenu())}
      PaperProps={{ className: classes.slideoutMenuPaperStyles }}
      style={{ top: 60 }}
      onKeyDown={onEscapeKeydown(() => dispatch(toggleGlobalMenu(false)))}
      onKeyUp={setTabIndexOnKeyUp}
      role="main"
    >
      <List>
        <ListItem
          aria-label={tA11y('aria.closeMenu')}
          tabIndex={tabIndex}
          disableGutters={true}
          button
          className={classes.closeBtn}
          style={{ paddingLeft: 12 }}
          onClick={() => dispatch(toggleSlideoutMenu())}
        >
          <Box display="flex" flex={0.75} margin="0px 11px">
            <FontAwesomeIcon icon={faTimes} size="2x" />
            <Typography style={{ fontFamily: PREKIT_FONT, fontWeight: 300, margin: '2px 21px' }}>
              {translateCommon('closeMenu')}
            </Typography>
          </Box>
        </ListItem>

        <MenuList
          show={!uiState.workbooksMenuOpen}
          menuItems={getMenuListItems()}
          toggleMenu={() => dispatch(toggleSlideoutMenu())}
          activeColor={currentWorkbook.primaryColor}
          keepSubmenuState={uiState.globalMenuOpen}
          selectedSubmenu={uiState.selectedSubmenu}
          toggleSubmenu={val => dispatch(toggleSubmenu(val))}
        />

        <ListItem
          disableGutters={true}
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <LocaleDropdown />
          <img src={GssLogo} className={classes.menuLogo} alt={tA11y('alt.gssLogo')} />
        </ListItem>
      </List>
    </Drawer>
  );
};

export const useStyles = makeStyles({
  slideoutMenuPaperStyles: {
    backgroundColor: '#202020',
    backgroundSize: 'cover',
    color: 'white',
    minWidth: remCalc(270),
    maxWidth: '18rem',
    display: 'flex',
    height: `100%`,
    justifyContent: 'space-between',
    '&::-webkit-scrollbar': {
      backgroundColor: 'rgba(32, 32, 34, 1)'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: remCalc(15),
      backgroundColor: 'rgba(85, 85, 85, 1)',
      backgroundClip: 'content-box',
      border: '5px solid transparent'
    }
  },
  menuLogo: {
    width: '100%',
    marginTop: '5%',
    bottom: remCalc(20),
    padding: remCalc(15),
    borderTop: '1px solid black'
  },
  listContainer: {
    flex: '2 auto',
    overflowY: 'auto',
    position: 'relative',
    '::-webkit-scrollbar': {
      backgroundColor: 'rgba(32, 32, 34, 1)'
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: remCalc(15),
      backgroundColor: 'rgba(85, 85, 85, 1)',
      backgroundClip: 'content-box',
      border: '5px solid transparent'
    }
  },
  closeBtn: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 10,
    ...FOCUS_ACTIVE_WHITE_BORDER_MUI
  }
});

export default NavigationDrawer;
