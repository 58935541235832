import React from 'react';

type Props =
  | {
      fill: string;
      xColor: string;
      stroke: string;
      strokeWidth: string;
    }
  | any;

const UnpinIcon = ({ fill, xColor, strokeWidth }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.04 21.04">
    <circle cx="10.53" cy="10.55" r="10.32" style={{ fill }} />
    <circle cx="10.53" cy="10.55" r="9.72" style={{ fill: 'none', stroke: xColor, strokeWidth }} />
    <path
      d="M14,15.18l-3.35-3.36L7.27,15.18,6.16,14.06l3.35-3.35L6.16,7.36,7.27,6.24l3.35,3.35L14,6.24l1.12,1.12-3.35,3.35,3.35,3.35Z"
      style={{ fill: xColor }}
    />
  </svg>
);
UnpinIcon.defaultProps = {
  fill: '#FFF',
  xColor: '#656565',
  strokeWidth: '1.2000000476837158px'
};

export default UnpinIcon;
