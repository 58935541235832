//
import React from 'react';

const DrawnCircleXIcon = ({ ...props }: any) => (
  <svg {...props} x="0px" y="0px" viewBox="0 0 38 38.5">
    <g transform="translate(923 509)">
      <g transform="translate(1.227 2.632)">
        <path
          style={{ fill: '#FFF' }}
          d="M-913.2-475.6c-1.3-0.5-2.5-1.2-3.6-2c-1.1-0.9-2-1.9-2.9-3c-0.9-1-1.7-2.2-2.3-3.4
          c-0.7-1.2-1.3-2.5-1.6-3.8c-0.3-1.4-0.4-2.8-0.4-4.2c0.1-1.4,0.2-2.8,0.6-4.1c0.4-1.3,0.9-2.6,1.6-3.8c0.6-1.2,1.3-2.4,2.1-3.5
          c0.8-1.1,1.8-2.1,2.8-3c1.1-0.9,2.2-1.6,3.4-2.2c1.1-0.8,2.4-1.4,3.7-1.9c1.4-0.2,2.8-0.3,4.2-0.2c1.4-0.2,2.8-0.2,4.2-0.1
          c1.4,0.3,2.7,0.7,4,1.3c1.3,0.6,2.4,1.4,3.6,2.2c1.1,0.9,2.1,1.9,3,2.9c0.9,1.1,1.1,2.6,1.6,3.8c0.7,1.2,1.3,2.4,1.8,3.7
          c0.3,1.4,0.5,2.7,0.5,4.1c-0.1,1.4-0.3,2.8-0.7,4.1c-0.2,1.4-0.5,2.8-0.9,4.1c-0.7,1.2-1.5,2.4-2.5,3.4c-0.8,1.2-1.7,2.2-2.7,3.2
          c-1.2,0.8-2.4,1.4-3.7,2c-1.2,0.7-2.4,1.3-3.8,1.8c-1.3,0.4-2.7,0.7-4.1,0.8c-1.4,0-2.8-0.2-4.2-0.5
          C-910.6-474.4-911.9-475-913.2-475.6z"
        />
      </g>
      <g transform="translate(1.906 2.332)">
        <path
          d="M-901-510.1c-1.6-0.6-3.2-0.8-4.9-0.9c-6.3,0-11.9,4.2-14.9,8.9c-4.5,7-4.3,15.7,0.6,21.6
          c4.9,6.1,13.3,8.2,20.5,5.2c3.9-1.8,9-5.8,11-10.4c0.6-1.4,0.8-2.9,0.8-4.4v-0.1l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3
          c0.8-3.6-0.3-7.8-3.3-11.7C-893.5-505.9-897-508.6-901-510.1z M-890.6-498.9l1.2,2.5c0,0,1.3,5.8-1.1,10
          c-2.5,4.1-6.1,7.4-10.4,9.6c-1.9,0.8-4,1.3-6.1,1.3c-2.7,0-5.3-0.7-7.6-2c-4.1-2.2-7-6.2-7.8-10.8c-1.3-8.2,4.1-17.3,12.1-20.2
          c4.9-1.8,10.5-0.6,14.9,3.3C-893.4-503.4-891.7-501.3-890.6-498.9z"
        />
      </g>
      <g transform="translate(8.184 10.75)">
        <g transform="translate(0.199 0.151)">
          <g transform="translate(0 5.31)">
            <g transform="translate(0.105 5.033)">
              <path
                d="M-922.9-503.3c1.2-0.8,2.3-1.9,3.3-3c0.5-0.5,1-1,1.5-1.6c0.6-0.5,1.1-1.1,1.5-1.8
                    c0.2-0.4,0-1-0.4-1.2c-0.3-0.1-0.6-0.1-0.8,0c-0.6,0.5-1.2,1-1.7,1.6c-0.5,0.5-1,1-1.5,1.6c-1.1,1-2.1,2.1-2.9,3.4
                    c-0.2,0.4-0.1,0.8,0.3,1C-923.4-503.2-923.1-503.2-922.9-503.3z"
              />
            </g>
            <g>
              <path
                d="M-923.9-510.2c0.7,1,1.6,1.9,2.6,2.7c0.9,0.9,1.9,1.8,2.9,2.6c0.4,0.3,0.9,0.3,1.2-0.1
                    c0.3-0.3,0.3-0.8,0-1.2c-0.9-0.9-2-1.8-3-2.7c-0.9-0.9-1.9-1.6-3.1-2.1c-0.3-0.1-0.6,0.1-0.7,0.4
                    C-924-510.5-924-510.3-923.9-510.2z"
              />
            </g>
          </g>
          <g transform="translate(4.337)">
            <g transform="translate(0 0.127)">
              <path
                d="M-923.9-509.9c0.8,1.2,1.9,2.3,3,3.3c0.5,0.5,1,1,1.6,1.5c0.5,0.6,1.1,1.1,1.8,1.5
                    c0.4,0.2,1,0,1.2-0.4c0.1-0.3,0.1-0.6,0-0.8c-0.5-0.6-1-1.2-1.6-1.7c-0.5-0.5-1-1-1.6-1.5c-1-1.1-2.1-2.1-3.4-2.9
                    c-0.4-0.2-0.8-0.1-1,0.3C-924-510.4-924-510.1-923.9-509.9L-923.9-509.9z"
              />
            </g>
            <g transform="translate(6.517)">
              <path
                d="M-918.5-510.9c-1,0.7-1.9,1.6-2.7,2.6c-0.9,0.9-1.8,1.9-2.6,2.9
                    c-0.3,0.4-0.3,0.9,0.1,1.2c0.3,0.3,0.8,0.3,1.2,0c0.9-0.9,1.8-2,2.7-3c0.9-0.9,1.6-1.9,2.1-3.1c0.1-0.3-0.1-0.6-0.4-0.7
                    C-918.3-511-918.4-511-918.5-510.9L-918.5-510.9z"
              />
            </g>
          </g>
          <g transform="translate(4.291 3.637)">
            <g transform="translate(9.536)">
              <path
                d="M-917.6-510.9c-1.2,0.8-2.3,1.9-3.3,3c-0.5,0.5-1,1-1.5,1.6c-0.6,0.5-1.1,1.1-1.5,1.8
                    c-0.2,0.4,0,1,0.4,1.2c0.3,0.1,0.6,0.1,0.8,0c0.6-0.5,1.2-1,1.7-1.6c0.5-0.5,1-1,1.5-1.6c1.1-1,2.1-2.1,2.9-3.4
                    c0.2-0.4,0.1-0.8-0.3-1C-917.1-511-917.4-511-917.6-510.9L-917.6-510.9z"
              />
              <g id="Group_545" transform="translate(0.502 6.517)">
                <path
                  d="M-917-505.5c-0.7-1-1.6-1.9-2.6-2.7c-0.9-0.9-1.9-1.8-2.9-2.6c-0.4-0.3-0.9-0.3-1.2,0.1
                    c-0.3,0.3-0.3,0.8,0,1.2c0.9,0.9,2,1.8,3,2.7c0.9,0.9,1.9,1.6,3.1,2.1c0.3,0.1,0.6-0.1,0.7-0.4
                    C-916.9-505.3-916.9-505.4-917-505.5L-917-505.5z"
                />
              </g>
            </g>
            <g transform="translate(0 10.513)">
              <g transform="translate(5.033)">
                <path
                  d="M-916.3-504.6c-0.8-1.2-1.9-2.3-3-3.3c-0.5-0.5-1-1-1.6-1.5c-0.5-0.6-1.1-1.1-1.8-1.5
                    c-0.4-0.2-1,0-1.2,0.4c-0.1,0.3-0.1,0.6,0,0.8c0.5,0.6,1,1.2,1.6,1.7c0.5,0.5,1,1,1.6,1.5c1,1.1,2.1,2.1,3.4,2.9
                    c0.4,0.2,0.8,0.1,1-0.3C-916.2-504.1-916.2-504.4-916.3-504.6L-916.3-504.6z"
                />
              </g>
              <g transform="translate(0 0.502)">
                <path
                  d="M-923.2-504c1-0.7,1.9-1.6,2.7-2.6c0.9-0.9,1.8-1.9,2.6-2.9c0.3-0.4,0.3-0.9-0.1-1.2
                    c-0.3-0.3-0.8-0.3-1.2,0c-0.9,0.9-1.8,2-2.7,3c-0.9,0.9-1.6,1.9-2.1,3.1c-0.1,0.3,0.1,0.6,0.4,0.7
                    C-923.4-503.9-923.3-503.9-923.2-504L-923.2-504z"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="translate(0 0.218)">
          <path
            d="M-922.7-504.8c0.8-0.7,1.5-1.4,2.2-2.2c0.8-0.7,1.5-1.5,2.1-2.4c0.6-0.9-0.7-2-1.5-1.4
              c-0.8,0.7-1.6,1.4-2.2,2.3c-0.7,0.8-1.4,1.6-1.9,2.6C-924.2-505.2-923.5-504.2-922.7-504.8L-922.7-504.8z"
          />
        </g>
        <g transform="translate(0.039 17.103)">
          <path
            d="M-924-510.2c0.3,0.8,0.9,1.4,1.6,1.9c0.7,0.6,1.4,1.2,2.2,1.7c1,0.7,2.3-0.7,1.4-1.4
              c-0.7-0.5-1.5-1.1-2.2-1.7c-0.6-0.6-1.4-1.1-2.2-1.3c-0.4,0-0.7,0.2-0.7,0.6C-924-510.3-924-510.2-924-510.2L-924-510.2z"
          />
        </g>
        <g transform="translate(16.509)">
          <path
            d="M-923.9-510c0.5,0.7,1.1,1.3,1.8,1.8c0.7,0.5,1.4,1.1,2,1.6c0.9,0.7,2.3-0.7,1.3-1.4
              c-0.7-0.6-1.5-1.1-2.2-1.7c-0.6-0.6-1.4-1-2.2-1.3c-0.4-0.1-0.7,0.2-0.8,0.5C-924-510.3-924-510.1-923.9-510L-923.9-510z"
          />
        </g>
        <g transform="translate(16.215 15.408)">
          <path
            d="M-923.2-506.6L-923.2-506.6c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,1,1,1c0,0,0,0,0.1,0
            c0.7,0,1.2-0.8,1.6-1.3c0.5-0.6,1-1.2,1.6-1.8c0.6-0.6,1.1-1.3,1.5-2c0.2-0.8-0.7-1.3-1.3-0.9c-0.8,0.6-1.5,1.4-2.1,2.2
            c-0.3,0.4-0.6,0.7-0.9,1.1c-0.2,0.3-0.6,0.9-0.9,1.1c0.2,0,0.5-0.1,0.7-0.1c0.1,0.1,0.3,0.3,0.4,0.4
            C-922.6-506.4-922.9-506.6-923.2-506.6L-923.2-506.6z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DrawnCircleXIcon;
