export type OnboardingState = {
  title?: string;
  modalFilename?: string;
  text?: string;
  settings?: {
    [settingName: string]: boolean;
  };
};

export type NotificationState = {
  title?: string;
  modalFilename?: string;
  text?: string;
};

export type SupportState = {
  title?: string;
  modalFilename?: string;
  text?: string;
  iconLegendURL?: string;
};

export type CopyrightState = {
  title?: string;
  modalFilename?: string;
  text?: string;
  settings?: {
    [settingName: string]: boolean;
  };
};

export const SHOW_APP_MODAL = 'SHOW_APP_MODAL';
export const CLOSE_APP_MODAL = 'CLOSE_APP_MODAL';
export type SHOW_APP_MODAL_ACTION = { type: typeof SHOW_APP_MODAL };
export type CLOSE_APP_MODAL_ACTION = { type: typeof CLOSE_APP_MODAL };

export const SHOW_LICENSING_MODAL = 'SHOW_LICENSING_MODAL';
export const CLOSE_LICENSING_MODAL = 'CLOSE_LICENSING_MODAL';
export type SHOW_LICENSING_MODAL_ACTION = {
  type: typeof SHOW_LICENSING_MODAL;
  modalType: string;
  modalProps: any;
};
export type CLOSE_LICENSING_MODAL_ACTION = { type: typeof CLOSE_LICENSING_MODAL };

export const SHOW_ONBOARDING_MODAL = 'SHOW_ONBOARDING_MODAL';
export type SHOW_ONBOARDING_MODAL_ACTION = {
  type: typeof SHOW_ONBOARDING_MODAL;
  onboarding: Array<OnboardingState>;
};

export const SHOW_NOTIFICATION_MODAL = 'SHOW_NOTIFICATION_MODAL';
export type SHOW_NOTIFICATION_MODAL_ACTION = {
  type: typeof SHOW_NOTIFICATION_MODAL;
  notifications: Array<NotificationState>;
};

export const SHOW_COPYRIGHT_MODAL = 'SHOW_COPYRIGHT_MODAL';
export type SHOW_COPYRIGHT_MODAL_ACTION = {
  type: typeof SHOW_COPYRIGHT_MODAL;
  copyright: Array<CopyrightState>;
};

export const SHOW_SUPPORT_MODAL = 'SHOW_SUPPORT_MODAL';
export type SHOW_SUPPORT_MODAL_ACTION = {
  type: typeof SHOW_SUPPORT_MODAL;
  support: Array<SupportState>;
};

export const TOGGLE_CLICK_AWAY_ALERT_DISPLAY = 'TOGGLE_CLICK_AWAY_ALERT_DISPLAY';

export type TOGGLE_CLICK_AWAY_ALERT_DISPLAY_ACTION = {
  type: typeof TOGGLE_CLICK_AWAY_ALERT_DISPLAY;
  productId: number;
  showExpiredLicenseAlert: boolean;
};

export type MessagingAction =
  | SHOW_APP_MODAL_ACTION
  | CLOSE_APP_MODAL_ACTION
  | SHOW_LICENSING_MODAL_ACTION
  | CLOSE_LICENSING_MODAL_ACTION
  | SHOW_ONBOARDING_MODAL_ACTION
  | TOGGLE_CLICK_AWAY_ALERT_DISPLAY_ACTION
  | SHOW_NOTIFICATION_MODAL_ACTION
  | SHOW_SUPPORT_MODAL_ACTION
  | SHOW_COPYRIGHT_MODAL_ACTION;

export type MessagingState = {
  showAppModal: boolean;
  onboarding?: Array<OnboardingState>;
  dismissedClickAwayAlerts: Array<number>;
  notifications?: Array<NotificationState>;
  modalType?: any;
};

/* eslint-disable no-use-before-define */
type GetState = () => { messaging: MessagingState };
type PromiseAction = Promise<MessagingAction>;
export type Dispatch = (
  action: MessagingState | ThunkAction | PromiseAction | Array<MessagingState>
) => any;
export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
