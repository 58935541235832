import { AccountInfo, Configuration, SilentRequest } from '@azure/msal-browser';

const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const KNOWN_AUTHORITIES = process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES
  ? process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES.split(',')
  : [];

const scopes = ['offline_access', 'openid', ...(CLIENT_ID ? [CLIENT_ID] : [])];

const AuthConfig: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    knownAuthorities: KNOWN_AUTHORITIES,
    postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI
  },
  cache: {
    claimsBasedCachingEnabled: true,
    cacheLocation: 'sessionStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (_level: any, message: any): void => {
        if (process.env.NODE_ENV !== 'production') console.log(message);
      },
      piiLoggingEnabled: process.env.NODE_ENV !== 'production'
    }
  }
};

export const getLoginRequest = (account?: AccountInfo): SilentRequest => ({
  scopes,
  ...(account && { account }),
  extraQueryParameters: { brand: 'prekitt' }
});

export default AuthConfig;
