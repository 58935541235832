import keyBy from 'lodash/keyBy';
import { pdfjs } from 'react-pdf';
import * as actions from './workbook-actions';
import PreKittApi from '../../api';
import type { Dispatch } from './workbook-types';
import { fetchPDFFail } from './workbook-actions';
import { hasClickAwayOnly } from 'modules/user';

// PDFJS.workerSrc = `${String(process.env.PUBLIC_URL)}/pdf.worker.min.js`;
// PDFJS.PDFJS.disableStream = true;
// PDFJS.PDFJS.disableAutoFetch = true;
// PDFJS.PDFJS.disableRange = true;

export function getUsersWorkbooks() {
  return async (dispatch: Dispatch, getState, api: typeof PreKittApi) => {
    try {
      if (!hasClickAwayOnly(getState())) {
        dispatch(actions.fetchWorkbooks());
        const response = await api.getBooks();
        const booksWithSortIndex = response.data.map((book, index) => {
          return { ...book, sortIndex: index };
        });
        const workbooks = keyBy(booksWithSortIndex, book => book.id);

        dispatch(actions.fetchWorkbooksSuccess(workbooks));
      }
    } catch (err) {
      dispatch(actions.fetchWorkbooksSuccess([]));
    }
  };
}

export function fetchWorkbookPDF(bookUrl: string) {
  return async (dispatch: Dispatch) => {
    if (!bookUrl) return;
    dispatch(actions.fetchPDF());

    try {
      const pdf = await pdfjs.getDocument(bookUrl).promise;

      dispatch(actions.fetchPDFSuccess(pdf));
    } catch (err) {
      dispatch(fetchPDFFail(err));
    }
  };
}

export function getWorkbookTableOfContents(bookId: number) {
  return async (dispatch: Dispatch, _, api: typeof PreKittApi) => {
    dispatch(actions.fetchWorkbookContent());

    try {
      const response = await api.getBookContent(bookId);

      dispatch(actions.fetchWorkbookContentSuccess(response.data));
    } catch (err) {
      dispatch(actions.fetchWorkbookContentFailure(err));
    }
  };
}

export function getPageDetails(bookId: number, page: string | any, isZoom = false) {
  return async (dispatch: Dispatch, _, api: typeof PreKittApi) => {
    const pageNumber = parseInt(page, 10);
    if (Number.isNaN(pageNumber)) {
      return;
    }

    dispatch(actions.fetchWorkbookPageDetails());

    const params = {
      include: ['resourceProducts'],
      pageNumber: isZoom ? pageNumber : [pageNumber, pageNumber + 1]
    };

    try {
      const response = await api.getBookPages(bookId, params);
      dispatch(actions.fetchWorkbookPagesDetailsSuccess(response.data));
      return response.data;
    } catch (err) {
      // dispatch(actions.fetchWorkbookPagesDetailsFailure(err));
    }
  };
}

export function setLastAccessed(book: any) {
  return async (dispatch: Dispatch) => {
    switch (book.type) {
      case 'activityBook':
        dispatch(actions.setLastWorkbook(book));
        break;
      case 'teachersGuide':
        dispatch(actions.setLastTeachersGuide(book));
        break;
      case 'storybook':
        dispatch(actions.setLastStorybook(book));
        break;
      default:
    }
  };
}
