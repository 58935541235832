//
import React from 'react';

type Props = {
  fill: string;
  borderFill: string;
};

const LeftArrowIcon = ({ fill, borderFill }: Props) => (
  <svg width="100%" viewBox="0 0 38 38">
    <g transform="translate(530.345 313.351)">
      <g transform="translate(63.721 329.606)">
        <path
          style={{ fill }}
          d="M-556.8-623.8c0,1.3-0.1,2.7-0.3,4c-0.3,1.3-0.7,2.6-1.4,3.8c-0.7,1.2-1.5,2.2-2.4,3.2
          c-0.8,1.1-1.6,2.2-2.6,3.1c-1.1,0.7-2.3,1.4-3.6,1.9c-1.3,0.5-2.6,0.9-3.9,1.1c-1.3,0.3-2.6,0.5-4,0.5c-1.3,0-2.7-0.2-4-0.5
          c-1.3-0.3-2.5-0.8-3.7-1.4c-1.2-0.5-2.4-1.1-3.5-1.9c-1.1-0.8-2.1-1.7-3-2.7c-0.8-1.1-1.5-2.3-2-3.5c-0.6-1.2-1.6-2.3-1.9-3.6
          c-0.2-1.3-0.3-2.7-0.3-4.1c0.1-1.3,0.4-2.7,0.8-4c0.2-1.3,0.7-2.6,1.2-3.8c0.7-1.1,1.6-2.2,2.5-3.1c0.8-1,1.7-2,2.7-2.8
          c1-0.9,2.1-1.6,3.3-2.3c1.3-0.5,2.6-0.8,3.9-1c1.3-0.3,2.6-0.9,4-0.9c1.3,0.1,2.7,0.4,4,0.8c1.3,0.2,2.7,0.5,3.9,1
          c1.2,0.7,2.3,1.4,3.3,2.3c1,0.9,1.9,1.8,2.7,2.9c0.8,1,1.6,2.1,2.1,3.3c0.7,1.1,1.3,2.3,1.8,3.6
          C-556.8-626.5-556.7-625.1-556.8-623.8z"
        />
      </g>
      <path
        style={{ fill: borderFill }}
        d="M-506.1-286.8c-2.6-3-6.4-4.3-9.7-6.4c2.5-1.8,6.4-5.1,8.8-7c1.4-1.1-0.5-3.2-1.9-2.1
        c-1.2,1-2.7,2.2-4.4,3.5c-1.8,1.5-4,3.1-5.6,4.3c-0.7,0.4-0.9,1.2-0.6,1.9c0.1,0.2,0.3,0.4,0.4,0.5c3.4,2.7,7.9,3.7,10.8,7.1
        c0.5,0.6,1.4,0.6,2,0.1C-505.7-285.3-505.7-286.2-506.1-286.8z"
      />
      <path
        style={{ fill: borderFill }}
        d="M-499.3-306.3c-3.4-3-8.9-5.5-13.4-5.3c-1.4,0.1-2.9,0.4-4.1,1.1c-0.2,0-0.3,0-0.5,0
        c-7.7,1.6-12.2,12.1-11.6,19.2c0.7,7.8,7.6,12.9,14.9,14c7.4,1,15.1-2.1,18.4-9.2C-492.3-293.2-493.6-301.4-499.3-306.3z
         M-501.8-282c-6.4,4.3-16,3.1-21.5-2.3c-3.6-3.6-4.4-8.8-2.9-13.6c0.7-2.3,1.9-4.5,3.4-6.4c-0.4,0.9,0,2.4,1,1.6
        c1-0.9,1.8-1.9,2.4-3.1c1.6-2.2,4.2-3.4,6.9-3.4c4,0,9,2.4,12,5C-493.8-298.4-494.3-287.1-501.8-282z"
      />
    </g>
  </svg>
);

LeftArrowIcon.defaultProps = {
  fill: '#FFF',
  borderFill: '#010101'
};

export default LeftArrowIcon;
