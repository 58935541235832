import React from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useModal } from '@lwtears/lwt-common-frontend/lib/Modal';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { ModalAriaLabel, TitleProps } from '@lwtears/lwt-common-frontend/lib/Modal/types';

type Props = {
  title: string;
  EndAdornment?: JSX.Element;
} & TitleProps;

export const DraggableModalTitle = ({ title, EndAdornment, tabIndex, ariaLabel }: Props) => {
  const classes = useStyles();
  const [, { closeModal }] = useModal();

  return (
    <div id="draggable-handle" className={classes.baseTitleClass}>
      <Typography
        aria-label={ariaLabel}
        style={{ fontWeight: 600, cursor: 'default' }}
        id={ModalAriaLabel.id}
        variant="h6"
      >
        {title}
      </Typography>
      <Box>
        {EndAdornment}
        <IconButton
          tabIndex={tabIndex}
          aria-label={tA11y('aria.closeModal')}
          className={classes.closeButton}
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faTimes} className={classes.closeIcon} />
        </IconButton>
      </Box>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  baseTitleClass: {
    backgroundColor: '#0171BB',
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    fontFamily: 'GSS Futura'
  },
  closeButton: () => ({
    margin: -10,
    padding: 10,
    top: 0.5,
    color: '#000',
    textShadow: '0 1px 0 #fff',
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'unset !important'
    }
  }),
  closeIcon: {
    fontSize: 14,
    color: '#fff'
  }
}));
