import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { DomUtil } from '@lwtears/lwt-common-frontend/lib/@common';
import { useLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';
import PrekittApi from 'api';
import Config from 'config';
import { getLoginRequest } from 'config/auth';
import AppRouter from './AppRouter';
import LoadingScreen from './components/core/LoadingScreen/LoadingScreen';
import { fetchUser } from './modules/user';
import { hasAuthCookies } from './util/auth-cookie-util';
import { useToggleSalesforceChatWidget } from './util/salesforce-chat-widget-util';

const getAuthContextFn = (azureContext: IMsalContext) => async () => {
  const { instance, inProgress } = azureContext;
  const accounts = await instance.getAllAccounts();

  const account = accounts?.[0];
  const loginRequest = getLoginRequest(account);

  if (account && inProgress === InteractionStatus.None) {
    try {
      const result = await instance.acquireTokenSilent(loginRequest);
      return { accessToken: result.accessToken, idToken: result.idToken };
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.loginRedirect(loginRequest);
      } else {
        throw error;
      }
    }
  }

  return null;
};

const useChangeTitle = () => {
  useEffect(() => {
    if (!DomUtil.getTitle()) DomUtil.changeTitle(Config.title ?? '');
  }, []);
};

const useAppInit = () => {
  const dispatch = useDispatch() as any;
  const [, { setSettings }] = useLwtStore();
  const azureContext = useMsal();
  const usingAuthCookies = hasAuthCookies();
  const [isAuthContextAndUserDispatchComplete, setIsAuthContextAndUserDispatchComplete] =
    useState(false);

  useEffect(() => {
    if (!isAuthContextAndUserDispatchComplete) {
      const authorizeUser = async () => {
        const authContextFn = getAuthContextFn(azureContext);
        PrekittApi.Api.addAuthContextCallback(authContextFn);

        const response = await fetchUser()(dispatch);
        if (response) setSettings(response.user.settings);
        setIsAuthContextAndUserDispatchComplete(true);
      };

      authorizeUser();
    }
  }, [azureContext, isAuthContextAndUserDispatchComplete, usingAuthCookies, dispatch]);

  return {
    appLoading:
      !isAuthContextAndUserDispatchComplete ||
      (!usingAuthCookies && azureContext.inProgress !== InteractionStatus.None)
  };
};

const GatedApp = (): any => {
  const { appLoading } = useAppInit();
  useToggleSalesforceChatWidget();
  useChangeTitle();

  if (appLoading) return <LoadingScreen show={true} />;

  return <AppRouter />;
};

export default GatedApp;
