//
import React from 'react';

type Props = {
  fill?: string;
  secondaryFill?: string;
  showPrimary?: string;
  width?: string;
};

const RepeatingCirclesIcon = ({
  fill,
  secondaryFill,
  showPrimary,
  width: propWidth,
  ...props
}: Props) => (
  <svg {...props} style={{ width: propWidth ? propWidth : '100%' }} viewBox="0 0 27 27">
    <g transform="translate(183.25 273.75)">
      <g transform="translate(748 236.331)">
        <g transform="translate(9.642)">
          <path
            style={{ fill }}
            d="M-926.5-509.8c-0.3-0.1-0.6-0.2-1-0.2c-1.2,0.1-2.3,0.7-2.9,1.8c-0.9,1.3-0.9,3,0.1,4.3
            c1,1.2,2.6,1.6,4.1,1c0.9-0.4,1.7-1.1,2.2-2.1c0.1-0.3,0.2-0.6,0.2-0.9v0l0,0c0,0,0,0,0-0.1c0.1-0.8-0.1-1.7-0.7-2.3
            C-925-509-925.7-509.5-926.5-509.8z M-924.4-507.6l0.2,0.5c0.1,0.7,0.1,1.4-0.2,2c-0.5,0.8-1.2,1.5-2.1,1.9
            c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.2-1.5-2.1c-0.2-1.7,0.8-3.3,2.4-4c1-0.4,2.2-0.1,2.9,0.7
            C-924.9-508.5-924.6-508.1-924.4-507.6z"
          />
          <g transform="translate(0.573 0.564)">
            <path
              style={{ fill: showPrimary ? fill : secondaryFill }}
              d="M-929.1-503.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
              c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7
              c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.5,0,0.7,0
              c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
              c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6
              c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
              C-928.6-503.6-928.9-503.7-929.1-503.8z"
            />
          </g>
        </g>
        <g>
          <path
            style={{ fill }}
            d="M-926.5-509.8c-0.3-0.1-0.6-0.2-1-0.2c-1.2,0.1-2.3,0.7-2.9,1.8c-0.9,1.3-0.9,3,0.1,4.3
            c1,1.2,2.6,1.6,4.1,1c0.9-0.4,1.7-1.1,2.2-2.1c0.1-0.3,0.2-0.6,0.2-0.9v0l0,0c0,0,0,0,0-0.1c0.1-0.8-0.1-1.7-0.7-2.3
            C-925-509-925.7-509.5-926.5-509.8z M-924.4-507.6l0.2,0.5c0.1,0.7,0.1,1.4-0.2,2c-0.5,0.8-1.2,1.5-2.1,1.9
            c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.2-1.5-2.1c-0.2-1.7,0.8-3.3,2.4-4c1-0.4,2.2-0.1,2.9,0.7
            C-924.9-508.5-924.6-508.1-924.4-507.6z"
          />
          <g transform="translate(0.573 0.564)">
            <path
              style={{ fill: showPrimary ? fill : secondaryFill }}
              d="M-929.1-503.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
              c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7
              c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.5,0,0.7,0
              c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
              c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6
              c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
              C-928.6-503.6-928.9-503.7-929.1-503.8z"
            />
          </g>
        </g>
        <g transform="translate(19.284)">
          <path
            style={{ fill }}
            d="M-926.5-509.8c-0.3-0.1-0.6-0.2-1-0.2c-1.2,0.1-2.3,0.7-2.9,1.8c-0.9,1.3-0.9,3,0.1,4.3
            c1,1.2,2.6,1.6,4.1,1c0.9-0.4,1.7-1.1,2.2-2.1c0.1-0.3,0.2-0.6,0.2-0.9v0l0,0c0,0,0,0,0-0.1c0.1-0.8-0.1-1.7-0.7-2.3
            C-925-509-925.7-509.5-926.5-509.8z M-924.4-507.6l0.2,0.5c0.1,0.7,0.1,1.4-0.2,2c-0.5,0.8-1.2,1.5-2.1,1.9
            c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.2-1.5-2.1c-0.2-1.7,0.8-3.3,2.4-4c1-0.4,2.2-0.1,2.9,0.7
            C-924.9-508.5-924.6-508.1-924.4-507.6z"
          />
          <g transform="translate(0.573 0.564)">
            <path
              style={{ fill: showPrimary ? fill : secondaryFill }}
              d="M-929.1-503.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
              c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7
              c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.5,0,0.7,0
              c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
              c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6
              c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
              C-928.6-503.6-928.9-503.7-929.1-503.8z"
            />
          </g>
        </g>
      </g>
      <g transform="translate(748 245.973)">
        <g transform="translate(9.642)">
          <path
            style={{ fill }}
            d="M-926.5-509.8c-0.3-0.1-0.6-0.2-1-0.2c-1.2,0.1-2.3,0.7-2.9,1.8c-0.9,1.3-0.9,3,0.1,4.3
            c1,1.2,2.6,1.6,4.1,1c0.9-0.4,1.7-1.1,2.2-2.1c0.1-0.3,0.2-0.6,0.2-0.9v0l0,0c0,0,0,0,0-0.1c0.1-0.8-0.1-1.7-0.7-2.3
            C-925-509-925.7-509.5-926.5-509.8z M-924.4-507.6l0.2,0.5c0.1,0.7,0.1,1.4-0.2,2c-0.5,0.8-1.2,1.5-2.1,1.9
            c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.2-1.5-2.1c-0.2-1.7,0.8-3.3,2.4-4c1-0.4,2.2-0.1,2.9,0.7
            C-924.9-508.5-924.6-508.1-924.4-507.6z"
          />
          <g transform="translate(0.573 0.564)">
            <path
              style={{ fill: showPrimary ? fill : secondaryFill }}
              d="M-929.1-503.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
              c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7
              c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.5,0,0.7,0
              c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
              c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6
              c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
              C-928.6-503.6-928.9-503.7-929.1-503.8z"
            />
          </g>
        </g>
        <path
          style={{ fill }}
          d="M-926.5-509.8c-0.3-0.1-0.6-0.2-1-0.2c-1.2,0.1-2.3,0.7-2.9,1.8c-0.9,1.3-0.9,3,0.1,4.3
          c1,1.2,2.6,1.6,4.1,1c0.9-0.4,1.7-1.1,2.2-2.1c0.1-0.3,0.2-0.6,0.2-0.9v0l0,0c0,0,0,0,0-0.1c0.1-0.8-0.1-1.7-0.7-2.3
          C-925-509-925.7-509.5-926.5-509.8z M-924.4-507.6l0.2,0.5c0.1,0.7,0.1,1.4-0.2,2c-0.5,0.8-1.2,1.5-2.1,1.9
          c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.2-1.5-2.1c-0.2-1.7,0.8-3.3,2.4-4c1-0.4,2.2-0.1,2.9,0.7
          C-924.9-508.5-924.6-508.1-924.4-507.6z"
        />
        <g transform="translate(0.573 0.564)">
          <path
            style={{ fill: showPrimary ? fill : secondaryFill }}
            d="M-929.1-503.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
            c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7
            c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.5,0,0.7,0
            c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
            c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6
            c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
            C-928.6-503.6-928.9-503.7-929.1-503.8z"
          />
        </g>
        <g transform="translate(19.284)">
          <path
            style={{ fill }}
            d="M-926.5-509.8c-0.3-0.1-0.6-0.2-1-0.2c-1.2,0.1-2.3,0.7-2.9,1.8c-0.9,1.3-0.9,3,0.1,4.3
            c1,1.2,2.6,1.6,4.1,1c0.9-0.4,1.7-1.1,2.2-2.1c0.1-0.3,0.2-0.6,0.2-0.9v0l0,0c0,0,0,0,0-0.1c0.1-0.8-0.1-1.7-0.7-2.3
            C-925-509-925.7-509.5-926.5-509.8z M-924.4-507.6l0.2,0.5c0.1,0.7,0.1,1.4-0.2,2c-0.5,0.8-1.2,1.5-2.1,1.9
            c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.2-1.5-2.1c-0.2-1.7,0.8-3.3,2.4-4c1-0.4,2.2-0.1,2.9,0.7
            C-924.9-508.5-924.6-508.1-924.4-507.6z"
          />
          <g transform="translate(0.573 0.564)">
            <path
              style={{ fill: showPrimary ? fill : secondaryFill }}
              d="M-929.1-503.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
              c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7
              c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.5,0,0.7,0
              c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
              c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6
              c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
              C-928.6-503.6-928.9-503.7-929.1-503.8z"
            />
          </g>
        </g>
      </g>
      <g transform="translate(748 255.615)">
        <g transform="translate(9.642)">
          <path
            style={{ fill }}
            d="M-926.5-509.8c-0.3-0.1-0.6-0.2-1-0.2c-1.2,0.1-2.3,0.7-2.9,1.8c-0.9,1.3-0.9,3,0.1,4.3
            c1,1.2,2.6,1.6,4.1,1c0.9-0.4,1.7-1.1,2.2-2.1c0.1-0.3,0.2-0.6,0.2-0.9v0l0,0c0,0,0,0,0-0.1c0.1-0.8-0.1-1.7-0.7-2.3
            C-925-509-925.7-509.5-926.5-509.8z M-924.4-507.6l0.2,0.5c0.1,0.7,0.1,1.4-0.2,2c-0.5,0.8-1.2,1.5-2.1,1.9
            c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.2-1.5-2.1c-0.2-1.7,0.8-3.3,2.4-4c1-0.4,2.2-0.1,2.9,0.7
            C-924.9-508.5-924.6-508.1-924.4-507.6z"
          />
          <g transform="translate(0.573 0.564)">
            <path
              style={{ fill: showPrimary ? fill : secondaryFill }}
              d="M-929.1-503.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
              c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7
              c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.5,0,0.7,0
              c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
              c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6
              c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
              C-928.6-503.6-928.9-503.7-929.1-503.8z"
            />
          </g>
        </g>
        <path
          style={{ fill }}
          d="M-926.5-509.8c-0.3-0.1-0.6-0.2-1-0.2c-1.2,0.1-2.3,0.7-2.9,1.8c-0.9,1.3-0.9,3,0.1,4.3
          c1,1.2,2.6,1.6,4.1,1c0.9-0.4,1.7-1.1,2.2-2.1c0.1-0.3,0.2-0.6,0.2-0.9v0l0,0c0,0,0,0,0-0.1c0.1-0.8-0.1-1.7-0.7-2.3
          C-925-509-925.7-509.5-926.5-509.8z M-924.4-507.6l0.2,0.5c0.1,0.7,0.1,1.4-0.2,2c-0.5,0.8-1.2,1.5-2.1,1.9
          c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.2-1.5-2.1c-0.2-1.7,0.8-3.3,2.4-4c1-0.4,2.2-0.1,2.9,0.7
          C-924.9-508.5-924.6-508.1-924.4-507.6z"
        />
        <g transform="translate(0.573 0.564)">
          <path
            style={{ fill: showPrimary ? fill : secondaryFill }}
            d="M-929.1-503.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
            c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7
            c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.5,0,0.7,0
            c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
            c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6             c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
            C-928.6-503.6-928.9-503.7-929.1-503.8z"
          />
        </g>
        <g transform="translate(19.284)">
          <path
            style={{ fill }}
            d="M-926.5-509.8c-0.3-0.1-0.6-0.2-1-0.2c-1.2,0.1-2.3,0.7-2.9,1.8c-0.9,1.3-0.9,3,0.1,4.3
            c1,1.2,2.6,1.6,4.1,1c0.9-0.4,1.7-1.1,2.2-2.1c0.1-0.3,0.2-0.6,0.2-0.9v0l0,0c0,0,0,0,0-0.1c0.1-0.8-0.1-1.7-0.7-2.3
            C-925-509-925.7-509.5-926.5-509.8z M-924.4-507.6l0.2,0.5c0.1,0.7,0.1,1.4-0.2,2c-0.5,0.8-1.2,1.5-2.1,1.9
            c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.2-1.5-2.1c-0.2-1.7,0.8-3.3,2.4-4c1-0.4,2.2-0.1,2.9,0.7
            C-924.9-508.5-924.6-508.1-924.4-507.6z"
          />
          <g transform="translate(0.573 0.564)">
            <path
              style={{ fill: showPrimary ? fill : secondaryFill }}
              d="M-929.1-503.8c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.4-0.3-0.5-0.5
              c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.5,0.3-0.7
              c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2,0,0.5,0,0.7,0
              c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.7
              c0.1,0.2,0.2,0.4,0.3,0.7c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.5-0.1,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.6
              c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1
              C-928.6-503.6-928.9-503.7-929.1-503.8z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default RepeatingCirclesIcon;
