import React from 'react';
import { useLocation } from 'react-router-dom';

import { Paper, Typography } from '@mui/material';
import { tabItems } from './AssessmentSideMenu';
import { parseQueryParams } from '../../../util/query-params-util';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const translateAssessments = key => translate('assessments', key);

const ActiveAssessment = () => {
  const location = useLocation();
  const queryParams = parseQueryParams(location.search);
  const { Component, name, showTitle } = tabItems?.find(x => queryParams?.activeTab === x.route) ?? {};

  const MaybePaper = ({ children }) => (
    <Paper
      style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%', padding: 20 }}
    >
      {children}
    </Paper>
  );

  return (
    <MaybePaper>
      {showTitle && (
        <Typography variant="h6" style={{ fontWeight: 'bold', margin: 10 }}>
          {translateAssessments(name)}
        </Typography>
      )}
      {Component && <Component />}
    </MaybePaper>
  );
};

export default ActiveAssessment;
