import values from 'lodash/values';

export const TOGGLE_WORKBOOKS_MENU = 'TOGGLE_WORKBOOKS_MENU';
export const TOGGLE_GLOBAL_MENU = 'TOGGLE_GLOBAL_MENU';
export const SET_GLOBAL_MENU_WIDTH = 'SET_GLOBAL_MENU_WIDTH';
export const TOGGLE_PAGE_SELECTOR_MENU = 'TOGGLE_PAGE_SELECTOR_MENU ';
export const GET_DEVICE_COMPATIBILITY = 'GET_DEVICE_COMPATIBILITY';
export const TOGGLE_GLOBAL_MENU_TUTORIAL = 'TOGGLE_GLOBAL_MENU_TUTORIAL';
export const CANCEL_DOWNLOAD_REQUEST = 'CANCEL_DOWNLOAD_REQUEST';
export const START_LOADING_SCREEN = 'START_LOADING_SCREEN';
export const STOP_LOADING_SCREEN = 'STOP_LOADING_SCREEN';
export const TOGGLE_SUBMENU = 'TOGGLE_SUBMENU';

export type Action =
  | { type: 'TOGGLE_GLOBAL_MENU'; open: boolean }
  | { type: 'TOGGLE_WORKBOOKS_MENU'; open: boolean }
  | { type: 'SET_GLOBAL_MENU_WIDTH'; width: number }
  | { type: 'TOGGLE_PAGE_SELECTOR_MENU'; open: boolean }
  | { type: 'GET_DEVICE_COMPATIBILITY'; isCompatible: boolean }
  | { type: 'TOGGLE_GLOBAL_MENU_TUTORIAL'; open: boolean }
  | { type: 'CANCEL_DOWNLOAD_REQUEST' }
  | { type: 'START_LOADING_SCREEN' }
  | { type: 'STOP_LOADING_SCREEN' }
  | { type: 'TOGGLE_SUBMENU' };

export const toggleWorkbooksMenu = (open: boolean) => ({
  type: TOGGLE_WORKBOOKS_MENU,
  open: open
});

export const toggleGlobalMenu = (open?: boolean) => ({
  type: TOGGLE_GLOBAL_MENU,
  open: open
});

export const setGlobalMenuWidth = (width = 0) => ({
  type: SET_GLOBAL_MENU_WIDTH,
  width
});

export const togglePageSelectorMenu = (open: boolean) => ({
  type: TOGGLE_PAGE_SELECTOR_MENU,
  open
});

export const getDeviceCompatibility = () => dispatch => {
  const isDeviceCompatible = window.innerWidth >= 768;

  dispatch({ type: GET_DEVICE_COMPATIBILITY, isDeviceCompatible });
};

export const closeAllMenus = () => dispatch => {
  const toggles = findMenuToggleByType('all');

  toggles.forEach(menuToggle => dispatch(menuToggle(false)));
};

export const toggleMenuByType = (menuType: string) => (open?: boolean) => (dispatch, getState) => {
  const triggerMenuToggle = findMenuToggleByType(menuType);
  const isOpen = getState().ui[`${menuType}MenuOpen`];

  dispatch(triggerMenuToggle(open === undefined ? !isOpen : open));
};

const findMenuToggleByType = (menuType: string) => {
  const menuToggles = {
    global: toggleGlobalMenu,
    workbooks: toggleWorkbooksMenu,
    pageSelector: togglePageSelectorMenu
  };

  if (menuType === 'all') {
    return values(menuToggles);
  }

  if (!menuToggles[menuType]) {
    throw new Error(`Calling for unsupported menu: ${menuType}`);
  }

  return menuToggles[menuType];
};

export const toggleSlideoutMenu = (open?: boolean) => (dispatch, getState) => {
  dispatch(toggleMenuByType('global')(open));
  if (getState().ui.workbooksMenuOpen) {
    dispatch(toggleWorkbooksMenu(false));
  }
};

export const toggleSlideoutMenuTutorial = (open: boolean) => ({
  type: TOGGLE_GLOBAL_MENU_TUTORIAL,
  open
});

export const toggleSubmenu = (submenuName: string) => ({
  type: TOGGLE_SUBMENU,
  submenuName
});

export const cancelDownloadRequest = () => ({
  type: CANCEL_DOWNLOAD_REQUEST
});

export const startLoadingScreen = () => ({
  type: START_LOADING_SCREEN
});

export const stopLoadingScreen = () => ({
  type: STOP_LOADING_SCREEN
});
