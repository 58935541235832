import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import PrekittApi from '../api';
import { createBrowserHistory as createHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import authFailure from '../middleware/authFailure';
import reducers from '../reducers';

const history = createHistory();

const middleware = routerMiddleware(history);

const createStoreWithThunk = compose(
  applyMiddleware(thunkMiddleware.withExtraArgument(PrekittApi), authFailure, middleware, logger)
)(createStore);

export const getDevStore = () =>
  createStoreWithThunk(
    reducers,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  );
