import React from 'react';

import { css, StyleSheet } from 'aphrodite';

type Props = {
  show?: boolean;
  zIndex: number;
  children?: any;
  onClick?: () => any;
  classList: Array<any>;
};

const getBaseStyle = (zIndex: number) => ({
  position: 'fixed',
  zIndex,
  display: 'flex',

  justifyContent: 'center',
  alignItems: 'flex-start',
  transition: 'all 100ms linear'
});

export const styles = StyleSheet.create({
  show: {
    top: -60,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'all 100ms linear 0s',
    height: '100vh',
    width: '100vw'
  },
  hide: {
    visibility: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '@media print': {
      display: 'none !important'
    }
  }
});

const ScreenOverlay = ({ show, zIndex, children, classList, onClick, ...props }: Props) => (
  <div
    tabIndex={-1}
    style={getBaseStyle(zIndex) as any}
    className={css(show ? styles.show : styles.hide, classList)}
    onClick={onClick}
    {...props}
  >
    {children}
  </div>
);

ScreenOverlay.defaultProps = {
  zIndex: 8,
  show: true,
  classList: []
};

export default ScreenOverlay;
