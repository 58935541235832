import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { PREKIT_FONT } from '../../../styles/style-constants';
import { useSelector } from 'react-redux';
import { settingsTypes } from 'modules/user';

const translateHome = (key, options?) => translate('home', key, options);

type AgiSettingsType =
  | {
      firstViewed: Date;
      firstAccess: Date;
      lastAccess: Date;
    }
  | undefined;

const AgiNewIndicator = () => {
  const classes = useStyles();
  const user = useSelector(({ user }: any) => user);
  const agiSettings = user.settings[
    settingsTypes.assessmentsToGuideInstructions
  ] as AgiSettingsType;

  const firstAccess = new Date(agiSettings?.firstAccess ?? '');
  const nowMinus30Days = new Date();
  nowMinus30Days.setDate(nowMinus30Days.getDate() - 30);
  const firstAccessOver30Days = firstAccess < nowMinus30Days;

  const June2023 = new Date('2023-06-30T23:59:59.999Z');
  const now = new Date();
  const pastJune2023 = June2023 < now;

  if (firstAccessOver30Days || pastJune2023) {
    return null;
  }
  return <Typography className={classes.newIndicator}>{translateHome('NEW!')}</Typography>;
};

const useStyles = makeStyles(() => ({
  newIndicator: {
    color: '#E20000',
    fontFamily: PREKIT_FONT,
    position: 'absolute',
    padding: '8px',
    top: 0,
    left: 0
  }
}));

export default AgiNewIndicator;
