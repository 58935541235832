import LwtApi, { AuthStrategy } from '@lwtears/lwt-common-frontend/lib/@common/util/api-util';
import { Headers } from '../types';
import { hasAuthCookies } from '../util/auth-cookie-util';

export const createLiteSession = async (appTarget: string) => {
  return LwtApi()
    .addDefaultAuthStrategy(
      hasAuthCookies() ? AuthStrategy.AUTH_TOKEN : AuthStrategy.HTTP_AUTHENTICATION
    )
    .onError(() => ({}))
    .post(`${process.env.REACT_APP_GATEKEEPER_URL}/lite-sessions`, {
      headers: { [Headers.APP_TARGET]: appTarget }
    });
};

export const getLiteSession = async (appTarget: string): Promise<Record<string, any>> => {
  const response = await createLiteSession(appTarget);

  return response.data || {};
};

export const appendSessionToUrl = (url: string, sessionId: string) => {
  const sessionifiedUrl = new URL(url);
  sessionifiedUrl.searchParams.set('session', sessionId);
  return sessionifiedUrl.toString();
};
