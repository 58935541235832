import React from 'react';
import { css } from 'aphrodite/no-important';
import { TweenMax, Power1 } from 'gsap';
import { Transition } from 'react-transition-group';
import MenuItem from './MenuItem';

const MenuList = ({
  menuItems,
  show,
  activeColor,
  keepSubmenuState,
  selectedSubmenu,
  toggleSubmenu
}: any) => {
  const animate = list => {
    TweenMax.staggerFromTo(
      list.children,
      0.3,
      {
        x: show ? -250 : 0,
        opacity: show ? 0 : 1
      },
      {
        x: show ? 0 : -250,
        opacity: show ? 1 : 0,
        ease: show ? Power1.easeOut : Power1.easeIn
      },
      0.1
    );
  };

  const renderAnimatedList: any = (children, listClassName) => {
    return (
      <Transition in={show} timeout={200} onEnter={animate} onExit={animate as any} appear={true}>
        <span className={css(listClassName)}>{children}</span>
      </Transition>
    );
  };

  const renderMenuItems = (menuItems, isSubmenuItem = false) =>
    menuItems.map((item, idx) => (
      <MenuItem
        onClick={item?.onClick ? item.onClick : undefined}
        tabIndex={idx}
        name={item.name}
        key={item.name}
        icon={item.icon}
        link={item.link}
        linkType={item.linkType}
        activeColor={activeColor}
        subItems={item?.children}
        isSubmenuItem={isSubmenuItem}
        keepSubmenuState={keepSubmenuState}
        renderChildren={selectedSubmenu === item.name}
        toggleSubmenu={toggleSubmenu}
        accessibilityLabelDescription={item.accessibilityLabelDescription}
        accessibilityLabelButton={item.accessibilityLabelButton}
      />
    ));
  return renderAnimatedList(renderMenuItems(menuItems, false));
};

export default MenuList;
