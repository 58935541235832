//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Pinned from '../../images/Pinned.svg';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const styles = StyleSheet.create({
  animatedPin: {
    width: '90%'
  }
});

function PinnedIcon() {
  return <img src={Pinned} className={css(styles.animatedPin)} alt={`${tA11y('alt.pinIcon')}`} />;
}

export default PinnedIcon;
