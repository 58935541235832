//
import {
  TOGGLE_GLOBAL_MENU,
  TOGGLE_WORKBOOKS_MENU,
  SET_GLOBAL_MENU_WIDTH,
  TOGGLE_PAGE_SELECTOR_MENU,
  GET_DEVICE_COMPATIBILITY,
  TOGGLE_GLOBAL_MENU_TUTORIAL,
  CANCEL_DOWNLOAD_REQUEST,
  TOGGLE_SUBMENU
} from '../actions/ui-actions';
import {
  FETCHING_WORKBOOK_CONTENT,
  FETCH_WORKBOOK_CONTENT_SUCCESS,
  FETCH_WORKBOOK_CONTENT_FAILURE,
  FETCHING_PAGE_DETAILS,
  FETCH_PAGE_DETAILS_SUCCESS,
  FETCH_PAGE_DETAILS_FAILURE,
  FETCHING_WORKBOOKS,
  FETCH_WORKBOOKS_SUCCESS,
  FETCH_WORKBOOKS_FAILURE,
  FETCHING_PDF,
  FETCH_PDF_SUCCESS,
  FETCH_PDF_FAIL
} from '../modules/workbook/workbook-types';
import { FETCH_CHARACTERS_FAILURE } from '../modules/character-selection/types';
import {
  FETCH_RESOURCES_REQUEST,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCES_FAILURE,
  FETCH_FAVORITE_RESOURCES_REQUEST,
  FETCH_FAVORITE_RESOURCES_SUCCESS,
  FETCH_FAVORITE_RESOURCES_FAILURE
} from '../actions/resource-types';

export type UIState = {
  globalMenuOpen: boolean;
  globalMenuTutorialOpen: boolean;
  selectedSubmenu: string;
  workbooksMenuOpen: boolean;
  globalMenuWidth: number;
  pageSelectorMenuOpen: boolean;
  isDeviceCompatible: boolean;
  isLoadingContent: boolean;
};

const initialState = {
  globalMenuOpen: false,
  globalMenuTutorialOpen: false,
  workbooksMenuOpen: false,
  globalMenuWidth: 0,
  pageSelectorMenuOpen: false,
  isDeviceCompatible: true,
  isLoadingContent: false,
  selectedSubmenu: ''
};

let counter = 0;

const ui = (state: UIState = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_GLOBAL_MENU:
      return {
        ...state,
        globalMenuOpen: action.open
      };
    case TOGGLE_GLOBAL_MENU_TUTORIAL:
      return {
        ...state,
        globalMenuTutorialOpen: action.open
      };
    case TOGGLE_SUBMENU:
      return {
        ...state,
        selectedSubmenu: action.submenuName
      };
    case TOGGLE_WORKBOOKS_MENU:
      return {
        ...state,
        workbooksMenuOpen: action.open
      };
    case SET_GLOBAL_MENU_WIDTH:
      return {
        ...state,
        globalMenuWidth: action.width
      };
    case TOGGLE_PAGE_SELECTOR_MENU:
      return {
        ...state,
        pageSelectorMenuOpen: action.open
      };
    case GET_DEVICE_COMPATIBILITY:
      return {
        ...state,
        isDeviceCompatible: action.isDeviceCompatible
      };
    case FETCHING_WORKBOOK_CONTENT:
    case FETCH_RESOURCES_REQUEST:
    case FETCH_FAVORITE_RESOURCES_REQUEST:
    case FETCHING_PAGE_DETAILS:
    case FETCHING_PDF:
    case FETCHING_WORKBOOKS: {
      const nextState =
        counter++ === 0
          ? {
              ...state,
              isLoadingContent: true
            }
          : state;

      return nextState;
    }
    case FETCH_RESOURCES_SUCCESS:
    case FETCH_RESOURCES_FAILURE:
    case FETCH_FAVORITE_RESOURCES_SUCCESS:
    case FETCH_FAVORITE_RESOURCES_FAILURE:
    case FETCH_WORKBOOK_CONTENT_SUCCESS:
    case FETCH_WORKBOOK_CONTENT_FAILURE:
    case FETCH_PAGE_DETAILS_SUCCESS:
    case FETCH_PAGE_DETAILS_FAILURE:
    case FETCH_WORKBOOKS_SUCCESS:
    case FETCH_WORKBOOKS_FAILURE:
    case FETCH_CHARACTERS_FAILURE:
    case FETCH_PDF_SUCCESS:
    case FETCH_PDF_FAIL:
    case CANCEL_DOWNLOAD_REQUEST: {
      const nextState =
        --counter === 0
          ? {
              ...state,
              isLoadingContent: false
            }
          : state;
      if (counter < 0) counter = 0;

      return nextState;
    }
    default:
      return state;
  }
};

export default ui;
