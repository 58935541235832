/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import flow from 'lodash/fp/flow';
import { isArray } from 'lodash';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import withRouter from 'state/hooks/withRouter';
import isEmpty from 'lodash/isEmpty';
import { FullScreen } from 'react-full-screen';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter
} from '@fortawesome/free-solid-svg-icons';
import ResourcePlayer from '../components/Resources/ResourcePlayer/ResourcePlayer';
import MemoPlayer from '../components/Resources/ResourcePlayer/MemoPlayer';
import { toggleSlideoutMenu, toggleSlideoutMenuTutorial } from '../actions/ui-actions';
import DoublePageWorkbook from '../components/Workbook/DoublePageWorkbook';
import SinglePageWorkbook from '../components/Workbook/SinglePageWorkbook';
import NavBar from '../components/PageNavBar/PageNavBar';
import PageSelectorMenu from '../components/PageSelectorMenu/PageSelectorMenu';
import ScreenOverlay from '../components/core/ScreenOverlay';
import { changeAppLocale } from '../modules/character-selection';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { setLocale } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import PrekittApi from '../api';
import ResourceSlider from 'components/Resources/ResourceSlider';

import {
  getCurrentPage,
  getCurrentWorkbook,
  getPageDetails,
  getWorkbookTableOfContents,
  getUsersWorkbooks,
  fetchWorkbookPDF,
  setLastAccessed,
  WorkbookState
} from '../modules/workbook';

import {
  hasClickAway,
  hasClickAwayOnly,
  saveProductAccess,
  getLicenseIsExpired,
  getLicenseIsTrial,
  getTrialDaysRemaining,
  saveUserSettings,
  settingsTypes
} from '../modules/user';
import { isBookCopyrightTermsViewed, isShowLicenseExpirationAlert } from '../modules/messaging';
import { selectResource, toggleResourcePlayerVisibility } from '../actions/resource-actions';
import withFtueTour from '../components/core/enhancers/withFtueTour';
import withFullScreen from 'state/hooks/withFullScreen';
import { getClickAwayResourcesLink } from '../util/workbook-util';
import { cancelDownloadRequest, toggleMenuByType } from '../actions/ui-actions';
import withRoutePersistence from '../components/core/enhancers/withRoutePersistence';

import { MessagingState } from '../modules/messaging';
import { UseModalDispatch, useModal } from '@lwtears/lwt-common-frontend/lib/Modal';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import CopyrightModal from '../components/core/Modals/CopyrightModal';
import { DraggableModalTitle } from '../components/core/Modals/DraggableModalTitle';
import { Locale, UserSetting, UserSettingTypes } from 'types';
import { AppState } from 'reducers';
import { LiteResource } from 'reducers/resource-reducer';
import { useFullScreen } from 'state/providers/FullScreenProvider';
import { mockedResource, useResources } from 'state/providers/ResourceProvider';
import { DEFAULT_ZOOM_SCALE, useZoom } from 'state/providers/ZoomProvider';
import useIdleTimeout from 'state/hooks/useIdleTimer';
import { getGroupedResources } from 'util/resource-util';
import ResourcesButton from 'components/Button/ResourcesButton';
import DragContainer from 'components/DragContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WorkbookTour from './Tours/Workbook';

const ICON_SIZE = 45;
const BOTTOM_NAV_HEIGHT = 75;

type Props =
  | ({
      bookCopyrightTerms: boolean;
      copyrightTerms: any[];
      currentPage: number;
      currentWorkbook: any;
      fetchWorkbookPDF: typeof fetchWorkbookPDF;
      getUsersWorkbooks: typeof getUsersWorkbooks;
      getWorkbookTableOfContents: typeof getWorkbookTableOfContents;
      globalMenuTutorialOpen: boolean;
      hasClickAway: boolean;
      isClickAwayOnly: boolean;
      isLicenseExpired: boolean;
      isShowLicenseExpirationAlert: boolean;
      isTrialLicense: boolean;
      location: Location;
      match: any;
      messaging: MessagingState;
      modalDisplaying: boolean;
      modalType: string;
      saveProductAccess: typeof saveProductAccess;
      selectedResource: LiteResource;
      showResourcePlayer: boolean;
      trialDaysLeft: number;
      shouldDoWorkbookTour: boolean;
      pageData: any;
      pageMenuOpen: boolean;
      viewedWelcome: boolean;
      workbook: WorkbookState;
      cancelDownloadRequest: () => any;
      changeAppLocale: (locale: Locale) => any;
      getPageDetails: (bookCode: number, pageNumber: string | number, isZoom?: boolean) => any;
      saveUserSettings: (type: UserSettingTypes, settings: UserSetting) => any;
      selectResource: (resource: LiteResource) => any;
      setLastAccessed: (workbook: any) => any;
      togglePageSelectorMenu: (open: boolean) => any;
      toggleResourcePlayerVisibility: (show: boolean) => any;
    } & UseModalDispatch)
  | any;

const Workbook = (props: Props) => {
  const {
    changeAppLocale,
    pageMenuOpen,
    trialDaysLeft,
    bookCopyrightTerms,
    getPageDetails,
    pdf,
    pageDetails,
    modalDisplaying,
    globalMenuTutorialOpen,
    currentWorkbook,
    currentPage,
    isClickAwayOnly,
    isTrialLicense,
    fetchWorkbookPDF,
    getWorkbookTableOfContents,
    saveProductAccess,
    shouldDoWorkbookTour,
    togglePageSelectorMenu
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { bookCode, edition, pageNumber, isZoom } = params as any;
  const shouldDisplayCopyrightModal =
    !bookCopyrightTerms && !globalMenuTutorialOpen && !modalDisplaying;

  const [
    { isFullScreen, isLayeredElementFullScreen, screen1Handle },
    { setFullScreen, setIsLayeredElementFullScreen }
  ] = useFullScreen();
  const [{ isOpen }, { setResources, setIsOpen }] = useResources();
  const [{ isOpen: isModalOpen }, { closeModal }] = useModal();
  const [{ zoomScale }, { resetZoom }] = useZoom();
  const { isIdle } = useIdleTimeout({});

  const isOnePage = location.pathname.includes('/zoom');
  const isZoomedIn = zoomScale > DEFAULT_ZOOM_SCALE;

  const { id: currentWorkbookId, bookUrl, locale, type } = currentWorkbook;
  const {
    presenterWrapper,
    fullScreenResourceWrapper,
    fullScreenSelectedResource,
    fullScreenResourceButton,
    fullScreenWrapper,
    fullScreenModalTitle,
    workbookStyle,
    workbookCover,
    iframeStyle
  } = useStyles({
    workbook: currentWorkbook,
    isIdle,
    isOpen,
    isFullScreen,
    isLayeredElementFullScreen
  }) as any;

  const handleTogglePageSelectorMenu = () => {
    togglePageSelectorMenu(!pageMenuOpen);
  };

  const handleFullScreenChange = (isFullScreen, handle) => {
    if (handle === screen1Handle && !isFullScreen) {
      setFullScreen(isFullScreen);
    }
  };

  const showCopyrightModal = () => {
    props.openModal({
      body: (
        <CopyrightModal
          currentWorkbook={currentWorkbook}
          handleDismiss={props.closeModal}
          saveCopyrightTerms={props.saveCopyrightTerms}
          showCheckbox={!bookCopyrightTerms}
        />
      ),
      routeParams: { id: props.id }
    });
  };

  const loadWorkbookData = async () => {
    if (!currentWorkbookId) return;

    await getWorkbookTableOfContents(currentWorkbookId);
    await fetchWorkbookPDF(bookUrl);

    saveProductAccess(currentWorkbookId);
  };

  const loadData = async () => {
    await loadWorkbookData();
    handlePageChange();
  };

  const handlePageChange = async () => {
    if (!pageNumber || !currentWorkbookId) {
      setResources([]);
      return;
    }

    const pagesDetails = await getPageDetails(
      currentWorkbookId,
      pageNumber ?? '',
      isZoom === 'zoom'
    );
    await getAndSetResources(pagesDetails);
    props.selectResource(mockedResource);

    const hasPageId = pageDetails && pageDetails[0];

    const event = {
      bookId: currentWorkbookId,
      bookType: type,
      pageNumber: parseInt(pageNumber ?? '', 10),
      pageLayout: isZoom ? 'singlePage' : 'doublePage',
      ...(hasPageId && { pageId: pageDetails[0].id })
    };

    PrekittApi.createEvent('accessPage', event);
  };

  const getAndSetResources = (pagesDetails): void => {
    if (isEmpty(pagesDetails) || !isArray(pagesDetails)) return;

    const resources = getGroupedResources(pagesDetails);
    setResources(resources);
  };

  const openResourcePlayer = selectedResource => {
    props.openDraggableModal({
      Title: props => (<DraggableModalTitle title={selectedResource.title} {...props} />) as any,
      body: <ResourcePlayer selectedResource={selectedResource} />,
      ariaLabel: tA11y('aria.modal', { title: selectedResource.title }),
      options: { closeOnBackdropClick: true },
      handleOnClose: handleResourceClose
    });
  };

  const handleSelectResource = async (resource: LiteResource) => {
    if (isFullScreen) {
      await props.selectResource(mockedResource);
      await props.selectResource(resource);
      return;
    }

    await props.selectResource(resource);
    openResourcePlayer(resource);
  };

  const handleResourceClose = async () => {
    setIsLayeredElementFullScreen(false);
    if (isModalOpen) closeModal();
    await props.selectResource(mockedResource);
  };

  const toggleResourceFullScreen = () => {
    setIsLayeredElementFullScreen(!isLayeredElementFullScreen);
  };

  const translateAmount =
    zoomScale <= DEFAULT_ZOOM_SCALE ? 0 : zoomScale * (zoomScale > 1.5 ? 100 : 50);

  useEffect(() => {
    if (bookCode && currentWorkbook?.bookCode && currentWorkbook.bookCode !== bookCode) {
      navigate('/404');
      return;
    }

    const pageNum: number = parseInt(pageNumber ?? '', 10);

    if (currentWorkbook?.bookCode && isClickAwayOnly) {
      navigate(getClickAwayResourcesLink());
      return;
    }

    if (pageNum && pageNum % 2 !== 0 && !isZoom) {
      navigate(`/books/${bookCode}/${edition}/${pageNum - 1}`);
    }

    if (!shouldDisplayCopyrightModal) {
      showCopyrightModal();
    }
  }, [currentWorkbook]);

  useEffect(() => {
    setIsOpen(false);
  }, [bookCode]);

  useEffect(() => {
    resetZoom();
  }, [isFullScreen, isOnePage]);

  useEffect(() => {
    if (document.body) {
      document.body.style.background = currentWorkbook.secondaryColor;
    }
  }, [currentWorkbook]);

  useEffect(() => {
    if (!locale) return;
    setLocale(locale);
    saveUserSettings(settingsTypes.locale, locale);
    changeAppLocale(locale);
  }, [locale]);

  useEffect(() => {
    setLastAccessed(currentWorkbook);
  }, [bookCode]);

  useEffect(() => {
    getUsersWorkbooks();
    loadData();
  }, [trialDaysLeft, bookCode, currentWorkbookId, pageNumber, isOnePage]);

  const renderCover = () => {
    const translateImageAmount = zoomScale <= DEFAULT_ZOOM_SCALE ? 0 : zoomScale * 100;
    return !currentWorkbook?.coverImageUrl?.endsWith('.html') ? (
      <img
        className={workbookCover}
        src={currentWorkbook.coverImageUrl}
        alt={`${currentWorkbook.title} ${tA11y('alt.cover')}`}
        style={{
          transform: `scale(${zoomScale})`,
          padding: `${
            translateImageAmount * 3
          }px ${translateImageAmount}px ${translateImageAmount}px ${translateImageAmount * 3}px`
        }}
      />
    ) : (
      <>
        {isFullScreen && (
          <div
            tabIndex={-1}
            style={{
              zIndex: 6,
              top: -60,
              position: 'absolute',
              height: '100vh',
              width: '100vw'
            }}
          />
        )}
        <iframe
          title={currentWorkbook.title}
          src={currentWorkbook.coverImageUrl}
          className={iframeStyle}
          style={{
            transform: `scale(${zoomScale}) translate(${translateAmount}px,${translateAmount}px)`
          }}
        />
      </>
    );
  };
  const renderWorkbookContent = () => {
    const fingerprint = pdf?.pdfInfo ? pdf?.pdfInfo?.fingerprint : undefined;

    if (!pageNumber) return renderCover();

    if (isZoom) {
      return (
        <SinglePageWorkbook
          workbook={currentWorkbook}
          currentPage={currentPage}
          iconSize={ICON_SIZE}
        />
      );
    }

    return (
      <DoublePageWorkbook
        workbook={currentWorkbook}
        currentPage={currentPage}
        fingerprint={fingerprint}
        iconSize={ICON_SIZE}
      />
    );
  };

  const renderWorkbook = () => {
    const isFullScreenAndHasSelectedResource = isFullScreen && props.selectedResource?.title;

    return (
      <div
        id="workbook"
        className={workbookStyle}
        style={
          isFullScreenAndHasSelectedResource
            ? {
                backgroundColor: 'rgb(51, 51, 51, 0.85)',
                zIndex: 9999,
                opacity: 1,
                transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
              }
            : {
                cursor: isZoomedIn ? 'inherit' : 'default'
              }
        }
      >
        {isFullScreenAndHasSelectedResource ? (
          <div className={fullScreenSelectedResource}>
            <div className={fullScreenModalTitle}>
              <div style={{ paddingLeft: '25px', fontSize: '24px' }}>
                {props.selectedResource?.title}
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  onClick={toggleResourceFullScreen}
                  style={{ paddingRight: '25px', fontSize: '24px' }}
                >
                  {isLayeredElementFullScreen ? (
                    <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
                  ) : (
                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                  )}
                </div>
                <div
                  onClick={handleResourceClose}
                  style={{ paddingRight: '25px', fontSize: '24px' }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            </div>
            <MemoPlayer selectedResource={props.selectedResource} />
          </div>
        ) : (
          renderWorkbookContent()
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        height: isFullScreen ? '100%' : `calc(100% - ${BOTTOM_NAV_HEIGHT}px)`
      }}
    >
      {shouldDoWorkbookTour && type !== 'storybook' && <WorkbookTour />}
      <div className={presenterWrapper}>
        <FullScreen
          handle={screen1Handle}
          className={fullScreenWrapper}
          onChange={handleFullScreenChange}
        >
          {isFullScreen && type !== 'storybook' && (
            <div className={fullScreenResourceWrapper}>
              <ResourcesButton buttonClass={fullScreenResourceButton} />
              <ResourceSlider
                workbook={currentWorkbook}
                handleSelectResource={handleSelectResource}
              />
            </div>
          )}
          <DragContainer isZoomedIn={zoomScale > DEFAULT_ZOOM_SCALE}>
            {renderWorkbook()}
          </DragContainer>
          <NavBar
            currentPage={currentPage}
            totalPages={currentWorkbook.totalPages}
            pageOffset={currentWorkbook.pageOffset}
            isZoom={location.pathname.includes('/zoom')}
            workbook={currentWorkbook}
            toggleMenu={handleTogglePageSelectorMenu}
            isTrialLicense={isTrialLicense}
            trialDaysLeft={trialDaysLeft}
            match={props.match}
            cancelDownloadRequest={cancelDownloadRequest}
            navBarDisabled={false}
            isIdle={isIdle}
          >
            <PageSelectorMenu
              show={pageMenuOpen}
              primaryBookColor={currentWorkbook.primaryColor}
              content={currentWorkbook.content}
              totalPages={currentWorkbook.totalPages}
              currentPageNumber={currentPage}
              toggleMenu={handleTogglePageSelectorMenu}
              workbookCode={currentWorkbook.bookCode}
            />
          </NavBar>
        </FullScreen>
        <ScreenOverlay show={pageMenuOpen} onClick={handleTogglePageSelectorMenu} zIndex={6} />
      </div>
      <ResourceSlider workbook={currentWorkbook} handleSelectResource={handleSelectResource} />
    </div>
  );
};

const useStyles = makeStyles(
  () =>
    ({
      presenterWrapper: ({ workbook }: any) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: workbook?.secondaryColor,
        position: 'relative'
      }),
      workbookCover: {
        height: '90%',
        width: 'auto',

        margin: '20px auto',
        userSelect: 'none',
        overflow: 'auto'
      },
      workbookStyle: ({ workbook }: any) => ({
        overflow: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: `100%`,
        width: '100%',
        position: 'relative',
        backgroundColor: workbook?.secondaryColor
      }),
      fullScreenResourceButton: ({ isIdle, isOpen, workbook }: any) => ({
        border: '1px solid black !important',
        background: workbook?.primaryColor,
        marginRight: '10px !important',
        position: 'absolute',
        right: -9,
        top: 30,
        transition: '0.5s',
        transform: !isIdle || isOpen ? 'translateX(0px)' : 'translateX(250px)',
        '&:hover': {
          background: workbook?.primaryColor,
          opacity: 0.5,
          transform: 'scale(1.02, 1.02)'
        }
      }),
      fullScreenSelectedResource: ({ isLayeredElementFullScreen }: any) => ({
        zIndex: 9999,
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: isLayeredElementFullScreen ? 'absolute' : 'relative',
        top: 0,
        width: isLayeredElementFullScreen ? '100%' : 'auto',
        maxWidth: '100%',
        '& #resourcePlayer': {
          maxHeight: isLayeredElementFullScreen ? 'calc(100vh - 190px)' : 'unset',
          width: isLayeredElementFullScreen ? '100vw' : 'auto',
          '& video': {
            maxHeight: isLayeredElementFullScreen ? 'calc(100vh - 90px)' : 'unset'
          }
        },
        '& #player': {
          height: 'auto',
          maxHeight: '100%',
          width: isLayeredElementFullScreen ? '100%' : 'unset',
          alignItems: 'center'
        }
      }),
      fullScreenResourceWrapper: {
        position: 'absolute',
        right: -7,
        top: 30,
        zIndex: 10
      },
      fullScreenWrapper: {
        height: '100%',
        width: '100% !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      },
      fullScreenModalTitle: {
        backgroundColor: '#0171BB',
        color: 'white',
        width: '100%',
        height: 'auto !important',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '25px 0px'
      },
      iframeStyle: {
        backgroundColor: 'transparent',
        width: '100%',
        height: 'calc(100vh - 150px)',
        border: 'none'
      }
    } as any)
);

const mapStateToProps = (state: AppState, { router: { params } }) => ({
  bookCopyrightTerms: isBookCopyrightTermsViewed(state, params),
  copyrightTerms: state.user.settings.copyrightTerms,
  currentPage: getCurrentPage(state, params),
  currentWorkbook: getCurrentWorkbook(state, params),
  globalMenuTutorialOpen: state.ui.globalMenuTutorialOpen,
  hasClickAway: hasClickAway(state),
  isClickAwayOnly: hasClickAwayOnly(state, params),
  isLicenseExpired: getLicenseIsExpired(state, params),
  isShowLicenseExpirationAlert: isShowLicenseExpirationAlert(state, params),
  isTrialLicense: getLicenseIsTrial(state, params),
  messaging: state.messaging,
  modalDisplaying: state.messaging.showAppModal,
  modalType: state.messaging.modalType,
  pageMenuOpen: state.ui.pageSelectorMenuOpen,
  selectedResource: state.resources.selectedResource,
  showResourcePlayer: state.resources.showResourcePlayer,
  trialDaysLeft: getTrialDaysRemaining(state.user),
  shouldDoWorkbookTour: state.ftue.queuedTours.includes('workbook'),
  viewedWelcome: !!state.user.settings.licensing,
  workbook: state.workbook,
  firstTimeUser: !!state.user.settings?.lastLoginDate
});

const withRedux = connect(mapStateToProps, {
  cancelDownloadRequest,
  changeAppLocale,
  fetchWorkbookPDF,
  getPageDetails,
  getUsersWorkbooks,
  getWorkbookTableOfContents,
  saveProductAccess,
  saveUserSettings,
  selectResource,
  setLastAccessed,
  togglePageSelectorMenu: toggleMenuByType('pageSelector'),
  toggleResourcePlayerVisibility,
  toggleSlideoutMenuTutorial,
  toggleSlideoutMenu
});

export default flow(
  withModal,
  withRedux,
  withRouter,
  withFullScreen,
  withFtueTour('workbook'),
  withRoutePersistence('books')
)(Workbook as any);
