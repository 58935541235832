import React from 'react';

const ZOOM_SCALE_INCREMENT = 0.1;

export const MAX_ZOOM_SCALE = 3.0;
export const MIN_ZOOM_SCALE = 0.5;
export const DEFAULT_ZOOM_SCALE = 1;

export enum ZoomTypes {
  IN = 'in',
  OUT = 'out',
  RESET = 'reset'
}

export type UseZoomState = {
  zoomScale: number;
};

type Action =
  | { type: 'DECREASE_ZOOM_SCALE' }
  | { type: 'INCREASE_ZOOM_SCALE' }
  | { type: 'RESET_ZOOM_SCALE' };

export type UseZoomDispatch = {
  increaseZoom: () => void;
  decreaseZoom: () => void;
  resetZoom: () => void;
};

type UseZoom = [UseZoomState, UseZoomDispatch];

export const getDefaultZoomPayload = (): UseZoomState => ({
  zoomScale: DEFAULT_ZOOM_SCALE
});

const zoomReducer = (state: UseZoomState, action: Action): UseZoomState => {
  switch (action.type) {
    case 'DECREASE_ZOOM_SCALE':
      return {
        ...state,
        zoomScale: +(state.zoomScale - ZOOM_SCALE_INCREMENT).toFixed(2)
      };
    case 'INCREASE_ZOOM_SCALE':
      return {
        ...state,
        zoomScale: +(state.zoomScale + ZOOM_SCALE_INCREMENT).toFixed(2)
      };
    case 'RESET_ZOOM_SCALE':
      return {
        ...state,
        zoomScale: DEFAULT_ZOOM_SCALE
      };
    default:
      return getDefaultZoomPayload();
  }
};

const ZoomContext = React.createContext<any>(getDefaultZoomPayload());

export const ZoomProvider = (props: any) => {
  const [state, dispatch] = React.useReducer<React.Reducer<UseZoomState, Action>>(
    zoomReducer,
    getDefaultZoomPayload()
  );

  const decreaseZoom = () => {
    dispatch({ type: 'DECREASE_ZOOM_SCALE' });
  };

  const increaseZoom = () => {
    dispatch({ type: 'INCREASE_ZOOM_SCALE' });
  };

  const resetZoom = () => {
    dispatch({ type: 'RESET_ZOOM_SCALE' });
  };

  return (
    <ZoomContext.Provider value={[state, { decreaseZoom, increaseZoom, resetZoom }]}>
      {props.children}
    </ZoomContext.Provider>
  );
};

export const useZoom = (): UseZoom => {
  const [state, dispatch] = React.useContext<UseZoom>(ZoomContext);
  return [state, dispatch];
};
