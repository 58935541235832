import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import debounce from 'lodash/debounce';
import { remCalc } from '../../../util/style-util';
import { Resource } from 'types';
import {
  resourceDarkGray2,
  resourceLightGray,
  resourceLightGray2
} from '../../../styles/style-constants';
import { getPageNumber } from '../../../util/resource-util';
import PinIcon from '../../icons/PinIcon';
import PinnedIcon from '../../icons/PinnedIcon';
import BasicButton from '../../../components/core/BasicButton';
import maybe, { MaybeProps } from '../../../components/core/enhancers/maybe';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props = {
  resource: Resource;
  hasUnpinIcon?: boolean;
  isFavoritePage?: boolean;
  onClickPinIcon: (event: any) => any;
};

function ResourceItemFooter(props: Props) {
  function getDebouncedHandler(callback: (event: any) => any, wait: number) {
    const debounced = debounce(callback, wait);

    return function (e: any) {
      e.stopPropagation();
      e.persist();
      debounced(e);
    };
  }

  const {
    resourceItemFooter,
    leftSideFooter,
    rightSideFooter,
    pageNumber,
    iconContainer,
    iconImg
  } = styles;
  const MaybeAlternateIcon: React.FC<MaybeProps> = maybe(BasicButton);
  const MaybePinButton: React.FC<MaybeProps> = maybe(BasicButton);

  return (
    <div className={css(resourceItemFooter)}>
      <div className={css(leftSideFooter)}>
        <div className={css(iconContainer)}>
          <img
            className={css(iconImg)}
            alt={props.resource.type.title}
            title={props.resource.type.title}
            src={props.resource.type.icon}
          />
        </div>
        <MaybeAlternateIcon
          shouldRender={props.resource.type.icon.alternate !== undefined}
          buttonStyles={[iconContainer]}
        >
          <img
            className={css(iconImg)}
            alt={props.resource.title}
            title={props.resource.title}
            src={props.resource.type.icon.alternate}
          />
        </MaybeAlternateIcon>
      </div>
      <div className={css(rightSideFooter)}>
        <span className={css(pageNumber)}>
          {getPageNumber(props.resource) ? 'p. ' + getPageNumber(props.resource).toString() : ''}
        </span>
        <MaybePinButton
          aria-label={`${tA11y('aria.pin')} ${props.resource.title}`}
          shouldRender={!props.isFavoritePage}
          buttonStyles={[iconContainer]}
          onClick={getDebouncedHandler(props.onClickPinIcon, 200)}
          aria-pressed={!!props.hasUnpinIcon}
        >
          {props.hasUnpinIcon ? <PinnedIcon /> : <PinIcon />}
        </MaybePinButton>
      </div>
    </div>
  );
}
const styles = StyleSheet.create({
  resourceItemFooter: {
    backgroundColor: resourceLightGray2,
    borderTop: remCalc(1) + ' solid' + resourceLightGray,
    bottom: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: remCalc(18)
  },
  leftSideFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    padding: remCalc(5)
  },
  rightSideFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: remCalc(5)
  },
  pageNumber: {
    color: resourceDarkGray2,
    paddingRight: remCalc(20)
  },
  iconContainer: {
    width: remCalc(32),
    height: remCalc(32),
    marginRight: remCalc(3),
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    ':focus': {
      border: 'none'
    }
  },
  iconImg: {
    marginTop: remCalc(2),
    marginLeft: remCalc(2),
    width: remCalc(24),
    height: remCalc(24)
  }
});

export default ResourceItemFooter;
