export const getQA01 = () => ({
  application: 'prekitt',
  pliURL: 'https://qa01-pli.lwtears.com',
  agiURL: 'https://qa01-agi.lwtears.com',
  apiGatewayURL: 'https://qa01-api.lwtears.com',
  apiURL: `https://qa01-api.lwtears.com/prekitt`,
  companyURL: 'https://dev.lwtears.com',
  storeURL: 'http://hwtcopy.commercev3.com',
  surveyURL: 'https://www.surveymonkey.com/r/PreKittFreeTrialExtension',
  gATrackingId: 'UA-106790492-4',
  supportEmail: '0c0f3614.lwtears.com@amer.teams.ms',
  wmUrl: 'https://qa01-worksheet-maker.lwtears.com/0?lite=true',
  copyrightEmail: 'legal@lwtears.com',
  digitalProductSupportUrl: 'https://dev.lwtears.com/contact-us/digital-product-support'
});
