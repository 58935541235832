import React from 'react';
import { Transition } from 'react-transition-group';
import { TweenMax, Back } from 'gsap';
import { StyleSheet, css } from 'aphrodite/no-important';
import WorkbooksMenuItem from './WorkbooksMenuItem';
import { getBadgeType } from '../../modules/user';
import { Workbook, License, Product } from 'types';
import { getGradeLevelDescription, getRouteForBook } from '../../util/workbook-util';

type Props = {
  workbooks: Array<Workbook>;
  show: boolean;
  userId: any;
  toggleMenu?: (isOpen?: boolean) => any;
  itemStyles?: any;
  licenses: Array<License>;
  allWorkbooksWithSameLicenseType?: boolean;
};

const styles = StyleSheet.create({
  workbookList: {
    display: 'flex',
    flexFlow: 'row wrap',
    overflow: 'hidden',
    listStyle: 'none',
    padding: '0 14px 5px 10px'
  }
});

const WorkbookMenu = ({
  workbooks,
  show,
  toggleMenu,
  itemStyles,
  licenses,
  allWorkbooksWithSameLicenseType
}: Props) => {
  const animate = (list: HTMLUListElement): TweenMax => {
    TweenMax.staggerFromTo(
      list.children,
      0.3,
      {
        scale: show ? 0 : 1,
        opacity: show ? 0 : 1
      },
      {
        delay: show ? 0.5 : 0,
        scale: show ? 1 : 0,
        opacity: show ? 1 : 0,
        ease: Back.easeOut.config(2.5)
      },
      show ? 0.35 : 0
    );
  };

  const getStyles = (
    wb: Workbook,
    licenses: Array<License>,
    itemStyles?: any,
    allWorkbooksWithSameLicenseType?: boolean
  ): any => {
    let workbookItemSize: any;

    if (itemStyles) {
      if (allWorkbooksWithSameLicenseType) {
        workbookItemSize = itemStyles.workbookItemLarge;
      } else if (getBadgeType(licenses)) {
        workbookItemSize = [itemStyles.workbookItemLarge, itemStyles.workbookItemSmall];
      } else {
        workbookItemSize = [itemStyles.workbookItemLarge, itemStyles.workbookItemMedium];
      }

      return {
        workbookTitle: itemStyles.workbookTitle,
        gradeLevelDescription: itemStyles.gradeLevelDescription,
        workbookItem: workbookItemSize
      };
    }

    return {};
  };

  const getLicense = (licenses: Array<License>, products: Product[]) =>
    licenses.find(l => products.map(product => product.productId).includes(l.productId));

  const transitionProps: any = {
    in: show,
    timeout: 500,
    onEnter: animate,
    onExit: animate,
    mountOnEnter: true,
    unmountOnExit: true,
    appear: true
  };
  return (
    <Transition {...transitionProps}>
      <ul className={css(styles.workbookList)}>
        {workbooks.map((wb: Workbook) => (
          <WorkbooksMenuItem
            workbook={wb}
            handleWorkbookClick={toggleMenu}
            key={wb.id}
            routeTo={getRouteForBook(wb, getLicense(licenses, wb.products))}
            styles={getStyles(wb, licenses, itemStyles, allWorkbooksWithSameLicenseType)}
            renderWorkbookBadge={!!getBadgeType(licenses)}
            badgeType={getBadgeType(licenses)}
            gradeLevelDescription={getGradeLevelDescription(wb.gradeLevel, wb.locale)}
          />
        ))}
      </ul>
    </Transition>
  );
};

export default WorkbookMenu;
