import React, { Component } from 'react';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

import { ComponentType } from 'react';
import withRouter from 'state/hooks/withRouter';
import { compose } from 'redux';

type Props = {
  location: Location;
  locale: string;
  router: {
    location: { pathname: string };
    params: {
      bookCode: string;
      edition: string;
      pageNumber?: string;
      isZoom?: string;
      section?: string;
    };
  };
  userId: number;
};

const withRoutePersistence = (name: string, saveData?: any) => (Route: ComponentType<Props>) => {
  class PersistentRoute extends Component<Props> {
    componentDidMount() {
      this.persistRoute();
    }

    componentDidUpdate(prevProps: Props) {
      const {
        router: {
          location: { pathname: oldPathname }
        }
      } = prevProps;
      const {
        router: {
          location: { pathname: newPathname }
        }
      } = this.props;

      if (oldPathname !== newPathname) {
        this.persistRoute();
      }
    }

    persistRoute() {
      const {
        userId,
        locale,
        router: {
          params: { bookCode, edition },
          location: { pathname }
        }
      } = this.props;

      const routeData = {
        pathname,
        bookCode,
        edition,
        locale
      };

      saveData?.(userId, routeData);
    }

    render() {
      return <Route {...omit(this.props, ['userId'])} />;
    }
  }

  return compose(
    withRouter,
    connect((state: any) => ({
      userId: state.user.id,
      locale: state.characterSelection.locale
    }))
  )(PersistentRoute);
};

export default withRoutePersistence;
