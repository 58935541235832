//
import {
  FETCHING_PDF,
  FETCHING_WORKBOOK_CONTENT,
  FETCH_PDF_SUCCESS,
  FETCH_WORKBOOKS_SUCCESS,
  FETCH_WORKBOOK_CONTENT_SUCCESS,
  FETCH_PAGE_DETAILS_SUCCESS,
  RESET_PAGE_DETAILS,
  SET_LAST_WORKBOOK,
  SET_LAST_TEACHERS_GUIDE,
  SET_LAST_STORYBOOK,
  SET_CURRENT_WORKBOOK
} from './workbook-types';

const initialState = {
  pdf: {},
  pageDetails: {},
  allWorkbooks: {},
  content: [],
  lastWorkbook: null,
  lastTeachersGuide: null,
  lastStorybook: null,
  currentWorkbook: null
};

const workbook = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCHING_PDF:
    case FETCHING_WORKBOOK_CONTENT:
      return {
        ...state,
        pdf: {}
      };
    case FETCH_PAGE_DETAILS_SUCCESS:
      return {
        ...state,
        pageDetails: action.pageDetails
      };
    case RESET_PAGE_DETAILS: {
      return {
        ...state,
        pageDetails: []
      };
    }
    case FETCH_PDF_SUCCESS:
      return {
        ...state,
        pdf: action.pdf
      };
    case FETCH_WORKBOOKS_SUCCESS:
      return {
        ...state,
        allWorkbooks: action.workbooks
      };
    case FETCH_WORKBOOK_CONTENT_SUCCESS:
      return {
        ...state,
        content: action.content
      };
    case SET_LAST_WORKBOOK:
      return {
        ...state,
        lastWorkbook: action.workbook
      };
    case SET_LAST_TEACHERS_GUIDE:
      return {
        ...state,
        lastTeachersGuide: action.teachersGuide
      };
    case SET_LAST_STORYBOOK:
      return {
        ...state,
        lastStorybook: action.storybook
      };
    case SET_CURRENT_WORKBOOK:
      return {
        ...state,
        currentWorkbook: action.currentWorkbook
      };
    default:
      return state;
  }
};

export default workbook;
