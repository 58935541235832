//
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import keyBy from 'lodash/fp/keyBy';
import flow from 'lodash/flow';
import {
  fetchTours,
  fetchToursSuccess,
  fetchToursFailure,
  addToTourQueue,
  removeFromTourQueue,
  prioritizeTour,
  incrementTourStep,
  putTourSettingsSuccess,
  putTourSettingsFailure,
  completeCurrentTour
} from './actions';
import { filterViewedSteps, processHasUnviewedSteps } from './util';
import PreKittApi from '../../api';
import type { FtueTour } from 'types';
import type { ThunkAction, Dispatch, GetState } from './types.js';

export function getTours(): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState, api: typeof PreKittApi) => {
    const { toursViewed } = getState().ftue;
    dispatch(fetchTours());

    try {
      const response = await api.getTourSteps();
      const tours = flow(
        filterViewedSteps(toursViewed),
        keyBy('name')
      )(response.data);

      return dispatch(fetchToursSuccess(tours));
    } catch (err) {
      dispatch(fetchToursFailure(err));
    }
  };
}

export function saveTourStep(tour: FtueTour[]): ThunkAction {
  return async (dispatch: Dispatch, _: GetState, api: typeof PreKittApi) => {
    try {
      await api.putTourStep(tour);
      dispatch(putTourSettingsSuccess);
    } catch (err) {
      dispatch(putTourSettingsFailure);
    }
  };
}

export function advanceTourStep(): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { allTours, queuedTours, currentStep } = getState().ftue;
    const currentTour = allTours[queuedTours[0]];
    const viewedStep = {
      name: currentTour.steps[currentStep].name,
      viewed: true
    };
    const updatedTour = { name: currentTour.name, steps: [viewedStep] };

    dispatch(saveTourStep([updatedTour]));
    await dispatch(incrementTourStep(viewedStep));

    if (currentTour.steps.length === currentStep + 1) {
      dispatch(completeCurrentTour());
    }
  };
}

export function queueTour(name: string, prioritize?: boolean): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const {
      ftue,
      ftue: { queuedTours }
    } = getState();
    const notQueued = !queuedTours.includes(name);
    const hasUnviewedSteps = processHasUnviewedSteps(name, ftue);
    const enqueue = isBoolean(prioritize) && prioritize ? prioritizeTour : addToTourQueue;

    if (hasUnviewedSteps && notQueued) {
      dispatch(enqueue(name));
    }
  };
}

export function dequeueTour(...names: string[]): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { queuedTours } = getState().ftue;

    if (isEmpty(queuedTours)) return;

    dispatch(removeFromTourQueue(names));
  };
}
