import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from '@lwtears/lwt-common-frontend/lib/Modal/providers/ModalProvider';
import { FullScreenProvider } from './FullScreenProvider';
import { ResourcesProvider } from './ResourceProvider';
import { ZoomProvider } from './ZoomProvider';
import { A11yTabNodeProvider } from '@lwtears/lwt-common-frontend/lib/@a11y';
import store from 'store';
import { StyledEngineProvider } from '@mui/material';

const BaseAppProviders = ({ children }) => {
  const A11yTabNodeProvider1 = A11yTabNodeProvider as any;
  const ModalProvider1 = ModalProvider as any;
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <FullScreenProvider>
          <ZoomProvider>
            <ResourcesProvider>
              <A11yTabNodeProvider1>
                <ModalProvider1>{children}</ModalProvider1>
              </A11yTabNodeProvider1>
            </ResourcesProvider>
          </ZoomProvider>
        </FullScreenProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
};

export default BaseAppProviders;
