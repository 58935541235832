//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ltGrey } from '../../styles/style-constants';
import { remCalc } from '../../util/style-util';
import gssLogo from '../../images/gss-logo-white.svg';
import chalkboardBackground from '../../images/chalkboard-background.jpg';

import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props = {
  showHeader?: boolean;
  showFooter?: boolean;
  children: any | Array<any>;
};

const SetupPage = ({ showHeader, showFooter, children }: Props) => (
  <div className={css(styles.setup)}>
    {showHeader && (
      <header className={css(styles.header)}>
        <img src={gssLogo} alt={tA11y('alt.gssLogo')} className={css(styles.gssLogo)} />
      </header>
    )}
    {children}
    {showFooter && (
      <footer className={css(styles.footer)}>
        <div>Learning Without Tears Copyright © 2018</div>
      </footer>
    )}
  </div>
);

const styles = StyleSheet.create({
  header: {
    maxWidth: '95%',
    padding: remCalc(25)
  },
  setup: {
    backgroundImage: `url(${chalkboardBackground})`,
    backgroundSize: 'cover',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  footer: {
    color: ltGrey,
    fontWeight: 400,
    textAlign: 'right',
    padding: remCalc(10, 25)
  },
  gssLogo: {
    width: '30%'
  }
});

SetupPage.defaultProps = {
  showHeader: true,
  showFooter: true
};

export default SetupPage;
