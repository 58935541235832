export const getDevNext = () => ({
  application: 'prekitt',
  pliURL: 'https://dev-next-pli.lwtears.com',
  agiURL: 'https://dev-next-agi.lwtears.com',
  apiGatewayURL: 'https://dev-next-api.lwtears.com',
  apiURL: `https://dev-next.lwtears.com/prekitt`,
  companyURL: 'https://dev-next-www.lwtears.com',
  gaTrackingId: 'UA-106790492-4',
  supportEmail: '0c0f3614.lwtears.com@amer.teams.ms',
  wmUrl: 'https://dev-next-worksheet-maker.lwtears.com/0?lite=true',
  copyrightEmail: 'legal@lwtears.com',
  digitalProductSupportUrl: 'https://dev-next-www.lwtears.com/contact-us/digital-product-support'
});
