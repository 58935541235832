import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Pin from '../../images/Pin.svg';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const styles = StyleSheet.create({
  animatedUnPin: {
    width: '64%'
  }
});

function PinIcon() {
  return <img src={Pin} className={css(styles.animatedUnPin)} alt={`${tA11y('alt.unpinIcon')}`} />;
}

export default PinIcon;
