import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import flow from 'lodash/fp/flow';
import IconModal from '../components/core/Modals/IconModal';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import config from 'config';
import Footer from '../components/core/PageFooter';
import CompanyInfo from '../components/Contact/CompanyInfo';
import navMap from '../images/icons/nav-map.png';
import multiIcon from '../images/icons/mult-icon-leg.png';
import contactIcon from '../images/icons/contact.png';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { fetchSupportResources } from '../actions/resource-operations';
import { toggleSlideoutMenu, toggleSlideoutMenuTutorial } from '../actions/ui-actions';
import { getUsersWorkbooks, WorkbookState } from '../modules/workbook';
import { getLicenseIsTrial, getTrialDaysRemaining } from '../modules/user';
import PrekittApi from '../api';
import { remCalc } from '../util/style-util';

import ModalHeader from '../components/core/Modals/ModalHeader';
import { Paper } from '@mui/material';
import { Resource, AppStoreState } from 'types';
import withRouter from 'state/hooks/withRouter';
const translateSupport = key => translate('support', key);
const prekittVideoTutorialId = 199;
const prekittMultimediaIconId = 200;
type Props = {
  router: any;
  isTrialLicense: boolean;
  trialDaysLeft: number;
  workbookState: WorkbookState;
  toggleSlideoutMenu: (value: boolean) => any;
  toggleSlideoutMenuTutorial: (value: boolean) => any;
} & UseModalDispatch;

type State = {
  prekittWelcomeVideoResource: Resource;
  prekittIconLegendResource: Resource;
};

export class Support extends Component<any, State> {
  constructor(props: Props) {
    super(props);

    this.state = { prekittWelcomeVideoResource: '', prekittIconLegendResource: '' };
  }

  componentDidMount() {
    /* The LWT Live Agent chat widget is loaded via a global script tag in index.html
       and immediately hidden. We only show it in this container. */
    const helpButton: any = document.getElementsByClassName('embeddedServiceHelpButton')[0];
    if (helpButton && helpButton.style) helpButton.style.display = 'block';

    this.props.getUsersWorkbooks();
    this.fetchResources();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.fetchResources();
    }
  }

  componentWillUnmount() {
    const helpButton: any = document.getElementsByClassName('embeddedServiceHelpButton')[0];
    if (helpButton && helpButton.style) {
      helpButton.style.display = 'none';
    }
  }

  fetchResources = async () => {
    const { data } = await this.props.fetchSupportResources();

    const videoWelcome = data.find(resource => resource.id === prekittVideoTutorialId);
    const legend = data.find(resource => resource.id === prekittMultimediaIconId);

    this.setState({
      prekittWelcomeVideoResource: videoWelcome,
      prekittIconLegendResource: legend
    });

    PrekittApi.createEvent('viewedSupport', { section: 'support' });
  };

  createEvent(resource: Resource) {
    const event = {
      section: 'support',
      resourceId: resource.id,
      resourceType: resource.type.type
    };

    PrekittApi.createEvent('accessResource', event);
  }

  handleContactClick = (): Window | null => window.open(config.digitalProductSupportUrl, '_blank');

  handleshowCloseIconModal = () => {
    this.createEvent(this.state.prekittIconLegendResource);
    this.props.openModal({
      Title: () => <ModalHeader isDismissOnClick title="support:iconLegend" />,
      body: <IconModal iconLegendURL={this.state.prekittIconLegendResource.src} />,
      ariaLabel: tA11y('aria.iconLegend'),
      options: { closeOnBackdropClick: true }
    });
  };

  handleShowTutorialOverlay = () => {
    const { router, toggleSlideoutMenu, toggleSlideoutMenuTutorial } = this.props;
    toggleSlideoutMenu(true);
    toggleSlideoutMenuTutorial(true);
    PrekittApi.createEvent('viewedSupportOverlay', { section: 'support' });
    router.navigate(`/home`);
  };

  render() {
    const { isTrialLicense, trialDaysLeft, workbookState } = this.props;
    const {
      containerPage,
      mainContent,
      headerContainer,
      headerContainerH2,
      icon,
      mailIcon,
      supportButton,
      supportButtonHeader,
      supportButtonText
    } = styles;
    const colors = {
      theme: '#0171BB',
      text: '#FFFFFF'
    };

    return (
      <section className={css(containerPage)} role="main">
        <div className={css(mainContent)}>
          <div className={css(headerContainer)}>
            <h2 className={css(headerContainerH2)}>{translateSupport('description')}</h2>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '20px'
            }}
          >
            <Paper
              component="button"
              className={css(supportButton)}
              style={{
                display: Object.entries(workbookState.allWorkbooks).length > 0 ? 'initial' : 'none'
              }}
              onClick={this.handleShowTutorialOverlay}
            >
              <img src={navMap} className={css(icon)} alt={tA11y('alt.navMap')} />
              <div className={css(supportButtonHeader)}>{translateSupport('prekittMap')}</div>
              <div className={css(supportButtonText)}>
                {translateSupport('prekittMapDescription')}
              </div>
            </Paper>
            <Paper
              tabIndex={0}
              component="button"
              className={css(supportButton)}
              onClick={this.handleshowCloseIconModal}
            >
              <img src={multiIcon} className={css(icon)} alt={tA11y('alt.multiIcon')} />
              <div className={css(supportButtonHeader)}>{translateSupport('iconLegend')}</div>
              <div className={css(supportButtonText)}>
                {translateSupport('iconLegendDescription')}
              </div>
            </Paper>
            <Paper
              component="button"
              className={css(supportButton)}
              onClick={() => this.handleContactClick()}
            >
              <img src={contactIcon} className={css(mailIcon)} alt={tA11y('alt.contactIcon')} />
              <div className={css(supportButtonHeader)}>{translateSupport('contact')}</div>
              <div className={css(supportButtonText)}>{translateSupport('contactDescription')}</div>
            </Paper>
          </div>
          <CompanyInfo />
        </div>
        <Footer
          themeColor={colors.theme}
          textColor={colors.text}
          isTrialLicense={isTrialLicense}
          trialDaysLeft={trialDaysLeft}
        />
      </section>
    );
  }
}

const styles = StyleSheet.create({
  containerPage: {
    backgroundColor: '#F2F8FF',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mainContent: {
    flex: 1
  },
  headerContainer: {
    padding: remCalc(50, 0, 16)
  },
  headerContainerH2: {
    fontSize: 36,
    margin: 0,
    textAlign: 'center',
    fontWeight: 500
  },
  container: {
    color: '#bca70b',
    fontSize: 32,
    marginTop: remCalc(200)
  },
  icon: {
    height: remCalc(100),
    margin: 'auto',
    marginBottom: remCalc(10)
  },
  mailIcon: {
    height: remCalc(90),
    margin: 'auto',
    marginTop: remCalc(5),
    marginBottom: remCalc(12)
  },
  supportButton: {
    background: '#ffffff',
    width: remCalc(325),
    height: remCalc(294),
    margin: remCalc(15),
    cursor: 'pointer',
    textAlign: 'center',
    paddingTop: remCalc(36),
    fontFamily: 'futura-pt',
    border: 'solid 1px #aaa',
    ':hover': {
      transform: 'scale(1.05, 1.05)',
      boxShadow: 'rgb(176 176 176) 0px 3px 7px 1px'
    }
  },
  supportButtonHeader: {
    fontSize: 24,
    fontWeight: 500,
    paddingTop: remCalc(32),
    fontFamily: 'futura-pt'
  },
  supportButtonText: {
    fontSize: 20,
    fontWeight: 'normal'
  }
});

const mapStateToProps = (state: AppStoreState, { router: { params } }) => ({
  isTrialLicense: getLicenseIsTrial(state, params),
  trialDaysLeft: getTrialDaysRemaining(state.user),
  workbookState: state.workbook,
  locale: state.user.settings.locale
});
const withRedux = connect(mapStateToProps, {
  toggleSlideoutMenu,
  toggleSlideoutMenuTutorial,
  getUsersWorkbooks,
  fetchSupportResources
});

export default flow(withRedux, withModal, withRouter)(Support);
