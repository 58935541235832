export const ltGrey = '#c3c3c3';
export const white = '#fff';
export const black = '#000';
export const lightGray = '#e7e7e7';
export const darkGray = '#6a6a6a';
export const darkestGray = '#333';
export const activeGreen = '#45D545';
export const oopsGreen = '#27862D';
export const errorRed = '#ff0033';
export const prekittOrange = '#ff9133';
export const gssYellow = '#F9E300';
export const gssGreen = '#94C948';
export const lnfBlue = '#006091';
export const lnfGray = '#eaeaea';
export const pinRed = '#eb3933';
export const buttonBlue = '#0075b5';

/********************************
 *       LWT Colors
 ********************************/

export const lwtYellow = '#F9DC33';
export const lwtBlue = '#0278C0';
export const lwtDarkBlue = '#005FA7';

/********************************
 *       Resource Theme Colors
 ********************************/

export const resourceThemeColors = {
  purple: '#A56DBB',
  orange: '#FF9133',
  yellow: '#F4D571',
  green: '#1CB1A8',
  pink: '#FD8389',
  blue: '#5C87D4'
};

/********************************
 *       Resources Colors
 ********************************/
export const resourceDarkGray = '#B0B0B0';
export const resourceDarkGray2 = '#2E2E2E';
export const resourceLightGray = '#E8E8E8';
export const resourceLightGray2 = '#F5F5F5';
export const resourceLightGray3 = '#C9C9C9';
export const resourceLightGray4 = '#E5E5E5';
export const resourcePinGreen = '#118C44';
export const gray3 = '#2E2E2E';

/********************************
 *       Page404
 ********************************/
export const lightGreen = '#31AA39';
export const gray4 = '#404040';

export const FOCUS_ACTIVE_WHITE_BORDER_MUI = {
  '&:focus': {
    outline: 'none',
    borderColor: '#fff',
    border: '2px solid #ffffff !important',
    color: '#fff'
  },
  '&:active': {
    outline: 'none',
    border: '2px solid  #ffffff !important',
    borderColor: '#fff',
    color: '#fff'
  }
};
export const FOCUS_ACTIVE_WHITE_BORDER = {
  ':focus': {
    outline: 'none',
    borderColor: '#fff ',
    border: '2px solid #ffffff ',
    color: '#fff'
  },
  ':active': {
    outline: 'none',
    border: '2px solid  #ffffff',
    borderColor: '#fff',
    color: '#000000'
  }
};

export const PREKIT_FONT = 'GSS Futura';
export const PREKITT_BG_COLOR = '#F2F8FF';
