//
import React from 'react';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { StyleSheet, css } from 'aphrodite/no-important';
import SquareButton from '../SquareButton';
import { remCalc } from '../../../util/style-util';
const translateModals = key => translate('modals', key);

type Props = {
  handleRefresh?: () => any;
};

const styles = StyleSheet.create({
  refreshModal: {
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: 25,
    border: '4px solid #000',
    marginTop: '-4px',
    maxWidth: remCalc(250),
    '@media(min-width: 1000px)': {
      maxWidth: remCalc(380)
    }
  },
  modalHeading: {
    fontFamily: 'futura-pt',
    fontWeight: 600,
    fontSize: remCalc(24),
    '@media(min-width: 1000px)': {
      fontSize: remCalc(30)
    }
  },
  modalText: {
    textAlign: 'left',
    fontWeight: 400
  },
  modalButton: {
    margin: remCalc(20, 0)
  }
});

const RefreshModal = ({ handleRefresh }: Props) => (
  <div className={css(styles.refreshModal)}>
    <h2 className={css(styles.modalHeading)}>{translateModals('updatedPreKittTitle')}</h2>
    <p className={css(styles.modalText)}>{translateModals('updatedPreKittDescription')}</p>
    <SquareButton
      buttonStyles={[styles.modalButton]}
      text={translateModals('clickToRefresh')}
      onClick={handleRefresh}
    />
  </div>
);

export default RefreshModal;
