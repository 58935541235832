import type { Resource } from 'types';
import type { LiteResource } from '../../reducers/resource-reducer';

export type AssessmentState = {
  administration: Array<Resource>;
  benchmarkAssessments: [];
  observationChecklists: [];
  implementationResources: Array<Resource>;
  overview: LiteResource;
};

// fetch Lesson Plan HWT Order
export const FETCH_ASSESSMENT_RESOURCES_REQUEST = 'FETCH_ASSESSMENT_RESOURCES_REQUEST';
export const FETCH_ASSESSMENT_RESOURCES_SUCCESS = 'FETCH_ASSESSMENT_RESOURCES_SUCCESS';
export const FETCH_ASSESSMENT_RESOURCES_FAILURE = 'FETCH_ASSESSMENT_RESOURCES_FAILURE';

// Lesson Plan HWT Order
export type FETCH_ASSESSMENT_RESOURCES_REQUEST_ACTION = {
  type: typeof FETCH_ASSESSMENT_RESOURCES_REQUEST;
};
export type FETCH_ASSESSMENT_RESOURCES_SUCCESS_ACTION = {
  type: typeof FETCH_ASSESSMENT_RESOURCES_SUCCESS;
  assessmentResources: AssessmentState;
};
export type FETCH_ASSESSMENT_RESOURCES_FAILURE_ACTION = {
  type: typeof FETCH_ASSESSMENT_RESOURCES_FAILURE;
  error: any;
};

// TODO: ADD failure actions in the error module
export type AssessmentAction =
  | FETCH_ASSESSMENT_RESOURCES_REQUEST_ACTION
  | FETCH_ASSESSMENT_RESOURCES_SUCCESS_ACTION
  | FETCH_ASSESSMENT_RESOURCES_FAILURE_ACTION;

/* eslint-disable no-use-before-define */
type PromiseAction = Promise<AssessmentAction>;
export type ThunkAction = (dispatch: Dispatch, getState: GetState, api: any) => any;
export type Dispatch = (action: AssessmentAction | ThunkAction | PromiseAction) => any;
export type GetState = () => AssessmentState;
