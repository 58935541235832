//
import React from 'react';

type Props = {
  fill: string;
};

const AllResourcesIcon = ({ fill }: Props) => (
  <svg viewBox="952.258 533 18 15.647" width="100%">
    <g style={{ fill }}>
      <path d="M959.46 545.52h3.6v3.13h-3.6z" />
      <path d="M966.66 545.52h3.6v3.13h-3.6z" />
      <path d="M952.26 533h3.6v3.13h-3.6z" />
      <path d="M952.26 539.26h3.6v3.13h-3.6z" />
      <path d="M959.46 533h3.6v3.13h-3.6z" />
      <path d="M959.46 539.26h3.6v3.13h-3.6z" />
      <path d="M952.26 545.52h3.6v3.13h-3.6z" />
      <path d="M966.66 533h3.6v3.13h-3.6z" />
      <path d="M966.66 539.26h3.6v3.13h-3.6z" />
    </g>
  </svg>
);

AllResourcesIcon.defaultProps = {
  fill: '#000'
};

export default AllResourcesIcon;
