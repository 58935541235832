type milliseconds = number;

const getTimeInMilliseconds = () => {
  const seconds = 1000;
  const minutes = seconds * 60;
  const hours = minutes * 60;
  const days = hours * 24;

  return { seconds, minutes, hours, days };
};

export const timeInMilliseconds = getTimeInMilliseconds();

export function getMSDiffEnding(endDate: string | Date) {
  return (startDate: string | Date): any => {
    return (new Date(startDate) as any) - (new Date(endDate) as any);
  };
}

export function getTimeFromMilliseconds(unit) {
  return (ms: milliseconds): number => {
    const diff = Math.floor(ms / timeInMilliseconds[unit]);

    return diff < 0 ? 0 : diff;
  };
}
