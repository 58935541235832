import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withRouter from 'state/hooks/withRouter';
import withRoutePersistence from '../../../components/core/enhancers/withRoutePersistence';
import camelCase from 'lodash/camelCase';
import { AppState, AssessmentState } from '../../../reducers/index';
import { parseQueryParams } from '../../../util/query-params-util';

type Props = {
  assessmentResources: AssessmentState;
  match: any;
  location: any;
};

class AssessmentPDF extends React.Component<Props> {
  render() {
    const { assessmentResources, location } = this.props;

    const queryParam = parseQueryParams(location.search);
    const camel = camelCase(queryParam?.activeTab);
    return (
      <object
        style={{ display: 'flex', flex: 1 }}
        data={assessmentResources[camel].src + '#toolbar=0&navpanes=0&scrollbar=0&view=FitH'}
        type="application/pdf"
        width="100%%"
        height="100%"
        aria-label={assessmentResources[camel]}
      />
    );
  }
}
const mapStateToProps = (state: AppState) => ({
  assessmentResources: state.assessment
});

export default compose(
  withRouter,
  withRoutePersistence('assessment'),
  connect(mapStateToProps, {})
)(AssessmentPDF);
