//
import * as types from './workbook-types';

export const fetchPDF = (): types.FETCH_PDF_ACTION => ({
  type: types.FETCHING_PDF
});

export const fetchPDFSuccess = (pdf: any): types.FETCH_PDF_SUCCESS_ACTION => ({
  type: types.FETCH_PDF_SUCCESS,
  pdf
});

export const fetchPDFFail: any = error => ({
  type: types.FETCH_PDF_FAIL,
  error
});

export const fetchWorkbooks = (): types.FETCH_WORKBOOKS_ACTION => ({
  type: types.FETCHING_WORKBOOKS
});

export const fetchWorkbooksSuccess = (
  workbooks: Array<any>
): types.FETCH_WORKBOOKS_SUCCESS_ACTION => ({
  type: types.FETCH_WORKBOOKS_SUCCESS,
  workbooks
});

export function fetchWorkbooksFailure(error: Error) {
  return {
    type: types.FETCH_WORKBOOKS_FAILURE,
    error
  };
}

export const fetchWorkbookPageDetails = (): types.FETCH_PAGE_DETAILS_ACTION => ({
  type: types.FETCHING_PAGE_DETAILS
});

export function fetchWorkbookPagesDetailsSuccess(pageDetails): any {
  return {
    type: types.FETCH_PAGE_DETAILS_SUCCESS,
    pageDetails
  };
}

export function fetchWorkbookPagesDetailsFailure(
  error: Error
): types.FETCH_PAGE_DETAILS_FAILURE_ACTION {
  return {
    type: types.FETCH_PAGE_DETAILS_FAILURE,
    error
  };
}

export const resetWorkbookPageDetails: any = () => ({
  type: types.RESET_PAGE_DETAILS
});

export const fetchWorkbookContent = (): types.FETCH_WORKBOOK_CONTENT_ACTION => ({
  type: types.FETCHING_WORKBOOK_CONTENT
});

export const fetchWorkbookContentSuccess: any = (content: any) => ({
  type: types.FETCH_WORKBOOK_CONTENT_SUCCESS,
  content
});

export function fetchWorkbookContentFailure(
  error: Error
): types.FETCH_WORKBOOK_CONTENT_FAILURE_ACTION {
  return {
    type: types.FETCH_WORKBOOK_CONTENT_FAILURE,
    error
  };
}

export function setLastWorkbook(book: any): any {
  return {
    type: types.SET_LAST_WORKBOOK,
    workbook: book
  };
}

export function setLastTeachersGuide(book: any): any {
  return {
    type: types.SET_LAST_TEACHERS_GUIDE,
    teachersGuide: book
  };
}

export function setLastStorybook(book: any): any {
  return {
    type: types.SET_LAST_STORYBOOK,
    storybook: book
  };
}

export function setCurrentWorkbook(currentWorkbook: any) {
  return {
    type: types.SET_CURRENT_WORKBOOK,
    currentWorkbook
  };
}
