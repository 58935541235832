import appsEn from './en-US/apps.json';
import assessmentsEn from './en-US/assessments.json';
import bookMenusEn from './en-US/bookMenus.json';
import copyrightEn from './en-US/copyright.json';
import errorsEn from './en-US/errors.json';
import footerEn from './en-US/footer.json';
import modalsEn from './en-US/modals.json';
import navEn from './en-US/nav.json';
import resourcesEn from './en-US/resources.json';
import selectEn from './en-US/select.json';
import supportEn from './en-US/support.json';
import homeEn from './en-US/home.json';
import commonEn from './en-US/common.json';
import appsEs from './es-US/apps.json';
import assessmentsEs from './es-US/assessments.json';
import bookMenusEs from './es-US/bookMenus.json';
import copyrightEs from './es-US/copyright.json';
import errorsEs from './es-US/errors.json';
import footerEs from './es-US/footer.json';
import modalsEs from './es-US/modals.json';
import navEs from './es-US/nav.json';
import resourcesEs from './es-US/resources.json';
import selectEs from './es-US/select.json';
import supportEs from './es-US/support.json';
import homeEs from './es-US/home.json';
import commonEs from './es-US/common.json';
import a11yEs from './es-US/a11y.json';
import a11yEn from './en-US/a11y.json';
export * from './translate-book-code';

export const localeToLanguage = {
  'en-US': 'english',
  'es-US': 'español'
};

export const i18n = {
  resourceMap: {
    'en-US': {
      apps: appsEn,
      assessments: assessmentsEn,
      bookMenus: bookMenusEn,
      copyright: copyrightEn,
      errors: errorsEn,
      footer: footerEn,
      modals: modalsEn,
      nav: navEn,
      resources: resourcesEn,
      select: selectEn,
      support: supportEn,
      home: homeEn,
      common: commonEn,
      a11y: a11yEn
    },
    'es-US': {
      apps: appsEs,
      assessments: assessmentsEs,
      bookMenus: bookMenusEs,
      copyright: copyrightEs,
      errors: errorsEs,
      footer: footerEs,
      modals: modalsEs,
      nav: navEs,
      resources: resourcesEs,
      select: selectEs,
      support: supportEs,
      home: homeEs,
      common: commonEs,
      a11y: a11yEs
    }
  }
};
