//
import React from 'react';

type Props = {
  fill?: string;
  stroke?: string;
};

function ChevronIcon({ fill = 'none', stroke = '#626262' }: Props) {
  return (
    <svg viewBox="0 0 9.89 6.43" width="100%">
      <path fill={fill} stroke={stroke} d="M.37.34L5.2 5.67 9.5.34" />
    </svg>
  );
}

export default ChevronIcon;
