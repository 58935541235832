import React, { PureComponent } from 'react';
import withRouter from 'state/hooks/withRouter';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import CharacterSelector from '../components/CharacterLists/CharacterSelector';
import ApplicationIFrame from '@lwtears/lwt-common-frontend/lib/ApplicationFrame';
import {
  selectCharacter,
  toggleAppModalVisibility,
  toggleAppLoadingScreenVisibility
} from '../modules/character-selection';
import { fetchCharacters } from '../modules/character-selection/operations';
import PrekittApi from '../api';

import { AppState } from '../reducers';
import { ReactRouterProps } from './Main';
import { Locale, CharacterTypeSectionState, Resource } from 'types';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
import { DraggableIframeWrapper } from '../components/core/Modals/DraggableIframeWrapper';
import { DraggableModalTitle } from '../components/core/Modals/DraggableModalTitle';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props = {
  charactersApp: string;
  items: CharacterTypeSectionState[];
  locale: Locale;
  selectedCharacter: Resource;
  showAppModal: boolean;
  showLoadingScreen: boolean;
  selectCharacter: (character: Resource) => any;
  toggleAppModalVisibility: () => any;
  toggleAppLoadingScreenVisibility: () => any;
  fetchCharacters: (applicationId: string) => Array<Resource>;
} & ReactRouterProps &
  UseModalDispatch;

class LetterNumberFormations extends PureComponent<Props> {
  componentDidMount() {
    this.props.fetchCharacters('lnf');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      prevProps.fetchCharacters('lnf');
    }
  }

  createEvent(resource: Resource) {
    const event = {
      section: 'lnf',
      resourceId: resource.id,
      resourceType: resource.type.type,
      locale: this.props.locale
    };

    PrekittApi.createEvent('accessResource', event);
  }

  handleClickItem = character => {
    this.createEvent(character);
    this.props.selectCharacter(character);
    this.openModal(character);
  };

  openModal = selectedCharacter => {
    this.props.openDraggableModal({
      Title: props => <DraggableModalTitle title={selectedCharacter.title} {...props} />,
      body: (
        <DraggableIframeWrapper>
          <ApplicationIFrame
            onMount={toggleAppLoadingScreenVisibility}
            onLoad={toggleAppLoadingScreenVisibility}
            aspectRatio={selectedCharacter.data.aspectRatio ?? '16/9'}
            heightAdjust={selectedCharacter.data.heightAdjust}
            url={selectedCharacter.url}
            title={selectedCharacter.title}
          />
        </DraggableIframeWrapper>
      ),
      ariaLabel: tA11y('aria.modal', { title: selectedCharacter.title }),
      options: { closeOnBackdropClick: true }
    } as any);
  };

  render() {
    const { charactersApp, items, locale } = this.props;

    const { container, content } = styles;

    return (
      <div className={css(container)}>
        <div className={css(content)}>
          <CharacterSelector
            items={charactersApp === 'lnf' ? items : []}
            onClickItem={this.handleClickItem}
            application={'LNF'}
            locale={locale}
          />
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#F2F8FF'
  },
  content: {
    flex: 1,
    width: '100%',
    overflowY: 'auto'
  }
});

const mapStateToProps = (state: AppState) => ({
  charactersApp: state.characterSelection.app,
  items: state.characterSelection.allCharacters,
  locale: state.characterSelection.locale,
  showAppModal: state.characterSelection.showAppModal,
  selectedCharacter: state.characterSelection.selectedCharacter,
  showLoadingScreen: state.characterSelection.showLoadingScreen
});

export default compose(
  withRouter,
  withModal,
  connect(mapStateToProps, {
    selectCharacter,
    toggleAppModalVisibility,
    toggleAppLoadingScreenVisibility,
    fetchCharacters
  })
)(LetterNumberFormations);
