import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Resource } from 'types';
import { lnfGray } from '../../styles/style-constants';
import { onlyOnSpaceAndEnter } from '../../util/accessibility-util';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { Box } from '@mui/material';
import useFocus from '@lwtears/lwt-common-frontend/lib/@common/hooks/app/use-focus';
import { useModal } from '@lwtears/lwt-common-frontend/lib/Modal/hooks/use-modal';

type Props = {
  onClickItem: (character: Resource, index?: any) => any;
  styleOverrides?: any;
  characters: Resource[];
  characterType: string;
  application: string;
  focusIndex: number | undefined;
};

const appWidths = {
  LNF: '100%',
  WDT: '100%',
  WoodPieces: '100%'
};

const appHeights = {
  LNF: '50%',
  WDT: '50%',
  WoodPieces: '35%'
};

const CharacterList = ({
  characters,
  onClickItem,
  styleOverrides,
  application,
  characterType,
  focusIndex
}: Props) => {
  const [focusRef] = useFocus([focusIndex]);
  const { listElement, list, cursiveFont } = getStyles();
  const [{ isOpen: modalOpen }] = useModal();

  const getCharacterType = characterType =>
    characterType === '123' ? tA11y('aria.numbers') : characterType;

  const maybeApplyFocus = condition => {
    if (!modalOpen && condition) {
      return { ref: focusRef };
    }
    return undefined;
  };

  const ElementsList = () =>
    characters.reduce((acc, character, index) => {
      const char = character.tags ? character.tags[0].value : 'A';
      acc.push(
        <Box
          {...maybeApplyFocus(focusIndex === index)}
          component="button"
          tabIndex={0}
          aria-label={`${getCharacterType(characterType)} ${char}`}
          id={char}
          key={`character_${character}_${index}`}
          className={css(
            listElement,
            character.characterType === 'cursive' && cursiveFont,
            styleOverrides && styleOverrides.listElement
          )}
          onClick={() => onClickItem(character, index)}
          onKeyDown={onlyOnSpaceAndEnter(() => onClickItem(character, index))}
        >
          <img
            src={require(`../../images/CharacterTile/LetterCards/${application}/${characterType}/${char}.png`)}
            alt={char}
            width={appWidths[application]}
            height={appHeights[application]}
          />
        </Box>
      );

      return acc;
    }, []);

  return (
    <div className={css(list)}>
      <ElementsList />
    </div>
  );
};

const getStyles = () =>
  StyleSheet.create({
    list: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      padding: '10px 40px',
      fontFamily: 'GSS Print Wide',
      fontSize: 28,
      userSelect: 'none'
    },
    cursiveFont: {
      fontFamily: 'GSS Cursive Beginner Wide',
      fontSize: 35
    },
    listElement: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      padding: 10,
      margin: 5,
      width: '150px',
      height: '100%',
      transition: 'background-color 0.1s linear',
      ':hover': {
        backgroundColor: lnfGray
      }
    }
  });

export default CharacterList;
