import React from 'react';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const tApps = key => translate('apps', key);

const LNFIcon = props => (
  <svg width="32" height="32" viewBox="0 0 56.56 54.35" {...props}>
    <title>{tApps('lnf')}</title>
    <path
      d="M50.15,15.86c0-1.23-.11-12-.18-13.21,0-.22-.11-.45-.24-.45-.76,0-4.27.11-5,0s-29.38-.27-30-.28c-1.48,0-5.84.27-7.33.27-.17,0-.29.28-.32.56A1.12,1.12,0,0,0,7,3.12C7,6.25,6.92,44.91,7,48c0,1.36-.2,4,.65,4.85a2.37,2.37,0,0,0,1.4.42,29.94,29.94,0,0,0,5,0c1.59-.37,34.05,0,35.64-.32.17,0,.32-.27.33-.58,0-2.53-.21-5.05-.07-7.57C50,43.73,50.13,17,50.15,15.86Z"
      fill="#bfbebd"
    />
    <path
      d="M55.75,53.14c-.59,0-1.58,0-2.2,0-.93,0-.86.13-1.8.15-2.47,0,1,0-1.42-.1-5-.21-6.92-.38-11.9-.23s-10,0-15-.09-10,0-14.94,0c-5.62,0-2.27-.32-7.88,0-.45,0-.6.88-.12.85,5.05-.29,1.13,0,6.18,0s10.25,0,15.38,0,10.15.27,15.23.13,7.2-.06,12.32.17c2.81.13-.36.2,2.45.12,1,0,1-.11,2.06-.16.59,0,1.22,0,1.91-.06C56.45,53.86,56.3,53.15,55.75,53.14Z"
      fill="#8d8b8b"
    />
    <path d="M9.28.88A2.56,2.56,0,0,0,5.84.94,2.66,2.66,0,0,0,5,2.15a.13.13,0,0,0-.06.09,3,3,0,0,0,1.54,3.1A2.47,2.47,0,0,0,9.64,4.16,2.45,2.45,0,0,0,9.28.88Z" />
    <path
      d="M55.75,28.64c-.59,0-1.58,0-2.2,0-.93,0-.86.13-1.8.14-2.47,0,1,0-1.42-.09-5-.21-6.92-.38-11.9-.23s-10,0-15-.09-10,0-14.94,0c-5.62,0-2.27-.32-7.88,0-.45,0-.6.88-.12.85,5.05-.29,1.13,0,6.18,0s10.25,0,15.38,0,10.15.26,15.23.13,7.2-.06,12.32.17c2.81.13-.36.2,2.45.12,1,0,1-.11,2.06-.16.59,0,1.22,0,1.91-.06C56.45,29.36,56.3,28.65,55.75,28.64Z"
      fill="#8d8b8b"
    />
    <path d="M8.81,45.83c-.26-7-.52-16.5-.4-23.51.12-7.6.31-2.12.64-9.71.1-2.38-.61-2.26-.23-9.64.07-1.32-2.47-1.8-2.55-.34C5.88,10,6.52,9,6.49,12.27c-.07,8-.53,3-.66,11-.11,6.77.31,16.07.49,22.82a1.46,1.46,0,0,0,1.33,1C8.73,47.16,8.81,45.83,8.81,45.83Z" />
    <path
      d="M32,19.6l-7.57,5.89L16.75,31l-3,2.09-1.23,1-1.14,2.17L8.36,43.7l3.37,2.84,5.54-.7a9.49,9.49,0,0,0,1.84-.48c.15-.17,1.75-1.57,1.75-1.57L22,42.94l13-7.87,4.38-3.14,1-1.93Z"
      fill="#fff"
    />
    <path d="M6.91,46.9l.16.05a26.09,26.09,0,0,0,7.64-.07c1.42-.17,4.44-.42,5.68-1.66a5.19,5.19,0,0,0,1-.88,16.88,16.88,0,0,1,2.48-1.72c1.85-1.15,3.76-2.15,5.56-3.36,2.21-1.47,4.56-2.72,6.78-4.17,1.58-1,4-2.48,4.67-4.38a.54.54,0,0,0-.07-.5.61.61,0,0,0,0-.66c.09.14-.52-.83-.69-1.07-.63-.93-1.22-1.9-1.83-2.85a48.93,48.93,0,0,0-5.25-6.08.7.7,0,0,0-.93-.68c-1.89.66-3.37,2.22-4.86,3.49-2.22,1.89-4.6,3.54-6.93,5.28-1.49,1.11-3.13,2-4.7,3-1.19.76-2.81,1.61-3.46,2.94a.49.49,0,0,0-.06.22c-2.69,2.94-3.4,7.22-5,10.85ZM38.13,28.18h0a5.77,5.77,0,0,0,1.72,2.14c-.71.59-1.3,1.33-2,1.94a28.23,28.23,0,0,1-3,2.12c-2.19,1.43-4.51,2.65-6.68,4.1-1.81,1.21-3.74,2.22-5.58,3.38A9.09,9.09,0,0,0,20,44a2.67,2.67,0,0,0-.4.13,3.63,3.63,0,0,0-2-1.48l.05,0c4.38-3.57,9.13-6.42,13.82-9.54,2.28-1.52,4.33-3.41,6.63-4.88Zm-24.86,6c2.61-1.84,5.39-3.49,8-5.39,2.14-1.59,4.34-3.1,6.39-4.82,1.46-1.22,2.88-2.87,4.65-3.68a36.4,36.4,0,0,0,3.16,4c-3.58,2.39-7.2,4.84-10.67,7.4-3.25,2.38-6.22,5.16-10,6.68a3.33,3.33,0,0,0-1.5-4.1s0-.05,0-.08Zm-.5.82a2.8,2.8,0,0,1,.44,4,.58.58,0,0,0,.4.92c1.63.08,3.48.82,2.48,2.8a.59.59,0,0,0,.33.76,4.1,4.1,0,0,1,2,1.19l-.31.13a18.36,18.36,0,0,1-3.61.74q-1.07.15-2.16.24A4.8,4.8,0,0,0,11.15,43,2.61,2.61,0,0,0,9.9,42l-.19,0a37.69,37.69,0,0,1,3.06-7Zm0,0" />
  </svg>
);

export default LNFIcon;
