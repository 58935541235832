import invert from 'lodash/invert';

const translatedBookCodes = {
  'en-US': {
    MMHATS: 'MMHATS',
    MMGO: 'MMGO',
    MMSH: 'MMSH',
    MMOPP: 'MMOPP',
    MMHATSTX: 'MMHATSTX',
    MMGOTX: 'MMGOTX',
    MMSHTX: 'MMSHTX',
    MMOPPTX: 'MMOPPTX',
    TGRW: 'TGRW',
    TGPKGSS1: 'TGPKGSS1',
    TGPKGSS: 'TGPKGSS',
    TGPK1TX: 'TGPK1TX',
    TGPK2TX: 'TGPK2TX',
    MFSB: 'MFSB',
    MFLB: 'MFLB',
    MB: 'MB',
    IKMN: 'IKMN',
    MFSBTX: 'MFSBTX',
    MFLBTX: 'MFLBTX',
    MBTX: 'MBTX',
    IKMNTX: 'IKMNTX'
  },
  'es-US': {
    MMHATS: 'SMMHATS',
    MMGO: 'SMMGO',
    MMSH: 'SMMSH',
    MMOPP: 'SMMOPP',
    MMHATSTX: 'SMMHATSTX',
    MMGOTX: 'SMMGOTX',
    MMSHTX: 'SMMSHTX',
    MMOPPTX: 'SMMOPPTX',
    TGRW: 'STGRW',
    TGPKGSS1: 'STGPKGSS1',
    TGPKGSS: 'STGPKGSS',
    TGPK1TX: 'STGPK1TX',
    TGPK2TX: 'STGPK2TX',
    MFSB: 'SMFB',
    MFLB: 'SMFLB',
    MB: 'SMB',
    IKMN: 'SIKMN',
    MFSBTX: 'SMFBTX',
    MFLBTX: 'SMFLBTX',
    MBTX: 'SMBTX',
    IKMNTX: 'SIKMNTX'
  }
};

export const translateBookCode = (
  oldLocale: string,
  newLocale: string,
  bookCode: string
): string => {
  const oldLocaleBookCodes = invert(translatedBookCodes[oldLocale]);

  return translatedBookCodes[newLocale][oldLocaleBookCodes[bookCode]];
};
