import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import type { FtueState } from './types';
import type { TourStep, FtueTour } from 'types';
import type { Props } from '../../components/Ftue/FtueCalloutContainer.js';
import { hasClickAwayOnly } from '../user';

export const getTourStepByName = (state: FtueState, props: Props): TourStep =>
  state.allTours[props.name].steps.find(s => s.name === props.step);

export function getCurrentStep({ allTours, queuedTours, currentStep }: FtueState): TourStep | any {
  const currentTour = allTours[queuedTours[0]];

  if (isEmpty(queuedTours) || !currentTour) return {};

  return currentTour.steps[currentStep];
}

const getNameFor =
  property =>
  (_, props): string =>
    props[property];

export const getViewedTour = (state: FtueState, props: Props): FtueTour =>
  state.toursViewed[props.name];

export const isUnviewedCallout = createSelector(
  [getViewedTour, getNameFor('step')],
  (viewedTour, step) => {
    if (!viewedTour) return true;

    const foundStep = viewedTour.steps.find(s => s.name === step);

    return !foundStep || !foundStep.viewed;
  }
);

export const hideCallout = createSelector(
  [state => state, getNameFor('name')],
  ({ auth, ftue }, name) => !auth.isAuthenticated || !ftue.allTours[name]
);

export const isCurrentStep = createSelector(
  [getCurrentStep, getTourStepByName],
  (currentStep, step) => currentStep === step
);

export const showFtueCallout = createSelector(
  [isCurrentStep, isUnviewedCallout],
  (isCurrentStep, isUnviewedCallout) => isCurrentStep && isUnviewedCallout
);

export const showTourOverlay: any = createSelector(
  [state => state] as any,
  (state, params) =>
    state.ui.globalMenuTutorialOpen &&
    !hasClickAwayOnly(state, params) &&
    state.user.settings?.licensing?.viewedWelcome === true
);
