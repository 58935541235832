//
import userReducer from './reducer';
export * from './operations';
export * from './types';
export * from './util';

/* Export these directly from selectors since we use AppState,
   rather than passing in only the user slice */
export {
  getClickAwayStatus,
  getLicenseIsExpired,
  getLicenseIsTrial,
  hasClickAwayOnly,
  hasClickAway
} from './selectors';

export default userReducer;
