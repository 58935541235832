//
import {
  FETCH_CHARACTERS_REQUEST,
  FETCH_CHARACTERS_SUCCESS,
  CHANGE_APP_LOCALE,
  SELECT_CHARACTER,
  TOGGLE_APP_MODAL_VISIBILITY,
  TOGGLE_APP_LOADING_SCREEN_VISIBILITY
} from './types';
import { CharacterSelectionState, CharacterSelectionAction } from './types';

const initialState: CharacterSelectionState = {
  selectedCharacter: {
    url: '#',
    characterType: '',
    characterCase: '',
    character: '',
    data: {}
  },
  showAppModal: false,
  showLoadingScreen: false,
  allCharacters: [],
  app: '',
  locale: 'en-US'
};

function characterSelection(
  state: CharacterSelectionState = initialState,
  action: CharacterSelectionAction
) {
  switch (action.type) {
    case FETCH_CHARACTERS_REQUEST: {
      return {
        ...state,
        allCharacters: [],
        app: action.application
      };
    }
    case FETCH_CHARACTERS_SUCCESS: {
      return {
        ...state,
        allCharacters: action.characters
      };
    }
    case CHANGE_APP_LOCALE: {
      return {
        ...state,
        locale: action.locale
      };
    }

    case SELECT_CHARACTER: {
      return {
        ...state,
        selectedCharacter: action.character
      };
    }

    case TOGGLE_APP_MODAL_VISIBILITY: {
      return {
        ...state,
        showAppModal: !state.showAppModal
      };
    }

    case TOGGLE_APP_LOADING_SCREEN_VISIBILITY: {
      return {
        ...state,
        showLoadingScreen: !state.showLoadingScreen
      };
    }

    default: {
      return state;
    }
  }
}

export default characterSelection;
