export enum ProductCode {
  PREKITT_RW = 20,
  PREKITT_GSS = 21,
  PREKITT_GSS_TX = 24
}

export enum MimeType {
  PDF = 'application/pdf',
  DOC = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export enum Headers {
  APP_TARGET = 'x-lwtears-app-target'
}

export enum AppNames {
  GSS = 'gss'
}
