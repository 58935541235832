import flatMap from 'lodash/flatMap';

const remBase = 16;

function convertPixelsToRem(px: string) {
  const pixelSize = parseInt(px, 10);

  if (Number.isNaN(pixelSize) || String(px).endsWith('%')) {
    return px;
  }

  return pixelSize === 0 ? '0' : `${pixelSize / remBase}rem`;
}

export function remCalc(...pxSizes: Array<number | string>): string {
  if (pxSizes.length > 4) {
    throw new Error(`CSS shortcuts have a max of 4 sizes. Recieved: ${pxSizes.length}`);
  }

  return pxSizes.map(convertPixelsToRem).join(' ');
}

export function mergeClasses(...classNames: any): string {
  return flatMap(classNames, (classes: any) => classes.split(' ')).join(' ');
}

// https://css-tricks.com/snippets/javascript/lighten-darken-color/
export function adjustColor(hexColor: string, percentageChanged: number): string {
  let usePound = false;

  /* eslint-disable no-param-reassign */
  if (hexColor[0] === '#') {
    hexColor = hexColor.slice(1);
    usePound = true;
  }

  const num = parseInt(hexColor, 16);

  let r = (num >> 16) + percentageChanged;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + percentageChanged;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + percentageChanged;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}
