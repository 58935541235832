import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import SquareButton from '../SquareButton';
import imageSrc from '../../../images/kid-floating-icons.png';
import { remCalc } from '../../../util/style-util';
import { saveUserSettings } from '../../../modules/user';
import { selectResource, toggleResourcePlayerVisibility } from '../../../actions/resource-actions';
import type { AppState } from '../../../reducers';
import PrekittApi from '../../../api';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { Resource, Workbook } from 'types';
import { getCurrentWorkbook } from '../../../modules/workbook/workbook-selectors';
import withRouter from 'state/hooks/withRouter';
import Plyr from '@lwtears/lwt-common-frontend/lib/Player';
import withModal from '@lwtears/lwt-common-frontend/lib/Modal/withModal';
import { UseModalDispatch } from '@lwtears/lwt-common-frontend/lib/Modal';
const translateModals = key => translate('modals', key);

type Props =
  | ({
      currentWorkbook: Workbook;
      selectedResource: any;
      showResourcePlayer: boolean;
      handleDismiss: (e: any) => any;
      selectResource: (resource: Resource) => any;
      toggleResourcePlayerVisibility: (show: boolean) => any;
    } & UseModalDispatch)
  | any;

type State = {
  prekittWelcomeVideo: any;
  playerVisible: boolean;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    padding: 25,
    border: '2px solid black',
    borderRadius: '0px 8px 0px 5px/8px 0px 5px 255px',
    boxShadow: '0 4px 20px 5px rgba(55, 55, 55, .5)',
    marginTop: '-10px'
  },
  welcomeModal: {
    textAlign: 'center',
    width: remCalc(450),
    '@media(min-width: 1000px)': {
      width: remCalc(580)
    }
  },
  modalImage: {
    width: '60%',
    minWidth: remCalc(160),
    '@media(min-width: 1000px)': {
      width: '70%',
      minWidth: remCalc(220)
    }
  },
  modalHeading: {
    fontFamily: 'futura-pt',
    fontWeight: 600,
    fontSize: remCalc(24),
    '@media(min-width: 1000px)': {
      fontSize: remCalc(30)
    }
  },
  modalText: {
    textAlign: 'left',
    fontWeight: 400
  },
  modalButton: {
    margin: remCalc(20, 0)
  }
});

export class Welcome extends Component<Props, State> {
  state = {
    prekittWelcomeVideo: null,
    playerVisible: false
  };

  createEvent = resource => {
    const event = {
      section: 'welcome',
      resourceId: resource.id,
      resourceType: resource.type.type
    };

    PrekittApi.createEvent('accessResource', event);
  };

  handleSaveUserSettings = () => {
    this.props.saveUserSettings('licensing', { viewedWelcome: true });
    this.props.closeModal();
  };

  handleCloseResourcePlayer = () => {
    this.setState({ playerVisible: false });
    this.props.closeModal();
  };

  render() {
    const { playerVisible } = this.state;
    const { selectedResource } = this.props;

    return (
      <div className={css(styles.container)}>
        {!playerVisible ? (
          <div className={css(styles.welcomeModal)}>
            <img className={css(styles.modalImage)} src={imageSrc} alt={tA11y('alt.prekittLogo')} />
            <h2 className={css(styles.modalHeading)}>{translateModals('welcome')}</h2>
            <p className={css(styles.modalText)}>{translateModals('welcomeToPreKitt')}</p>
            <SquareButton
              buttonStyles={[styles.modalButton]}
              text={translateModals('blueButton')}
              onClick={this.handleSaveUserSettings}
            />
          </div>
        ) : (
          <Plyr
            key={selectedResource?.title}
            media={{
              type: 'video',
              source: {
                src: selectedResource?.url,
                type: 'video/mp4'
              }
            }}
            title={selectedResource?.title}
            onDone={this.handleCloseResourcePlayer}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, { router: { params } }) => ({
  selectedResource: state.resources.selectedResource,
  showResourcePlayer: state.resources.showResourcePlayer,
  currentWorkbook: getCurrentWorkbook(state, params)
});

const withRedux = connect(mapStateToProps, {
  saveUserSettings,
  selectResource,
  toggleResourcePlayerVisibility
});

export default compose(withModal, withRouter, withRedux)(Welcome);
