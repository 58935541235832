//
import React, { PureComponent } from 'react';
import Config from 'config';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

type Props = any;

const { companyURL } = Config;
class LogoutFrame extends PureComponent<Props> {
  iframe?: HTMLIFrameElement;

  componentDidMount() {
    if (this.iframe) {
      this.iframe.onload = () => {
        const loginURL = `${companyURL}/prekitt-login?returnURL=${window.location.origin}`;

        window.location.replace(loginURL);
      };
    }
  }

  render() {
    return (
      <iframe
        ref={iframe => (this.iframe = iframe as any)}
        src={`${companyURL}/logout-sso`}
        title={tA11y('title.prekittLogout')}
        style={{ position: 'absolute', visibility: 'hidden' }}
      />
    );
  }
}

export default LogoutFrame;
