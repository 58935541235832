import React from 'react';
import { useParams } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Button } from '@mui/material';
import flow from 'lodash/fp/flow';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { remCalc } from '../../util/style-util';
import PageNavButton from './PageNavButton';
import PageSelector from './PageSelector';
import TrialBadge from '../Badges/TrialBadge';
import maybe from '../core/enhancers/maybe';
import withFtueTour from '../core/enhancers/withFtueTour';
import { getRoutingForPage } from '../../util/workbook-util';
import OnePageSpread from '../../images//icons/OnePageSpread';
import TwoPageSpread from '../../images/icons/TwoPageSpread';
import { Workbook, PageDetail } from 'types';
import { useZoom, MAX_ZOOM_SCALE, MIN_ZOOM_SCALE, ZoomTypes } from 'state/providers/ZoomProvider';
import ZoomButton from 'components/Button/ZoomButton';
import { useFullScreen } from 'state/providers/FullScreenProvider';
import withRouter from 'state/hooks/withRouter';

type Props = {
  totalPages: number;
  workbook: Workbook;
  pageOffset: number;
  isZoom: boolean;
  isIdle: boolean;
  toggleMenu?: () => any;
  children?: any;
  router: any;
  isTrialLicense: boolean;
  trialDaysLeft: number;
  singlePage: boolean;
  pageDetails: Array<PageDetail>;
  navBarDisabled: boolean;
  queueTour?: (name: string) => any;
  cancelDownloadRequest: () => any;
};

const MaybeTrialBadge: any = maybe(TrialBadge);
const translateCommon = key => translate('common', key);
export const PageNavBar = ({
  totalPages,
  workbook,
  pageOffset,
  isZoom,
  toggleMenu,
  children: Menu,
  router,
  isTrialLicense,
  trialDaysLeft,
  singlePage,
  navBarDisabled,
  isIdle
}: Props) => {
  const [{ zoomScale }, { increaseZoom, decreaseZoom, resetZoom }] = useZoom();
  const [{ isFullScreen, screen1Handle }, { setFullScreen }] = useFullScreen();

  const params = useParams<any>();
  const currentPage = parseInt(params.pageNumber ?? '', 10);

  const {
    navBar,
    controls,
    reminder,
    pageZoom,
    zoomLevelText,
    pageNavContainer,
    pageToggleContainer,
    onePageToggle,
    twoPageToggle,
    stopButton,
    fullScreenNavBar,
    verticalLine
  } = getStyles(workbook.primaryColor, workbook.textColor, isZoom, isIdle, isFullScreen);

  const turnPage = (moveForward: boolean) => () => {
    const changePageBy = isZoom || singlePage ? 1 : 2;
    const newPage = currentPage + (moveForward ? changePageBy : -changePageBy);
    const { path, replace } = getRoutingForPage(newPage, router, isZoom);

    return router.navigate(path, { replace });
  };

  const nextPageDisabled = (): boolean => {
    const remainingPages = isZoom || singlePage ? 1 : 2;

    return (
      currentPage >= totalPages ||
      (totalPages % 2 !== 0 && currentPage > totalPages - remainingPages)
    );
  };

  const gotoTwoPage = () => {
    const { bookCode, edition, pageNumber } = params;
    const newPageNumber =
      parseInt(pageNumber ?? '', 10) % 2 !== 0
        ? parseInt(pageNumber ?? '', 10) - 1
        : parseInt(pageNumber ?? '', 10);
    router.navigate(`/books/${String(bookCode)}/${String(edition)}/${newPageNumber}`);
  };

  const gotoOnePage = () => {
    const { bookCode, edition, pageNumber } = params;
    router.navigate(`/books/${String(bookCode)}/${String(edition)}/${pageNumber}/zoom`);
  };

  const switchPageView = (isOnePage: boolean) => {
    if (isNaN(currentPage)) return;
    isOnePage ? gotoTwoPage() : gotoOnePage();
  };

  const handleExitFullScreen = () => {
    setFullScreen(false);
    screen1Handle.exit();
  };

  const renderZoomButtons = () => (
    <div className={css(pageZoom)}>
      <ZoomButton
        type={ZoomTypes.RESET}
        onClick={resetZoom}
        key={'resetZoom'}
        isDisabled={zoomScale === 1}
      />
      <ZoomButton
        type={ZoomTypes.OUT}
        onClick={decreaseZoom}
        key={'zoomIOut'}
        isDisabled={zoomScale <= MIN_ZOOM_SCALE}
      />
      <div className={css(zoomLevelText)}>{Math.floor(zoomScale * 100)}%</div>
      <ZoomButton
        type={ZoomTypes.IN}
        onClick={increaseZoom}
        key={'zoomIn'}
        isDisabled={zoomScale >= MAX_ZOOM_SCALE}
      />
    </div>
  );

  const renderPageNavigation = () => (
    <div className={css(pageNavContainer)}>
      <PageNavButton
        name={translateCommon('previous')}
        isDisabled={Number.isNaN(currentPage) || (singlePage && currentPage <= 1) || navBarDisabled}
        isZoom={isZoom}
        turnPage={turnPage(false)}
      />
      <PageSelector
        currentPage={currentPage}
        totalPages={totalPages}
        pageOffset={pageOffset}
        isZoom={isZoom}
        toggleMenu={toggleMenu}
        singlePage={singlePage}
      />
      <PageNavButton
        name={translateCommon('next')}
        isDisabled={nextPageDisabled() || navBarDisabled}
        isZoom={isZoom}
        turnPage={turnPage(true)}
      />
    </div>
  );

  const renderPageToggle = () => (
    <div className={css(pageToggleContainer)}>
      <Button
        className={css(onePageToggle)}
        title={'One Page'}
        onClick={() => switchPageView(false)}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      />
      <Button
        className={css(twoPageToggle)}
        title={'Two Pages'}
        onClick={() => switchPageView(true)}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      />
      {isZoom ? <OnePageSpread /> : <TwoPageSpread />}
    </div>
  );

  return (
    <nav id="bottomNavBar" className={css(isFullScreen ? fullScreenNavBar : navBar)}>
      {Menu}
      {!isFullScreen && (
        <div className={css(reminder)}>
          <MaybeTrialBadge
            shouldRender={isTrialLicense}
            daysLeft={trialDaysLeft}
            textColor={workbook.textColor}
          />
        </div>
      )}
      <div className={css(controls)}>
        {renderZoomButtons()}
        <div className={css(verticalLine)} />
        {renderPageNavigation()}
        <div className={css(verticalLine)} />
        {renderPageToggle()}
        {isFullScreen && <div className={css(verticalLine)} />}
        {isFullScreen && (
          <Button
            className={css(stopButton)}
            title={'Exit Fullscreen'}
            onClick={handleExitFullScreen}
          >
            STOP
          </Button>
        )}
      </div>
    </nav>
  );
};

const getStyles = (
  primaryColor: string,
  textColor: string,
  isZoom: boolean,
  isIdle: boolean,
  isFullScreen: boolean
) =>
  StyleSheet.create({
    fullScreenNavBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      bottom: 0,
      width: '60%',
      textAlign: 'center',
      backgroundColor: primaryColor,
      zIndex: 7,
      userSelect: 'none',
      fontSize: '15px',
      transition: '0.5s',
      transform: !isIdle ? 'translateY(0)' : 'translateY(300px)'
    },
    navBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      textAlign: 'center',
      backgroundColor: primaryColor,
      zIndex: 7,
      userSelect: 'none',
      fontSize: '15px'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: isFullScreen ? '90%' : '70%',
      marginLeft: isFullScreen ? '0' : remCalc(-78)
    },
    titleDisplay: {
      top: '26px',
      position: 'absolute',
      right: '25px',
      color: textColor
    },
    reminder: {
      position: 'absolute',
      left: '15px'
    },
    stopButton: {
      width: remCalc(70),
      backgroundColor: 'white',
      color: 'black',
      border: '1px black solid',
      fontWeight: 'bold',
      borderRadius: remCalc(5),
      boxShadow: 'rgb(44,44,44) 0px 3px 7px 1px',
      ':hover': {
        transform: 'scale(1.05, 1.05)',
        backgroundColor: 'white',
        color: 'black'
      }
    },
    verticalLine: {
      height: remCalc(50),
      width: remCalc(2),
      backgroundColor: 'black',
      margin: remCalc(0, 20)
    },
    pageZoom: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    zoomLevelText: {
      fontWeight: 400,
      color: textColor ?? 'black',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      fontFamily: 'GSS Futura'
    },
    pageNavContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    pageToggleContainer: {
      width: remCalc(120),
      position: 'relative',
      height: remCalc(75),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ':hover': {
        transform: 'scale(1.05, 1.05)'
      }
    },
    onePageToggle: {
      left: 0,
      width: remCalc(60),
      position: 'absolute',
      bottom: 0,
      height: '100%',
      cursor: isZoom ? 'default' : 'pointer',
      zIndex: 100
    },
    twoPageToggle: {
      right: 0,
      width: remCalc(60),
      position: 'absolute',
      bottom: 0,
      height: '100%',
      cursor: isZoom ? 'pointer' : 'default',
      zIndex: 100
    }
  });

PageNavBar.defaultProps = {
  singlePage: false,
  isZoom: false
};

PageNavBar.displayName = 'PageNavBar';

export default flow(withFtueTour('bookNavigation'), withRouter)(PageNavBar);
