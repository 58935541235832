//
import React from 'react';

type Props =
  | {
      to: string;
      name: string;
      activeColor?: string;
      children?: any;
    }
  | any;

/* eslint-disable no-unused-vars */
const WebLink = ({ to, name, activeColor, children, ...rest }: Props) => (
  <a href={to} {...rest} style={{ color: activeColor }}>
    {children || name}
  </a>
);

export default WebLink;
