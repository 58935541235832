//
import * as types from './types';
import { UserSettings, BookCode, ProductCopyrightTerms } from 'types';

export const fetchUserSettings = (): types.FETCHING_USER_SETTINGS_ACTION => ({
  type: types.FETCHING_USER_SETTINGS
});

export const fetchUserSettingsSuccess = (
  settings: UserSettings
): types.FETCH_USER_SETTINGS_SUCCESS_ACTION => ({
  type: types.FETCH_USER_SETTINGS_SUCCESS,
  settings
});

export const fetchUserSettingsFail = (error: Error): types.FETCH_USER_SETTINGS_FAIL_ACTION => ({
  type: types.FETCH_USER_SETTINGS_FAIL,
  error
});

export const putUserSettings = (settings: UserSettings): types.SAVING_USER_SETTINGS_ACTION => ({
  type: types.SAVING_USER_SETTINGS,
  settings
});

export const putUserSettingsSuccess = (): types.SAVE_USER_SETTINGS_SUCCESS_ACTION => ({
  type: types.SAVE_USER_SETTINGS_SUCCESS
});

export const putUserSettingsFail = (error: Error): types.SAVE_USER_SETTINGS_FAIL_ACTION => ({
  type: types.SAVE_USER_SETTINGS_FAIL,
  error
});

export const putClickAwayAccess = (
  bookCode: BookCode,
  edition: number
): types.SAVING_CLICKAWAY_ACCESS_ACTION => ({
  type: types.SAVING_CLICKAWAY_ACCESS,
  bookCode,
  edition
});

export const putClickAwayAccessSuccess = clickAwayAccess => ({
  type: types.SAVE_CLICKAWAY_ACCESS_SUCCESS,
  clickAwayAccess
});

export const putClickAwayAccessFail = (error: Error): types.SAVE_CLICKAWAY_ACCESS_FAIL_ACTION => ({
  type: types.SAVE_CLICKAWAY_ACCESS_FAIL,
  error
});

export const putCopyrightTerms = (
  productCopyrightTerms: ProductCopyrightTerms
): types.SAVING_COPYRIGHT_TERMS_ACTION => ({
  type: types.SAVING_COPYRIGHT_TERMS,
  productCopyrightTerms
});

export const putCopyrightTermsSuccess = copyrightTerms => ({
  type: types.SAVE_COPYRIGHT_TERMS_SUCCESS,
  copyrightTerms
});

export const putCopyrightTermsFail = (error: Error): types.SAVE_COPYRIGHT_TERMS_FAIL_ACTION => ({
  type: types.SAVE_COPYRIGHT_TERMS_FAIL,
  error
});
