import workbookReducer from './workbook-reducer';
import * as selectors from './workbook-selectors';
export * from './workbook-operations';
export * from './workbook-types';

export default workbookReducer;

export const getCurrentPage = (state, routeParams) =>
  selectors.getCurrentPage(state.workbook, routeParams);

export const getUsersInactiveWorkbooks = (state, routeParams) =>
  selectors.getUsersInactiveWorkbooks(state.workbook, state.user, routeParams);

export const getUsersStudentWorkbooks = state =>
  selectors.getUsersStudentWorkbooks(state.workbook, state.user);

export const getCurrentWorkbook = (state, routeParams) =>
  selectors.getCurrentWorkbook(state.workbook, routeParams);

export const getCurrentStudentWorkbook = (state, routeParams) =>
  selectors.getCurrentStudentWorkbook(state.workbook, routeParams);

export const getLimitedLicensedWorkbooks = state =>
  selectors.getLimitedLicensedWorkbooks(state.workbook, state.user);

export const getFullLicensedWorkbooks = state =>
  selectors.getFullLicensedWorkbooks(state.workbook, state.user);
