//
import React from 'react';
import { StyleSheet } from 'aphrodite';
import BoxIcon from '../icons/BoxIcon';
import BasicButton from '../core/BasicButton';
import { tA11y, translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { activeGreen } from '../../styles/style-constants';
import { adjustColor, remCalc } from '../../util/style-util';
const translateBookMenus = key => translate('bookMenus', key);

type Props =
  | {
      disabled: boolean;
      buttonStyles: any[];
    }
  | any;

const styles = StyleSheet.create({
  goButton: {
    flex: 2,
    maxWidth: remCalc(140),
    paddingTop: remCalc(2),
    fontWeight: 700
  }
});

const GoButton = ({ disabled, buttonStyles, ...props }: Props) => (
  <BasicButton
    aria-disabled={disabled}
    disabled={disabled}
    buttonStyles={[styles.goButton, ...buttonStyles]}
    {...props}
    aria-label={tA11y('aria.goButton')}
  >
    <BoxIcon
      fill={disabled ? adjustColor(activeGreen, -50) : activeGreen}
      text={translateBookMenus('go')}
    />
  </BasicButton>
);

export default GoButton;
