//
import isEmpty from 'lodash/isEmpty';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import find from 'lodash/find';
import sortBy from 'lodash/fp/sortBy';
import { createSelector } from 'reselect';
import { WorkbookState } from './workbook-types';
import { UserState } from '../user';

const getUsersWorkbooks = (workbook: WorkbookState) => workbook.allWorkbooks;
const getUsersLicenses = (workbook: WorkbookState, user: UserState) => user.licenses;
const isExpired = workbook => licenses => !isEmpty(filter(expired(workbook))(licenses));
const expired = workbook => license =>
  workbook.products.map(product => product.productId).includes(license.productId) &&
  license.expired &&
  !license.clickAwayAccess;
const limitedAccessWorkbook = workbook => licenses =>
  !isEmpty(filter(checkWorkbookAccess(workbook))(licenses));
const checkWorkbookAccess = workbook => license =>
  workbook.products.map(product => product.productId).includes(license.productId) &&
  (license.trial || (license.expired && license.clickAwayAccess));

export const getCurrentPage = (state: WorkbookState, routeParams: any) => {
  const currentWorkbook = getCurrentWorkbook(state, routeParams);

  const pageNumber = parseInt(routeParams.pageNumber, 10);

  return pageNumber > currentWorkbook.totalPages ? currentWorkbook.totalPages : pageNumber;
};

export const getCurrentWorkbook = createSelector(
  [(_, params) => params, getUsersWorkbooks, state => state],
  (bookParams, workbooks, workbookState) => {
    const { bookCode, edition } = bookParams;
    const currentWorkbook = find(
      workbooks,
      book => book.bookCode === bookCode && book.edition.toString() === edition
    );
    // pdf.numPages includes the intro page (page i), so page 1 is page 2 in the pdf.

    return {
      ...workbookState,
      ...currentWorkbook
    };
  }
);

export const getCurrentStudentWorkbook = createSelector(
  getCurrentWorkbook,
  getUsersWorkbooks,
  state => state,
  ((currentWorkbook, usersWorkbooks, workbookState) => {
    if (
      currentWorkbook &&
      currentWorkbook.workbookId &&
      usersWorkbooks[currentWorkbook.workbookId]
    ) {
      return {
        ...workbookState,
        ...usersWorkbooks[currentWorkbook.workbookId]
      };
    }

    return currentWorkbook;
  }) as any
);

export const getUsersStudentWorkbooks = createSelector(
  getUsersWorkbooks,
  getUsersLicenses,
  (workbooks, usersLicenses) =>
    flow(
      filter(book => book.type === 'activityBook'),
      filter(book => !isExpired(book)(usersLicenses)),
      sortBy('gradeOrder')
    )(workbooks)
);

export const getUsersInactiveWorkbooks = createSelector(
  (workbookState, userState, routeParams) => getCurrentStudentWorkbook(workbookState, routeParams),
  getUsersWorkbooks,
  getUsersLicenses,
  (activeWorkbook, workbooks, usersLicenses) =>
    flow(
      filter(book => book.bookCode !== (activeWorkbook as any).bookCode),
      filter(book => book.type === 'activityBook'),
      filter(book => !isExpired(book)(usersLicenses)),
      sortBy('gradeOrder')
    )(workbooks)
);

export const getLimitedLicensedWorkbooks = createSelector(
  getUsersWorkbooks,
  getUsersLicenses,
  (workbooks, usersLicenses) =>
    flow(
      filter(book => book.type === 'activityBook'),
      filter(book => !isExpired(book)(usersLicenses)),
      filter(book => limitedAccessWorkbook(book)(usersLicenses)),
      sortBy('gradeOrder')
    )(workbooks)
);

export const getFullLicensedWorkbooks = createSelector(
  getUsersWorkbooks,
  getUsersLicenses,
  (workbooks, usersLicenses) =>
    flow(
      filter(book => book.type === 'activityBook'),
      filter(book => !isExpired(book)(usersLicenses)),
      filter(book => !limitedAccessWorkbook(book)(usersLicenses)),
      sortBy('gradeOrder')
    )(workbooks)
);
