//
import React from 'react';

type Props = {
  fill: string;
  borderColor: string;
};

const WorkbookIcon = ({ fill, borderColor }: Props) => (
  <svg viewBox="0 0 17.14 22.28">
    <path
      style={{ fill }}
      d="M18.64,14.37l-.27,2.39-.87.6s-.49,1.09-.33,1.2.27.76.49,1l1,1-.38.63s-.76.62-.82.84-.45,1-.3,1.13,1.5,1.49,
      1.5,1.49-.22,1.47-.44,1.33-1.06-.23-1.1,0a11.71,11.71,0,0,0,0,1.38c0,
      .27.58.44.76.65s.89.54.78.81-.73.71-.77.87-.53-.44-.26.76a8.34,8.34,0,0,0,.49,1.8,1.52,1.52,0,0,1,.11.6l.92,2.94,
      13.71-.65s.05-16.38,0-16.92-.44-3.81-.44-3.81Z"
      transform="translate(-16.42 -13.84)"
    />
    <path
      style={{ fill: borderColor }}
      d="M32.36,33c-4,.83-8.91.39-13,.36h-.11c-.09-1.19-.14-2.38-.17-3.57a6.37,6.37,0,0,1,.85-.13c.66-.06.67-1.1,
      0-1a8.58,8.58,0,0,0-.87.13c0-.92,0-1.84,0-2.76.28,0,.55,0,.76,0,.67.07.66-1,0-1a6.79,6.79,0,0,0-.75,0c0-1.08,
      0-2.15,0-3.23a7.92,7.92,0,0,1,1,0c.67,0,.66-1,0-1a6.9,6.9,0,0,0-1,0c0-1.12,0-2.25.07-3.37a6.63,6.63,0,0,1,
      .87.05c.66.09.65-1,0-1a5,5,0,0,0-.86,0c0-.44,0-.88,0-1.32,4,0,8.87-.05,12.91-.07C32.81,20.88,32.38,26.93,32.36,
      33ZM19.54,35.11a7.33,7.33,0,0,1-.19-.76c4.12,0,9,.45,13.11-.36,0,.22,0,.43,0,.65C28.17,35,23.86,35.11,19.54,
      35.11Zm-1.4-15.9a1.11,1.11,0,0,1-.3-1.37,1,1,0,0,1,.34-.32Zm-.2,11.26a1.11,1.11,0,0,1,.14-.21c0,.36,0,.73,0,
      1.09A.81.81,0,0,1,17.94,30.47Zm-.05-8.32a1.08,1.08,0,0,1,.2-.16c0,.5,0,1,0,1.49C17.74,23.08,17.49,22.57,17.88,
      22.15Zm-.35,4.42a1,1,0,0,1,.5-.44c0,.52,0,1,0,1.57C17.64,27.4,17.3,27,17.53,26.57Zm15.6-12.21a.57.57,0,0,
      0-.55-.5l-.16,0c-4.2,0-9.18.13-13.37.07a.52.52,0,0,0-.19,0,.51.51,0,0,0-.65.48c0,.67,0,1.34,0,2a2.51,2.51,0,0,
      0-1.25.88c-.76,1,.07,2.29.84,3a.5.5,0,0,0,.34.14c0,.14,0,.27,0,.41a2.34,2.34,0,0,0-1.1.72c-.8,1-.15,2.15.57,
      2.95a.49.49,0,0,0,.28.16,1.44,1.44,0,0,0,.18.18v.2a2.23,2.23,0,0,0-1.45,1.07c-.63,1.19.52,2.27,1.46,2.81V29a2.25,
      2.25,0,0,0-1.06,1c-.54,1.12.38,2.13,1.21,2.75,0,.22,0,.44,0,.65a.45.45,0,0,0,0,.2,7.89,7.89,0,0,0,.46,2.09.52.52,
      0,0,0,.5.34.48.48,0,0,0,.18,0c4.5,0,9-.07,13.48-.51l.1,0a.49.49,0,0,0,.54-.49,16.11,16.11,0,0,0-.07-1.77.55.55,0,
      0,0,0-.14C33.41,26.92,33.89,20.62,33.13,14.36Z"
      transform="translate(-16.42 -13.84)"
    />
    <path
      style={{ fill: borderColor }}
      d="M23,24l-.57,0,.26-.88C22.8,23.36,22.9,23.66,23,24Zm0-2.58a.44.44,0,0,0-.4-.26.54.54,0,0,0-.51.4c-.33,
      1-.65,2.11-1,3.55a.46.46,0,0,0,.07.41.53.53,0,0,0,.42.2.47.47,0,0,0,.46-.35l.09-.32,0-.11c.39,0,.79,0,1.14,0,
      .05.2.11.4.16.59a.46.46,0,0,0,.46.36.55.55,0,0,0,.42-.2.45.45,0,0,0,.08-.41A18,18,0,0,0,23,21.4Z"
      transform="translate(-16.42 -13.84)"
    />
    <path
      style={{ fill: borderColor }}
      d="M27.37,23.6a.48.48,0,0,0-.48-.5H25.22a.5.5,0,0,0,0,1h1.66A.47.47,0,0,0,27.37,23.6Z"
      transform="translate(-16.42 -13.84)"
    />
    <path
      style={{ fill: borderColor }}
      d="M30.43,21.1l-.16,0H28.35a.5.5,0,0,0,0,1h1.21c-.69,1-1.44,1.94-2.28,3a.53.53,0,0,0-.09.16v0a.56.56,0,0,
      0,.08.46.51.51,0,0,0,.38.22l2.58.25h.05a.43.43,0,0,0,.44-.44.53.53,0,0,0-.47-.56L28.6,25h0c.94-1.19,1.7-2.21,
      2.38-3.21a.43.43,0,0,0,0-.46A.54.54,0,0,0,30.43,21.1Z"
      transform="translate(-16.42 -13.84)"
    />
  </svg>
);

WorkbookIcon.defaultProps = {
  fill: '#FFF',
  borderColor: '#111'
};

export default WorkbookIcon;
