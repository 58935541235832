import React from 'react';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ltGrey } from '../../styles/style-constants';
import MaterialButton from '@mui/material/Button';

type Props = {
  id: number;
  icon: string;
  title: string;
  size: number;
};

const getIcon = (icon: string, size = 25) => <img src={icon} width={size} height={size} alt="" />;

const Button = (props: Props) => {
  const { icon, id, title, size = 25, tabIndex, ...rest }: any = props;
  const { btnCircle, grow } = getStyles(size);

  return (
    <MaterialButton
      tabIndex={tabIndex}
      id={id}
      type="button"
      title={title}
      className={`btn ${css(btnCircle, grow)}`}
      {...rest}
    >
      {getIcon(icon, size)}
    </MaterialButton>
  );
};

const getStyles = (size: number) =>
  StyleSheet.create({
    btnCircle: {
      width: size * 1.45,
      height: size * 1.45,
      margin: size * 0.15,
      backgroundColor: 'white',
      padding: 0,
      border: `${size * 0.05}px black solid`,
      ':focus': {
        backgroundColor: 'white'
      },
      ':hover': {
        backgroundColor: 'white'
      },
      borderRadius: '255px 235px 300px 215px / 225px 245px 235px 255px',
      textAlign: 'center'
    },

    grow: {
      ':enabled': {
        transition: 'all 0.2s'
      },
      ':hover': {
        transform: 'scale(1.2)'
      },
      ':focus': {
        transform: 'scale(1.2)'
      },
      ':active': {
        backgroundColor: ltGrey
      }
    }
  });

export default onlyUpdateForKeys(['icon', 'id', 'title', 'size'])(Button);
