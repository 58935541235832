//
import React from 'react';

type Props = {
  fill: string;
  borderColor: string;
};

const LogoutIcon = ({ fill, borderColor }: Props) => (
  <svg viewBox="0 0 25.58 20.75">
    <path
      style={{ fill }}
      d="M2.83,3.88v14l11.26.2.37-4.2,2.43.08.21,4.12s6.25-6.87,6.12-6.83a6.89,
      6.89,0,0, 1-.29-1.58L16.77,3.88l-.13,3.7s-2.59-.08-2.55-.12-.39-4-.39-4Z"
    />
    <path
      style={{ fill: borderColor }}
      d="M21.64,7.68,17.18,3.15c-.26-.25-.54-.13-.73.12A.92.92,0,0,0,16,4c0,.66,0,2.62.06,
      3.26l-1.51.06,0-3.49s0-.07,0-.1c0-.27-.13-.41-.44-.52a.68.68,0,0,0-.31-.08l-2.7-.05-.1.08L2.75,
      3A.77.77,0,0,0,2,3.77c0,.9,0,1.8,0,2.7A113.92,113.92,0,0,0,2.1,18a.79.79,0,0,0,.52.73c1.17.23,
      9.3,0,10.41,0l.86,0,0,0a.8.8,0,0,0,.73-.53v0a1.12,1.12,0,0,0,0-.31v0a32.74,32.74,0,0,0,
      .08-3.71c.66,0,1,.1,1.66.12.09.84.14,3.1.14,3.95s.72.58,1,.1h0l4-4.73c.63-.76,2.16-2,
      2.18-3.14S22.33,8.38,21.64,7.68ZM13.87,17.1c0,.06-.12,1-.16,1s-.17,0-.25,0c-.77,0-9.37,
      0-10.11-.12-.13,0-.49-.33-.5-.47C2.63,14.62,2.74,11,2.8,8.06c0-1.1,0-2.86,0-3.95a.29.29,0,0,
      1,.29-.28l10,.28c.1,0,.49,0,.49.13l.14,3.1c-1.12,0-4.54.09-5.66.12a.79.79,0,0,0-.69.88c.12,
      2.63.24,2.62.36,5.24a.55.55,0,0,0,.5.54c1.16.07,4.51.05,5.67.09C13.89,15,14,16.45,13.87,
      17.1Zm.25-3.86c-1.08,0-4.45-.09-5.53-.16-.1-2.16-.2-2.5-.3-4.66,2.18-.07,6.11-.07,8.29-.13.43,
      0,.54-.15.5-.56-.06-.56,0-2.63,0-3.48.73.74,1.85,2.13,2.58,2.87a32,32,0,0,1,2.43,
      2.26c.29.36.85.81.88,1.16s-.41.5-.56.8a18.29,18.29,0,0,1-2.44,2.85c-.66.79-2,2-2.69,2.77,0-.91,
      0-2.38,0-3,0-.27-.2-.64-.5-.65"
    />
  </svg>
);

LogoutIcon.defaultProps = {
  fill: '#FFF',
  borderColor: '#111'
};

export default LogoutIcon;
