//
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import withRouter from 'state/hooks/withRouter';
import { connect } from 'react-redux';
import flow from 'lodash/fp/flow';
import PrivateRoute from '../components/core/PrivateRoute';
import OptimalExperiencePage from '../components/Setup/OptimalExperiencePage/OptimalExperiencePage';
import WorkbookSelectorPanel from '../components/Setup/WorkbookSelectorPanel';

type Props = {
  isAuthenticated: boolean;
  match: {
    path: string;
  };
};

const Setup = ({ isAuthenticated, match }: Props) => (
  <Routes>
    <PrivateRoute
      path={`${match.path}/workbook`}
      isAuthenticated={isAuthenticated}
      component={WorkbookSelectorPanel}
    />
    <Route path={`${match.path}/device`} element={<OptimalExperiencePage />} />
    <Route path="*" element={<Navigate to={'/404'} replace />} />
  </Routes>
);

const mapStateToProps = state => ({
  isAuthenticated: !!state.auth.isAuthenticated
});

const withRedux = connect(mapStateToProps);

export default flow(withRedux, withRouter)(Setup);
