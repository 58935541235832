import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { AppState } from 'reducers';
import { PREKIT_FONT } from '../../../styles/style-constants';
import { getRouteForBook } from '../../../util/workbook-util';

type Props = {
  data: any;
  style?: any;
};
const GridCardContent: React.FC<Props> = ({ data, style }) => {
  const classes = useStyles();
  const user = useSelector(({ user }: AppState) => user);

  const getLink = useCallback(
    workbook => {
      return getRouteForBook(workbook, user);
    },
    [user]
  );
  return data?.map((x, idx) => (
    <Link
      className={classes.link}
      tabIndex={0}
      to={getLink(x)}
      key={`grid_card_navigation_link_${idx}`}
    >
      <Paper
        tabIndex={-1}
        aria-label={x.title}
        key={`${x.title}_${idx}`}
        component="div"
        className={classes.cardRow}
        style={style}
        variant="outlined"
      >
        <img alt={`${x.title} ${tA11y('alt.thumbnailIcon')}`} src={x.thumbnailUrl} />
        <Typography
          className={classes.titleText}
          variant="h5"
          style={{ margin: 0, fontSize: '0.875rem' }}
        >
          {x.title}
        </Typography>
      </Paper>
    </Link>
  ));
};

const useStyles = makeStyles(() => ({
  cardRow: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    margin: 10,
    height: 112,
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.05, 1.05)',
      boxShadow: 'rgb(176 176 176) 0px 3px 7px 1px'
    }
  },
  titleText: {
    fontFamily: PREKIT_FONT,
    fontWeight: 300,
    textAlign: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4
  },
  alingTextCenter: {
    textAlign: 'center'
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    flex: 1,
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

export default GridCardContent;
