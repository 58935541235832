import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import prekittLogo from '../../images/gss-logo-round.png';
import { remCalc } from '../../util/style-util';
import { getSpecificTranslation } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const translateSelectForLocale = (locale, text) => getSpecificTranslation(locale, 'select', text);

const headerLogoSize = 90;
const { logo, center, header } = StyleSheet.create({
  logo: {
    position: 'absolute',
    left: `calc(50% - ${remCalc(headerLogoSize / 2)})`,
    top: remCalc(-60),
    width: remCalc(headerLogoSize)
  },
  center: {
    textAlign: 'center'
  },
  header: {
    paddingTop: remCalc(15)
  }
});

const WorkbookSelectorHeader = props => (
  <header className={css(header, center)}>
    <img className={css(logo)} src={prekittLogo} alt={tA11y('alt.prekittLogo')} />
    <h1>{translateSelectForLocale(props.textLocale, 'select:header')}</h1>
  </header>
);

export default WorkbookSelectorHeader;
