//
import React from 'react';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const translateBookMenus = key => translate('bookMenus', key);

type Props = {
  fill: string;
  text: string;
  borderColor: string;
  textColor: string;
};

const TextBoxIcon = ({ fill, text, borderColor, textColor, ...props }: Props) => (
  <svg {...props} viewBox="0 0 128 80">
    <g transform="translate(858 512)">
      <path
        style={{ fill }}
        d="M-733.4-497c-2.7-2.5-3.4,0.5-8.2,0.4c-2.2,0-4.8,0.3-7.6,0.3c-2.3,0-5,0.3-7.6,0.3
        c-2.4,0-5-0.7-7.6-0.7c-2.5,0-5.1,0.6-7.6,0.6c-2.5,0-5.1,0.1-7.6,0.1s-5.1-1-7.6-1c-2.7,0-41.7,0.2-44.1,0.2
        c-2.7,0-5.2,1.2-7.6,1.3c-2.9,0.1-5.5-1.4-7.6-1.4c-4.8,0.1-7.2,1-7.2,1.2c-0.9,2.2-1.5,4.5-1.8,6.8c-0.1,2-0.1,4.4-0.3,6.9
        c-0.2,2.3,0.4,13.1,0.3,15.4c-0.1,2.5,0.3,4.9,0.2,6.9c-0.1,4-1,6.3-0.6,6.3c0.2,0,3,0,7.3,0.1c2.1,0.1,4.5,0.1,7.2,0.2
        c2.2,0.1,4.6-0.1,7.2,0c2.3,0.1,4.8,1.3,7.2,1.4c2.3,0.1,41.2-0.4,43.7-0.3s4.8,0.3,7.2,0.4c2.4,0.1,4.9-0.9,7.3-0.9
        c2.4,0.1,4.9,0.1,7.2,0.2c2.5,0,4.9,1.2,7.2,1.2c2.5,0,5-0.3,7.2-0.3c2.8,0,5.2-0.6,7.2-0.7c4.3-0.1,7.1,0.1,7.2-0.2
        c0.1-0.5-0.7-3.3-0.8-7.1c0-2.1,1.4-4.6,1.3-7.1c-0.1-2.3-0.6-13.2-0.7-15.6c-0.1-2.5-0.9-5-0.9-7.1
        C-734.1-493.6-730.7-494.4-733.4-497z"
      />
      <path
        style={{ fill: borderColor }}
        d="M-732.9-469.6c-0.8-5.3,0-19.2-0.7-24.4c-0.4-3.5-2-4.1-7.6-4.1c-8.9,0.2-79.3-0.1-88.2,0.1
        c-8.2,0.1-16.6,1.1-24.8,0.9c-2.2-0.1-3,2.5-0.7,2.6c-1.1,4.3,0.3,8.6,0.4,12.9c0.1,6.9,0.7,22.4,0.8,29.2c0,0.9,1.3,1.1,2.4,0.9
        c3.9-1.1,8.3-0.5,12.5-0.3c4.3,0.3,8.7,0.2,13,0.1c7.7-0.1,76.9-0.5,84.6-0.7c4.7-0.1,7-0.4,8-3.5
        C-731.6-460.3-732.3-465-732.9-469.6z M-736.5-456.2c-1.4,2.3-38.4,1.6-41.9,1.7c-7.2,0.2-50.9,0.6-58.1,0.2
        c-4.7-0.3-9.1-0.8-13.5-0.1c-0.1-5.6-0.7-11-0.7-16.6c0-4.7-2-18.8-0.5-23.5c5.4-0.1,10.7-0.5,16.2-0.7c7.9-0.2,52-0.2,59.9-0.2
        c3.2,0,32-0.3,35.1,0c1.8,0.1,1.9-0.1,2.7,1.1c0.7,0.8,0.2,2.3,0.2,3.2c0,4.5-0.1,17.6,0.5,22.2c0.4,2.2,0.6,4.5,0.7,6.8
        C-735.8-460.7-735.6-457.7-736.5-456.2z"
      />
      <text
        transform={`matrix(1 0 0 1 ${translateBookMenus('goButtonOffset')} -466.0108)`}
        style={{ fill: textColor, fontFamily: 'futura-pt', fontSize: '22px' }}
      >
        {text}
      </text>
    </g>
  </svg>
);

TextBoxIcon.defaultProps = {
  text: 'Stay Classy',
  borderColor: '#010101',
  textColor: '#FFF'
};

export default TextBoxIcon;
