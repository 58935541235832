export const getDev = () => ({
  application: 'prekitt',
  pliURL: 'http://localdev.lwtears.com:8081',
  agiURL: 'https://qa01-agi.lwtears.com',
  apiURL: `http://localdev.lwtears.com:5004/prekitt/api`,
  companyURL: 'https://dev.lwtears.com',
  gaTrackingId: 'UA-106790492-4',
  supportEmail: '0c0f3614.lwtears.com@amer.teams.ms',
  wmUrl: 'https://qa01-worksheet-maker.lwtears.com/0?lite=true',
  copyrightEmail: 'legal@lwtears.com',
  digitalProductSupportUrl: 'https://dev.lwtears.com/contact-us/digital-product-support'
});
