//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

import { TYPOGRAPHY_TYPES } from '../../constants/Resources';
import { getTypographyType } from '../../util/character-util';
import { white, black } from '../../styles/style-constants';

import { FilterTag } from '../../reducers/resource-reducer';

type Props = {
  selected: boolean;
  size: number;
  character: FilterTag;
  ariaLabel: string;
  onClick: () => any;
};

function CharacterTile(props: Props) {
  const { characterTile, svgBackground, text } = getStyles(props);
  const handleKeyDown = event => {
    const key = event.keyCode;
    if (key === 32 || key === 13) {
      props.onClick();
    }
  };
  return (
    <div
      className={css(characterTile)}
      onClick={props.onClick}
      role="option"
      aria-label={props.ariaLabel}
      onKeyDown={handleKeyDown}
      aria-selected={props.selected}
      tabIndex={0}
    >
      <svg
        className={css(svgBackground)}
        height={props.size}
        width={props.size}
        viewBox="0 0 14.64 14.34"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          style={{ fill: props.selected ? black : white }}
          x="0.769"
          y="0.819"
          width="13.208"
          height="12.288"
          transform="matrix(0.999997, -0.002232, 0.011083, 0.999978, -0.267447, 0.197449)"
        />
        <path
          style={{ fill: black }}
          d="M17.19,6.72a28.94,28.94,0,0,0-.28-3.22.42.42,0,0,0-.38-.4c-1.16,0-2.29.28-3.45.18a25,25,0,0,0-2.86-.43c-2.24,0-4.53.25-6.77.25-.26,0-.43.25-.48.5a.51.51,0,0,0-.13.33c0,2.77,0,5.53.08,8.3.05,1.18-.33,3.77,1,4.5A6.27,6.27,0,0,0,6,17.11a14.72,14.72,0,0,0,3.25,0c2.41-.33,4.8,0,7.22-.28a.57.57,0,0,0,.5-.52c.08-2.24-.33-4.46-.1-6.7C17,8.63,17.16,7.68,17.19,6.72Zm-6.45,9.13A21.45,21.45,0,0,1,5.31,16c-.78-.1-1.16-.07-1.39-1a4.42,4.42,0,0,1,0-1c-.05-1.13-.1-2.26-.12-3.37,0-2.19,0-4.35,0-6.54,2.11,0,4.25-.25,6.36-.23,1,.1,1.94.24,2.9.43a20.38,20.38,0,0,0,3.12-.15,18.51,18.51,0,0,1,.22,2.34c0,1.21-.25,2.39-.32,3.57-.13,1.92.15,3.8.15,5.72C14.34,15.91,12.53,15.63,10.74,15.85Z"
          transform="translate(-2.73 -2.85)"
        />
      </svg>
      <span className={css(text)}>{props.character.value}</span>
    </div>
  );
}

const getStyles = (props: Props) => {
  const { selected, size, character } = props;
  const typographyType: string = getTypographyType(character.value);
  let top: number = 0;

  switch (typographyType) {
    case TYPOGRAPHY_TYPES.ASCENDER: {
      top = 55;
      break;
    }
    case TYPOGRAPHY_TYPES.DESCENDER: {
      top = 35;
      break;
    }

    default: {
      top = (character.value >= 'a' && character.value <= 'z') || character.value === 'ñ' ? 50 : 60;
    }
  }

  if (character.value === 'f' || character.value === 'j') {
    top += 5;
  }

  return StyleSheet.create({
    characterTile: {
      userSelect: 'none',
      fontFamily: 'GSS Print Wide',
      color: selected ? white : black,
      width: size,
      height: size,
      cursor: 'pointer',
      position: 'relative'
    },
    svgBackground: {
      position: 'absolute',
      width: size,
      height: size
    },
    text: {
      fontSize: size * 0.5,
      position: 'absolute',
      top: `${top}%`,
      left: '52%',
      transform: 'translate(-50%, -50%)'
    }
  });
};

export default CharacterTile;
