import WDTIcon from '../../../components/icons/WDTIcon';
import WPIcon from '../../../components/icons/WPIcon';
import LNFIcon from '../../../components/icons/LNFIcon';
import ResourcesIcon from '../../../components/icons/ToolsIcon';
import AppleInBoxIcon from '../../../components/icons/AppleInBoxIcon';
import WorksheetMakerIcon from '../../../components/icons/WorksheetMakerIcon';
import Config from 'config';
import PrekittApi from '../../../api';

const createEvent = appName => {
  const event = {
    section: 'apps',
    application: appName
  };
  PrekittApi.createEvent('externalApp', event);
};

export const AppNavigationItems = [
  {
    name: 'lnf',
    link: '/apps/letter-number-formations',
    linkType: 'prekitt',
    icon: LNFIcon,
    clickAway: false
  },
  {
    name: 'wdt',
    link: '/apps/wet-dry-try',
    linkType: 'prekitt',
    icon: WDTIcon,
    clickAway: false
  },
  {
    name: 'woodPieces',
    link: '/apps/wood-pieces',
    linkType: 'prekitt',
    icon: WPIcon,
    clickAway: false
  },
  {
    name: 'worksheetMaker',
    link: '/apps/worksheet-maker',
    linkType: 'prekitt',
    icon: WorksheetMakerIcon,
    clickAway: false,
    onClick: () => createEvent('worksheetMaker')
  }
];

export const AssessmentsAndResources = (agiOnClickCallback: () => void) => {
  const { agiURL } = Config;

  return [
    {
      title: 'assessmentsToGuideInstruction',
      link: '',
      linkType: 'web',
      icon: AppleInBoxIcon,
      clickAway: false,
      onClick: () => {
        agiOnClickCallback();
        window.open(agiURL, '_blank');
        createEvent('AGI');
      },
      MaybeShowNew: true // true means it will potentially show the "NEW" indicator
    },
    {
      title: 'resources',
      icon: ResourcesIcon,
      link: '/resources',
      clickAway: true
    }
  ];
};
