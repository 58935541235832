import React from 'react';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import flow from 'lodash/fp/flow';
import withRouter from 'state/hooks/withRouter';
import PdfViewer from './PdfViewer';

type Props = {
  currentPage: number;
  workbook: any;
  fingerprint?: string;
};

const DoublePageViewer = ({ currentPage, workbook, fingerprint }: Props) => {
  const isPortrait = workbook.orientation === 'portrait';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isPortrait ? 'row' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <PdfViewer
        currentPage={currentPage}
        workbook={workbook}
        fingerprint={fingerprint}
        isTwoPages={true}
      />
    </div>
  );
};

export default flow(
  onlyUpdateForKeys(['currentPage', 'fingerprint', 'zoom', 'workbook']),
  withRouter
)(DoublePageViewer);
