import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import WebLink from '../core/WebLink';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import drawnCirclePath from '../../images/gray-drawn-circle.png';
import { remCalc } from '../../util/style-util';
import { darkestGray } from '../../styles/style-constants';
const translateFooter = key => translate('footer', key);

function TrialBadge({ daysLeft, showTrialModal, textColor }) {
  const disableTrialModal = showTrialModal === null;
  const { trialReminder, trialBadge, dayCount, trialLink } = getStyles(
    textColor,
    disableTrialModal
  );

  return (
    <div className={css(trialReminder)}>
      <div className={css(trialBadge)}>
        <span className={css(dayCount)}>{daysLeft}</span>
      </div>
      {disableTrialModal ? (
        <div className={css(trialLink)}>{translateFooter('trialDaysLeft')}</div>
      ) : (
        <WebLink className={css(trialLink)} name={translateFooter('trialDaysLeft')} />
      )}
    </div>
  );
}

export function getStyles(textColor: string, disableTrialModal: boolean) {
  return StyleSheet.create({
    trialReminder: {
      marginRight: remCalc(10)
    },
    trialBadge: {
      height: remCalc(35),
      width: remCalc(35),
      paddingTop: remCalc(7),
      backgroundImage: `url('${drawnCirclePath}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      display: 'inline-block',
      textAlign: 'center'
    },
    dayCount: {
      display: 'inline-block',
      width: remCalc(21),
      textAlign: 'center',
      color: darkestGray
    },
    trialLink: {
      width: '20vw',
      textDecoration: disableTrialModal ? '' : 'underline',
      color: textColor,
      marginLeft: remCalc(10),
      ':hover': {
        color: textColor
      },
      ':active': {
        color: textColor
      }
    }
  });
}

export default TrialBadge;
