import type { UserState } from '../user';
import PreKittApi from '../../api';

export type AuthState = {
  isAuthenticated: boolean;
  hasLoggedOut: boolean;
  isLoggingIn: boolean;
};

export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export type USER_LOGIN_STARTED_ACTION = { type: typeof USER_LOGIN_STARTED };
export type LOGIN_SUCCESS_ACTION = { type: typeof LOGIN_SUCCESS; user: UserState };
export type LOGIN_FAIL_ACTION = { type: typeof LOGIN_FAIL; error: Error };

export const LOGOUT_USER = 'LOGOUT_USER';
export type LOGOUT_USER_ACTION = { type: typeof LOGOUT_USER };

export type AuthAction =
  | USER_LOGIN_STARTED_ACTION
  | LOGIN_SUCCESS_ACTION
  | LOGIN_FAIL_ACTION
  | LOGOUT_USER_ACTION;

/* eslint-disable no-use-before-define */
type GetState = () => { auth: AuthState };
type PromiseAction = Promise<AuthAction>;
export type Dispatch = (
  action: AuthAction | ThunkAction | PromiseAction | Array<AuthAction>
) => any;
export type ThunkAction = (dispatch: Dispatch, getState: GetState, api: typeof PreKittApi) => any;
