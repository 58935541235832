import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../util/style-util';
import ArrowDropdown from '../core/Dropdowns/ArrowDropdown';
import maybe, { MaybeProps, Maybe } from '../core/enhancers/maybe';

import { FOCUS_ACTIVE_WHITE_BORDER, PREKIT_FONT } from '../../styles/style-constants';
import { connect } from 'react-redux';
import { Box, Collapse, List, ListItem, Typography } from '@mui/material';
import withRouter from 'state/hooks/withRouter';
import { Link, LinkProps } from 'react-router-dom';
import flow from 'lodash/fp/flow';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { AppStoreState } from 'types';
import { toggleSlideoutMenu } from 'actions/ui-actions';

export type LinkType = 'prekitt' | 'web' | 'static';

export type Props = {
  name: string;
  link: string;
  icon: string;
  subItems: any;
  children?: any;
  onClick?: () => any;
  linkType: LinkType;
  activeColor: string;
  handleItemClick: () => any;
  toggleSubmenu: (string: any) => any;
  isSubmenuItem: boolean;
  keepSubmenuState: boolean;
  renderChildren: boolean;
  accessibilityLabelDescription: string;
  accessibilityLabelButton: (bool: boolean) => void;
  setCloseTabIndex: (tabIndex: number) => any;
  router: any;
  toggleSlideoutMenu: (bool: boolean) => void;
};

type State = {
  open: boolean;
};

type MaybeLinkProps = Partial<LinkProps> & MaybeProps;
type MaybeDropDownProps = { accessibilityLabelButton?: any } & State & MaybeProps;

const MaybeLink: React.FC<MaybeLinkProps> = Maybe(Link);

const MaybeDropdown: React.FC<MaybeDropDownProps> = maybe(ArrowDropdown);
class MenuItem extends Component<Props, State> {
  state = {
    open: false
  };

  handleDropdownState = () => {
    const { toggleSubmenu, name, renderChildren } = this.props;
    renderChildren ? toggleSubmenu('') : toggleSubmenu(name);
  };

  static getDerivedStateFromProps(props: Props) {
    return {
      open: props.keepSubmenuState ? props.renderChildren : false
    };
  }

  handleClick = (props, isSubmenuItem?) => {
    this.handleDropdownState();

    if (props?.onClick) props.onClick();
    if (!this.props.subItems?.length) this.props.toggleSlideoutMenu(false);
    if (isSubmenuItem) this.props.toggleSlideoutMenu(false);
  };

  render() {
    const {
      name,
      link,
      icon: Icon,
      activeColor,
      isSubmenuItem,
      accessibilityLabelButton,
      subItems
    } = this.props;

    const { menuItem, wrapper, iconWrapper, menuLink, menuButton, smallText } = getStyles(
      activeColor
    );

    const maybeApplyProps: any = subItems?.length && {
      button: true
    };

    const MaybeAriaProps = !!subItems &&
      this.state.open && {
        'aria-expanded': true
      };

    return (
      <MaybeLink
        tabIndex={!subItems?.length ? 0 : -1}
        shouldRender={!subItems?.length}
        className={css(menuItem)}
        to={link}
        onClick={() => this.handleClick(this.props)}
      >
        <ListItem
          {...(subItems ? { role: 'menu' } : undefined)}
          tabIndex={subItems ? 0 : -1}
          {...MaybeAriaProps}
          aria-haspopup={!!subItems}
          aria-label={`${name} ${subItems ? tA11y('aria.listItems') : ''}`}
          disableGutters
          {...maybeApplyProps}
          alignItems="flex-start"
          style={{ padding: 10 }}
          className={css(menuItem)}
          onClick={() => this.handleClick({ onClick: this.props?.onClick })}
        >
          <Box style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'space-between' }}>
            <Box display="flex">
              <span className={css(iconWrapper)}>
                <Icon />
              </span>
              <Typography
                style={{ marginLeft: '5px', alignSelf: 'center', whiteSpace: 'pre-wrap' }}
                className={css(menuLink, isSubmenuItem && smallText)}
              >
                {name}
              </Typography>
            </Box>
            <MaybeDropdown
              shouldRender={!!subItems}
              open={this.state.open}
              accessibilityLabelButton={accessibilityLabelButton}
            />
          </Box>

          <Collapse
            in={this.state.open}
            timeout="auto"
            unmountOnExit
            style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'flex-end' }}
            classes={{ wrapper: css(wrapper) }}
          >
            <List component="div" role="menu">
              {subItems?.map(({ name, icon: Icon, link, ...childProps }, idx) => (
                <Link
                  key={`menu_link_${idx}`}
                  className={css(menuItem)}
                  to={link}
                  onClick={() => this.handleClick(childProps, true)}
                >
                  <ListItem
                    key={`list_item_${idx}`}
                    tabIndex={-1}
                    className={css(menuButton, smallText)}
                  >
                    <Icon />
                    <Typography
                      style={{ marginLeft: 10, fontFamily: PREKIT_FONT, whiteSpace: 'pre-wrap' }}
                    >
                      {name}
                    </Typography>
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
        </ListItem>
      </MaybeLink>
    );
  }
}

const getStyles = (activeColor: string) =>
  StyleSheet.create({
    menuItem: {
      display: 'flex',
      flexDirection: 'column',
      textDecoration: 'none',
      ...FOCUS_ACTIVE_WHITE_BORDER
    },
    wrapper: {
      flex: 1,
      paddingLeft: 0
    },
    menuButton: {
      color: '#fff',
      display: 'flex',
      borderBottom: '1px solid #4d4d4d',
      padding: '15px 5px',
      width: '100%',
      alignItems: 'center'
    },
    menuSection: {
      color: '#fff',
      display: 'flex',
      alignItems: 'flex-start',
      borderBottom: '1px solid #4d4d4d',
      padding: '10px',
      width: '100%'
    },
    iconWrapper: {
      width: remCalc(52)
    },
    menuLink: {
      fontSize: '1rem',
      padding: '5px 0px 5px 5px',
      color: 'white',
      textDecoration: 'none',
      ':hover': {
        color: activeColor,
        textDecoration: 'none',
        cursor: 'pointer'
      }
    },
    smallText: {
      fontSize: '0.9rem',
      alignItems: 'initial',
      paddingLeft: 20,
      justifyContent: 'flex-start',
      ...FOCUS_ACTIVE_WHITE_BORDER
    },
    border: { ...FOCUS_ACTIVE_WHITE_BORDER }
  });

const withRedux = connect(
  (state: AppStoreState) => ({
    selectedSubmenu: state.ui.selectedSubmenu
  }),
  { toggleSlideoutMenu }
);

export default flow(withRedux, withRouter)(MenuItem);
