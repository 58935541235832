import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { remCalc } from '../../../util/style-util';
import { Resource } from 'types';
type Props =
  | {
      resource: Resource;
    }
  | any;

function ResourceItemContent(props: Props) {
  const { resourceItemContent, resourceTitle, resourceSubtitle } = styles;

  return (
    <div className={css(resourceItemContent)} {...props.itemStyleOverides}>
      <span className={css(resourceTitle)}>{props.resource.title}</span>
      <span className={css(resourceSubtitle)}>{props.resource.description}</span>
    </div>
  );
}
const styles = StyleSheet.create({
  resourceItemContent: {
    padding: '20px 30px 20px 30px',
    position: 'relative',
    height: remCalc(190),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  resourceTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: remCalc(18),
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden',
    marginBottom: remCalc(9),
    maxHeight: remCalc(77)
  },
  resourceSubtitle: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: remCalc(18),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    maxHeight: remCalc(77)
  }
});

export default ResourceItemContent;
