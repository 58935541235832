import { LOGIN_SUCCESS } from '../auth';
import {
  FETCH_USER_SETTINGS_SUCCESS,
  SAVING_USER_SETTINGS,
  SAVE_CLICKAWAY_ACCESS_SUCCESS,
  SAVE_COPYRIGHT_TERMS_SUCCESS
} from './types';
import { License } from 'types';
import { ClickAwayAccess, UserState, UserAction } from './types';

const initialState = {
  id: null,
  authToken: '',
  firstName: '',
  lastName: '',
  licenses: [],
  username: '',
  settings: {},
  clickAwayAccess: []
};

const addClickAwayLicenses = (licenses: Array<License>, clickAwayAccess: ClickAwayAccess) => {
  const updatedLicenses = licenses.map(license => ({
    ...license,
    hasClickAway: clickAwayAccess.some(clickAway => clickAway.productCode === license.productCode)
  }));

  return [
    ...updatedLicenses,
    ...clickAwayAccess
      .filter(clickAway => !licenses.find(license => clickAway.productCode === license.productCode))
      .map(clickAway => ({ ...clickAway, hasClickAway: true }))
  ];
};

function userReducer(state: UserState = initialState, action: UserAction) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.user
      };
    case FETCH_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.settings || {}
      };
    case SAVING_USER_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.settings
        }
      };
    case SAVE_CLICKAWAY_ACCESS_SUCCESS:
      return {
        ...state,
        licenses: addClickAwayLicenses(state.licenses, action.clickAwayAccess)
      };
    case SAVE_COPYRIGHT_TERMS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          copyrightTerms: action.copyrightTerms
        }
      };
    default:
      return state;
  }
}

export default userReducer;
