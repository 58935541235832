import React, { PureComponent } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import ResourceItem from '../ResourceItem/ResourceItem';
import FtueCallout from '../../Ftue/FtueCalloutContainer';
import { remCalc } from '../../../util/style-util';
import { Resource, ResourceType } from 'types';
import { LiteResource } from '../../../reducers/resource-reducer';

type Props =
  | {
      resourcesItems: Resource[];
      isFavoritePage?: boolean;
      isAssessmentPage?: boolean;
      onSelectItem: (resource: LiteResource) => any;
      onClickPinIcon?: (resource: Resource) => any;
      onRemoveItem?: (resource: Resource) => any;
    }
  | any;

class ResourcesList extends PureComponent<Props> {
  handleSelectItem(resource: any) {
    return () => {
      this.props.onSelectItem(resource);
    };
  }

  renderResourcesItems() {
    const sortedItems = this.props.resourcesItems.sort((resourceA, resourceB) => {
      const titleA = resourceA.title.toUpperCase();
      const titleB = resourceB.title.toUpperCase();

      return titleA < titleB ? -1 : titleA > titleB ? 1 : 0;
    });

    const resourceItems: Array<any> = sortedItems.map((item: Resource, index: number) => {
      const resource: ResourceType = {
        ...item.data,
        id: item?.id ?? 0,
        title: item.title,
        type: item.type.type,
        src: item.src ?? item?.url,
        vtt: item?.vtt,
        narration: item?.narration ?? null
      };

      return (
        <li key={index} {...this.props.listStyleOverrides}>
          <ResourceItem
            listStyleOverrides={this.props.listStyleOverrides}
            tabIndex={0}
            resource={item}
            hasUnpinIcon={item.favorites && item.favorites.length > 0}
            isFavoritePage={this.props.isFavoritePage}
            onClick={this.handleSelectItem(resource)}
            onClickPinIcon={this.props.onClickPinIcon}
            onClickCloseIcon={this.props.onRemoveItem}
            isAssessmentPage={this.props.isAssessmentPage}
          />
        </li>
      );
    });

    return resourceItems;
  }

  render() {
    const { resourcesList, gridLayout } = getStyles(this.props.isAssessmentPage);

    return (
      <ul className={css(resourcesList, gridLayout)}>
        <FtueCallout
          name="resources"
          step="tiles"
          position="top"
          justifyPointer="center"
          style={{ transform: 'translate(50%, 10%)' }}
          noFlex
        />
        {this.renderResourcesItems()}
      </ul>
    );
  }
}

const screenSize = {
  smartphone: '@media only screen and (max-width: 499px)',
  smartphoneLandscape: '@media only screen and (min-width: 500px) and (max-width: 799px)',
  tablet: '@media only screen and (min-width: 800px) and (max-width: 1199px)',
  desktop: '@media only screen and (min-width: 1200px) and (max-width: 1699px)',
  wideScreen: '@media only screen and (min-width: 1700px)'
};

const getStyles = isAssessmentPage =>
  StyleSheet.create({
    resourcesList: {
      fontFamily: 'GSS Futura',
      padding: remCalc(19.2),
      ...(!isAssessmentPage && {
        width: '97.5%',
        minHeight: '100%'
      })
    },
    gridLayout: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: '30px 30px',
      justifyContent: 'center',
      listStyle: 'none',
      [screenSize.smartphone]: {
        gridTemplateColumns: '1fr'
      },
      [screenSize.smartphoneLandscape]: {
        gridTemplateColumns: '1fr'
      },
      [screenSize.tablet]: {
        gridTemplateColumns: '1fr 1fr'
      },
      [screenSize.desktop]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr'
      },
      [screenSize.wideScreen]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr'
      }
    }
  });

export default ResourcesList;
