// https://stackoverflow.com/questions/75833107/click-and-drag-to-scroll-with-mouse-react-typescript-component
import React, { useRef, useState } from 'react';

type Props = {
  children: React.ReactElement<any, any>;
  isZoomedIn: boolean;
};

const DragContainer = ({ children, isZoomedIn }: Props) => {
  const wrapperRef = useRef<any>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });

  const handleDragStart = e => {
    if (!wrapperRef.current) return;
    const slider = wrapperRef.current.children[0];
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = 'grabbing';
  };

  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!wrapperRef.current) return;
    document.body.style.cursor = 'auto';
  };

  const handleDrag = e => {
    if (!isMouseDown || !wrapperRef.current) return;
    e.preventDefault();
    const slider = wrapperRef.current.children[0];
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  };

  const getCursor = () => {
    if (isMouseDown && isZoomedIn) return 'grabbing';
    if (isZoomedIn) return 'grab';
    return 'pointer';
  };

  return (
    <div
      ref={wrapperRef}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      style={{
        height: '100%',
        width: '100%',
        cursor: getCursor()
      }}
    >
      {children}
    </div>
  );
};

export default DragContainer;
