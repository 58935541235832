//
import { createSelector } from 'reselect';
import { filterResources } from '../util/resource-util';
import { ResourcesState } from '../reducers/resource-reducer';
import { ResourcesSections } from '../constants/Resources';
import { Resource, UserFavorite } from 'types';

const allResources = (state: ResourcesState) => state.allResources;
const userFavorites = (state: ResourcesState) => state.favoriteResources;
const getSelectedTags = (state: ResourcesState) => state.selectedFilters.selectedTags;
const getSearchText = (state: ResourcesState) => state.selectedFilters.searchText;

export const getFavoriteResources = createSelector(
  [allResources, userFavorites],
  (allResources: Array<Resource>, userFavorites: Array<UserFavorite>) => {
    return allResources
      .filter(resource =>
        userFavorites.find(userFavorite => userFavorite.resourceId === resource.id)
      )
      .map(resource => {
        const favorites = userFavorites.filter(
          userFavorite => userFavorite.resourceId === resource.id
        );
        resource.favorites = favorites !== undefined ? favorites : [];

        return resource;
      });
  }
);

export const getAllResourcesWithFavorites = createSelector(
  [allResources, userFavorites],
  (allResources: Array<Resource>, userFavorites: Array<UserFavorite>) => {
    return allResources.map(resource => {
      const favorites = userFavorites.filter(
        userFavorite => userFavorite.resourceId === resource.id
      );
      if (favorites !== undefined) {
        resource.favorites = userFavorites.filter(
          userFavorite => userFavorite.resourceId === resource.id
        );
      } else resource.favorites = [];

      return resource;
    });
  }
);

export const getFilteredResources: any = createSelector(
  [
    getFavoriteResources,
    getAllResourcesWithFavorites,
    getSelectedTags,
    getSearchText,
    (_, resourcesSection) => resourcesSection
  ],
  (favoriteResources, allResourcesWithFavorites, selectedTags, searchText, resourcesSection) => {
    return filterResources(
      resourcesSection === ResourcesSections.MYSTUFF.code
        ? favoriteResources
        : allResourcesWithFavorites,
      selectedTags,
      searchText
    );
  }
);
