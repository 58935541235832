import {
  SELECT_RESOURCE,
  TOGGLE_RESOURCE_PLAYER_VISIBILITY,
  SET_FILTERS,
  CLEAR_RESOURCE_FILTERS,
  TOGGLE_TAG,
  UPDATE_COUNTER,
  RESET_RESOURCES,
  FETCH_RESOURCES_REQUEST,
  FETCH_RESOURCES_SUCCESS,
  FETCH_FAVORITE_RESOURCES_REQUEST,
  FETCH_FAVORITE_RESOURCES_SUCCESS,
  FETCH_FILTER_TAGS_REQUEST,
  FETCH_FILTER_TAGS_SUCCESS,
  CREATE_USER_FAVORITE_REQUEST,
  CREATE_USER_FAVORITE_SUCCESS,
  CREATE_USER_FAVORITE_FAILURE,
  REMOVE_USER_FAVORITE_REQUEST,
  REMOVE_USER_FAVORITE_SUCCESS,
  FETCH_CLICKAWAYS_SUCCESS
} from '../actions/resource-types';
import { toggleFilterTag, updateSelectedFilters } from '../util/resource-util';

export type FilterTag = {
  id: number; //the same value with tag id
  value: string;
  componentType: string;
};

export type FilterGroup = {
  filter: string;
  values: Array<FilterTag>;
};

export type LiteResource = {
  id: number;
  type: any | '';
  mediaType?: string;
  url: string;
  title: string;
  aspectRatio?: string;
  heightAdjust?: number;
  fileExtension?: string;
  resourceTypeId: number;
};

export type ResourcesState = {
  allResources: Array<any>;
  clickAways: Array<any>;
  filterGroups: Array<FilterGroup>;
  selectedResource: LiteResource;
  showResourcePlayer: boolean;
  counter: number;

  //allResources that have favorites[] attr .length > 0
  favoriteResources: Array<any>;

  selectedFilters: any;
};

export const initialState = {
  counter: 0,
  allResources: [],
  clickAways: [],
  filterGroups: [],

  selectedResource: {
    id: 0,
    type: '',
    mediaType: '',
    url: '',
    title: '',
    aspectRatio: '',
    heightAdjust: 125,
    fileExtension: '',
    resourceTypeId: 0
  },
  showResourcePlayer: false,
  favoriteResources: [],

  selectedFilters: {
    searchText: '',
    selectedTags: []
  }
};

export default function resource(state: ResourcesState = initialState, action: any) {
  switch (action.type) {
    case FETCH_RESOURCES_REQUEST: {
      return {
        ...state
      };
    }
    case FETCH_RESOURCES_SUCCESS: {
      return {
        ...state,
        allResources: action.resources
      };
    }
    case FETCH_CLICKAWAYS_SUCCESS: {
      return {
        ...state,
        clickAways: action.clickaways
      };
    }
    case FETCH_FAVORITE_RESOURCES_REQUEST: {
      return {
        ...state
      };
    }

    case RESET_RESOURCES: {
      return {
        ...state,
        allResources: []
      };
    }
    case FETCH_FAVORITE_RESOURCES_SUCCESS: {
      return {
        ...state,
        favoriteResources: action.favoriteResources
      };
    }
    case FETCH_FILTER_TAGS_REQUEST: {
      return {
        ...state,
        filterGroups: []
      };
    }
    case FETCH_FILTER_TAGS_SUCCESS: {
      return {
        ...state,
        filterGroups: action.filterTags
      };
    }

    case SET_FILTERS: {
      return {
        ...state,
        selectedFilters: updateSelectedFilters(state, action.data)
      };
    }

    case CLEAR_RESOURCE_FILTERS: {
      return {
        ...state,
        selectedFilters: {
          searchText: state.selectedFilters.searchText,
          selectedTags: []
        }
      };
    }

    case SELECT_RESOURCE: {
      return {
        ...state,
        selectedResource: action.selectedResource
      };
    }

    case TOGGLE_RESOURCE_PLAYER_VISIBILITY: {
      return {
        ...state,
        showResourcePlayer: action.show
      };
    }
    case CREATE_USER_FAVORITE_REQUEST: {
      const favoriteResources: Array<any> = state.favoriteResources.concat([action.userFavorite]);

      return {
        ...state,
        favoriteResources
      };
    }
    case CREATE_USER_FAVORITE_SUCCESS: {
      const favoriteResources: Array<any> = state.favoriteResources
        .filter(
          fr =>
            !(
              !fr.id &&
              (fr.resourceId === action.userFavorite.resourceId ||
                fr.clickAwayId === action.userFavorite.clickAwayId)
            )
        )
        .concat([action.userFavorite]);

      return {
        ...state,
        favoriteResources
      };
    }
    case CREATE_USER_FAVORITE_FAILURE: {
      const favoriteResources: Array<any> = state.favoriteResources
        .filter(fr => fr.id)
        .concat([action.userFavorite]);

      return {
        ...state,
        favoriteResources
      };
    }
    case REMOVE_USER_FAVORITE_REQUEST: {
      const favoriteResources: Array<any> = state.favoriteResources.filter(
        favorite => favorite.id !== action.favoriteId
      );

      return {
        ...state,
        favoriteResources
      };
    }
    case REMOVE_USER_FAVORITE_SUCCESS: {
      const favoriteResources: Array<any> = state.favoriteResources.filter(
        favorite => favorite.id !== action.favoriteId
      );

      return {
        ...state,
        favoriteResources
      };
    }
    case TOGGLE_TAG: {
      const newSelectedFilterTags = toggleFilterTag(state.selectedFilters.selectedTags, action.tag);

      return {
        ...state,
        selectedFilters: updateSelectedFilters(state, {
          searchText: '',
          selectedTags: newSelectedFilterTags
        })
      };
    }

    case UPDATE_COUNTER: {
      const { operationType } = action;

      return {
        ...state,
        counter:
          operationType === 'reset'
            ? 0
            : operationType === 'increment'
            ? state.counter + 1
            : state.counter - 1
      };
    }

    default:
      return state;
  }
}
