import  { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import PrekittApi from '../api';
import { saveClickAwayAccess } from '../modules/user';
import { getCurrentWorkbook, getPageDetails, getUsersWorkbooks } from '../modules/workbook';
import { getPathForWorkbook, getPathFromExternalLink } from '../util/url-util';
import { AppStoreState } from 'types';

const ExternalLink = () => {
  const params: any = useParams();
  const [redirectPath, setRedirectPath] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(({ auth }: AppStoreState) => auth.isAuthenticated);
  const currentWorkbook = useSelector((state: AppStoreState) => getCurrentWorkbook(state, params));
  const workbook = useSelector(({ workbook }: AppStoreState) => workbook);
  const dispatch = useDispatch() as any;
  const getRedirect = async () => (currentWorkbook.id ? await getWorkbookPath() : `/resources`);

  // run once on mount
  useEffect(() => {
    if (!isAuthenticated) {
      setRedirectPath('/login');
      return;
    }

    const handleClickAwayOnAuthenticated = async () => {
      await dispatch(saveClickAwayAccess(params.bookCode, params.edition));
      await dispatch(getUsersWorkbooks());

      await setRedirect();
      postEvent();
    };

    handleClickAwayOnAuthenticated();
    // eslint-disable-next-line
  }, []);

  const setRedirect = async () => {
    const redirectPath = await getRedirect();
    setRedirectPath(redirectPath);
  };

  const postEvent = () => {
    const values = queryString.parse(location.search);
    const { bookCode, edition, pageNumber } = params;

    const event = {
      path: location.pathname,
      bookCode,
      edition,
      ...(pageNumber && { pageNumber: pageNumber }),
      ...(values.source && { source: values.source })
    };
    PrekittApi.createEvent('externalAccess', event);
  };

  const getWorkbookPath = async () => {
    if (currentWorkbook.type === 'teacherGuide') {
      const mappedPath = await getStudentWorkbookMapping(
        currentWorkbook,
        params.pageNumber,
        !!params.isZoom,
        workbook.allWorkbooks
      );
      if (mappedPath) return mappedPath;
    }

    return getPathFromExternalLink(location.pathname);
  };

  const getStudentWorkbookMapping = async (teacherGuide, pageNumber, isZoom, workbooks) => {
    await dispatch(getPageDetails(teacherGuide.id, pageNumber, !!isZoom));
    const workbookPageDetails = workbook.pagesDetails;
    if (!isEmpty(workbookPageDetails)) {
      const pageDetail = workbookPageDetails?.find(
        pageDetail => pageDetail.teacherGuidePage === parseInt(pageNumber ?? '', 10)
      );

      if (pageDetail && workbooks[pageDetail.workbookId]) {
        return getPathForWorkbook({
          ...workbooks[pageDetail.workbookId],
          pageNumber: pageDetail.workbookPage,
          isZoom: true
        });
      }
    }

    return null;
  };

  if (redirectPath) {
    navigate(redirectPath, {
      state: {
        referrer: location,
        code: params.bookCode,
        workbookParams: params
      }
    });
  }

  return null;
};

export default ExternalLink;
