import set from 'lodash/set';
import get from 'lodash/get';
import { getLocale } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

export type RouteDataOptions = {
  routeName?: string;
  pathname?: string;
  bookCode: string;
  edition: string | number;
  locale?: string;
};

const storage = {
  get(itemName: string | number) {
    try {
      const serializedData = localStorage.getItem(itemName.toString());

      if (serializedData === null || serializedData === undefined) {
        return undefined;
      }

      return JSON.parse(serializedData);
    } catch (e) {
      return undefined;
    }
  },

  put(itemName: string | number, data: any) {
    try {
      localStorage.setItem(itemName.toString(), JSON.stringify(data));

      return { [itemName]: data };
    } catch (e) {
      // Do nothing
    }
  },

  remove(itemName: string | number) {
    try {
      localStorage.removeItem(itemName.toString());
    } catch (err) {
      // Do nothing
    }
  },

  clear() {
    try {
      localStorage.clear();
    } catch (err) {
      // Do nothing
    }
  }
};

export default storage;

export function saveRouteData(userId: number, routeData: RouteDataOptions): void {
  const { bookCode, edition, locale, pathname } = routeData;

  const update = storage.get(userId) || {};

  set(update, ['lastPath', locale], pathname);
  set(update, [edition, bookCode], pathname);

  storage.put(userId, update);
}

export function getLastPathFor(userId: number, options?: RouteDataOptions): any {
  const prekittData = storage.get(userId);

  if (!prekittData || !prekittData.lastPath) return null;

  if (options) {
    const { bookCode, edition } = options;
    const getLastBookPath = () => get(prekittData, [edition, bookCode]);

    return getLastBookPath();
  }

  return prekittData.lastPath[getLocale()];
}

export function clearLocalStorage(): void {
  storage.clear();
}
