import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { getCurrentWorkbook } from '../workbook';
import { hasClickAwayOnly } from '../user';
import { AppState } from '../../reducers';

export const isShowClickAwayAlert = createSelector(
  (state: AppState, routeParams: any) => getCurrentWorkbook(state, routeParams),
  (state: AppState, routeParams: any) => hasClickAwayOnly(state, routeParams),
  (state: AppState) => state.user.licenses,
  (state: AppState) => state.messaging.dismissedClickAwayAlerts,
  (currentWorkbook, hasClickAwayOnly, licenses, dismissedClickAwayAlerts) => {
    if (!hasClickAwayOnly) {
      return false;
    }

    return isEmpty(
      filter(productId => licenses.map(license => license.productId).includes(productId))(
        dismissedClickAwayAlerts
      )
    );
  }
);

export const isShowLicenseExpirationAlert: any = createSelector(
  (state: AppState) => state.user.licenses,
  (state: AppState) => state.user.settings.suppressedExpirationAlerts,
  (userLicenses = [], suppressedExpirationAlerts) =>
    !suppressedExpirationAlerts && !isEmpty(filter(license => license.expired)(userLicenses))
);

export const isShowTermsConditionsCheckbox = createSelector(
  (state: AppState) => state.user.settings.copyrightTerms || [],
  copyrightTerms => isEmpty(copyrightTerms)
);

export const isBookCopyrightTermsViewed = createSelector(
  (state: AppState, routeParams: any) => getCurrentWorkbook(state, routeParams),
  (state: AppState, routeParams: any) => hasClickAwayOnly(state, routeParams),
  (state: AppState) => state.user.settings.copyrightTerms,
  (currentWorkbook, isClickAwayOnly, copyrightTerms) =>
    !isClickAwayOnly &&
    !isEmpty(
      filter(copyrightTerm => copyrightTerm.bookCode === currentWorkbook.bookCode)(copyrightTerms)
    )
);
