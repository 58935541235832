import React from 'react';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const t = key => translate('home', key);

type Props = {
  fill: string;
  borderColor: string;
};

const AppleInBoxIcon = ({ fill, borderColor, ...props }: Props) => (
  <svg viewBox="0 0 320 320" {...props}>
    <title>{t('assessmentsToGuideInstruction')}</title>
    <path
      style={{ fill: borderColor }}
      d="M311.68,173.82c-0.43-37.97,5.29-88.85-23.02-117.69c-27.12-27.63-83.09-10.76-116.29-13.16
		C136.35,40.36,14.61,16.58,16.92,84.49c-0.42,0.48-0.78,1.04-0.88,1.79c-4.85,33.2-4.13,66.6-3.28,100.11
		c0.55,21.43-0.38,47.84,9.11,67.59c16.13,33.54,51.09,29.82,81.56,28.68c34.85-1.3,69.57,0.54,104.39,1.85
		c22.91,0.87,54.08,5.88,75.4-6.47C318.78,257.44,312.09,210.7,311.68,173.82z M259.95,271.73c-52.06,6.39-106.55-3.46-158.98-1.35
		c-30.85,1.24-64,6.55-73.94-32.51c-11.52-45.24-3.32-96.21-7.84-143.37c2.41-0.89,4.54-3.04,4.74-6.14
		c3.9-61.07,111.91-35.88,148.95-32.71c31.26,2.65,62.31-4.44,93.54,1.33c44.31,8.18,34.91,82.38,34.52,117.4
		C300.48,214.97,310.78,265.48,259.95,271.73z"
    />
    <path
      style={{ fill }}
      d="M259.95,271.73c-52.06,6.39-106.55-3.46-158.98-1.35c-30.85,1.24-64,6.55-73.94-32.51
		c-11.52-45.24-3.32-96.21-7.84-143.37c2.41-0.89,4.54-3.04,4.74-6.14c3.9-61.07,111.91-35.88,148.95-32.71
		c31.26,2.65,62.31-4.44,93.54,1.33c44.31,8.18,34.91,82.38,34.52,117.4C300.48,214.97,310.78,265.48,259.95,271.73z"
    />
    <path
      style={{ fill: borderColor }}
      d="M276.66,80.93c-0.11-1.4-0.73-2.81-2.14-3.86c-20.03-15.05-58.61-4.65-81.1-2.76c-45.71,3.82-93.41,5.78-138.95-0.07
		c-5.11-0.67-6.4,7.84-2.16,9.85c-1.41,0.27-2.73,1.43-2.78,3.22c-1.25,49.32,0.25,102.1,4.68,151.2c0.25,2.75,2.14,4.09,4.01,4.05
		c0.67,1.93,2.29,3.52,4.88,3.74c5.75,0.48,12,1.15,18.06,1c19.57,5.33,47.51,1.28,62.2,0.81c41.15-1.31,82.12-6.18,123.3-7.25
		c1.39-0.04,2.43-0.57,3.3-1.27c1.89-0.56,3.49-2.19,3.6-5.01c2.02-50-3.79-98.19,5.44-147.75
		C279.45,84.37,278.31,82.22,276.66,80.93z M268,236c-28.54,0.1-74.62,2.44-103,5c-29.41,2.66-33.55,5.74-63,3
		c-4.9-0.46-34-1-38.89-9.34c-0.16-0.27-0.53,0.16-0.82,0.23C55,198,55,150,55.64,86.43c0.01-0.77-0.47-1.27-0.89-1.66
		C249,84,201.86,69.92,272,81.76C261,133.76,272,190,268,236z"
    />
    <circle style={{ fill: borderColor }} cx="284.81" cy="159.54" r="4.81" />
    <path
      style={{ fill: borderColor }}
      d="M204.35,132.99l-0.02-0.01c-4.27-2.61-8.82-4.69-13.55-6.19l0.08-0.06c8.61-7,12.35-17.19,11.43-31.13
		c0.2-0.57,0.17-1.2-0.08-1.76c-0.22-1.07-0.96-2-2.05-2.51c-1.03-0.41-2.16-0.42-3.19-0.03c-8.71,2.47-17.25,5.63-25.31,9.35
		c-4.16,1.6-7.63,4.53-9.91,8.28c-0.81-2.25-1.86-4.41-3.14-6.43l-0.82-1.33h-1.46l-0.14,0.01c-2.32,0.22-4.46,2.36-6.93,4.84
		l-3.35,3.35l1.22,1.92c2.08,4.48,3.74,9.12,4.97,13.84c-13.15-3.67-27.43-1.29-38.73,6.57c-11.1,8.12-15.81,22.42-12.93,39.29
		c3.14,15.16,9.87,29.58,19.46,41.69c3.92,5.83,8.91,10.69,14.94,14.5c4.01,2.19,8.51,3.34,13.03,3.34c0.17,0,0.35,0,0.52,0l0.13,0
		c3.09-0.14,6.18-0.62,9.2-1.42h0.26c7.94,1.72,16.31,0.61,23.56-3.13l0.09-0.05c6.41-3.91,11.94-8.94,16.42-14.96
		c10.4-11.72,17.74-26.02,21.23-41.33C222.59,155.48,216.6,140.77,204.35,132.99z M185.22,122.84c-3.33,1.74-7.25,1.88-10.68,0.38
		l-3.76-0.8h-0.19c1.34-1.52,2.79-2.94,4.33-4.25c2.56-1.98,5.27-3.76,8.08-5.37l1.97-1.18l0.61-0.36c3.84-2.11,7.38-4.72,10.5-7.78
		C195.73,111.28,191.7,118.47,185.22,122.84z M168.62,110.57c3.04-2.86,6.69-4.97,10.68-6.17c3.15-1.18,6.17-2.16,9.31-3.15
		l2.96-0.88c-1.07,0.94-2.17,1.83-3.33,2.67c-2.41,1.78-4.94,3.51-7.53,5.12c-0.74,0.61-1.54,0.98-2.35,1.61
		c-3.99,2.52-7.6,5.6-10.74,9.14C166.19,116.17,166.6,112.86,168.62,110.57z M154.91,109.94l0.8-0.8c0.99,2.53,3.76,9.7,3.76,10.87
		c0.33,2.05,0.33,4.13,0,6.17v2.77c-0.04,0.1-0.04,0.2-0.05,0.29c-0.18-1.86-0.41-3.68-0.75-5.66c-0.43-0.72-0.97-1.37-1.61-1.91
		c-0.19-0.38-0.8-1.18-0.8-1.18c-0.33-0.94-0.72-1.87-1.17-2.77c-1.03-1.93-1.76-4.02-2.16-6.17
		C153.75,111.31,154.3,110.57,154.91,109.94z M211.88,170.06c-3.34,13.25-9.67,25.56-18.52,35.98c-3.81,4.99-8.34,9.37-13.45,13.03
		c-6.71,4.03-14.78,5.17-22.35,3.15c-0.61-0.16-1.28-0.03-1.78,0.36c-6.01,1.67-12.41,1.09-18.02-1.61
		c-5.26-3.39-9.78-7.82-13.28-13.01c-7.78-10.16-13.44-21.8-16.6-34.19c-2.22-8.71-3.76-24.87,8.09-35.19
		c6.58-5.32,14.86-8.12,23.33-7.89v0.06c6.31-0.26,12.61,0.85,18.45,3.28c0.67,0.39,1.49,0.39,2.16,0c0.38,0,0.38,0,0.38,0
		c0.36,0,0.72-0.14,0.99-0.38c12.65-4,26.43-2.33,37.77,4.57C210.29,144.48,215.63,157.75,211.88,170.06z"
    />
  </svg>
);

AppleInBoxIcon.defaultProps = {
  fill: '#FFF',
  borderColor: '#111'
};

export default AppleInBoxIcon;
