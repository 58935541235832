import { UserFavorite, Resource } from 'types';

//action types
export const SET_FILTERS = 'SET_FILTERS';
export type SET_FILTERS_ACTION = {
  type: typeof SET_FILTERS;
  data: { resourceSection: any; searchText: any; selectedTags: any };
};

export const CLEAR_RESOURCE_FILTERS = 'CLEAR_RESOURCE_FILTERS';
export type CLEAR_RESOURCE_FILTERS_ACTION = { type: typeof CLEAR_RESOURCE_FILTERS };

export const UPDATE_COUNTER = 'UPDATE_COUNTER';
export type UPDATE_COUNTER_ACTION = { type: typeof UPDATE_COUNTER; operationType: string };

export const TOGGLE_TAG = 'TOGGLE_TAG';
export type TOGGLE_TAG_ACTION = { type: typeof TOGGLE_TAG; tagId: number; resourceSection: string };

export const FETCH_RESOURCES_REQUEST = 'FETCH_RESOURCES_REQUEST';
export type FETCH_RESOURCES_REQUEST_ACTION = { type: typeof FETCH_RESOURCES_REQUEST };

export const FETCH_RESOURCES_SUCCESS = 'FETCH_RESOURCES_SUCCESS';
export type FETCH_RESOURCES_SUCCESS_ACTION = {
  type: typeof FETCH_RESOURCES_SUCCESS;
  resources: Array<Resource>;
};

export const FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE';
export type FETCH_RESOURCES_FAILURE_ACTION = { type: typeof FETCH_RESOURCES_FAILURE; error: Error };

export const FETCH_CLICKAWAYS_REQUEST = 'FETCH_CLICKAWAYS_REQUEST';
export type FETCH_CLICKAWAYS_REQUEST_ACTION = { type: typeof FETCH_CLICKAWAYS_REQUEST };

export const FETCH_CLICKAWAYS_SUCCESS = 'FETCH_CLICKAWAYS_SUCCESS';
export type FETCH_CLICKAWAYS_SUCCESS_ACTION = {
  type: typeof FETCH_CLICKAWAYS_SUCCESS;
  resources: Array<Resource>;
};

export const FETCH_CLICKAWAYS_FAILURE = 'FETCH_CLICKAWAYS_FAILURE';
export type FETCH_CLICKAWAYS_FAILURE_ACTION = {
  type: typeof FETCH_CLICKAWAYS_FAILURE;
  error: Error;
};

export const RESET_RESOURCES = 'RESET_RESOURCES';
export type RESET_RESOURCES_ACTION = { type: typeof RESET_RESOURCES };

export const FETCH_FAVORITE_RESOURCES_REQUEST = 'FETCH_FAVORITE_RESOURCES_REQUEST';
export type FETCH_FAVORITE_RESOURCES_REQUEST_ACTION = {
  type: typeof FETCH_FAVORITE_RESOURCES_REQUEST;
};

export const FETCH_FAVORITE_RESOURCES_SUCCESS = 'FETCH_FAVORITE_RESOURCES_SUCCESS';
export type FETCH_FAVORITE_RESOURCES_SUCCESS_ACTION = {
  type: typeof FETCH_FAVORITE_RESOURCES_SUCCESS;
  favoriteResources: Array<UserFavorite>;
};

export const FETCH_FAVORITE_RESOURCES_FAILURE = 'FETCH_FAVORITE_RESOURCES_FAILURE';
export type FETCH_FAVORITE_RESOURCES_FAILURE_ACTION = {
  type: typeof FETCH_FAVORITE_RESOURCES_FAILURE;
  error: Error;
};

export const CREATE_USER_FAVORITE_REQUEST = 'CREATE_USER_FAVORITE_REQUEST';
export type CREATE_USER_FAVORITE_REQUEST_ACTION = { type: typeof CREATE_USER_FAVORITE_REQUEST };

export const CREATE_USER_FAVORITE_SUCCESS = 'CREATE_USER_FAVORITE_SUCCESS';
export type CREATE_USER_FAVORITE_SUCCESS_ACTION = {
  type: typeof CREATE_USER_FAVORITE_SUCCESS;
  userFavorite: UserFavorite;
};

export const CREATE_USER_FAVORITE_FAILURE = 'CREATE_USER_FAVORITE_FAILURE';
export type CREATE_USER_FAVORITE_FAILURE_ACTION = {
  type: typeof CREATE_USER_FAVORITE_FAILURE;
  error: Error;
};

export const REMOVE_USER_FAVORITE_REQUEST = 'REMOVE_USER_FAVORITE_REQUEST';
export type REMOVE_USER_FAVORITE_REQUEST_ACTION = {
  type: typeof REMOVE_USER_FAVORITE_REQUEST;
  favoriteId: number;
};

export const REMOVE_USER_FAVORITE_SUCCESS = 'REMOVE_USER_FAVORITE_SUCCESS';
export type REMOVE_USER_FAVORITE_SUCCESS_ACTION = { type: typeof REMOVE_USER_FAVORITE_SUCCESS };

export const REMOVE_USER_FAVORITE_FAILURE = 'REMOVE_USER_FAVORITE_FAILURE';
export type REMOVE_USER_FAVORITE_FAILURE_ACTION = {
  type: typeof REMOVE_USER_FAVORITE_FAILURE;
  error: Error;
};

export const SELECT_RESOURCE = 'SELECT_RESOURCE';
export type SELECT_RESOURCE_ACTION = { type: typeof SELECT_RESOURCE; resource: any };

export const TOGGLE_RESOURCE_PLAYER_VISIBILITY = 'TOGGLE_RESOURCE_PLAYER_VISIBILITY';
export type TOGGLE_RESOURCE_PLAYER_VISIBILITY_ACTION = {
  type: typeof TOGGLE_RESOURCE_PLAYER_VISIBILITY;
  show: boolean;
};

export const FETCH_FILTER_TAGS_REQUEST = 'FETCH_FILTER_TAGS_REQUEST';
export type FETCH_FILTER_TAGS_REQUEST_ACTION = { type: typeof FETCH_FILTER_TAGS_REQUEST };

export const FETCH_FILTER_TAGS_SUCCESS = 'FETCH_FILTER_TAGS_SUCCESS';
export type FETCH_FILTER_TAGS_SUCCESS_ACTION = {
  type: typeof FETCH_FILTER_TAGS_SUCCESS;
  filterTags: Array<any>;
};

export const FETCH_FILTER_TAGS_FAILURE = 'FETCH_FILTER_TAGS_FAILURE';
export type FETCH_FILTER_TAGS_FAILURE_ACTION = {
  type: typeof FETCH_FILTER_TAGS_FAILURE;
  error: Error;
};

export type ResourceAction =
  | SET_FILTERS_ACTION
  | TOGGLE_TAG_ACTION
  | 'UPDATE_COUNTER'
  | FETCH_RESOURCES_REQUEST_ACTION
  | FETCH_RESOURCES_SUCCESS_ACTION
  | FETCH_RESOURCES_FAILURE_ACTION
  | FETCH_CLICKAWAYS_REQUEST_ACTION
  | FETCH_CLICKAWAYS_SUCCESS_ACTION
  | FETCH_CLICKAWAYS_FAILURE_ACTION
  | UPDATE_COUNTER_ACTION
  | FETCH_FAVORITE_RESOURCES_REQUEST_ACTION
  | FETCH_FAVORITE_RESOURCES_SUCCESS_ACTION
  | FETCH_FAVORITE_RESOURCES_FAILURE_ACTION
  | CREATE_USER_FAVORITE_REQUEST_ACTION
  | { type: 'CREATE_USER_FAVORITE_SUCCESS' }
  | { type: 'FETCH_FAVORITE_RESOURCES_FAILURE' }
  | { type: 'CREATE_USER_FAVORITE_REQUEST' }
  | { type: 'CREATE_USER_FAVORITE_SUCCESS' }
  | { type: 'CREATE_USER_FAVORITE_FAILURE' }
  | { type: 'REMOVE_USER_FAVORITE_REQUEST' }
  | { type: 'REMOVE_USER_FAVORITE_SUCCESS' }
  | { type: 'REMOVE_USER_FAVORITE_FAILURE' }
  | { type: 'SELECT_RESOURCE' }
  | { type: 'TOGGLE_RESOURCE_PLAYER_VISIBILITY' }
  | { type: 'FETCH_FILTER_TAGS_REQUEST' }
  | { type: 'FETCH_FILTER_TAGS_SUCCESS' }
  | { type: 'FETCH_FILTER_TAGS_FAILURE ' };
