import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { buildSearchQueryParams, parseQueryParams } from '../../../util/query-params-util';
import AssessmentPDF from '../frames/AssessmentPDF';
import AssessmentResources from '../frames/AssessmentResources';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
const translateAssessments = key => translate('assessments', key);

export const tabItems = [
  {
    name: 'overview',
    route: 'overview',
    Component: AssessmentPDF,
    showPrint: true,
    showTitle: true
  },
  {
    name: 'administration',
    route: 'administration',
    Component: AssessmentPDF,
    showPrint: true,
    showTitle: true
  },
  {
    name: 'assessmentTools',
    route: 'assessment-tools',
    Component: AssessmentResources,
    showPrint: false,
    showTitle: false
  },
  {
    name: 'implementationResources',
    route: 'implementation-resources',
    Component: AssessmentResources,
    showPrint: false,
    showTitle: true
  }
];

const AssessmentSideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const queryParams = parseQueryParams(location.search);

  const handleSetActiveTab = activeTab => {
    navigate({
      pathname: `/assessments`,
      search: buildSearchQueryParams({ activeTab })
    });
  };

  const applyClasses = route =>
    `${classes.sideMenuItem} ${
      queryParams?.activeTab === route ? classes.selectedSideMenuItem : ''
    }`;

  return (
    <Paper className={classes.sideMenu}>
      <Typography className={classes.title} variant="h5">
        {translateAssessments('header')}
      </Typography>
      {tabItems.map((x, idx) => (
        <Paper
          key={`menu_item_${idx}`}
          aria-label={x.name}
          tabIndex={0}
          variant="outlined"
          component="button"
          className={applyClasses(x.route)}
          onClick={() => handleSetActiveTab(x.route)}
        >
          {translateAssessments(x.name)}
          {queryParams?.activeTab === x.route && (
            <FontAwesomeIcon style={{ alignSelf: 'center' }} icon={faArrowRight} size="sm" />
          )}
        </Paper>
      ))}
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    margin: 32
  },
  sideMenu: {
    display: 'flex',
    width: 296,
    alignContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  },
  sideMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    width: 248,
    margin: 5
  },
  selectedSideMenuItem: {
    color: '#2878C1'
  },
  title: {
    alignSelf: 'flex-start',
    padding: 10,
    fontWeight: 'bold'
  }
}));

export default AssessmentSideMenu;
