export const getProd = () => ({
  application: 'prekitt',
  pliURL: 'https://pli.lwtears.com',
  agiURL: 'https://agi.lwtears.com',
  apiGatewayURL: 'https://api.lwtears.com',
  apiURL: `https://api.lwtears.com/prekitt`,
  companyURL: 'https://www.lwtears.com',
  gaTrackingId: 'UA-154381347-1',
  supportEmail: 'technology@lwtears.com',
  wmUrl: 'https://worksheet-maker.lwtears.com/0?lite=true',
  copyrightEmail: 'legal@lwtears.com',
  digitalProductSupportUrl: 'https://www.lwtears.com/contact-us/digital-product-support'
});
