export const BOOK_CODES = [
  'MFSB',
  'MFLB',
  'IKMN',
  'MB',
  'SMFB',
  'SMFLB',
  'SIKMN',
  'SMB',

  'MFSBTX',
  'MFLBTX',
  'IKMNTX',
  'MBTX',
  'SMFBTX',
  'SMFLBTX',
  'SIKMNTX',
  'SMBTX',

  'MMGO',
  'MMHATS',
  'MMOPP',
  'MMSH',
  'SMMGO',
  'SMMHATS',
  'SMMOPP',
  'SMMSH',

  'MMGOTX',
  'MMHATSTX',
  'MMOPPTX',
  'MMSHTX',
  'SMMGOTX',
  'SMMHATSTX',
  'SMMOPPTX',
  'SMMSHTX',

  'TGRW',
  'TGPKGSS1',
  'TGPKGSS',
  'STGRW',
  'STGPKGSS1',
  'STGPKGSS',

  'TGPK1TX',
  'TGPK2TX',
  'STGPK1TX',
  'STGPK2TX'
];

export const RESOURCE_TYPES = {
  CLICK_AWAY: 'clickAway',
  WOOD_PIECES: 'woodPieces',
  LNF: 'lnf',
  WDT: 'wdt',
  ANIMATION: 'animation',
  MUSIC: 'music',
  VIDEO: 'video',
  OUR_WORLD: 'ourWorld',
  PDF: 'pdf',
  SUPPORT: 'support',
  KARAOKE: 'karaoke',
  AUDIO: 'audio'
};
