import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const translateSupport = key => translate('support', key);
const styles = StyleSheet.create({
  companyInfo: {
    textAlign: 'center',
    margin: 'auto'
  },
  info: {
    fontWeight: 400,
    margin: '0 0 10px 0'
  }
});

const CompanyInfo = () => (
  <div className={css(styles.companyInfo)}>
    <p className={css(styles.info)}>{translateSupport('officeHours')}</p>
    <p className={css(styles.info)}>{translateSupport('phone')}: 301.263.2700 | 1.888.983.8409</p>
    <p className={css(styles.info)}>Version: {process.env.REACT_APP_APP_VERSION}</p>
  </div>
);

export default CompanyInfo;
