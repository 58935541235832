import React from 'react';
import { useAsync } from 'react-async-hook';
import PlyerWithClosedCaptions from '@lwtears/lwt-common-frontend/lib/PlayerWithClosedCaptions';
import ApplicationIFrame from '@lwtears/lwt-common-frontend/lib/ApplicationFrame';
import { isString } from 'lodash';
import { AppNames, MimeType } from 'types';
import { DOC_SRC_PREFIX } from 'constants/Resources';
import { appendSessionToUrl, getLiteSession } from '../../../api/lite-session';
import { DraggableIframeWrapper } from '../../../components/core/Modals/DraggableIframeWrapper';
import { RESOURCE_TYPES } from '../../../constants/Workbook';
import { getLocale } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import storage from 'util/local-storage-util';
import {
  PlyrOptions,
  CCPlyrProps
} from '@lwtears/lwt-common-frontend/lib/PlayerWithClosedCaptions/Player/Player';
import { useFullScreen } from 'state/providers/FullScreenProvider';

export type ResourcePlayerProps = {
  loadedData?: () => any;
  showCloseIcon?: boolean;
  selectedResource: {
    data: any;
    url: string;
    title: string;
    mimeType: string;
    mediaType: string;
    aspectRatio?: string;
    heightAdjust?: number;
    type: { type: string };
    src: string;
    vtt: string;
    narration: {
      src: string;
      vtt: string;
    };
  };
  plyrOptions?: Partial<PlyrOptions>;
};

const APPLICATION_RESOURCE_TYPES = [
  RESOURCE_TYPES.WOOD_PIECES,
  RESOURCE_TYPES.WDT,
  RESOURCE_TYPES.LNF,
  RESOURCE_TYPES.PDF
];

export const getShouldUseIframe = (resourceType, mediaType) => {
  const isClickAwayNonMedia = resourceType === RESOURCE_TYPES.CLICK_AWAY && !mediaType;
  return isClickAwayNonMedia || APPLICATION_RESOURCE_TYPES.includes(resourceType as any);
};

export const getResourceType = (resourceType?: string | { type: string }): string | undefined =>
  isString(resourceType) ? (resourceType as string) : (resourceType as { type: string })?.type;

const getUrl = async (url: any, resourceType?: string) => {
  if (resourceType === RESOURCE_TYPES.WDT) {
    const { sessionId } = await getLiteSession(AppNames.GSS);
    return appendSessionToUrl(url, sessionId);
  }

  return url;
};

const ResourcePlayer = ({
  selectedResource: { data, aspectRatio = '1.5', narration, ...selectedResource },
  loadedData
}: ResourcePlayerProps) => {
  const resourceAspectRatio = data?.aspectRatio ?? aspectRatio;
  const mediaType = data?.mediaType ?? selectedResource.mediaType;
  const resourceType = getResourceType(selectedResource.type?.type ?? selectedResource.type);
  const heightAdjust = data?.heightAdjust ?? selectedResource.heightAdjust;
  const cacheplayerConfig = storage.get('plyr');

  const [{ isFullScreen }] = useFullScreen();

  const { result: applicationUrl } = useAsync(
    () => getUrl(selectedResource?.src ?? selectedResource.url, resourceType),
    [selectedResource?.src, selectedResource.url, resourceType]
  );

  /*
  FIXME: Using an empty dependency array here because re-renders from the useAsync hook cause the media
  to be played multiple times. While I don't _think_ this is a problem since the selectedResource never
  changes after the modal is mounted, it's definitely a hack and there's definitely a better way to do it.
  */
  /* eslint-disable react-hooks/exhaustive-deps */
  const AVPlayer = React.useCallback(() => {
    const PlyrProps: CCPlyrProps = {
      mimeType: '',
      aspectRatio: resourceAspectRatio,
      src: selectedResource?.src ?? selectedResource.url,
      type: mediaType,
      title: selectedResource.title,
      onLoad: loadedData,
      narration: {
        ...narration,
        captions: [{ src: narration?.vtt ?? '', label: selectedResource.title, kind: 'captions' }]
      },
      options: {
        withNativeControls: true,
        locale: getLocale(),
        captions: { active: cacheplayerConfig?.captions ?? false },
        showSettings: !!narration?.src,
        containerStyles: {
          id: 'resourcePlayer'
        }
      },
      captions: [{ kind: 'captions', src: selectedResource.vtt, label: selectedResource.title }],
      config: {
        volume: cacheplayerConfig?.volume ?? 0.5,
        fullscreen: {
          enabled: !isFullScreen
        }
      }
    };

    return <PlyerWithClosedCaptions id="player" {...PlyrProps} />;
  }, []);
  /* eslint-disable */

  const Iframe = ({ url }) => {
    // TODO: The fetching of the URL combined with the async 'getUrl' above to
    // generate the lite session for WDT is pretty wonky/confusing.
    const getUrl = () => {
      if (selectedResource.mimeType === MimeType.DOC) {
        return `${DOC_SRC_PREFIX}${selectedResource?.src}`;
      }

      return url ?? selectedResource?.src;
    };

    const targetUrl = getUrl();

    // Having no 'url' value should really only happen while we await the response
    // from gatekeeper for the lite session (WDT). Loading spinner/error handling?
    if (!targetUrl) return null;

    return (
      <DraggableIframeWrapper>
        <ApplicationIFrame
          onLoad={loadedData}
          aspectRatio={resourceAspectRatio}
          heightAdjust={heightAdjust}
          url={targetUrl}
          title={selectedResource.title ?? ''}
        />
      </DraggableIframeWrapper>
    );
  };

  if (getShouldUseIframe(resourceType, mediaType)) {
    return <Iframe url={applicationUrl} />;
  } else {
    return <AVPlayer />;
  }
};

export default ResourcePlayer;
