//
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import reactHtmlParser from 'react-html-parser';
import SquareButton from '../core/SquareButton';
import { remCalc } from '../../util/style-util';
import { darkestGray } from '../../styles/style-constants';

type Props = {
  title?: string;
  text?: string;
  confirmText: string;
  advance: () => any;
};

const styles = StyleSheet.create({
  tourCard: {
    textAlign: 'right',
    width: remCalc(250),
    padding: remCalc(0, 24),
    whiteSpace: 'normal',
    '@media(min-width: 1000px)': {
      width: remCalc(385)
    },
    zIndex: 5,
    position: 'relative',
    color: darkestGray
  },
  calloutTitle: {
    textAlign: 'left',
    fontFamily: 'futura-pt',
    fontWeight: 600,
    fontSize: remCalc(24),
    '@media(min-width: 1000px)': {
      fontSize: remCalc(30)
    }
  },
  calloutText: {
    textAlign: 'left',
    fontSize: remCalc(16),
    fontWeight: 400
  },
  modalButton: {
    textAlign: 'right',
    fontWeight: 600,
    margin: remCalc(20, 0)
  }
});

const TourCard = ({ title, text, advance: handleClick, confirmText }: Props) => (
  <div className={css(styles.tourCard)}>
    <h2 className={css(styles.calloutTitle)}>{title}</h2>
    <div className={css(styles.calloutText)}>{reactHtmlParser(text)}</div>
    <SquareButton buttonStyles={[styles.modalButton]} text={confirmText} onClick={handleClick} />
  </div>
);

export default TourCard;
