//
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom';
import withRouter from 'state/hooks/withRouter';
import { remCalc } from '../../util/style-util';
import { getRoutingForPage } from '../../util/workbook-util';

import diamond from '../../images/QuickJumpMenuIcons/diamond.png';
import green from '../../images/QuickJumpMenuIcons/green.png';
import heart from '../../images/QuickJumpMenuIcons/heart.png';
import hexagon from '../../images/QuickJumpMenuIcons/hexagon.png';
import moon from '../../images/QuickJumpMenuIcons/moon.png';
import octagon from '../../images/QuickJumpMenuIcons/octagon.png';
import orange from '../../images/QuickJumpMenuIcons/orange.png';
import pentagon from '../../images/QuickJumpMenuIcons/pentagon.png';
import red from '../../images/QuickJumpMenuIcons/red.png';
import square from '../../images/QuickJumpMenuIcons/square.png';
import star from '../../images/QuickJumpMenuIcons/star.png';
import yellow from '../../images/QuickJumpMenuIcons/yellow.png';
import blueOrange from '../../images/QuickJumpMenuIcons/blue-orange.png';
import grayBlack from '../../images/QuickJumpMenuIcons/gray-black.png';
import pinkBrown from '../../images/QuickJumpMenuIcons/pink-brown.png';
import redGreen from '../../images/QuickJumpMenuIcons/red-green.png';
import yellowPurple from '../../images/QuickJumpMenuIcons/yellow-purple.png';
import circles1 from '../../images/QuickJumpMenuIcons/circles1.png';
import circles2 from '../../images/QuickJumpMenuIcons/circles2.png';
import circle from '../../images/QuickJumpMenuIcons/circle.png';
import crescent from '../../images/QuickJumpMenuIcons/crescent.png';
import cross from '../../images/QuickJumpMenuIcons/cross.png';
import oval from '../../images/QuickJumpMenuIcons/oval.png';
import rectangle from '../../images/QuickJumpMenuIcons/rectangle2.png';
import rectangle1 from '../../images/QuickJumpMenuIcons/rectangle1.png';
import rectangle2 from '../../images/QuickJumpMenuIcons/rectangle.png';
import rectangle3 from '../../images/QuickJumpMenuIcons/rectangle3.png';
import rhombus from '../../images/QuickJumpMenuIcons/rhombus.png';
import triangle from '../../images/QuickJumpMenuIcons/triangle.png';

const linkIcons = {
  diamaond: diamond,
  heart: heart,
  hexagon: hexagon,
  moon: moon,
  octagon: octagon,
  pentagon: pentagon,
  rectangle: rectangle,
  rectangle1: rectangle1,
  rectangle2: rectangle2,
  rectangle3: rectangle3,
  triangle: triangle,
  circles1: circles1,
  circles2: circles2,
  circle: circle,
  crescent: crescent,
  cross: cross,
  oval: oval,
  rhombus: rhombus,
  square: square,
  star: star,
  red: red,
  green: green,
  orange: orange,
  yellow: yellow,
  'blue-orange': blueOrange,
  'gray-black': grayBlack,
  'pink-brown': pinkBrown,
  'red-green': redGreen,
  'yellow-purple': yellowPurple
};

type Props = {
  symbol: { character: string; characterType: string; pageNumber: number | number[] };
  color: string;
  noLeftSpacing: boolean;
  closeMenu: () => any;
  useFlex: boolean;
  router: any;
  hasIcon: boolean;
};

const getStyles = (color: string) =>
  StyleSheet.create({
    pageLink: {
      color,
      fontSize: remCalc(32),
      minWidth: remCalc(30),
      display: 'inline-block',
      transition: 'transform 250ms',
      ':active': {
        textDecoration: 'none'
      },
      ':hover': {
        transform: 'scale(1.2)',
        textDecoration: 'none'
      },
      ':focus': {
        transform: 'scale(1.2)',
        textDecoration: 'none'
      }
    },
    flex: {
      minWidth: remCalc(50),
      flex: 1,
      margin: remCalc(10, 5),
      marginBottom: remCalc(16)
    },
    evenSpacing: {
      padding: remCalc(0, 10),
      margin: remCalc(0, 14)
    },
    rightSpacingOnly: {
      paddingRight: remCalc(10),
      marginRight: remCalc(14)
    }
  });

const iconWidths = {
  color: '200px',
  shape: '100px'
};

const PageCharacterLink = ({
  symbol,
  color,
  noLeftSpacing,
  closeMenu,
  useFlex,
  router,
  hasIcon
}: Props) => {
  const { pageLink, flex, evenSpacing, rightSpacingOnly } = getStyles(color);
  const isZoom = Number.isInteger(symbol.pageNumber);
  const newPage: number = Array.isArray(symbol.pageNumber)
    ? symbol.pageNumber[0]
    : symbol.pageNumber;
  const { path } = getRoutingForPage(newPage, router, isZoom, true);

  const className = css(pageLink, useFlex && flex, noLeftSpacing ? rightSpacingOnly : evenSpacing);

  return (
    <Link to={path} className={className} onClick={closeMenu}>
      {hasIcon ? (
        Object.prototype.hasOwnProperty.call(linkIcons, symbol.character.toLowerCase()) ? (
          <img
            src={linkIcons[symbol.character.toLowerCase()]}
            alt={symbol.character}
            width={iconWidths[symbol.characterType]}
          />
        ) : (
          symbol.character
        )
      ) : (
        symbol.character
      )}
    </Link>
  );
};

const withRouterPageCharacterLink: any = withRouter(PageCharacterLink as any);
export default withRouterPageCharacterLink;
