import React, { Component } from 'react';
import CharacterList from './CharacterList';
import AppsToggleHeader from '../core/AppsToggleHeader/AppsToggleHeader';
import { Container, Paper, Typography } from '@mui/material';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { Maybe } from '../core/enhancers/maybe';
const getTranslation = key => translate('apps', key);

const MaybePaperTitle = ({ application }) =>
  application === 'WoodPieces' && (
    <Typography
      role="heading"
      aria-level={1}
      style={{ padding: '32px 0 0px 42px', fontWeight: 'bold' }}
      variant="h6"
      component="h2"
    >
      {getTranslation('woodPiecesPleaseSelect')}
    </Typography>
  );
class CharacterSelector extends Component<any, any> {
  state = {
    selectedGroup: this.props.items[0],
    application: '',
    locale: '',
    focusIndex: undefined
  };

  onGroupClick = selectedGroup => {
    this.setState({ selectedGroup, focusIndex: undefined });
  };

  static getDerivedStateFromProps(props, state) {
    if (
      !state.selectedGroup ||
      props.application !== state.application ||
      props.locale !== state.locale
    ) {
      return {
        selectedGroup: props.locale !== state.locale ? null : props.items[0],
        application: props.application,
        locale: props.locale
      };
    }

    return null;
  }

  render() {
    const { items, onClickItem, styleOverrides, application } = this.props;
    const MaybePaper: any = Maybe(Paper as any);

    const handleClick = (value, idx) => {
      onClickItem(value);
      this.setState({ focusIndex: idx });
    };

    return (
      <>
        <AppsToggleHeader
          selected={this.state.selectedGroup}
          handleSelected={this.onGroupClick}
          values={items}
          application={application}
        />

        <Container maxWidth={'xl'} {...this.props?.containerStyles}>
          <MaybePaper
            shouldRender={application !== 'WDT'}
            elevation={0}
            style={{ backgroundColor: 'transparent' }}
          >
            <MaybePaperTitle application={application} />
            {this.state.selectedGroup ? (
              <CharacterList
                characters={this.state.selectedGroup.characters}
                onClickItem={handleClick}
                styleOverrides={styleOverrides}
                characterType={this.state.selectedGroup.grouping}
                application={application}
                focusIndex={this.state.focusIndex}
              />
            ) : items.length !== 0 ? (
              <CharacterList
                focusIndex={this.state.focusIndex}
                characters={items[0].characters}
                onClickItem={handleClick}
                styleOverrides={styleOverrides}
                characterType={items[0].grouping}
                application={application}
              />
            ) : (
              <div />
            )}
          </MaybePaper>
        </Container>
      </>
    );
  }
}

export default CharacterSelector;
