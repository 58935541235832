import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Button from '../Button';
import Sound from 'react-sound';
import { ltGrey } from '../../styles/style-constants';
import { tA11y } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { PageDetail } from 'types';

export type Props =
  | {
      pages: Array<PageDetail>;
      iconSize: number;
      isDouble: boolean;
      currentPage: number;
    }
  | any;

type State = {
  pages: Array<PageDetail>;
  currentPage: number;
  url: string;
  playing: boolean;
  firstRun: boolean;
};

const checkIfAudioResource = (page): boolean => {
  return (
    page &&
    page.resources &&
    page.resources.audio &&
    page.resources.audio[0] &&
    page.resources.audio[0].src
  );
};

class AudioPlayer extends Component<Props, State> {
  state = {
    pages: [],
    currentPage: 0,
    url: 'blank',
    playing: false,
    firstRun: true
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.currentPage !== state.currentPage) {
      return {
        playing: false,
        currentPage: props.currentPage
      };
    }
    if (props.pages !== state.pages) {
      if (props.pages.length === 0) {
        return {
          pages: [],
          currentPage: props.currentPage,
          url: '',
          playing: false,
          firstRun: true
        };
      }
      if (
        checkIfAudioResource(props.pages[0]) &&
        (props.isDouble || props.pages[0].pageNumber === state.currentPage)
      ) {
        return {
          pages: props.pages,
          currentPage: props.currentPage,
          url: props.pages[0].resources.audio[0].src,
          playing: true,
          firstRun: true
        };
      } else if (checkIfAudioResource(props.pages[1])) {
        return {
          pages: props.pages,
          currentPage: props.currentPage,
          url: props.pages[1].resources.audio[0].src,
          playing: true,
          firstRun: false
        };
      }
    }

    return null;
  }

  onLeftButtonClick = () => {
    this.setState({
      url: this.props.pages[0].resources.audio[0].src,
      firstRun: false,
      playing: true
    });
  };

  onRightButtonClick = () => {
    this.setState({
      url: this.props.pages[1].resources.audio[0].src,
      firstRun: false,
      playing: true
    });
  };

  onEnded = () => {
    if (this.state?.firstRun) {
      this.setState({
        url: this.props?.pages?.[1] ? this.props?.pages?.[1].resources.audio[0].src : 'null',
        firstRun: false,
        playing: this.props?.pages?.[1] ? true : false
      });
    } else {
      this.setState({
        url: 'null',
        playing: false
      });
    }
  };

  render() {
    const { currentPage, iconSize, isDouble, pages } = this.props;
    const { btnCircle, grow, leftButton, rightButton } = getStyles(45);

    let audio1 = null as any;
    let audio2 = null as any;

    if (
      pages.length > 0 &&
      pages[0].pageNumber === currentPage &&
      pages[0].resources &&
      pages[0].resources.audio
    ) {
      audio1 = pages[0].resources?.audio?.[0] as any;
    }

    if (pages.length > 1 && pages[1].resources && pages[1].resources.audio) {
      audio2 = pages[1].resources?.audio?.[0];
    } else if (
      pages.length > 0 &&
      pages[0].pageNumber === currentPage + 1 &&
      pages[0].resources?.audio
    ) {
      audio2 = pages[0].resources?.audio?.[0];
    }

    if (!currentPage) return;

    return (
      <div>
        <br />
        <br />
        <br />
        {audio1 && (
          <Button
            name={audio1.title}
            icon={audio1.type.icon}
            size={iconSize}
            onClick={this.onLeftButtonClick}
            className={isDouble ? css(leftButton, btnCircle, grow) : css(btnCircle, grow)}
            aria-label={`${audio1.title} ${tA11y('aria.replayAudioButton')}`}
          />
        )}
        {audio2 && (
          <Button
            name={audio2.title}
            icon={audio2.type.icon}
            size={iconSize}
            onClick={this.onRightButtonClick}
            className={css(rightButton, btnCircle, grow)}
            aria-label={`${audio2.title} ${tA11y('aria.replayAudioButton')}`}
          />
        )}
        <Sound
          url={this.state.url}
          playStatus={this.state.playing ? Sound.status.PLAYING : Sound.status.STOPPED}
          onFinishedPlaying={this.onEnded}
        />
      </div>
    );
  }
}

const getStyles = (size: number) =>
  StyleSheet.create({
    leftButton: {
      position: 'fixed',
      top: '75%',
      left: '0px'
    },
    rightButton: {
      position: 'fixed',
      top: '75%',
      right: '0px'
    },
    btnCircle: {
      width: size * 1.45,
      height: size * 1.45,
      margin: size * 0.15,
      backgroundColor: 'white',
      padding: 0,
      border: `${size * 0.05}px black solid`,
      ':focus': {
        backgroundColor: 'white'
      },
      ':hover': {
        backgroundColor: 'white'
      },
      borderRadius: '255px 235px 300px 215px / 225px 245px 235px 255px',
      textAlign: 'center'
    },

    grow: {
      ':enabled': {
        transition: 'all 0.2s'
      },
      ':hover': {
        transform: 'scale(1.2)'
      },
      ':focus': {
        transform: 'scale(1.2)'
      },
      ':active': {
        backgroundColor: ltGrey
      }
    }
  });
export default AudioPlayer;
