//
import React from 'react';
import { remCalc } from '../../util/style-util';

type Props = {
  fill: string;
  arrowColor: string;
  borderColor: string;
  width: number;
};

const DrawnArrowCircle = ({ fill, arrowColor, borderColor, width, ...props }: Props) => (
  <svg {...props} width={remCalc(width)} viewBox="0 0 17.5 17">
    <g transform="translate(752.5 359) rotate(180)">
      <path
        style={{ fill }}
        d="M752.4,350.5c0,0.6,0,1.2-0.2,1.9c-0.1,0.6-0.3,1.2-0.6,1.8c-0.3,0.5-0.7,1-1.1,1.5
       c-0.4,0.5-0.8,1-1.2,1.4c-0.5,0.3-1.1,0.6-1.7,0.9c-0.6,0.2-1.2,0.4-1.8,0.5c-0.6,0.1-1.2,0.2-1.9,0.2c-0.6,0-1.2-0.1-1.9-0.2
       c-0.6-0.1-1.2-0.4-1.7-0.7c-0.6-0.2-1.1-0.5-1.7-0.9c-0.5-0.4-1-0.8-1.4-1.2c-0.4-0.5-0.7-1.1-0.9-1.6c-0.4-0.5-0.7-1.1-0.9-1.7
       c-0.1-0.6-0.2-1.3-0.1-1.9c0-0.6,0.2-1.3,0.4-1.9c0.1-0.6,0.3-1.2,0.6-1.8c0.3-0.5,0.7-1,1.2-1.4c0.4-0.5,0.8-0.9,1.3-1.3
       c0.5-0.4,1-0.8,1.6-1.1c0.6-0.2,1.2-0.4,1.8-0.5c0.6-0.2,1.2-0.3,1.9-0.4c0.6,0.1,1.3,0.2,1.9,0.4c0.6,0.1,1.2,0.2,1.8,0.4
       c0.6,0.3,1.1,0.7,1.5,1.1c0.5,0.4,0.9,0.9,1.3,1.4c0.4,0.5,0.7,1,1,1.5c0.3,0.5,0.6,1.1,0.8,1.7
       C752.4,349.3,752.5,349.9,752.4,350.5z"
      />
      <path
        style={{ fill: arrowColor }}
        d="M741.6,347.8c1.1,1.3,2.7,1.8,4.1,2.7c-1,0.7-2.7,2.1-3.7,2.9c-0.6,0.5,0.2,1.3,0.8,0.9
       c1.2-0.9,3-2.4,4.2-3.3c0.3-0.2,0.4-0.5,0.2-0.8c0-0.1-0.1-0.1-0.2-0.2c-1.4-1.1-3.3-1.6-4.5-3c-0.2-0.2-0.6-0.2-0.8,0
       C741.4,347.3,741.4,347.6,741.6,347.8z"
      />
      <path
        style={{ fill: borderColor }}
        d="M749.5,344.9c-1.7-1.5-3.9-2.4-6.3-2.5c-0.7,0-1.3,0.2-1.9,0.5c-0.1,0-0.2,0-0.2,0
       c-3.6,0.7-5.7,5.6-5.4,9c0.3,3.6,3.5,6,7,6.5c3.5,0.6,7-1.1,8.6-4.3C752.8,351,752.1,347.2,749.5,344.9z M748.3,356.2
       c-3.2,1.9-7.3,1.5-10-1.1c-1.6-1.7-2.1-4.2-1.4-6.4c0.3-1.1,0.9-2.1,1.6-3c-0.2,0.4,0,1.1,0.5,0.8c0.4-0.4,0.8-0.9,1.1-1.4
       c0.8-1,2-1.6,3.2-1.6c2.1,0.2,4,1,5.6,2.3c2.7,2.5,2.9,6.7,0.5,9.4C749.1,355.6,748.7,355.9,748.3,356.2L748.3,356.2z"
      />
    </g>
  </svg>
);

DrawnArrowCircle.defaultProps = {
  fill: '#ED3838',
  arrowColor: '#FFF',
  borderColor: '#010101',
  width: 32
};

export default DrawnArrowCircle;
