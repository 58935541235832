import React, { useEffect } from 'react';
import withRouter from 'state/hooks/withRouter';
import { connect } from 'react-redux';
import flow from 'lodash/fp/flow';
import { StyleSheet, css } from 'aphrodite/no-important';
import SSOLogoutFrame from '../components/core/SSOLogoutFrame';
// import LogoutFrame from '../components/core/LogoutFrame';
import SWNotifier from '../components/SWNotifier';
import LoadingScreen from '../components/core/LoadingScreen/LoadingScreen';
import { logoutUser } from '../modules/auth';
import { getCurrentWorkbook, getUsersWorkbooks } from '../modules/workbook';
import { setCurrentWorkbook } from '../modules/workbook/workbook-actions';
import { fetchClickAways } from '../actions/resource-operations';
import { fetchCharacters } from '../modules/character-selection/operations';
import maybe from '../components/core/enhancers/maybe';
import { UserState } from '../modules/user';
import { withLoginMessaging, TLoginMessagingDispatch } from 'components/hooks/use-login-messaging';

import { A11yMain } from '@lwtears/lwt-common-frontend/lib/@a11y';

export type ReactRouterProps = {
  router: {
    params: any;
    location: Location;
    navigate: any;
  };
};

type Props = {
  setCurrentWorkbook: (value: any) => any;
  hasLoggedOut: boolean;
  location: Location;
  router: any;
  user: UserState;
  redirectToErrorRoute: boolean;
  logoutUser: () => any;
  isLoadingContent: boolean;
  showLicensingModal: (modalType: string, modalProps: any) => any;
  showCheckbox: boolean;
  currentWorkbook: any;
  getUsersWorkbooks: () => any[];
  children: any;
} & TLoginMessagingDispatch;

const SSOMaybeLogout: any = maybe(SSOLogoutFrame);

const Main = (props: Props) => {
  const {
    user,
    hasLoggedOut,
    isLoadingContent,
    currentWorkbook,
    setCurrentWorkbook,
    loginMessagingDispatch
  } = props;
  const { wrapperContainer } = styles;

  useEffect(() => {
    props.getUsersWorkbooks();
  }, []);

  useEffect(() => {
    loginMessagingDispatch.invokeModalMessaging();
  }, [user]);

  useEffect(() => {
    setCurrentWorkbook({ ...currentWorkbook, ...currentWorkbook.products?.[0] });
  }, [currentWorkbook.title]);

  return (
    <div className={css(wrapperContainer)}>
      <SWNotifier>
        <LoadingScreen show={isLoadingContent} wait={2000} />
        <A11yMain>{props.children}</A11yMain>
        <SSOMaybeLogout shouldRender={hasLoggedOut} />
      </SWNotifier>
    </div>
  );
};

const HEADER_HEIGHT = 60;

const styles = StyleSheet.create({
  wrapperContainer: {
    width: '100%',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    position: 'relative'
  }
});

const mapStateToProps = (state, { router: { params } }) => ({
  currentWorkbook: getCurrentWorkbook(state, params),
  hasLoggedOut: state.auth.hasLoggedOut,
  isLoadingContent: state.ui.isLoadingContent,
  redirectToErrorRoute: state.error.redirectToErrorRoute,
  user: state.user
});

const withRedux = connect(mapStateToProps, {
  logoutUser,
  fetchClickAways,
  fetchCharacters,
  setCurrentWorkbook,
  getUsersWorkbooks
});

export default flow(withRedux, withRouter, withLoginMessaging)(Main);
