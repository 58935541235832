import React from 'react';
import clsx from 'clsx';
import { useTheme, Drawer, Typography, Divider, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import resourcesIconPng from 'images/icons/resources-white.png';
import { useResources, DRAWER_WIDTH } from 'state/providers/ResourceProvider';
import { useFullScreen } from 'state/providers/FullScreenProvider';
import { remCalc } from 'util/style-util';
import { PREKIT_FONT } from 'styles/style-constants';

const ICON_SIZE = 40;

const t = key => translate('resources', key);

const ResourcesSlider = ({ workbook, handleSelectResource }) => {
  const [{ isOpen, resources }, { setIsOpen }] = useResources();
  const [{ isFullScreen }] = useFullScreen();
  const classes = useStyles()({ isFullScreen, workbook });

  const theme = useTheme();

  // const categories = Object.keys(resources);

  // const [expanded, setExpanded] = React.useState<string[]>(categories);

  // useEffect(() => {
  //   setExpanded(categories);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [categories.length]);

  // const handleChange = (panel: string) => (e: any, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? [...expanded, panel] : [...expanded.filter(e => e !== panel)]);
  // };

  const handleClick = resource => {
    handleSelectResource(resource);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={isOpen}
      PaperProps={{ elevation: 5 }}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <img src={resourcesIconPng} className={classes.iconImage} alt={t('title')} />
        <Typography
          variant="h4"
          style={{
            padding: '5px 0 0 15px',
            fontFamily: PREKIT_FONT,
            fontSize: '24px',
            fontWeight: 'bold'
          }}
        >
          {t('title')}
        </Typography>
        <IconButton
          onClick={() => setIsOpen(false)}
          size="small"
          style={{ color: 'black', padding: remCalc(12, 12, 6, 12) }}
        >
          {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
      <Divider />
      {!resources.length && (
        <Typography className={classes.text} style={{ padding: '30px' }}>
          {t('noResources')}
        </Typography>
      )}
      <div>
        {/* {categories.map(category => { */}
        {/* return ( */}
        {/* <Accordion
              key={category}
              expanded={expanded.includes(category)}
              onChange={handleChange(category)}
              className={classes.accordion}
              square
            >
              <AccordionSummary aria-controls={`${category}-content`} className={classes.summary}>
                <div className={classes.categoryHeader}>
                  {category}
                  <IconButton
                    onClick={() => handleChange(category)}
                    size="small"
                    style={{ color: workbook.textColor, padding: remCalc(12) }}
                  >
                    <FontAwesomeIcon icon={expanded.includes(category) ? faArrowUp : faArrowDown} />
                  </IconButton>
                </div>
              </AccordionSummary> */}
        {/* <AccordionDetails className={classes.details}> */}
        {resources.map(resource => (
          <div key={resource.id} className={classes.detailsContentWrapper}>
            <Button key={resource.id} onClick={() => handleClick(resource)}>
              <div className={classes.contentWrapper}>
                <div className={classes.topContent}>
                  <img
                    src={resource.type.icon}
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                    alt={resource.title}
                  />
                  <div className={classes.text} style={{ width: '80%', justifyContent: 'left' }}>
                    {resource.title}
                  </div>
                </div>
                {resource.description && (
                  <div className={clsx(classes.text, classes.descriptionText)}>
                    {resource.description}
                  </div>
                )}
              </div>
            </Button>
          </div>
        ))}
        {/* </AccordionDetails> */}
        {/* </Accordion> */}
        {/* ); */}
        {/* // })} */}
      </div>
    </Drawer>
  );
};

export default React.memo(ResourcesSlider);

const useStyles = () =>
  makeStyles(() => ({
    drawer: {
      transition: '0.5s'
    },
    drawerPaper: ({ isFullScreen }: any) => ({
      height: `calc(100% - ${isFullScreen ? 150 : 75}px) !important`,
      width: DRAWER_WIDTH,
      top: `${isFullScreen ? remCalc(60) : 0} !important`,
      overflowY: 'auto',
      overflowX: 'hidden'
    }),
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      minHeight: remCalc(60),
      height: remCalc(60),
      transition: '0.5s',
      fontFamily: PREKIT_FONT
    },
    categoryHeader: {
      lineHeight: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: PREKIT_FONT
    },
    contentWrapper: {
      display: 'flex',
      width: DRAWER_WIDTH - 15,
      flexDirection: 'column'
    },
    topContent: {
      display: 'flex',
      width: DRAWER_WIDTH - 15,
      flexDirection: 'row',
      alignItems: 'center'
    },
    iconImage: {
      height: remCalc(40)
    },
    text: {
      whiteSpace: 'normal',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'left',
      alignItems: 'center',
      padding: '2px 2px 2px 10px',
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: PREKIT_FONT
    },
    descriptionText: {
      paddingTop: remCalc(15),
      fontSize: remCalc(14),
      fontWeight: '300',
      fontFamily: PREKIT_FONT
    },
    accordion: ({ workbook }: any) => ({
      backgroundColor: `${workbook.primaryColor} !important`,
      color: `${workbook.textColor} !important`,
      borderBottom: '1px solid white',
      '&.Mui-expanded': {
        margin: '0 !important',
        borderBottom: 'unset'
      }
    }),
    summary: {
      height: remCalc(55),
      padding: '5px 15px',
      fontFamily: PREKIT_FONT
    },
    details: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: `0px !important`
    },
    detailsContentWrapper: {
      borderBottom: '1px solid black',
      height: 'max-content',
      padding: '15px 0px',
      backgroundColor: 'white',
      width: '100%',
      '& .MuiButton-text': {
        textTransform: 'unset',
        backgroundColor: 'white',
        color: 'black',
        width: DRAWER_WIDTH,
        height: '100%',
        lineHeight: remCalc(21),
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: '0px',
        '&:focus': {
          backgroundColor: 'white !important',
          color: 'black'
        },
        '&:hover': {
          color: 'black',
          backgroundColor: 'white !important',
          transform: 'scale(1.02, 1.02)'
        }
      }
    }
  }));
