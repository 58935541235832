import React from 'react';

export const DraggableIframeWrapper = ({ children }) => {
  return (
    <div
      id="player"
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        padding: '10px 11px',
        height: 'fit-content'
      }}
    >
      {children}
    </div>
  );
};
