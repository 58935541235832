import {
  fetchUserSettings,
  fetchUserSettingsSuccess,
  fetchUserSettingsFail,
  putUserSettings,
  putUserSettingsSuccess,
  putUserSettingsFail,
  putClickAwayAccess,
  putClickAwayAccessSuccess,
  putClickAwayAccessFail,
  putCopyrightTerms,
  putCopyrightTermsSuccess,
  putCopyrightTermsFail
} from './actions';
import PreKittApi from '../../api';
import type {
  UserSetting,
  UserSettingTypes,
  EmailConfig,
  BookCode,
  ProductCopyrightTerms
} from 'types';
import { Dispatch, GetState, ThunkAction } from './types';
import { getDeviceCompatibility } from '../../actions/ui-actions';
import { getTours } from '../ftue';
import { getUser } from '../auth';
import { getUsersWorkbooks } from '../workbook';

export const fetchUser = () => async dispatch => {
  const response = await dispatch(getUser());

  dispatch(getDeviceCompatibility());

  if (response?.user) {
    if (response.user.licenses) {
      const hasWorkbooks = response.user.licenses.filter(license => !license.expired).length > 0;
      if (hasWorkbooks) {
        await dispatch(getUsersWorkbooks());
      }
    }
    if (response.user.settings) {
      await dispatch(getTours());
    }
  }

  return response;
};

export function getUserSettings() {
  return async (dispatch: Dispatch, _: GetState, api: typeof PreKittApi) => {
    dispatch(fetchUserSettings());
    try {
      const response = await api.getUserSettings();

      return dispatch(fetchUserSettingsSuccess(response.data));
    } catch (err) {
      dispatch(fetchUserSettingsFail(err));
    }
  };
}

export function saveUserSettings(type: UserSettingTypes, newSetting: UserSetting): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState, api: typeof PreKittApi) => {
    const settingsForType = {
      [type]: newSetting
    };

    dispatch(putUserSettings(settingsForType));

    try {
      await api.putUserSettings(type, settingsForType);

      return dispatch(putUserSettingsSuccess());
    } catch (err) {
      dispatch(putUserSettingsFail(err));
    }
  };
}

export function saveClickAwayAccess(bookCode: BookCode, edition: number) {
  return async (dispatch: Dispatch, _: GetState, api: typeof PreKittApi) => {
    dispatch(putClickAwayAccess(bookCode, edition));
    try {
      const response = await api.putUserClickAwayAccess(bookCode, edition);

      return dispatch(putClickAwayAccessSuccess(response.data) as any);
    } catch (err) {
      dispatch(putClickAwayAccessFail(err));
    }
  };
}

export function saveProductAccess(bookId: number) {
  return async (dispatch: Dispatch, _: GetState, api: typeof PreKittApi) => {
    try {
      await api.putProductAccess(bookId);
    } catch (err) {
      // don't think this warrants a redirect to oops page, so swallow
    }
  };
}

export function saveCopyrightTerms(productCopyrightTerms: ProductCopyrightTerms) {
  return async (dispatch: Dispatch, _: GetState, api: typeof PreKittApi) => {
    dispatch(putCopyrightTerms(productCopyrightTerms));

    try {
      await api.putCopyrightTerms(productCopyrightTerms);
      const response = await api.getCopyrightTerms();

      return dispatch(putCopyrightTermsSuccess(response.data) as any);
    } catch (err) {
      dispatch(putCopyrightTermsFail(err));
    }
  };
}

export function sendEmail(config: EmailConfig) {
  return (dispatch: Dispatch, _: GetState, api: typeof PreKittApi): void => {
    api.sendEmail(config);
  };
}

export function sendEmailLink() {
  return (dispatch: Dispatch, _: GetState, api: typeof PreKittApi): void => {
    api.sendEmailLink();
  };
}

export function sendEmailWelcome() {
  return (dispatch: Dispatch, _: GetState, api: typeof PreKittApi): void => {
    api.sendEmailWelcome();
  };
}
