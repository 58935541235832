//
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PageCharacterLink from './PageCharacterLink';
import { remCalc } from '../../util/style-util';
import { darkGray, lightGray } from '../../styles/style-constants';

type Props = {
  title: string;
  characters: { character: string; characterType: string; pageNumber: number | number[] }[];
  color: string;
  closeMenu: () => any;
  showBottomBorder: boolean;
  multiColumn: boolean;
  isCursive: boolean;
  hasIcon: boolean;
};

export const getStyles = (multiColumn: boolean, showBottomBorder: boolean, isCursive: boolean) =>
  StyleSheet.create({
    pageCategory: {
      ...(showBottomBorder && { borderBottom: showBottomBorder && `1px solid ${lightGray}` }),
      paddingBottom: 20,
      textAlign: multiColumn ? 'left' : 'center'
    },
    categoryTitle: {
      fontSize: remCalc(16),
      color: darkGray,
      marginBottom: remCalc(24),
      whiteSpace: 'nowrap'
    },
    categoryCharacters: {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: multiColumn ? 'flex-start' : 'center',
      fontFamily: isCursive ? 'GSS Cursive Beginner Book' : 'inherit'
    }
  });

const PageCategory = ({
  title,
  characters,
  color,
  closeMenu,
  showBottomBorder,
  multiColumn,
  isCursive,
  hasIcon
}: Props) => {
  const { pageCategory, categoryTitle, categoryCharacters } = getStyles(
    multiColumn,
    showBottomBorder,
    isCursive
  );

  return (
    <li className={css(pageCategory)}>
      <h3 className={css(categoryTitle)}>{title}</h3>
      <div className={css(categoryCharacters)}>
        {characters.map(symbol => (
          <PageCharacterLink
            color={color}
            handleClick={closeMenu}
            key={symbol.character}
            symbol={symbol}
            closeMenu={closeMenu}
            noLeftSpacing={multiColumn}
            useFlex={false}
            hasIcon={hasIcon}
          />
        ))}
      </div>
    </li>
  );
};

export default PageCategory;
