import flow from 'lodash/fp/flow';
import Config from 'config';
import { getMSDiffEnding, getTimeFromMilliseconds } from '../../util/date-util';
import { EmailConfig, BadgeType, License } from 'types';
import { UserState } from './types';

type SupportEmailOptions = {
  email: string;
  name: string;
  message: string;
};

const { supportEmail } = Config;
export const isTrialUser = (user: UserState) => !!findFirstTrialLicenseExpiry(user);

export function isClickAwayUser(user: UserState): boolean {
  let license;
  for (license of user.licenses) {
    if (license.clickAwayAccess) {
      return true;
    }
  }

  return false;
}

export function findFirstTrialLicenseExpiry(user: UserState): string | null {
  let license;
  for (license of user.licenses) {
    if (license.licenseTypeId === 6) {
      return license.dateExpiration;
    }
  }

  return null;
}

export function getTrialDaysRemaining(user: UserState, now?: Date): number {
  return flow(
    findFirstTrialLicenseExpiry,
    getMSDiffEnding(now || new Date()),
    getTimeFromMilliseconds('days')
  )(user);
}

export const getBadgeType = (licenses: Array<License>): BadgeType => {
  let license;
  for (license of licenses) {
    if (!license.expired && Object.prototype.hasOwnProperty.call(license, 'dateExpiration')) {
      return license.licenseTypeId === 6 ? 'freeTrial' : null;
    }
    if (license.clickAwayAccess) {
      return 'clickAway';
    }

    return null;
  }

  return null;
};

export function buildLinkEmail(recipient: string): EmailConfig {
  return {
    recipient,
    template: 'prekittLink'
  };
}

export function buildSupportEmail({ email, name, message }: SupportEmailOptions): EmailConfig {
  return {
    recipient: supportEmail,
    senderEmail: email,
    senderName: name,
    message,
    subject: 'PreKITT Application'
  };
}
