import { Locale, CharacterCase, CharacterType, Resource } from 'types';

export type CharacterSelectionState = {
  selectedCharacter: {
    characterCase: CharacterCase | '';
    characterType: CharacterType | '';
    character: string | '';
    data: any;
    url: string;
  };
  locale: Locale;
  showAppModal: boolean;
  showLoadingScreen: boolean;
  allCharacters: any[];
  app: string;
};

export const FETCH_CHARACTERS_REQUEST = 'FETCH_CHARACTERS_REQUEST';
export type FETCH_CHARACTERS_REQUEST_ACTION = {
  type: typeof FETCH_CHARACTERS_REQUEST;
  application: string;
};

export const FETCH_CHARACTERS_SUCCESS = 'FETCH_CHARACTERS_SUCCESS';
export type FETCH_CHARACTERS_SUCCESS_ACTION = {
  type: typeof FETCH_CHARACTERS_SUCCESS;
  characters: any;
};

export const FETCH_CHARACTERS_FAILURE = 'FETCH_CHARACTERS_FAILURE';
export type FETCH_CHARACTERS_FAILURE_ACTION = {
  type: typeof FETCH_CHARACTERS_FAILURE;
  error: Error;
};

export const SELECT_CHARACTER = 'SELECT_CHARACTER';
export type SELECT_CHARACTER_ACTION = { type: typeof SELECT_CHARACTER; character: Resource };

export const CHANGE_APP_LOCALE = 'CHANGE_APP_LOCALE';
export type CHANGE_APP_LOCALE_ACTION = { type: typeof CHANGE_APP_LOCALE; locale: Locale };

export const TOGGLE_APP_MODAL_VISIBILITY = 'TOGGLE_APP_MODAL_VISIBILITY';
export type TOGGLE_APP_MODAL_VISIBILITY_ACTION = { type: typeof TOGGLE_APP_MODAL_VISIBILITY };

export const TOGGLE_APP_LOADING_SCREEN_VISIBILITY = 'TOGGLE_APP_LOADING_SCREEN_VISIBILITY';
export type TOGGLE_APP_LOADING_SCREEN_VISIBILITY_ACTION = {
  type: typeof TOGGLE_APP_LOADING_SCREEN_VISIBILITY;
};

export type CharacterSelectionAction =
  | CHANGE_APP_LOCALE_ACTION
  | SELECT_CHARACTER_ACTION
  | TOGGLE_APP_MODAL_VISIBILITY_ACTION
  | TOGGLE_APP_LOADING_SCREEN_VISIBILITY_ACTION
  | FETCH_CHARACTERS_SUCCESS_ACTION
  | FETCH_CHARACTERS_REQUEST_ACTION;

/* eslint-disable no-use-before-define */
type GetState = () => { characterSelectionTest: CharacterSelectionState };
type PromiseAction = Promise<CharacterSelectionAction>;
export type Dispatch = (
  action: CharacterSelectionAction | ThunkAction | PromiseAction | Array<CharacterSelectionAction>
) => any;
export type ThunkAction = (dispatch: Dispatch, getState: GetState) => any;
