import React, { PureComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import Transition from 'react-transition-group/Transition';
import { connect } from 'react-redux';
import withRouter from 'state/hooks/withRouter';
import { StyleSheet, css } from 'aphrodite/no-important';
import SetupPage from './SetupPage';
import { getLimitedLicensedWorkbooks, getFullLicensedWorkbooks } from '../../modules/workbook';
import LimitedAccessWorkbook from './LimitedAccessWorkbook';
import FullAccessWorkbook from './FullAccessWorkbook';
import maybe from '../core/enhancers/maybe';
import { remCalc } from '../../util/style-util';
import PreKittMessageFrame from '../../components/core/Modals/PreKittMessageFrame';
import WorkbooksMenu from '../../components/AppMenu/WorkbooksMenu';
import WorkbookSelectorHeader from './WorkbookSelectorHeader';
import { black } from '../../styles/style-constants';

import { AppState } from '../../reducers/index';
import { ReactRouterProps } from '../../containers/Main';
import { UserState } from '../../modules/user';

import { Workbook } from 'types';

type Props = {
  limitedAccessWorkbooks: Array<Workbook>;
  fullLicensedWorkbooks: Array<Workbook>;
  redirectToErrorRoute: boolean;
  user: UserState;
} & ReactRouterProps;

const transitionStyles = {
  entering: {
    opacity: 0,
    transform: 'translateY(-50px)'
  },
  entered: {
    opacity: 1,
    transform: 'translateY(0)'
  }
};

const duration = 500;

const MaybeFullAccessWorkbook: any = maybe(FullAccessWorkbook);
const MaybeLimitedAccessWorkbook: any = maybe(LimitedAccessWorkbook);

class WorkbookSelectorPanel extends PureComponent<Props> {
  componentDidMount() {
    if (this.props.redirectToErrorRoute) {
      this.props.router.navigate('/oops');
    }
  }

  componentDidUpdate() {
    if (this.props.redirectToErrorRoute) {
      this.props.router.navigate('/oops');
    }
  }

  renderWorkbooks(workbooks: Array<Workbook>): any {
    const {
      workbookItemLarge,
      workbookItemMedium,
      workbookItemSmall,
      workbookTitle,
      gradeLevelDescription
    } = styles;
    const allWorkbooksWithSameLicenseType =
      isEmpty(this.props.limitedAccessWorkbooks) || isEmpty(this.props.fullLicensedWorkbooks);

    return (
      <WorkbooksMenu
        show
        workbooks={workbooks}
        licenses={this.props.user.licenses}
        userId={this.props.user.id}
        itemStyles={{
          workbookItemLarge,
          workbookItemMedium,
          workbookItemSmall,
          workbookTitle,
          gradeLevelDescription
        }}
        allWorkbooksWithSameLicenseType={allWorkbooksWithSameLicenseType}
      />
    );
  }

  render() {
    const { container, panel } = styles;
    const { limitedAccessWorkbooks, fullLicensedWorkbooks } = this.props;

    const workbookLocales = new Set(
      fullLicensedWorkbooks.concat(limitedAccessWorkbooks).map(workbook => workbook.locale)
    );
    const workbookLocale = workbookLocales.values().next();
    const textLocale =
      workbookLocales.size === 1 ? workbookLocale && workbookLocale.value : 'en-US';

    return (
      <SetupPage>
        <div className={css(container)}>
          <Transition in appear={true} timeout={duration}>
            {state => (
              <PreKittMessageFrame
                position="relative"
                classList={panel}
                style={{
                  ...transitionStyles[state],
                  transition: `all ${duration}ms ease-in-out`
                }}
              >
                <WorkbookSelectorHeader textLocale={textLocale} />
                <MaybeFullAccessWorkbook shouldRender={!isEmpty(fullLicensedWorkbooks)}>
                  {this.renderWorkbooks(fullLicensedWorkbooks)}
                </MaybeFullAccessWorkbook>
                <MaybeLimitedAccessWorkbook
                  shouldRender={!isEmpty(this.props.limitedAccessWorkbooks)}
                  textLocale={textLocale}
                >
                  {this.renderWorkbooks(limitedAccessWorkbooks)}
                </MaybeLimitedAccessWorkbook>
              </PreKittMessageFrame>
            )}
          </Transition>
        </div>
      </SetupPage>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: '1 0 auto',
    padding: remCalc(0, 50),
    textAlign: 'center'
  },
  panel: {
    margin: remCalc(60, 'auto'),
    width: '75%',
    maxWidth: remCalc(1200),
    padding: remCalc(5),
    zIndex: 0
  },
  workbookItemLarge: {
    flexBasis: '28%',
    margin: '0 auto',
    maxWidth: remCalc(500),
    padding: remCalc(10, 12)
  },
  workbookItemMedium: {
    flexBasis: '20%'
  },
  workbookItemSmall: {
    flexBasis: '18%'
  },
  workbookTitle: {
    color: black
  },
  gradeLevelDescription: {
    color: black,
    display: 'none'
  }
});

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  limitedAccessWorkbooks: getLimitedLicensedWorkbooks(state),
  fullLicensedWorkbooks: getFullLicensedWorkbooks(state),
  redirectToErrorRoute: state.error.redirectToErrorRoute
});

const withRouterConnectedWorkbookSelectorPanel = withRouter(
  connect(mapStateToProps)(WorkbookSelectorPanel) as any
);
export default withRouterConnectedWorkbookSelectorPanel;
